import axios, { AxiosError, AxiosRequestConfig } from 'axios'
import { constants } from 'config'

const clientRest = axios.create({
  baseURL: constants.URL_API + 'api/v1/',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': 'X-Requested-With',
  },
})

clientRest.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error.response.status === 401) {
      const requestConfig = error.config
      return axios(requestConfig)
    }
    return Promise.reject(error)
  },
)

clientRest.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    return config
  },
  (error: AxiosError) => {
    return Promise.reject(error)
  },
)

export default clientRest
