import {
  Alert,
  Box,
  Breadcrumbs,
  Grid,
  Link,
  Paper,
  Typography,
} from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { Loading } from 'components'
import { usePromoter } from 'contexts/promoterContext'
import dayjs from 'dayjs'
import { useCallback, useEffect, useState } from 'react'
import { Link as RouterLink, useParams } from 'react-router-dom'
import clientPromoter from 'services/api/v1/clientPromoter'
import { IPedido } from 'types/pedido'
import { datagrid } from 'utils'
import { getErrorMessage } from 'utils/AppError'
import { moneyFormatter } from 'utils/formatter'

export function StaffMemberSalesList() {
  const { eventId, userStaffId } = useParams()
  const { selectedEvent } = usePromoter()

  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState('')
  const [orders, setOrders] = useState<IPedido[]>([])

  const fetchOrders = useCallback(async () => {
    try {
      setIsLoading(true)
      const response = await clientPromoter.get(
        `promoter/events/${eventId}/staff/${userStaffId}/orders`,
      )
      setOrders(response.data)
    } catch (error) {
      setError(getErrorMessage(error))
    } finally {
      setIsLoading(false)
    }
  }, [eventId, userStaffId])

  useEffect(() => {
    fetchOrders()
  }, [fetchOrders])

  const columns: GridColDef[] = [
    { field: 'codigo', headerName: 'Código', width: 150 },
    {
      field: 'data',
      headerName: 'Data da venda',
      renderCell: (values) => dayjs(values.value).format('DD/MM/YYYY HH:mm'),
      width: 150,
    },
    {
      field: 'usuario.nome',
      headerName: 'Comprador',
      renderCell: (cell) =>
        [cell.row.usuario.nome, cell.row.usuario.sobrenome]
          .join(' ')
          .trim()
          .toUpperCase(),
      flex: 1,
    },
    {
      field: 'metodo_pagamento',
      headerName: 'Forma de pagamento',
      renderCell: (cell) => cell.row.metodo_pagamento.descricao,
      width: 150,
    },
    {
      field: 'valor_ingressos',
      headerName: 'Valor',
      headerAlign: 'right',
      renderCell: (cell) => moneyFormatter.format(cell.value),
      align: 'right',
      width: 150,
    },
  ]

  return (
    <Grid container spacing={2} p={2} component={Paper}>
      <Grid item xs={12}>
        <Box role="presentation">
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              component={RouterLink}
              underline="hover"
              color="inherit"
              to="/organizador/eventos"
            >
              Eventos
            </Link>
            <Link
              component={RouterLink}
              underline="hover"
              color="inherit"
              to={`/organizador/eventos/${eventId}`}
            >
              {selectedEvent?.titulo}
            </Link>
            <Link
              component={RouterLink}
              underline="hover"
              color="inherit"
              to={`/organizador/eventos/${eventId}/staff`}
            >
              Staff
            </Link>
            <Link
              component={RouterLink}
              underline="hover"
              color="inherit"
              to={`/organizador/eventos/${eventId}/staff/${userStaffId}`}
            >
              Detalhes
            </Link>
            <Typography color="text.primary">Lista de vendas</Typography>
          </Breadcrumbs>
        </Box>
      </Grid>
      {error && (
        <Grid item xs={12}>
          <Alert severity="error" onClose={() => setError('')}>
            {error}
          </Alert>
        </Grid>
      )}
      <Grid item xs={12} mb={2}>
        <Typography variant="h4">Lista de vendas</Typography>
      </Grid>
      {isLoading ? (
        <Box justifyContent="center" width="100%" alignItems="center">
          <Loading />
        </Box>
      ) : (
        <DataGrid
          rows={orders}
          columns={columns}
          autoHeight
          localeText={datagrid.localizedTextsMap}
        />
      )}
    </Grid>
  )
}
