import * as React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Toolbar } from '@mui/material'
import { AxiosError } from 'axios'

import { Loading } from 'components'
import { apiV1 } from 'services'
import { ILocationProps } from 'types/locationProps'
import { IUsuario } from 'types/usuario'
import { functionWithLoading } from 'utils'

type MessageType = 'SUCCESS' | 'ERROR'

type MessageProps = {
  message: string
  type?: MessageType
}

type AdminContextType = {
  admin: IUsuario
  login: (username: string, password: string) => void
  logout: () => void
  loading: boolean
  errorMessage: string
  addErrorMessage: (message: string) => void
  closeError: (index: number) => void
  errors: string[]

  messages: MessageProps[]
  addMessage: (message: MessageProps) => void
  removeMessage: (index: number) => void
}

export const AdminContext = React.createContext<AdminContextType | null>(null)

export const LogoutAdmin = () => {
  localStorage.removeItem('admin')
  localStorage.removeItem('admin_token')
  window.location.reload()
}

const AdminProvider: React.FC<React.ReactNode> = ({ children }) => {
  const [admin, setAdmin] = React.useState<IUsuario>({} as IUsuario)
  const [loading, setLoading] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState('')
  const [errors, setErrors] = React.useState<string[]>([])
  const [messages, setMessages] = React.useState<MessageProps[]>([])

  const navigate = useNavigate()
  const location = useLocation() as ILocationProps

  const login = (username: string, password: string) => {
    functionWithLoading(
      apiV1.admin.adminService
        .login(username, password)
        .then((res) => {
          if (res.data.auth) {
            localStorage.setItem('admin', JSON.stringify(res.data.usuario))
            localStorage.setItem('admin_token', res.data.token)
            setAdmin(res.data.usuario)
            setErrorMessage('')
          } else {
            setErrorMessage('Usuário ou senha incorretos!')
          }
        })
        .catch((error: AxiosError) =>
          setErrorMessage(error.response?.data.message ?? error.message),
        ),
      setLoading,
    )
    const origin = location.state?.from?.pathname || '/'
    navigate(origin)
  }

  const logout = () => {
    LogoutAdmin()
  }

  const addErrorMessage = React.useCallback((message: string) => {
    setErrors((prevState) => [...prevState, message])
  }, [])

  const closeError = React.useCallback((index) => {
    setErrors((prevState) => prevState.filter((_, i) => i !== index))
  }, [])

  const addMessage = React.useCallback((message: MessageProps) => {
    setMessages((prevState) => [...prevState, message])
  }, [])

  const removeMessage = React.useCallback((index) => {
    setMessages((prevState) => prevState.filter((_, i) => i !== index))
  }, [])

  if (loading) return <Loading />

  return (
    <AdminContext.Provider
      value={{
        admin,
        login,
        logout,
        loading,
        errorMessage,
        addErrorMessage,
        closeError,
        errors,

        messages,
        addMessage,
        removeMessage,
      }}
    >
      <Toolbar />
      {children}
    </AdminContext.Provider>
  )
}

const ERROR_MSG_PROVIDER_NEEDED = 'useAdmin must be used within a AdminProvider'

export const useAdmin = () => {
  const context = React.useContext(AdminContext)
  if (!context) {
    throw new Error(ERROR_MSG_PROVIDER_NEEDED)
  }
  return context
}

export default AdminProvider
