import LockResetIcon from '@mui/icons-material/LockReset'
import { LoadingButton } from '@mui/lab'
import {
  Alert,
  Button,
  Container,
  Grid,
  Paper,
  Typography,
} from '@mui/material'
import { FormItem } from 'components'
import { useFormik } from 'formik'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { apiV1 } from 'services'
import { functionWithLoading } from 'utils'
import { getErrorMessage } from 'utils/AppError'
import * as yup from 'yup'

const validationSchema = yup.object({
  id: yup.string().min(1).required('É necessário preencher o id').nullable(),
  hash: yup
    .string()
    .min(1)
    .required('É necessário preencher a hash')
    .nullable(),
  newPassword: yup
    .string()
    .min(8, 'A senha precisa ter no mínimo 8 caracteres')
    .required('É necessário preencher a senha')
    .nullable(),
  confirmNewPassword: yup
    .string()
    .oneOf([yup.ref('newPassword'), null], 'As senhas devem ser iguais')
    .nullable(),
})

const RecoverPasswordPage: React.FC = () => {
  const { id, hash } = useParams()
  const [loading, setLoading] = React.useState(false)
  const [success, setSuccess] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState('')
  const navigate = useNavigate()

  const formik = useFormik({
    initialValues: { id, hash, newPassword: '', confirmNewPassword: '' },
    enableReinitialize: true,
    validateOnChange: true,
    validationSchema,
    onSubmit: (values) => {
      functionWithLoading(
        apiV1.userService
          .recoverPassword(values)
          .then(() => {
            setSuccess(true)
          })
          .catch((err) => {
            setErrorMessage(getErrorMessage(err))
          }),
        setLoading,
      )
    },
  })

  if (success)
    return (
      <Container maxWidth="md" sx={{ my: 2 }}>
        <Paper sx={{ p: 2 }}>
          <Typography variant="h4">Prontinho!</Typography>
          <Typography variant="body1" sx={{ mt: 2 }}>
            Sua senha foi alterada! Clique no botão abaixo para entrar com sua
            nova senha!
          </Typography>
          <Button variant="contained" onClick={() => navigate('/login')}>
            Entrar
          </Button>
        </Paper>
      </Container>
    )

  return (
    <Container maxWidth="md" sx={{ my: 2 }}>
      <Paper
        sx={{ p: 2 }}
        component="form"
        onSubmit={formik.handleSubmit}
        noValidate
      >
        <Typography variant="h4">Vamos criar uma nova senha?</Typography>
        <Typography variant="caption" sx={{ mt: 2 }}>
          Crie uma nova senha e confirme ela!
        </Typography>
        {errorMessage && (
          <Alert severity="error" onClose={() => setErrorMessage('')}>
            {errorMessage}
          </Alert>
        )}
        <Container maxWidth="xs">
          <Grid container spacing={2} my={2}>
            <Grid item xs={12}>
              <FormItem.TextField
                variant="outlined"
                label="Senha"
                name="newPassword"
                value={formik.values.newPassword}
                error={
                  formik.touched.newPassword &&
                  Boolean(formik.errors.newPassword)
                }
                helperText={
                  formik.touched.newPassword && formik.errors.newPassword
                }
                onChange={formik.handleChange}
                type="password"
              />
            </Grid>
            <Grid item xs={12}>
              <FormItem.TextField
                label="Confirmar senha"
                name="confirmNewPassword"
                value={formik.values.confirmNewPassword}
                error={
                  formik.touched.confirmNewPassword &&
                  Boolean(formik.errors.confirmNewPassword)
                }
                helperText={
                  formik.touched.confirmNewPassword &&
                  formik.errors.confirmNewPassword
                }
                onChange={formik.handleChange}
                type="password"
              />
            </Grid>
            <Grid item xs={12} sx={{ textAlign: 'center' }}>
              <LoadingButton
                loading={loading}
                loadingPosition="start"
                variant="contained"
                type="submit"
                disabled={loading}
                startIcon={<LockResetIcon />}
                size="large"
              >
                Recuperar minha senha
              </LoadingButton>
            </Grid>
          </Grid>
        </Container>
      </Paper>
    </Container>
  )
}

export default RecoverPasswordPage
