/* eslint-disable react/display-name */
import { Visibility, VisibilityOff } from '@mui/icons-material'
import {
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
} from '@mui/material'
import { forwardRef, useState } from 'react'

const Password: React.FC<TextFieldProps> = forwardRef(
  ({ InputProps, ...rest }, ref) => {
    const [showPassword, setShowPassword] = useState(false)

    const handleClickShowPassword = () => setShowPassword(!showPassword)

    return (
      <TextField
        ref={ref}
        variant="outlined"
        fullWidth
        type={showPassword ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
          ...InputProps,
        }}
        {...rest}
      />
    )
  },
)

export default Password
