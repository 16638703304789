import PartnerProvider from 'contexts/partnerContext'
import { Outlet, Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { NoMatch } from 'components'
import {
  EventList,
  EventStaff,
  EventStaffDetails,
  LoginPage,
} from 'pages/partner'
import { PartnerLayout } from 'layout'

const ProtectedRoute = () => {
  const location = useLocation()
  const partner = localStorage.getItem('partner')
  const token = localStorage.getItem('partner_token')

  if (!token && !partner) {
    return <Navigate to="/parceiro/login" replace state={{ from: location }} />
  }

  return (
    <PartnerLayout>
      <Outlet />
    </PartnerLayout>
  )
}

const PartnerRouter = () => {
  return (
    <PartnerProvider>
      <Routes>
        <Route path="/" element={<ProtectedRoute />}>
          <Route index element={<EventList />} />
          <Route path="eventos" element={<EventList />} />
          <Route path="eventos/:eventId" element={<EventStaff />} />
          <Route
            path="eventos/:eventId/equipe/:partnerTeamId"
            element={<EventStaffDetails />}
          />
        </Route>

        <Route path="login" element={<LoginPage />} />
        <Route path="*" element={<NoMatch />} />
      </Routes>
    </PartnerProvider>
  )
}

export default PartnerRouter
