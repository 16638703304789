import { zodResolver } from '@hookform/resolvers/zod'
import CancelIcon from '@mui/icons-material/Cancel'
import PriceCheckIcon from '@mui/icons-material/PriceCheck'
import { LoadingButton } from '@mui/lab'
import {
  Alert,
  AlertTitle,
  Box,
  Chip,
  Grid,
  LinearProgress,
  Paper,
  Skeleton,
  TextField,
} from '@mui/material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { Info } from '@phosphor-icons/react'
import { BootstrapTooltip } from 'components/BootstrapTooltip'
import { Typography } from 'components/Typography'
import { useAdmin } from 'contexts/adminContext'
import dayjs from 'dayjs'
import { SaqueDTO } from 'dto/SaqueDTO'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useParams } from 'react-router-dom'
import { apiV1 } from 'services'
import { getErrorMessage } from 'utils/AppError'
import { moneyFormatter } from 'utils/formatter'
import { z } from 'zod'

type ResumeType = {
  digitalSalesSummary: {
    valor_vendas: number
    valor_bloqueado: number
    saques_aprovados: number
    percentual_adiantamento: number
    dias_retidos_saque: number
    taxa_produtor: number
  }
  physicalSalesSummary: {
    valor_vendas: number
    valor_bloqueado: number
    saques_aprovados: number
    percentual_adiantamento: number
    dias_retidos_saque: number
  }
  responsible: {
    name: string
    taxId: string
  }
  eventIsClosed: boolean
  checkInInfos: {
    checkIns: number
    total: number
  }
  withdrawals: SaqueDTO[]
}

const schema = z.object({
  observacoes: z.string(),
})

type WithdrawalSchemaFormType = z.infer<typeof schema>

export function Withdrawal() {
  const [isLoading, setIsLoading] = useState(false)
  const [isRequesting, setIsRequesting] = useState(false)
  const [withdrawal, setWithdrawal] = useState<SaqueDTO>({} as SaqueDTO)
  const [resume, setResume] = useState<ResumeType>({} as ResumeType)

  const { addErrorMessage } = useAdmin()
  const { withdrawalId } = useParams()

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<WithdrawalSchemaFormType>({
    resolver: zodResolver(schema),
    defaultValues: { observacoes: '' },
  })

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true)
      const {
        data: { withdrawal, ...rest },
      } = await apiV1.admin.withdrawalService.get(withdrawalId)
      setWithdrawal(withdrawal)
      setResume(rest)
    } catch (error) {
      addErrorMessage(getErrorMessage(error))
    } finally {
      setIsLoading(false)
    }
  }, [addErrorMessage, withdrawalId])

  const handleRefuse = useCallback(
    async (withdrawal: WithdrawalSchemaFormType) => {
      if (isSubmitting) {
        return
      }
      try {
        setIsRequesting(true)
        const { data } = await apiV1.admin.withdrawalService.refuse(
          Number(withdrawalId),
          withdrawal.observacoes,
        )
        setWithdrawal(data)
        fetchData()
      } catch (error) {
        addErrorMessage(getErrorMessage(error))
      } finally {
        setIsRequesting(false)
      }
    },
    [addErrorMessage, fetchData, isSubmitting, withdrawalId],
  )

  const handlePay = useCallback(
    async (withdrawal: WithdrawalSchemaFormType) => {
      if (isSubmitting) {
        return
      }
      try {
        setIsRequesting(true)
        const { data } = await apiV1.admin.withdrawalService.pay(
          Number(withdrawalId),
          withdrawal.observacoes,
        )
        setWithdrawal(data)
        fetchData()
      } catch (error) {
        addErrorMessage(getErrorMessage(error))
      } finally {
        setIsRequesting(false)
      }
    },
    [addErrorMessage, fetchData, isSubmitting, withdrawalId],
  )

  const progressBarValueCheckIns = useMemo(
    () =>
      resume.checkInInfos?.total > 0
        ? (100 * resume.checkInInfos?.checkIns) / resume.checkInInfos?.total
        : 0,
    [resume.checkInInfos?.checkIns, resume.checkInInfos?.total],
  )

  const progressBarValueFaltam = useMemo(
    () =>
      resume.checkInInfos?.total - resume.checkInInfos?.checkIns > 0
        ? (100 * (resume.checkInInfos?.total - resume.checkInInfos?.checkIns)) /
          resume.checkInInfos?.total
        : 0,
    [resume.checkInInfos?.checkIns, resume.checkInInfos?.total],
  )

  useEffect(() => {
    fetchData()
  }, [fetchData])

  if (isLoading || !resume.responsible) return <PageLoading />

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      width: 50,
      renderCell: (cell) => (
        <Link
          to={`/organizador/eventos/${cell.row.cod_evento}/solicitacao-de-saque/${cell.value}`}
        >
          #{cell.value}
        </Link>
      ),
    },
    {
      field: 'beneficiario',
      headerName: 'Beneficiário',
      flex: 1,
      minWidth: 120,
    },
    {
      field: 'tipo_saque',
      headerName: 'Canal de vendas',
      width: 130,
      renderCell: ({ value }) =>
        value === 'VENDA_FISICA' ? 'Físico' : 'Digital',
    },
    {
      field: 'valor',
      headerName: 'Valor',
      width: 120,
      type: 'number',
      renderCell: (cell) => moneyFormatter.format(Number(cell.value)),
    },
    {
      field: 'data_situacao',
      headerName: 'Data',
      width: 124,
      type: 'number',
      renderCell: ({ value, row }) =>
        value
          ? dayjs(value).format('DD/MM/YYYY HH:mm')
          : dayjs(row.data_solicitacao).format('DD/MM/YYYY HH:mm'),
    },
    {
      field: 'cod_saque_status',
      headerName: 'Situação',
      type: 'number',
      width: 120,
      renderCell: (cell) => (
        <Chip
          label={cell.row.saque_status.titulo}
          color={
            cell.value === 1 ? 'info' : cell.value === 2 ? 'success' : 'error'
          }
        />
      ),
    },
  ]

  const requestedDigitalWithdrawals = resume.withdrawals?.filter(
    (x) => x.tipo_saque === 'VENDA_DIGITAL' && x.cod_saque_status === 1,
  )
  const requestedPhysicalWithdrawals = resume.withdrawals?.filter(
    (x) => x.tipo_saque === 'VENDA_FISICA' && x.cod_saque_status === 1,
  )

  return (
    <Grid container spacing={2} component={Paper} p={2}>
      {!!withdrawal.evento.evento_nota?.length && (
        <Grid item xs={12}>
          <Alert severity="warning">O evento tem observações</Alert>
        </Grid>
      )}
      <Grid
        item
        xs={12}
        sm={6}
        sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
      >
        <Typography variant="h5">Resumo de movimentações</Typography>

        <TableContainer component={Paper}>
          <Typography sx={{ flex: '1 1 100%', pt: 2, pl: 2 }} variant="h6">
            Vendas digitais (Site)
          </Typography>
          {resume.eventIsClosed ? (
            <Table size="small">
              <TableBody>
                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Valor de vendas (A)
                  </TableCell>
                  <TableCell align="right">
                    {moneyFormatter.format(
                      resume.digitalSalesSummary.valor_vendas,
                    )}
                  </TableCell>
                </TableRow>

                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Valor sacado (B)
                  </TableCell>
                  <TableCell align="right">
                    {moneyFormatter.format(
                      resume.digitalSalesSummary.saques_aprovados,
                    )}
                  </TableCell>
                </TableRow>

                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Valores solicitados (C){' '}
                    <BootstrapTooltip title="Saques solicitados ainda em análise">
                      <Info color="#000" size={16} />
                    </BootstrapTooltip>
                  </TableCell>
                  <TableCell align="right">
                    {moneyFormatter.format(
                      requestedDigitalWithdrawals.reduce(
                        (total, withdrawal) => total + Number(withdrawal.valor),
                        0,
                      ),
                    )}
                  </TableCell>
                </TableRow>

                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Taxas absorvidas (D){' '}
                    <BootstrapTooltip title="Taxas absorvidas dos lotes">
                      <Info color="#000" size={16} />
                    </BootstrapTooltip>
                  </TableCell>
                  <TableCell align="right">
                    {moneyFormatter.format(
                      resume.digitalSalesSummary.taxa_produtor,
                    )}
                  </TableCell>
                </TableRow>

                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ fontWeight: 'bold' }}
                  >
                    Valor liberado para saque = (A - B - C - D)
                  </TableCell>
                  <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                    {moneyFormatter.format(
                      resume.digitalSalesSummary.valor_vendas -
                        resume.digitalSalesSummary.saques_aprovados -
                        requestedDigitalWithdrawals.reduce(
                          (total, withdrawal) =>
                            total + Number(withdrawal.valor),
                          0,
                        ) -
                        resume.digitalSalesSummary.taxa_produtor,
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          ) : (
            <Table size="small">
              <TableBody>
                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Valor de vendas (A)
                  </TableCell>
                  <TableCell align="right">
                    {moneyFormatter.format(
                      resume.digitalSalesSummary.valor_vendas,
                    )}
                  </TableCell>
                </TableRow>

                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Valor bloqueado{' '}
                    {resume.digitalSalesSummary.dias_retidos_saque} dias (B)
                  </TableCell>
                  <TableCell align="right">
                    {moneyFormatter.format(
                      resume.digitalSalesSummary.valor_bloqueado,
                    )}
                  </TableCell>
                </TableRow>

                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Valor disponível (C) = (A - B) *{' '}
                    {resume.digitalSalesSummary.percentual_adiantamento}%
                  </TableCell>
                  <TableCell align="right">
                    {moneyFormatter.format(
                      ((resume.digitalSalesSummary.valor_vendas -
                        resume.digitalSalesSummary.valor_bloqueado) *
                        resume.digitalSalesSummary.percentual_adiantamento) /
                        100,
                    )}
                  </TableCell>
                </TableRow>

                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Valor sacado (D)
                  </TableCell>
                  <TableCell align="right">
                    {moneyFormatter.format(
                      resume.digitalSalesSummary.saques_aprovados,
                    )}
                  </TableCell>
                </TableRow>

                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Valores solicitados (E){' '}
                    <BootstrapTooltip title="Saques solicitados ainda em análise">
                      <Info color="#000" size={16} />
                    </BootstrapTooltip>
                  </TableCell>
                  <TableCell align="right">
                    {moneyFormatter.format(
                      requestedDigitalWithdrawals.reduce(
                        (total, withdrawal) => total + Number(withdrawal.valor),
                        0,
                      ),
                    )}
                  </TableCell>
                </TableRow>

                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Taxas absorvidas (F){' '}
                    <BootstrapTooltip title="Taxas absorvidas dos lotes">
                      <Info color="#000" size={16} />
                    </BootstrapTooltip>
                  </TableCell>
                  <TableCell align="right">
                    {moneyFormatter.format(
                      resume.digitalSalesSummary.taxa_produtor,
                    )}
                  </TableCell>
                </TableRow>

                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ fontWeight: 'bold' }}
                  >
                    Valor liberado para saque = (C - D - E - F)
                  </TableCell>
                  <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                    {moneyFormatter.format(
                      ((resume.digitalSalesSummary.valor_vendas -
                        resume.digitalSalesSummary.valor_bloqueado) *
                        resume.digitalSalesSummary.percentual_adiantamento) /
                        100 -
                        resume.digitalSalesSummary.saques_aprovados -
                        requestedDigitalWithdrawals.reduce(
                          (total, withdrawal) =>
                            total + Number(withdrawal.valor),
                          0,
                        ),
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          )}
        </TableContainer>

        <TableContainer component={Paper}>
          <Typography sx={{ flex: '1 1 100%', pt: 2, pl: 2 }} variant="h6">
            Vendas físicas (PDV/POS)
          </Typography>
          {resume.eventIsClosed ? (
            <Table size="small">
              <TableBody>
                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Valor de vendas (A){' '}
                    <BootstrapTooltip title="Valor de vendas subtraído taxas">
                      <Info color="#000" size={16} />
                    </BootstrapTooltip>
                  </TableCell>
                  <TableCell align="right">
                    {moneyFormatter.format(
                      resume.physicalSalesSummary.valor_vendas,
                    )}
                  </TableCell>
                </TableRow>

                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Valor sacado (B)
                  </TableCell>
                  <TableCell align="right">
                    {moneyFormatter.format(
                      resume.physicalSalesSummary.saques_aprovados,
                    )}
                  </TableCell>
                </TableRow>

                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Valores solicitados (C){' '}
                    <BootstrapTooltip title="Saques solicitados ainda em análise">
                      <Info color="#000000" size={16} />
                    </BootstrapTooltip>
                  </TableCell>
                  <TableCell align="right">
                    {moneyFormatter.format(
                      requestedPhysicalWithdrawals.reduce(
                        (total, withdrawal) => total + Number(withdrawal.valor),
                        0,
                      ),
                    )}
                  </TableCell>
                </TableRow>

                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ fontWeight: 'bold' }}
                  >
                    Valor liberado para saque (D) = (A - B - C)
                  </TableCell>
                  <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                    {moneyFormatter.format(
                      resume.physicalSalesSummary.valor_vendas -
                        resume.physicalSalesSummary.saques_aprovados -
                        requestedPhysicalWithdrawals.reduce(
                          (total, withdrawal) =>
                            total + Number(withdrawal.valor),
                          0,
                        ),
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          ) : (
            <Table size="small">
              <TableBody>
                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Valor de vendas (A){' '}
                    <BootstrapTooltip title="Valor de vendas subtraído taxas">
                      <Info color="#000" size={16} />
                    </BootstrapTooltip>
                  </TableCell>
                  <TableCell align="right">
                    {moneyFormatter.format(
                      resume.physicalSalesSummary.valor_vendas,
                    )}
                  </TableCell>
                </TableRow>

                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Valor bloqueado{' '}
                    {resume.physicalSalesSummary.dias_retidos_saque} dias (B)
                  </TableCell>
                  <TableCell align="right">
                    {moneyFormatter.format(
                      resume.physicalSalesSummary.valor_bloqueado,
                    )}
                  </TableCell>
                </TableRow>

                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Valor disponível (C) = (A - B) *{' '}
                    {resume.physicalSalesSummary.percentual_adiantamento}%
                  </TableCell>
                  <TableCell align="right">
                    {moneyFormatter.format(
                      ((resume.physicalSalesSummary.valor_vendas -
                        resume.physicalSalesSummary.valor_bloqueado) *
                        resume.physicalSalesSummary.percentual_adiantamento) /
                        100,
                    )}
                  </TableCell>
                </TableRow>

                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Valor sacado (D)
                  </TableCell>
                  <TableCell align="right">
                    {moneyFormatter.format(
                      resume.physicalSalesSummary.saques_aprovados,
                    )}
                  </TableCell>
                </TableRow>

                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Valores solicitados (E){' '}
                    <BootstrapTooltip title="Saques solicitados ainda em análise">
                      <Info color="#000000" size={16} />
                    </BootstrapTooltip>
                  </TableCell>
                  <TableCell align="right">
                    {moneyFormatter.format(
                      requestedPhysicalWithdrawals.reduce(
                        (total, withdrawal) => total + Number(withdrawal.valor),
                        0,
                      ),
                    )}
                  </TableCell>
                </TableRow>

                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ fontWeight: 'bold' }}
                  >
                    Valor liberado para saque (F) = (C - D - E)
                  </TableCell>
                  <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                    {moneyFormatter.format(
                      ((resume.physicalSalesSummary.valor_vendas -
                        resume.physicalSalesSummary.valor_bloqueado -
                        requestedPhysicalWithdrawals.reduce(
                          (total, withdrawal) =>
                            total + Number(withdrawal.valor),
                          0,
                        )) *
                        resume.physicalSalesSummary.percentual_adiantamento) /
                        100 -
                        resume.physicalSalesSummary.saques_aprovados,
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          )}
        </TableContainer>

        {withdrawal?.evento?.evento_nota &&
          withdrawal?.evento?.evento_nota.length > 0 && (
            <Alert severity="error" sx={{ mb: 2 }}>
              <AlertTitle>Notas do evento</AlertTitle>
              {withdrawal?.evento?.evento_nota.map((nota) => (
                <Typography key={nota.id} variant="body1">
                  {nota.descricao}
                </Typography>
              ))}
            </Alert>
          )}

        <Alert severity="info" sx={{ mb: 2 }}>
          <AlertTitle>Informações de check-ins</AlertTitle>
          <Box sx={{ width: '100%' }}>
            <LinearProgress
              color="success"
              variant="determinate"
              value={progressBarValueCheckIns}
            />
            <Typography variant="body1">
              {progressBarValueCheckIns.toFixed(0)}% (
              {resume.checkInInfos?.checkIns}) realizados
            </Typography>
          </Box>
          <Box sx={{ width: '100%' }}>
            <LinearProgress
              color="error"
              variant="determinate"
              value={progressBarValueFaltam}
            />
            <Typography variant="body1">
              {progressBarValueFaltam.toFixed(0)}% (
              {resume.checkInInfos?.total - resume.checkInInfos?.checkIns})
              faltaram
            </Typography>
          </Box>
        </Alert>
      </Grid>
      <Grid container spacing={2} item xs={12} sm={6}>
        <Grid item xs={12}>
          <Typography variant="h5">Detalhes da solicitação</Typography>
        </Grid>
        <Grid item xs={12}>
          <Alert severity="success">
            Dados da produtora:
            <p>Nome: {resume.responsible?.name}</p>
            <p>Documento: {resume.responsible?.taxId}</p>
          </Alert>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5">{withdrawal.tipo_saque}</Typography>
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table size="small" aria-label="detalhes da solicitação">
              <TableBody>
                <TableRow>
                  <TableCell>Evento</TableCell>
                  <TableCell>
                    {withdrawal.evento?.id} - {withdrawal.evento?.titulo}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Data do fim do evento</TableCell>
                  <TableCell>
                    {dayjs(withdrawal.evento?.data_final).format(
                      'DD/MM/YYYY [às] HH:mm',
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Nome do beneficiário</TableCell>
                  <TableCell>{withdrawal.beneficiario}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Tipo da chave</TableCell>
                  <TableCell>{withdrawal.tipo_chave}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Chave Pix</TableCell>
                  <TableCell>{withdrawal.chave}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Banco</TableCell>
                  <TableCell>{withdrawal.banco}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Valor solicitado</TableCell>
                  <TableCell>
                    {moneyFormatter.format(withdrawal.valor)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid item xs={12}>
          {withdrawal.cod_saque_status === 1 ? (
            <TextField
              label="Observações"
              error={!!errors.observacoes}
              helperText={errors.observacoes?.message}
              fullWidth
              required
              size="small"
              multiline
              maxRows={10}
              {...register('observacoes')}
            />
          ) : (
            <Alert
              severity={withdrawal.cod_saque_status === 2 ? 'success' : 'error'}
            >
              <AlertTitle>{withdrawal.saque_status?.titulo}</AlertTitle>
              {withdrawal.observacoes}
            </Alert>
          )}
        </Grid>

        {withdrawal.cod_saque_status === 1 && (
          <Grid item xs={12} display="flex" justifyContent="flex-end">
            <LoadingButton
              loading={isRequesting}
              loadingPosition="start"
              startIcon={<CancelIcon />}
              onClick={handleSubmit(handleRefuse)}
              variant="contained"
              color="error"
              sx={{ mr: 2 }}
            >
              Recusar
            </LoadingButton>
            <LoadingButton
              loading={isRequesting}
              loadingPosition="start"
              startIcon={<PriceCheckIcon />}
              onClick={handleSubmit(handlePay)}
              variant="contained"
              color="success"
            >
              Pagar
            </LoadingButton>
          </Grid>
        )}
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h5">Histórico de solicitações de saque</Typography>
      </Grid>
      <Grid item xs={12}>
        <DataGrid rows={resume.withdrawals} columns={columns} autoHeight />
      </Grid>
    </Grid>
  )
}

function PageLoading() {
  return (
    <Grid item xs={12}>
      <Skeleton height={20} />
      <Skeleton />
      <Skeleton />
      <Skeleton />
      <Skeleton />
    </Grid>
  )
}
