/* eslint-disable react/no-unescaped-entities */
import { Container, Paper } from '@mui/material'
import React from 'react'

const DoubsPage: React.FC = () => {
  return (
    <Container sx={{ padding: 2, marginY: 2 }} component={Paper}>
      <h1>
        Como funciona a compra de ingresso pelo site Z&eacute; do Ingresso?
      </h1>

      <h5>
        <em>Qual &eacute; o passo a passo?</em>
      </h5>

      <p>Simples, vamos ao passo a passo:</p>

      <ol>
        <li>
          Realize o <a href="/signup">cadastro</a> no site utilizando seus
          dados,
        </li>
        <li>localize o evento que deseja comprar,</li>
        <li>
          seleciona a quantidade, aperte o bot&atilde;o COMPRAR e aguarde a
          proxima tela,
        </li>
        <li>
          agora confira seu pedido e aperte o bot&atilde;o FINALZIAR PAGAMENTO,
          voc&ecirc; ser&aacute; direcionada ao Mercado Pago,
        </li>
        <li>
          secione a forma de pagamento (cart&atilde;o de cr&eacute;dito,
          cat&atilde;o d&eacute;bito CAIXA VIRTUAL, PIX, PayPal e boleto),
        </li>
        <li>
          insira as informa&ccedil;&otilde;es de pagamento solicitadas e aperte
          os bot&atilde;o PAGAR,
        </li>
        <li>
          aguarde a aprova&ccedil;&atilde;o que ser&aacute; enviada via e-mail,
          juntamente com seus ingressos,
        </li>
        <li>
          ou encontre seus ingressos acessando o menu&nbsp; &quot;
          <a href="/usuario/pedidos">Meus Ingressos</a>
          &quot;&nbsp;&nbsp;
        </li>
      </ol>

      <p>
        e pronto agora &eacute; so apresentar o ingresso na portaria e curtir o
        role!
        <br />
        <br />
        Obs. N&atilde;o &eacute; necess&aacute;rio imprimir o ingresso, basta
        apresentar no celular na portaria do evento.
      </p>

      <h1>O ingresso est&aacute; em meu nome, outra pessoa pode utilizar?</h1>

      <h5>
        <em>
          Como trocar o nome do participante?&nbsp;
          <br />
          Meu amigo(a) quem ir&aacute; usar o ingresso, como colocar o nome
          dele?
        </em>
      </h5>

      <p>
        Por padr&atilde;o os ingressos s&atilde;o gerados em nome do comprador.
        No entanto, caso quem for utilizar o ingresso seja outra pessoa, basta
        acessar o pedido do ingresso em &quot;
        <a href="/usuario/pedidos">Meus Ingressos</a>
        &quot; &nbsp;editar o nome e CPF&nbsp; da pessoa que ir&aacute;
        utiliz&aacute;-lo. Em seguida baixe o ingresso no bot&atilde;o BAIXAR
        INGRESSO PDF e encaminhe para o participante.&nbsp;
      </p>

      <p>
        N&atilde;o &eacute; necess&aacute;rio imprimir o ingresso, basta
        apresent&aacute;-lo no celular na portaria do evento.&nbsp;
        <br />
        Lembrando que, se voc&ecirc; estiver vendendo ou comprando um ingresso
        de algu&eacute;m desconhecido, por seguran&ccedil;a, ser&aacute;
        necess&aacute;rio transferir a titularidade, realizando o procedimento
        abaixo.
      </p>

      <h1>
        Como efetuar a troca de titularidade do meu ingresso?&nbsp; &nbsp;
      </h1>

      <h5>
        <em>
          Vou vender meu ingresso para outra pessoa, como proceder?
          <br />
          Estou comprando meu ingresso de um desconhecido, qual &eacute; o
          procedimento?
        </em>
      </h5>

      <p>
        Se você estiver vendendo ou comprando um ingresso de alguém
        desconhecido, por segurança será necessário transferir a titularidade
        através do botão "Transferir Ingresso" encontrado logo abaixo do QrCode
        do mesmo. Após realizar a transferência, o novo usuário receberá um
        email com a confirmação e também poderá visualizá-lo em{' '}
        <a href="/usuario/pedidos">Meus ingressos</a>.
      </p>

      <h1>Como efetuar o cancelamento e reembolso de um pedido?</h1>

      <h5>
        <em>N&atilde;o irei mais ao evento, como solicitar o reembolso?</em>
        <br />
        <em>Quando tenho direito a estorno da compra?</em>
      </h5>
      <p>
        Conforme previsto no artigo 49, do Código de Defesa do Consumidor,
        eventual pedido de cancelamento da compra deverá ser feito no prazo de
        até 7 dias da data da compra. Após esse prazo, ou no caso do pedido de
        cancelamento ser realizado nas 48 horas anteriores ao evento, a opção
        "CANCELAR PEDIDO" não estará disponível.
        <br />
        Se o seu pedido esta dentro dos prazos mencionados, basta acessar o
        pedido e clicar no botão "CANCELAR PEDIDO"
        <br />
        <a href=" https://zedoingresso.com.br/usuario/pedidos">
          Click aqui para acessar seus pedidos
        </a>
      </p>

      <p>
        Compras realizadas via promoter, comissário, atlética, ponto de vendas
        ou qualquer outro meio que não seja diretamente ao site, não se
        enquadram nos termos acima ficando sobe responsabilidade da organização
        do evento em realizar o cancelamento e reembolso.
      </p>

      <p>
        Pedidos transferidos ou solicitações efetuados após o inicio do evento
        não serão realizados, independente do motivo.
      </p>

      <h1>
        Porque meu pedido está com status &quot;Pagamento Rejeitado&quot; ?
      </h1>

      <h5>
        <em>
          Realizei uma compra por&eacute;m o pagamento foi rejeitado!
          <br />
          Porque meu pagamento foi recusado? eu tenho saldo!!
        </em>
      </h5>

      <p>
        As compras dos ingressos realizadas com cart&atilde;o de cr&eacute;dito
        passam por uma avalia&ccedil;&atilde;o de seguran&ccedil;a feita pelo
        Mercado Pago e podem ser recusadas.&nbsp;
      </p>

      <p>
        Seguem abaixo os motivos mais comuns para a recusa da
        compra:&nbsp;&nbsp;
      </p>

      <ol>
        <li>
          Utilizar cart&otilde;es e dados de pagamentos de titularidade
          divergentes ao cadastro no site.
          <br />
          Ex. comprar em sua conta utilizando cart&otilde;es de amigos ou
          parentes;
        </li>
        <li>
          Algum dado foi informado incorretamente no momento do pagamento,
        </li>
        <li>O cart&atilde;o usado est&aacute; expirado ou bloqueado,</li>
        <li>Falta de limite no cart&atilde;o,</li>
        <li>O banco recusou por problemas ou quest&otilde;es de segurança,</li>
        <li>
          A transa&ccedil;&atilde;o n&atilde;o passou na nossa
          valida&ccedil;&atilde;o de seguran&ccedil;a que protege cliente e
          vendedor contra fraudes.
        </li>
        <li>
          Perfil de compra n&atilde;o aprovado pelo Mercado Pago por
          quest&atilde;o de seguran&ccedil;a, baseado em hist&oacute;rico de
          compras passados.
        </li>
      </ol>

      <p>
        Caso a recusa do cart&atilde;o seja 1 ou 2, basta&nbsp; corrigir os
        dados e efetuar uma nova compra;&nbsp;
        <br />
        Se a recusa for 3, 4 ou 5 utilize outro cart&atilde;o;&nbsp;
        <br />
        Se mesmo assim o problema persistir utilize outra forma de pagamento
        (PIX ou BOLETO).
        <br />
        Caso o pagamento da sua compra ainda esteja aparecendo na fatura,
        n&atilde;o se preocupe, em breve o mesmo ser&aacute; estornado
        automaticamente
      </p>

      <h1>Evento foi cancelado ou adiado?</h1>

      <h5>
        <em>
          Ouvi dizer que o evento foi cancelado, como solicitar o estorno do meu
          dinheiro?
        </em>
      </h5>

      <p>
        Em caso de cancelamento e/ou reagendamento de eventos devido &agrave;
        caso fortuito ou for&ccedil;a maior, nos termos do 393 do C&oacute;digo
        Civil Brasileiro, por se tratar de eventos extraordin&aacute;rios, o
        Z&eacute; do Ingresso n&atilde;o se responsabilizar&aacute; pelo estorno
        dos valores dos ingressos, devendo o usu&aacute;rio aguardar a
        manifesta&ccedil;&atilde;o do organizador do evento para
        devolu&ccedil;&atilde;o ou reagendamento da data do evento. Para estes
        casos a respos&aacute;bilidade da devolu&ccedil;&atilde;o dos valores
        ficam por conta da organiza&ccedil;&atilde;o do evento (conforme os
        <a href="/termos-de-uso"> TERMOS DE USO</a>), exceto&nbsp;para
        solicita&ccedil;&otilde;es efetuadas dentro dos 7 dias ap&oacute;s a
        data da compra, estes sim ter&atilde;o o estorno realizado direto pelo
        site.
      </p>
    </Container>
  )
}

export default DoubsPage
