import { IGenero } from 'types/genero'
import clientProducer from './clientProducer'

const getList = () => {
  return clientProducer.get<Array<IGenero>>(`genders`)
}

const genderService = {
  getList,
}

export default genderService
