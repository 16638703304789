import { Search, SearchOutlined } from '@mui/icons-material'
import { Container, Grid, InputAdornment, TextField } from '@mui/material'
import { IPedido } from 'types/pedido'

import { zodResolver } from '@hookform/resolvers/zod'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { Button } from 'components/button'
import { Typography } from 'components/Typography'
import dayjs from 'dayjs'
import { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import clientAdmin from 'services/api/v1/admin/client'
import { moneyFormatter } from 'utils/formatter'
import { z } from 'zod'

const schema = z.object({
  query: z.string().min(1, 'Informe o parâmetro para a pesquisa'),
})

type SchemaType = z.infer<typeof schema>

export default function SearchOrder() {
  const [orders, setOrders] = useState<IPedido[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm<SchemaType>({
    resolver: zodResolver(schema),
  })

  const onSubmit = useCallback(
    async ({ query }: SchemaType) => {
      try {
        if (isSubmitting) return
        setIsLoading(true)
        const {
          data: { orders },
        } = await clientAdmin.get(`orders/search?query=${query}`)
        setOrders(orders)
      } catch (error) {
        alert(error)
      } finally {
        setIsLoading(false)
      }
    },
    [isSubmitting],
  )

  return (
    <Container maxWidth="lg" component={Paper} sx={{ p: 2, m: 2 }}>
      <Grid
        container
        spacing={2}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        mb={2}
      >
        <Grid item xs={12}>
          <Typography variant="h4">Pesquisar pedidos</Typography>
        </Grid>
        <Grid item xs={12} sm={10}>
          <TextField
            label="Pesquisar por nome, cpf, telefone, e-mail, código do pedido ou id do ingresso"
            variant="outlined"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            size="small"
            {...register('query')}
            error={!!errors.query}
            helperText={errors.query?.message}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Button
            isLoading={isLoading}
            variant="contained"
            type="submit"
            size="large"
            startIcon={<SearchOutlined />}
          >
            Pesquisar
          </Button>
        </Grid>
      </Grid>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Código</TableCell>
              <TableCell>Comprador</TableCell>
              <TableCell>Data</TableCell>
              <TableCell>Evento</TableCell>
              <TableCell>Situação</TableCell>
              <TableCell>Valor</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.map((order) => (
              <TableRow
                key={order.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <Link
                    to={`/admin/order-details/${order.codigo}`}
                    target="_blank"
                  >
                    {order.codigo}
                  </Link>
                </TableCell>
                <TableCell>
                  {[order.usuario.nome, order.usuario.sobrenome]
                    .join(' ')
                    .trim()
                    .toUpperCase()}
                </TableCell>
                <TableCell>
                  {dayjs(order.data).add(3, 'hours').format('DD/MM/YY HH:mm')}
                </TableCell>
                <TableCell>{order.evento.titulo}</TableCell>
                <TableCell>{order.status}</TableCell>
                <TableCell>
                  {moneyFormatter.format(order.valor_pedido)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  )
}
