/* eslint-disable @typescript-eslint/no-explicit-any */
import SearchIcon from '@mui/icons-material/Search'
import { Box, Button, Grid, Skeleton, Typography } from '@mui/material'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import InputBase from '@mui/material/InputBase'
import Paper from '@mui/material/Paper'
import { DataGrid, GridColDef, GridRowsProp } from '@mui/x-data-grid'
import { constants } from 'config'
import { usePromoter } from 'contexts/promoterContext'
import dayjs from 'dayjs'
import * as React from 'react'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { apiV1 } from 'services'
import { IEvento } from 'types/evento'
import { datagrid, functionWithLoading } from 'utils'

export default function EventList() {
  const [events, setEvents] = React.useState<IEvento[]>([])
  const [loading, setLoading] = React.useState(true)
  const [searchQuery, setSearchQuery] = React.useState('')
  const { setPageTitle } = usePromoter()
  const navigate = useNavigate()

  const filteredEvents =
    searchQuery.length > 0
      ? events.filter(
          (x) =>
            x.titulo
              .toLocaleLowerCase()
              .includes(searchQuery.toLocaleLowerCase()) ||
            x.id.toString().includes(searchQuery),
        )
      : events

  React.useEffect(() => {
    setPageTitle('Meus eventos')
    functionWithLoading(
      apiV1.eventService.getAll(true).then((res) => {
        setEvents(res.data)
      }),
      setLoading,
    )
  }, [setPageTitle])

  const loadAllEvents = React.useCallback(() => {
    functionWithLoading(
      apiV1.eventService.getAll(true).then((res) => {
        setEvents(res.data)
      }),
      setLoading,
    )
  }, [])

  const onChangeSearch = React.useCallback(
    (e: any) => setSearchQuery(e.target.value),
    [],
  )

  const rows: GridRowsProp =
    filteredEvents.map((x) => ({
      ...x,
    })) ?? []

  const columns: GridColDef[] = [
    {
      field: 'titulo',
      headerName: 'Nome do evento',
      flex: 1,
      renderCell: (cell) => (
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="start"
          alignItems="center"
        >
          <img
            src={constants.URL_FTP + cell.row.imagem}
            alt=""
            height="52px"
            width="100px"
            style={{ objectFit: 'contain' }}
          />
          <Typography>{cell.value}</Typography>
        </Box>
      ),
    },
    {
      field: 'data',
      headerName: 'Data',
      flex: 1,
      renderCell: (v) =>
        dayjs(v.value)
          .add(3, 'hours')
          .format('DD [de] MMMM [de] YYYY [às] HH:mm'),
    },
    {
      field: 'local_evento',
      headerName: 'Local',
      flex: 1,
    },
    {
      field: 'actions',
      headerName: 'Ações',
      renderCell: (values) => {
        return (
          <Button
            variant="contained"
            onClick={() => navigate(`/organizador/eventos/${values.id}`)}
          >
            Gerenciar
          </Button>
        )
      },
      width: 140,
    },
  ]

  return (
    <Box>
      <Grid container spacing={2} component={Paper} sx={{ p: 1 }}>
        <Grid item xs={12}>
          <Typography variant="h4">Meus eventos</Typography>
        </Grid>
        <Grid item xs={12}>
          <Paper
            sx={{
              p: '2px 4px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Pesquisar eventos"
              inputProps={{ 'aria-label': 'pesquisar eventos' }}
              onChange={onChangeSearch}
              value={searchQuery}
            />
            <IconButton type="button" sx={{ p: '10px' }} aria-label="pesquisar">
              <SearchIcon />
            </IconButton>
            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            <Button variant="outlined" onClick={loadAllEvents} size="small">
              Ver todos
            </Button>
            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            <Button
              color="success"
              aria-label="cadastrar novo evento"
              component={RouterLink}
              to="/organizador/eventos/novo"
              variant="contained"
              sx={{ ml: 1 }}
            >
              Cadastrar
            </Button>
            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          {loading ? (
            <Grid container spacing={2}>
              <Grid item sm={12}>
                <Skeleton variant="rectangular" height={400} />
              </Grid>
            </Grid>
          ) : (
            <DataGrid
              rows={rows}
              columns={columns}
              autoHeight
              localeText={datagrid.localizedTextsMap}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  )
}
