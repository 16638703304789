import adminService from './adminService'
import categoriesService from './categoriesService'
import discountCouponService from './discountCouponService'
import eventService from './eventService'
import genderService from './genderService'
import localService from './localService'
import orderService from './orderService'
import promoterService from './promoterService'
import sectorService from './sectorService'
import ticketService from './ticketService'
import uploadService from './uploadService'
import withdrawalService from './withdrawalService'

const admin = {
  adminService,
  categoriesService,
  discountCouponService,
  eventService,
  genderService,
  localService,
  orderService,
  promoterService,
  sectorService,
  ticketService,
  uploadService,
  withdrawalService,
}

export default admin
