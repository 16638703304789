import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import LoginIcon from '@mui/icons-material/Login'
import LoadingButton from '@mui/lab/LoadingButton'
import { Alert, Button, Grid } from '@mui/material'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Copyright, FormItem } from 'components'
import { usePromoter } from 'contexts/promoterContext'
import { useFormik } from 'formik'
import * as React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ILocationProps } from 'types/locationProps'
import * as yup from 'yup'

const validationSchema = yup.object({
  email: yup
    .string()
    .email('Preencha um e-mail válido')
    .required('É necessário preencher o e-mail'),
  password: yup.string().required('É necessário preencher a senha').nullable(),
})

const ProducerLoginForm = () => {
  const { login, loading, promoter, errorMessage } = usePromoter()
  const location = useLocation() as ILocationProps
  const navigate = useNavigate()

  const from = location.state?.from?.pathname ?? '/organizador'

  React.useEffect(() => {
    const token = localStorage.getItem('promoter_token')
    const promoter = localStorage.getItem('promoter')
    if (token && promoter) {
      navigate(from, { replace: true })
    }
  }, [from, navigate, promoter])

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validateOnChange: true,
    validationSchema,
    onSubmit: (values) => {
      login(values.email, values.password)
    },
  })

  return (
    <Box
      sx={{
        my: 8,
        mx: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Painel do organizador
      </Typography>
      <Box
        component="form"
        noValidate
        onSubmit={formik.handleSubmit}
        sx={{ mt: 1 }}
      >
        <FormItem.TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email"
          name="email"
          autoComplete="email"
          autoFocus
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
        <FormItem.TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Senha"
          type="password"
          id="password"
          autoComplete="current-password"
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
        />
        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
        <LoadingButton
          loading={loading}
          loadingPosition="start"
          startIcon={<LoginIcon />}
          variant="contained"
          type="submit"
          fullWidth
          sx={{ mt: 3, mb: 2 }}
        >
          Entrar
        </LoadingButton>
        <Grid container sx={{ justifyContent: 'space-between' }}>
          <Grid item>
            <Button
              variant="outlined"
              onClick={() => navigate('/esqueci-senha')}
              size="small"
            >
              Esqueci a senha
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              onClick={() => navigate('/cadastre-sua-produtora')}
              size="small"
            >
              Cadastrar produtora
            </Button>
          </Grid>
        </Grid>
        <Copyright sx={{ mt: 5 }} />
      </Box>
    </Box>
  )
}

export default ProducerLoginForm
