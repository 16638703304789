import {
  Box,
  Breadcrumbs as BreadcrumbsMUI,
  Link,
  Typography,
} from '@mui/material'
import { Link as LinkRouterDom } from 'react-router-dom'

export type BreadcrumbItem = {
  title: string
  to?: string
}

type Props = {
  items: BreadcrumbItem[]
}

export function Breadcrumbs({ items }: Props) {
  const last = items.pop()
  return (
    <Box role="presentation" ml={2} mt={2}>
      <BreadcrumbsMUI aria-label="breadcrumb">
        {items.map((item, index) => (
          <Link
            key={index}
            component={LinkRouterDom}
            underline="hover"
            color="inherit"
            to={item.to ?? ''}
          >
            <Typography variant="overline">{item.title}</Typography>
          </Link>
        ))}
        <Typography variant="overline" color="text.primary">
          {last?.title}
        </Typography>
      </BreadcrumbsMUI>
    </Box>
  )
}
