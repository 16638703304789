import DeleteIcon from '@mui/icons-material/Delete'
import { LoadingButton } from '@mui/lab'
import { Alert } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import * as React from 'react'
import { apiV1 } from 'services'
import { ISetor } from 'types/setor'
import { functionWithLoading } from 'utils'
import { getErrorMessage } from 'utils/AppError'

interface SectorDeleteButtonProps {
  sector: ISetor
  isAdmin: boolean
  callback: () => void
}

const SectorDeleteButton = ({
  sector,
  isAdmin,
  callback,
}: SectorDeleteButtonProps) => {
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState('')

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleClickRemove = () => {
    if (isAdmin) {
      functionWithLoading(
        apiV1.admin.sectorService
          .remove(sector.cod_evento, sector.id)
          .then(() => callback())
          .catch((err) => {
            setError(err.response?.data.message ?? err.message)
          }),
        setLoading,
      )
    } else {
      functionWithLoading(
        apiV1.producer.sectorService
          .remove(sector.cod_evento, sector.id)
          .then(() => callback())
          .catch((error) => {
            setError(getErrorMessage(error))
          }),
        setLoading,
      )
    }
  }

  return (
    <React.Fragment>
      <IconButton aria-label="delete" onClick={handleClickOpen}>
        <DeleteIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`Excluir o setor ${sector.titulo}?`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Ao excluir o setor sairá da lista de lotes.
          </DialogContentText>
          {error && (
            <Alert severity="error" sx={{ mt: 1 }} onClose={() => setError('')}>
              {error}
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <LoadingButton
            loading={loading}
            loadingPosition="start"
            startIcon={<DeleteIcon />}
            onClick={handleClickRemove}
            color="error"
            variant="contained"
          >
            Sim
          </LoadingButton>{' '}
          <Button
            onClick={handleClose}
            color="primary"
            variant="outlined"
            autoFocus
          >
            Não
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default SectorDeleteButton
