/* eslint-disable camelcase */
/* eslint-disable react/jsx-no-duplicate-props */
import { Box, Container, Grid, Typography } from '@mui/material'
import { Loading } from 'components'
import dayjs from 'dayjs'
import { useCountdown } from 'hooks/useCountdown'
import moment from 'moment'
import Image from 'mui-image'
import { useCallback, useEffect, useState } from 'react'
import ReactPixel from 'react-facebook-pixel'
import ReactGA from 'react-ga'
import TagManager from 'react-gtm-module'
import { apiV1 } from 'services'
import { IEvento } from 'types/evento'
import { functionWithLoading } from 'utils'
import ShoppingCart from './EventPage/ShoppingCart'

import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
}

const lineup = [
  'https://images.zedoingresso.com.br/uploads/megabaile/1. CARAI.png',
  'https://images.zedoingresso.com.br/uploads/megabaile/2. MIAH.png',
  'https://images.zedoingresso.com.br/uploads/megabaile/3. GUI.png',
  'https://images.zedoingresso.com.br/uploads/megabaile/4. GD.png',
  'https://images.zedoingresso.com.br/uploads/megabaile/5. CAROL.png',
  'https://images.zedoingresso.com.br/uploads/megabaile/6. LEO B.png',
  'https://images.zedoingresso.com.br/uploads/megabaile/7. DAN.png',
]

const openBar = [
  'https://images.zedoingresso.com.br/uploads/megabaile/ORLOFF.png',
  'https://images.zedoingresso.com.br/uploads/megabaile/BLACK PRINCESS.png',
  'https://images.zedoingresso.com.br/uploads/megabaile/CABARE.png',
  'https://images.zedoingresso.com.br/uploads/megabaile/ITAIPAVA.png',
  'https://images.zedoingresso.com.br/uploads/megabaile/PETRA.png',
  'https://images.zedoingresso.com.br/uploads/megabaile/AGUA.png',
  'https://images.zedoingresso.com.br/uploads/megabaile/SUCÃO.png',
  'https://images.zedoingresso.com.br/uploads/megabaile/CORETES.png',
  'https://images.zedoingresso.com.br/uploads/megabaile/PEPSI.png',
  'https://images.zedoingresso.com.br/uploads/megabaile/SUKITA.png',
  'https://images.zedoingresso.com.br/uploads/megabaile/CITRUS.png',
]

export function MegaBaile() {
  const [event, setEvent] = useState<IEvento>()
  const [loading, setLoading] = useState(true)

  const facebook_pixel = event?.facebook_pixel
  const google_analytics = event?.google_analytics
  const google_tag_manager = event?.google_tag_manager

  const [days, hours, minutes, seconds] = useCountdown(
    moment(event?.data_entrar).add(3, 'hours').toDate().getTime(),
  )

  const loadEvent = useCallback(
    () =>
      functionWithLoading(
        apiV1.eventService
          .getForSite(989, window.location.search)
          .then((res) => setEvent(res.data)),
        setLoading,
      ),
    [],
  )

  useEffect(() => {
    loadEvent()
    if (facebook_pixel) {
      const options = {
        autoConfig: true,
        debug: true,
      }
      ReactPixel.grantConsent()
      ReactPixel.init(facebook_pixel, undefined, options)
      ReactPixel.pageView()
    }
    if (google_analytics) {
      ReactGA.initialize(google_analytics)
      ReactGA.pageview(window.location.pathname + window.location.search)
    }
    if (google_tag_manager) {
      TagManager.initialize({
        gtmId: google_tag_manager,
      })
    }
  }, [facebook_pixel, google_analytics, google_tag_manager, loadEvent])

  if (!event || loading) {
    return <Loading />
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      style={{
        // backgroundImage: `url("https://images.zedoingresso.com.br/uploads/eventos/imgR59CO27FNQPT36HD90EHE2HZRQQCBJ.png")`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPositionX: 'center',
        backgroundPositionY: 'bottom',
        backgroundColor: '#FFFFFF',
        // zIndex: -1,
        backdropFilter: 'blur(8px)',
      }}
    >
      <Container maxWidth="md">
        <Grid container spacing={2} my={3}>
          {dayjs().isBefore(event.data_entrar) && (
            <Grid item xs={12}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                style={{ backgroundColor: '#D9D9D9', height: 55 }}
              >
                <Typography
                  variant="subtitle1"
                  fontWeight="900"
                  textAlign="center"
                >
                  FALTAM{' '}
                  {days.toLocaleString('pt-BR', { minimumIntegerDigits: 2 })}D :{' '}
                  {hours.toLocaleString('pt-BR', { minimumIntegerDigits: 2 })}H
                  :{' '}
                  {minutes.toLocaleString('pt-BR', { minimumIntegerDigits: 2 })}
                  M :{' '}
                  {seconds.toLocaleString('pt-BR', { minimumIntegerDigits: 2 })}
                  S PARA O EVENTO
                </Typography>
              </Box>
            </Grid>
          )}

          <Grid item xs={12}>
            <Box
              style={{
                aspectRatio: '16/9',
              }}
            >
              <Image
                src="https://images.zedoingresso.com.br/uploads/eventos/imgR59CO27FNQPT36HD90EHE2HZRQQCBJ.png"
                alt="banner"
              />
              <Box
                py={2}
                px={4}
                style={{
                  backgroundColor: '#EAEAEA',
                  borderBottomLeftRadius: 8,
                  borderBottomRightRadius: 8,
                }}
              >
                <Typography variant="subtitle1">
                  MEGA BAILE DA CAPIVARA
                </Typography>
                <Typography variant="subtitle1">
                  Data: 18 de março de 2023 às 23:59
                </Typography>
                <Typography variant="subtitle1">
                  Local: Mixed Club - São José do Rio Preto
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box
              py={2}
              px={4}
              style={{
                backgroundColor: '#EAEAEA',
              }}
            >
              <p>
                Que voc&ecirc; j&aacute; ouviu falar que a{' '}
                <strong>
                  Capivara &eacute; uma das melhores produtoras de festas
                </strong>{' '}
                da cidade &eacute; verdade, n&atilde;o &eacute; mesmo?
              </p>

              <p>
                Somos suspeitos pra falar 🤷&zwj;♂️ mas tudo isso porque
                gostamos de inovar e dessa vez n&atilde;o poderia ser diferente
                com o nosso pr&oacute;ximo evento!
              </p>

              <p>
                Decidimos trazer{' '}
                <strong>
                  3 atra&ccedil;&otilde;es dos MAIORES PALCOS DAS FESTAS
                  UNIVERSIT&Aacute;RIOS{' '}
                </strong>
                para o <strong>PALCO DO MEGA BAILE DA CAPIVARA</strong>{' '}
                exclusivamente pra voc&ecirc;! 🔥
              </p>

              <p>
                Al&eacute;m disso, selecionamos{' '}
                <strong>+ 4 atra&ccedil;&otilde;es FERAS </strong>da cidade pra
                agitar a pista e o camarote:
              </p>

              <p>
                🎧 CARAI, MIAH, GUI SCOCHI, CAROL GUEDES, DAN, GD E L&Eacute;O
                BURIK
              </p>

              <p>
                Junto com isso preparamos um <strong>SUPER OPEN BAR </strong>que
                vai te deixar embrazando a noite toda enquanto os DJs te levam
                pra curtir o melhor do Funk Rave &agrave; P*t4ria:
              </p>

              <p>
                <strong>🍻 PISTA</strong>
              </p>

              <p>VODKA</p>

              <p>ENERG&Eacute;TICO</p>

              <p>CAIPIRINHA</p>

              <p>&Aacute;GUA</p>

              <p>SUC&Otilde;ES ESPECIAIS</p>

              <p>
                COROTES MELANCIA, MORANGO, LIM&Atilde;O COM BAUNILHA E TUTTI
                FRUTTI
              </p>

              <p>REFRIGERANTES PEPSI, SUKITA E CITRUS</p>

              <p>
                <strong>🍺 CAMAROTE</strong>
              </p>

              <p>VODKA ORLOFF</p>

              <p>ENERG&Eacute;TICO</p>

              <p>CAIPIRINHA</p>

              <p>CERVEJA | BLACK PRINCESS</p>

              <p>CERVEJA | CABAR&Eacute;</p>

              <p>CERVEJA | ITAIPAVA</p>

              <p>CERVEJA | PETRA</p>

              <p>&Aacute;GUA</p>

              <p>SUC&Otilde;ES ESPECIAIS</p>

              <p>
                COROTES MELANCIA, MORANGO, LIM&Atilde;O COM BAUNILHA E TUTTI
                FRUTTI
              </p>

              <p>REFRIGERANTES PEPSI, SUKITA E CITRUS</p>

              <p>Quer mais?</p>

              <p>
                Ent&atilde;o desliza a tela pra baixo e olha s&oacute; o que
                espera por voc&ecirc; no dia 18/03! 😎
              </p>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box
              py={2}
              px={4}
              display="flex"
              alignItems="center"
              justifyContent="center"
              style={{
                backgroundColor: '#EAEAEA',
              }}
            >
              <Typography
                variant="subtitle2"
                fontWeight="bold"
                textAlign="center"
              >
                ATRAÇÕES CONFIRMADAS
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} mb={2}>
            <Carousel
              responsive={responsive}
              showDots
              autoPlay
              autoPlaySpeed={3000}
              infinite
            >
              {lineup.map((item) => (
                <Box key={item}>
                  <Image
                    src={item}
                    alt="lineup"
                    style={{
                      paddingLeft: 8,
                      paddingRight: 8,
                    }}
                  />
                </Box>
              ))}
            </Carousel>
          </Grid>

          <Grid item xs={12}>
            <Box
              py={2}
              px={4}
              display="flex"
              alignItems="center"
              justifyContent="center"
              style={{
                backgroundColor: '#EAEAEA',
              }}
            >
              <Typography
                variant="subtitle2"
                fontWeight="bold"
                textAlign="center"
              >
                OPEN BAR DIVERSIFICADO
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} mb={2}>
            <Carousel
              responsive={responsive}
              showDots
              autoPlay
              autoPlaySpeed={3000}
              infinite
            >
              {openBar.map((item) => (
                <Box key={item}>
                  <Image
                    src={item}
                    alt="openBar"
                    style={{
                      paddingLeft: 8,
                      paddingRight: 8,
                    }}
                  />
                </Box>
              ))}
            </Carousel>
          </Grid>

          <Grid item xs={12}>
            <Box
              py={2}
              px={4}
              display="flex"
              alignItems="center"
              justifyContent="center"
              style={{
                backgroundColor: '#EAEAEA',
              }}
            >
              <Typography
                variant="subtitle2"
                fontWeight="bold"
                textAlign="center"
              >
                COMPRE SEU INGRESSO AGORA
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <ShoppingCart event={event} refreshEvent={loadEvent} />
          </Grid>

          <Grid item xs={12}>
            <Box
              py={2}
              px={4}
              display="flex"
              alignItems="center"
              justifyContent="center"
              style={{
                backgroundColor: '#EAEAEA',
              }}
            >
              <Typography
                variant="subtitle2"
                fontWeight="bold"
                textAlign="center"
              >
                MAPA DO EVENTO
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Image
              src="https://images.zedoingresso.com.br/uploads/megabaile/mapa_evento.png"
              alt="mapa"
            />
          </Grid>

          <Grid item xs={12}>
            <Box
              py={2}
              px={4}
              style={{
                backgroundColor: '#EAEAEA',
              }}
            >
              <p>
                <strong>INFORMA&Ccedil;&Otilde;ES IMPORTANTES:</strong>
              </p>

              <p>
                <strong>
                  🔴 N&Atilde;O SER&Aacute; PERMITIDO A ENTRADA DE MENORES DE 18
                  ANOS.
                </strong>
              </p>

              <p>
                <strong>
                  🔴 N&Atilde;O SER&Aacute; PERMITIDO A ENTRADA DE PESSOAS
                  PORTANDO QUALQUER TIPO DE DROGA.
                </strong>
              </p>

              <p>
                <strong>
                  🔴 &Eacute; OBRIGAT&Oacute;RIO A APRESENTA&Ccedil;&Atilde;O DO
                  INGRESSO COM QR CODE JUNTAMENTE COM UM DOCUMENTO DE IDENTIDADE
                  COM FOTO (RG OU CNH).
                </strong>
              </p>

              <p>
                <strong>
                  🔴 O ROL&Ecirc; &Eacute; MOMENTO DE DIVERS&Atilde;O
                  ENT&Atilde;O BEBA COM MODERA&Ccedil;&Atilde;O E RESPEITE O
                  ESPA&Ccedil;O DAS PESSOAS!
                </strong>
              </p>

              <p>
                <strong>
                  🔴 N&Atilde;O DIRIJA SE BEBER E LEMBRE-SE QUE PESSOAS
                  IMPORTANTES ESPERAM O SEU RETORNO PRA CASA.
                </strong>
              </p>

              <p>
                <strong>
                  🟢 OS EVENTOS DA CAPIVARA PRODU&Ccedil;&Otilde;ES CONTAM COM
                  UMA EQUIPE DE SEGURAN&Ccedil;A E BOMBEIROS QUE VOC&Ecirc; PODE
                  SOLICITAR AJUDA SE PRECISAR.
                </strong>
              </p>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box
              py={2}
              px={4}
              style={{
                backgroundColor: '#EAEAEA',
              }}
            >
              <Typography variant="subtitle1" textAlign="center">
                REALIZAÇÃO - CAPIVARA PRODUÇÕES | APOIO: ZÉ DO INGRESSO E MIXED
                CLUB
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}
