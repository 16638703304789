import { zodResolver } from '@hookform/resolvers/zod'
import { Save } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Alert,
  Autocomplete,
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Skeleton,
  TextField,
} from '@mui/material'
import { Typography } from 'components/Typography'
import dayjs from 'dayjs'
import { FC, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import ReactInputMask from 'react-input-mask'
import { useNavigate } from 'react-router-dom'
import { apiV1 } from 'services'
import clientUser from 'services/api/v1/clientUser'
import { functionWithLoading } from 'utils'
import { getErrorMessage } from 'utils/AppError'
import { ChangePassword } from 'widgets/ChangePassword'
import { z } from 'zod'

const userDetailsFormSchema = z.object({
  nome: z.string(),
  sobrenome: z.string(),
  email: z.string().optional(),
  ddd: z.string().optional(),
  telefone: z.string().optional(),
  cpf: z.string().optional(),
  data_nascimento: z.string().optional(),
  sexo: z.string(),
  cod_brasil_cidade: z.number({
    required_error: 'Por favor selecione a cidade!',
  }),
  uf: z.string(),
})

type UserDetailsFormSchema = z.infer<typeof userDetailsFormSchema>

const UserDetails: FC = () => {
  const [successMessage, setSuccessMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const [isLoadingCity, setIsLoadingCity] = useState(false)
  const [cities, setCities] = useState<{ id: number; label: string }[]>([])
  const [ufs, setUfs] = useState<string[]>([])
  const [loadingUf, setLoadingUf] = useState(false)
  const [selectedUF, setSelectedUF] = useState<string>('')

  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    control,
    reset,
    resetField,
    setValue,
    watch,
    formState: { isSubmitting, errors },
  } = useForm<UserDetailsFormSchema>({
    defaultValues: {
      nome: ' ',
      sobrenome: ' ',
      email: ' ',
      data_nascimento: ' ',
      cpf: ' ',
      cod_brasil_cidade: 0,
      ddd: ' ',
      telefone: ' ',
      uf: '',
    },
    resolver: zodResolver(userDetailsFormSchema),
  })

  const onSubmit = async (data: UserDetailsFormSchema) => {
    if (isSubmitting) return
    try {
      setIsLoading(true)
      setErrorMessage('')
      setSuccessMessage('')
      await clientUser.patch('users', data)
      setSuccessMessage('Alterações efetuadas com sucesso!')
    } catch (error) {
      setErrorMessage(getErrorMessage(error))
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    functionWithLoading(
      apiV1.cityService.getUFs().then(({ data }) => {
        setUfs(data.map((x) => x.uf))
      }),
      setLoadingUf,
    )
  }, [])

  useEffect(() => {
    if (selectedUF) {
      functionWithLoading(
        apiV1.cityService.getCities(selectedUF).then(({ data }) => {
          setCities(
            data.map((x) => ({
              id: x.codigo_cidade,
              label: x.cidade,
            })),
          )
        }),
        setIsLoadingCity,
      )
    }
  }, [selectedUF])

  useEffect(() => {
    apiV1.userService.getUserByToken().then(({ data }) => {
      reset(data as unknown as UserDetailsFormSchema)
      setValue('uf', data.brasil_cidade?.uf, {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true,
      })
      setSelectedUF(data.brasil_cidade?.uf)
      setValue('cod_brasil_cidade', Number(data.cod_brasil_cidade))
    })
  }, [reset, resetField, setValue])

  return (
    <Container component={Paper} maxWidth="md">
      <Grid container spacing={2} py={8}>
        <Grid item xs={12}>
          <Typography variant="h4">Meu Perfil</Typography>
        </Grid>

        <Grid item xs={12} mt={2}>
          <Typography variant="subtitle2">Dados pessoais</Typography>
          <Typography variant="caption" color="InfoText">
            * Para alterar os dados dos campos bloqueados é necessário entrar em
            contato com o suporte.
          </Typography>
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextField
            size="small"
            label="Nome"
            variant="outlined"
            fullWidth
            required
            {...register('nome')}
            error={!!errors.nome}
            helperText={errors.nome?.message}
          />
        </Grid>

        <Grid item xs={12} sm={5}>
          <TextField
            size="small"
            label="Sobrenome"
            variant="outlined"
            fullWidth
            required
            {...register('sobrenome')}
            error={!!errors.sobrenome}
            helperText={errors.sobrenome?.message}
          />
        </Grid>

        <Grid item xs={12} sm={3}>
          <Controller
            control={control}
            name="cpf"
            render={({ field }) => (
              <ReactInputMask
                mask="999.999.999-99"
                disabled
                value={field.value}
                onChange={field.onChange}
              >
                <TextField size="small" label="CPF" fullWidth />
              </ReactInputMask>
            )}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextField
            size="small"
            label="E-mail"
            variant="outlined"
            fullWidth
            value={watch('email')}
            disabled
          />
        </Grid>

        <Grid item xs={2} sm={2}>
          <TextField
            size="small"
            label="DDD"
            fullWidth
            value={watch('ddd')}
            disabled
          />
        </Grid>

        <Grid item xs={9} sm={3}>
          <ReactInputMask mask="99999-9999" disabled value={watch('telefone')}>
            <TextField size="small" label="Telefone" fullWidth />
          </ReactInputMask>
        </Grid>

        <Grid item xs={3}>
          <Button
            variant="contained"
            onClick={() => navigate('/usuario/verificar-numero-telefone')}
            fullWidth
          >
            Alterar telefone
          </Button>
        </Grid>

        <Grid item xs={10} sm={4}>
          <ReactInputMask
            mask="99/99/9999"
            disabled
            value={dayjs(watch('data_nascimento')).format('DD/MM/YYYY')}
          >
            <TextField size="small" label="Data de nascimento" fullWidth />
          </ReactInputMask>
        </Grid>

        <Grid item xs={12} sm={8}>
          <Controller
            control={control}
            name="sexo"
            render={({ field }) => (
              <FormControl fullWidth>
                <InputLabel>Sexo</InputLabel>
                <Select
                  label="Sexo"
                  defaultValue="Prefiro não informar"
                  value={field.value || 'Prefiro não informar'}
                  onChange={field.onChange}
                  fullWidth
                  size="small"
                >
                  <MenuItem value={'Masculino'}>Masculino</MenuItem>
                  <MenuItem value={'Feminino'}>Feminino</MenuItem>
                  <MenuItem value={'Prefiro não informar'}>
                    Prefiro não informar
                  </MenuItem>
                </Select>
              </FormControl>
            )}
          />
        </Grid>

        <Grid item xs={12} mt={2}>
          <Typography variant="subtitle2">Endereço</Typography>
        </Grid>

        <Grid item xs={12} sm={2}>
          {loadingUf ? (
            <Skeleton />
          ) : (
            <Controller
              control={control}
              name="uf"
              render={({ field: { onChange, value } }) => (
                <FormControl fullWidth>
                  <InputLabel>UF</InputLabel>
                  <Select
                    value={value}
                    label="UF"
                    onChange={(event) => {
                      onChange(event)
                      setSelectedUF(event.target.value)
                    }}
                    size="small"
                  >
                    {ufs.map((uf) => (
                      <MenuItem key={uf} value={uf}>
                        {uf}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          )}
        </Grid>

        <Grid item xs={12} sm={10}>
          <Controller
            name="cod_brasil_cidade"
            control={control}
            render={({ field }) => (
              <Autocomplete
                options={cities.map((x) => x.id)}
                getOptionLabel={(item) =>
                  cities.find((x) => Number(x.id) === Number(item))?.label ??
                  'Selecione'
                }
                value={field.value > 0 ? field.value : null}
                loading={isLoadingCity}
                loadingText="Carregando..."
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Cidade"
                    error={!!errors.cod_brasil_cidade}
                    helperText={errors.cod_brasil_cidade?.message}
                    disabled={!selectedUF}
                    size="small"
                  />
                )}
                onChange={(event, values) => field.onChange(values)}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          {errorMessage && (
            <Alert severity="error" onClose={() => setErrorMessage('')}>
              {errorMessage}
            </Alert>
          )}
          {successMessage && (
            <Alert severity="success" onClose={() => setSuccessMessage('')}>
              {successMessage}
            </Alert>
          )}
          <LoadingButton
            loading={isLoading}
            loadingPosition="start"
            startIcon={<Save />}
            onClick={handleSubmit(onSubmit)}
            color={errorMessage ? 'error' : 'primary'}
            variant="contained"
            fullWidth
          >
            Salvar
          </LoadingButton>
        </Grid>

        <Grid item xs={12} mt={6}>
          <ChangePassword />
        </Grid>
      </Grid>
    </Container>
  )
}

export default UserDetails
