/* eslint-disable @typescript-eslint/no-explicit-any */
import { zodResolver } from '@hookform/resolvers/zod'
import { PersonAddAlt, Refresh, Search } from '@mui/icons-material'
import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import { LoadingButton } from '@mui/lab'
import {
  Alert,
  Box,
  Breadcrumbs,
  Button,
  Card,
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Modal,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { DataGrid, GridColDef, GridRowsProp } from '@mui/x-data-grid'
import * as PhosphorIcons from '@phosphor-icons/react'
import * as React from 'react'
import { useForm } from 'react-hook-form'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { apiV1 } from 'services'
import { IEvento } from 'types/evento'
import { datagrid, icons } from 'utils'
import { getErrorMessage } from 'utils/AppError'
import { moneyFormatter } from 'utils/formatter'
import { z } from 'zod'

export const MOBILE_COLUMNS = {
  nome: true,
  _quantidade_app: false,
  _quantidade_site: false,
  _quantidade_fisico: false,
  usuario_permissao_evento: true,
  actions: false,
}

export const ALL_COLUMNS = {
  nome: true,
  _quantidade_app: true,
  _quantidade_site: true,
  _quantidade_fisico: true,
  usuario_permissao_evento: true,
  actions: true,
}

const addPermissionsSchema = z.object({
  email: z
    .string({ required_error: 'É necessário preencher o e-mail' })
    .email('Preencha um e-mail válido'),
  permissions: z
    .array(z.number())
    .min(1, 'Selecione pelo menos uma permissão antes de adicionar'),
})

type AddPermissionsFormSchemaType = z.infer<typeof addPermissionsSchema>

interface EventStaffProps {
  eventId: number
  isAdmin?: boolean
}

const EventStaff = ({ eventId, isAdmin = false }: EventStaffProps) => {
  const [loadingData, setLoadingData] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState('')
  const [event, setEvent] = React.useState<IEvento>()
  const [searchQuery, setSearchQuery] = React.useState('')
  const [open, setOpen] = React.useState(false)
  const [isLoadingAdd, setIsLoadingAdd] = React.useState(false)

  const [permissions, setPermissions] = React.useState([
    { id: 1, descricao: 'Vender por link', checked: false },
    { id: 2, descricao: 'Produtor', checked: false },
    { id: 3, descricao: 'Vender ingressos via APP', checked: false },
    { id: 4, descricao: 'Validar ingressos', checked: false },
    { id: 5, descricao: 'Ver relatórios de vendas', checked: false },
    { id: 6, descricao: 'Check-in manual', checked: false },
    { id: 7, descricao: 'Parceiro', checked: false },
    { id: 10, descricao: 'PDV', checked: false },
  ])

  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('sm'))
  const [columnVisible, setColumnVisible] = React.useState(ALL_COLUMNS)
  React.useEffect(() => {
    const newColumns = matches ? ALL_COLUMNS : MOBILE_COLUMNS
    setColumnVisible(newColumns)
  }, [matches])

  const navigate = useNavigate()

  const loadEvent = React.useCallback(async () => {
    try {
      setLoadingData(true)
      const { data } = isAdmin
        ? await apiV1.admin.eventService.getDetails(Number(eventId))
        : await apiV1.producer.eventService.getDetails(Number(eventId))
      setEvent(data)
    } catch (error) {
      setErrorMessage(getErrorMessage(error))
    } finally {
      setLoadingData(false)
    }
  }, [eventId, isAdmin])

  React.useEffect(() => {
    loadEvent()
  }, [loadEvent])

  const {
    register,
    handleSubmit,
    setValue,
    resetField,
    formState: { errors },
  } = useForm<AddPermissionsFormSchemaType>({
    resolver: zodResolver(addPermissionsSchema),
    defaultValues: {
      email: '',
      permissions: [],
    },
  })

  const handleAddUserPermissions = React.useCallback(
    async (formData: AddPermissionsFormSchemaType) => {
      try {
        setIsLoadingAdd(true)
        if (event) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { data } = isAdmin
            ? await apiV1.admin.eventService.addUserPermissions(
                event?.id,
                formData.email,
                formData.permissions,
              )
            : await apiV1.eventService.addUserPermissions(
                event?.id,
                formData.email,
                formData.permissions,
              )
          setErrorMessage('')
          resetField('email')
        }
      } catch (error) {
        setErrorMessage(getErrorMessage(error))
      } finally {
        setIsLoadingAdd(false)
      }
    },
    [event, isAdmin, resetField],
  )

  React.useEffect(() => {
    setValue(
      'permissions',
      permissions.filter((x) => x.checked).map((x) => x.id),
    )
  }, [permissions, setValue])

  const list =
    event?._staff?.map((x) => ({
      ...x,
      nome: [x.nome, x.sobrenome].join(' ').trim(),
      telefone: [x.ddd, x.telefone].join(''),
    })) ?? []

  const rows: GridRowsProp =
    searchQuery.length > 0
      ? list.filter(
          (x) =>
            x.nome
              .toLocaleLowerCase()
              .includes(searchQuery.toLocaleLowerCase()) ||
            x.telefone
              .toLocaleLowerCase()
              .includes(searchQuery.toLocaleLowerCase()) ||
            x.email
              ?.toLocaleLowerCase()
              .includes(searchQuery.toLocaleLowerCase()) ||
            x.id.toString().includes(searchQuery),
        )
      : list

  const columns: GridColDef[] = [
    { field: 'nome', headerName: 'Nome', flex: 1 },
    { field: '_quantidade_app', headerName: 'Qtd. APP' },
    { field: '_quantidade_site', headerName: 'Qtd. Link' },
    { field: '_quantidade_fisico', headerName: 'Qtd. Físico' },
    {
      field: 'usuario_permissao_evento',
      headerName: 'Permissões',
      renderCell: (cellValues) => {
        return cellValues.value.map(({ permissao }: any) => (
          <React.Fragment key={permissao.id}>
            {icons.getPermissionIcon(permissao.id)}
          </React.Fragment>
        ))
      },
      width: 120,
    },
    {
      field: 'actions',
      headerName: 'Ações',
      renderCell: (cellValues) => {
        return (
          <Link
            component={RouterLink}
            to={`/organizador/eventos/${eventId}/staff/${cellValues.id}`}
          >
            Detalhes
          </Link>
        )
      },
    },
  ]

  const handleChangePermission = (event: any) => {
    const newPermissions = permissions.map((permission) => {
      if (permission.id === Number(event.target.value)) {
        permission.checked = event.target.checked
      }
      return permission
    })
    setPermissions(newPermissions)
  }

  const totalValueApp = React.useMemo(
    () =>
      event?._staff?.reduce(
        (total, user) => total + Number(user._valor_app),
        0,
      ) ?? 0,
    [event?._staff],
  )

  const totalValueSite = React.useMemo(
    () =>
      event?._staff?.reduce(
        (total, user) => total + Number(user._valor_site),
        0,
      ) ?? 0,
    [event?._staff],
  )

  const totalValueFisico = React.useMemo(
    () =>
      event?._staff?.reduce(
        (total, user) => total + Number(user._valor_fisico),
        0,
      ) ?? 0,
    [event?._staff],
  )

  return (
    <React.Fragment>
      <Box role="presentation" sx={{ pt: 2, pb: 2 }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            component={RouterLink}
            underline="hover"
            color="inherit"
            to="/organizador/eventos"
          >
            Eventos
          </Link>
          <Link
            component={RouterLink}
            underline="hover"
            color="inherit"
            to={`/organizador/eventos/${event?.id}`}
          >
            {event?.titulo}
          </Link>
          <Typography color="text.primary">Equipe</Typography>
        </Breadcrumbs>
      </Box>

      <Grid container spacing={2} p={2}>
        <Grid item xs={12} sm={4}>
          <Card
            variant="outlined"
            color="#FFFFFF"
            sx={{ backgroundColor: '#ed5454', borderColor: '#bd4242', p: 2 }}
          >
            <Typography variant="h4" color="#FFFFFF" mb={1}>
              Link
            </Typography>
            <Stack direction="row">
              <PhosphorIcons.Link size={48} color="#FFFFFF" />
              <Stack
                sx={{
                  borderLeft: 'solid #FFFFFF 2px',
                  justifyContent: 'center',
                  ml: 1,
                  pl: 1,
                }}
              >
                <Typography variant="subtitle2" color="#FFFFFF">
                  Qtd.: 0
                </Typography>
                <Typography variant="subtitle2" color="#FFFFFF">
                  Total: {moneyFormatter.format(totalValueSite)}
                </Typography>
              </Stack>
            </Stack>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card
            variant="outlined"
            color="#FFFFFF"
            sx={{ backgroundColor: '#5456ed', borderColor: '#4c42bd', p: 2 }}
          >
            <Typography variant="h4" color="#FFFFFF" mb={1}>
              App
            </Typography>
            <Stack direction="row">
              <PhosphorIcons.DeviceMobile size={48} color="#FFFFFF" />
              <Stack
                sx={{
                  borderLeft: 'solid #FFFFFF 2px',
                  justifyContent: 'center',
                  ml: 1,
                  pl: 1,
                }}
              >
                <Typography variant="subtitle2" color="#FFFFFF">
                  Qtd.: 0
                </Typography>
                <Typography variant="subtitle2" color="#FFFFFF">
                  Total: {moneyFormatter.format(totalValueApp)}
                </Typography>
              </Stack>
            </Stack>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card
            variant="outlined"
            color="#FFFFFF"
            sx={{
              backgroundColor: '#54ed7d',
              borderColor: '#42bd48',
              p: 2,
            }}
          >
            <Typography variant="h4" color="#FFFFFF" mb={1}>
              PDV
            </Typography>
            <Stack direction="row">
              <PhosphorIcons.Printer size={48} color="#FFFFFF" />
              <Stack
                sx={{
                  borderLeft: 'solid #FFFFFF 2px',
                  justifyContent: 'center',
                  ml: 1,
                  pl: 1,
                }}
              >
                <Typography variant="subtitle2" color="#FFFFFF">
                  Qtd.: 0
                </Typography>
                <Typography variant="subtitle2" color="#FFFFFF">
                  Total: {moneyFormatter.format(totalValueFisico)}
                </Typography>
              </Stack>
            </Stack>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Stack direction="row" gap={1}>
            <Button
              variant="outlined"
              component={RouterLink}
              to={`/organizador/eventos/${eventId}/relatorios/usuario`}
            >
              Relatório geral por usuário
            </Button>

            {/* <Button
              variant="outlined"
              component={RouterLink}
              to={`/organizador/eventos/${eventId}/relatorios/usuario-lote`}
            >
              Ranking por lote
            </Button> */}
          </Stack>
        </Grid>

        <Grid item xs={7} sm={7}>
          <TextField
            label="Pesquisar"
            variant="outlined"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            onChange={(e) => setSearchQuery(e.target.value)}
            value={searchQuery}
            size="small"
          />
        </Grid>
        <Grid item xs={5} sm={2}>
          <LoadingButton
            loading={loadingData}
            loadingPosition="start"
            startIcon={<Refresh />}
            variant="contained"
            onClick={loadEvent}
            fullWidth
          >
            Atualizar
          </LoadingButton>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Button
            variant="contained"
            color="success"
            startIcon={<AddIcon />}
            onClick={() => setOpen(true)}
            fullWidth
          >
            Adicionar membro
          </Button>
        </Grid>

        <Grid item xs={12}>
          <DataGrid
            rows={rows}
            columns={columns}
            autoHeight
            localeText={datagrid.localizedTextsMap}
            columnVisibilityModel={columnVisible}
            onRowClick={(params) =>
              navigate(`/organizador/eventos/${eventId}/staff/${params.id}`)
            }
          />
        </Grid>
      </Grid>

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        sx={{ overflow: 'scroll' }}
      >
        <Container
          maxWidth="md"
          sx={{
            bgcolor: 'grey.50',
          }}
        >
          <Box sx={{ margin: 'auto', padding: 2 }}>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h6">Adicionar membro na equipe</Typography>
              <IconButton
                aria-label="delete"
                size="large"
                color="error"
                onClick={() => {
                  setOpen(false)
                  loadEvent()
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            </Stack>

            <Box sx={{ flex: 0 }} component="form" noValidate>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                }}
              >
                <TextField
                  label="E-mail"
                  error={!!errors.email}
                  helperText={errors.email?.message}
                  fullWidth
                  required
                  size="small"
                  type="email"
                  {...register('email')}
                />
              </Box>
              {errorMessage && (
                <Alert severity="error" onClose={() => setErrorMessage('')}>
                  {errorMessage}
                </Alert>
              )}

              <FormGroup sx={{ display: 'flex', flexDirection: 'row' }}>
                {permissions.map((permission) => (
                  <FormControlLabel
                    key={permission.id}
                    control={<Checkbox />}
                    value={permission.id}
                    label={
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        {icons.getPermissionIcon(permission.id)}
                        {permission.descricao}
                      </Box>
                    }
                    checked={permission.checked}
                    onChange={(e) => {
                      handleChangePermission(e)
                    }}
                    style={{ width: '100%' }}
                  />
                ))}
              </FormGroup>
              {errors.permissions && (
                <FormHelperText error>
                  {errors.permissions.message}
                </FormHelperText>
              )}
            </Box>
            <Stack direction="row" justifyContent="space-between">
              <Button
                variant="contained"
                onClick={() => {
                  setOpen(false)
                  loadEvent()
                }}
                color="error"
              >
                Fechar
              </Button>
              <LoadingButton
                loading={isLoadingAdd}
                loadingPosition="start"
                startIcon={<PersonAddAlt />}
                variant="contained"
                onClick={handleSubmit(handleAddUserPermissions)}
              >
                Adicionar
              </LoadingButton>
            </Stack>
          </Box>
        </Container>
      </Modal>
    </React.Fragment>
  )
}

export default EventStaff
