import clientProducer from './clientProducer'

const eventImage = (data: FormData) => {
  return clientProducer.post<string>(`upload/event`, data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}

const uploadService = {
  eventImage,
}

export default uploadService
