import { Person } from '@mui/icons-material'
import LocalActivityIcon from '@mui/icons-material/LocalActivity'
import { Box, Button, Typography } from '@mui/material'
import Avatar from '@mui/material/Avatar'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Tooltip from '@mui/material/Tooltip'
import { constants } from 'config'
import { useUser } from 'contexts/userContext'
import * as React from 'react'
import { Link as RouterLink, useNavigate } from 'react-router-dom'

export default function AccountMenu() {
  const { user, logout } = useUser()
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  return (
    <React.Fragment>
      <Tooltip title={[user.nome, user.sobrenome].join(' ')}>
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <Avatar
            sx={{ width: 32, height: 32, m: 1, bgcolor: 'secondary.main' }}
          >
            {user.nome?.substring(0, 1)}
            {user.sobrenome?.substring(0, 1)}
          </Avatar>
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            p: 2,
          }}
        >
          {user.imagem ? (
            <Avatar
              alt={[user.nome, user.sobrenome].join(' ')}
              src={user.imagem ?? constants.URL_FTP + user.imagem}
              sx={{ width: 56, height: 56, m: 1 }}
            />
          ) : (
            <Avatar
              sx={{ width: 56, height: 56, m: 1, bgcolor: 'secondary.main' }}
            >
              {user.nome?.substring(0, 1)}
              {user.sobrenome?.substring(0, 1)}
            </Avatar>
          )}

          <Typography variant="subtitle1">
            {[user.nome, user.sobrenome].join(' ')}
          </Typography>
          <Typography variant="caption">{user.email}</Typography>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          <MenuItem onClick={() => navigate('usuario')}>
            <ListItemIcon>
              <Person fontSize="small" />
            </ListItemIcon>
            Meus dados
          </MenuItem>
          <MenuItem onClick={() => navigate('usuario/pedidos')}>
            <ListItemIcon>
              <LocalActivityIcon fontSize="small" />
            </ListItemIcon>
            Meus ingressos
          </MenuItem>
        </Box>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            p: 2,
          }}
        >
          <Button
            variant="outlined"
            onClick={logout}
            sx={{ textTransform: 'none' }}
          >
            Sair da conta
          </Button>
        </Box>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            p: 2,
            gap: 2,
            alignItems: 'center',
          }}
        >
          <Typography
            variant="caption"
            component={RouterLink}
            to="/termos-de-uso"
            color="inherit"
            sx={{ textDecoration: 'none' }}
          >
            Termos de uso
          </Typography>
          •
          <Typography
            variant="caption"
            component={RouterLink}
            to="/politica-de-privacidade"
            color="inherit"
            sx={{ textDecoration: 'none' }}
          >
            Política de Privacidade
          </Typography>
        </Box>
      </Menu>
    </React.Fragment>
  )
}
