import * as React from 'react'
import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import {
  Alert,
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  Link,
  Paper,
} from '@mui/material'
import validationSchema, { SignupFields } from './validationSchema'
import { functionWithLoading } from 'utils'
import { apiV1 } from 'services'
import { Form, Formik } from 'formik'
import { useNavigate, Link as RouterLink } from 'react-router-dom'
import Step1 from './Step1'
import Step3 from './Step3'
import moment from 'moment'
import { LoadingButton } from '@mui/lab'
import { ArrowLeft, Save } from '@mui/icons-material'

export default function SignUpPage() {
  const [activeStep, setActiveStep] = React.useState(0)
  const [skipped, setSkipped] = React.useState(new Set<number>())
  const [loading, setLoading] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState('')
  const [successMessage, setSuccessMessage] = React.useState('')
  const [useTerm, setUseTerm] = React.useState(true)
  const navigate = useNavigate()

  const handleSubmit = React.useCallback(
    (values: SignupFields) => {
      if (!useTerm) return
      functionWithLoading(
        apiV1.userService
          .create(values)
          .then(() => {
            setSuccessMessage('Usuário cadastrado com sucesso')
            navigate('/')
          })
          .catch((error) => console.log(error)),
        setLoading,
      )
    },
    [navigate, useTerm],
  )

  const isStepSkipped = React.useCallback(
    (step: number) => {
      return skipped.has(step)
    },
    [skipped],
  )

  const handleNext = React.useCallback(() => {
    let newSkipped = skipped
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values())
      newSkipped.delete(activeStep)
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1)
    setSkipped(newSkipped)
  }, [activeStep, isStepSkipped, skipped])

  const handleBack = React.useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }, [])

  const handleReset = React.useCallback(() => {
    setActiveStep(0)
  }, [])

  const steps = React.useMemo(
    () => [
      {
        title: 'E-mail, CPF e telefone',
        component: <Step1 handleNext={handleNext} />,
      },
      // {
      //   title: "Telefone",
      //   component: <Step2 handleNext={handleNext} />,
      // },
      {
        title: 'Dados pessoais',
        component: <Step3 />,
      },
    ],
    [handleNext],
  )

  return (
    <Container maxWidth="md" component={Paper} sx={{ py: 1, my: 2 }}>
      <Typography variant="h4" textAlign="center" mt={1} mb={3}>
        Faça seu cadastro
      </Typography>
      {errorMessage && (
        <Alert
          severity="error"
          sx={{ mt: 1 }}
          onClose={() => setErrorMessage('')}
        >
          {errorMessage}
        </Alert>
      )}
      {successMessage && (
        <Alert
          severity="success"
          sx={{ mt: 1 }}
          onClose={() => setSuccessMessage('')}
        >
          {successMessage}
        </Alert>
      )}
      <Formik
        initialValues={
          {
            email: '',
            confirmarEmail: '',
            nome: '',
            cpf: '',
            data_nascimento: moment().format('YYYY-MM-DD'),
            telefone: '',
            sexo: '0',
            cod_brasil_cidade: 0,
            senha: '',
            confirmarSenha: '',
          } as SignupFields
        }
        validateOnChange={true}
        validationSchema={validationSchema}
        validateOnMount={true}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {() => (
          <Form>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((step, index) => {
                const stepProps: { completed?: boolean } = {}
                const labelProps: {
                  optional?: React.ReactNode
                } = {}
                if (isStepSkipped(index)) {
                  stepProps.completed = false
                }
                return (
                  <Step key={step.title} {...stepProps}>
                    <StepLabel {...labelProps}>{step.title}</StepLabel>
                  </Step>
                )
              })}
            </Stepper>
            {activeStep === steps.length ? (
              <React.Fragment>
                <Typography sx={{ mt: 2, mb: 1 }}>
                  All steps completed - you&apos;re finished
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <Box sx={{ flex: '1 1 auto' }} />
                  <Button onClick={handleReset}>Reset</Button>
                </Box>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Grid container spacing={2} mt={2} mb={2}>
                  {steps[activeStep].component}
                  {activeStep === steps.length - 1 && (
                    <Grid item xs={12}>
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox checked={useTerm} />}
                          label={
                            <Typography>
                              Concordo com os{' '}
                              <Link
                                href={
                                  'https://zedoingresso.com.br/termos-de-uso'
                                }
                                target="_blank"
                              >
                                termos de uso
                              </Link>{' '}
                              e com a{' '}
                              <Link
                                href={
                                  'https://zedoingresso.com.br/politica-de-privacidade'
                                }
                                target="_blank"
                              >
                                política de privacidade
                              </Link>
                              .
                            </Typography>
                          }
                          sx={{ alignItems: 'center' }}
                          onChange={() => setUseTerm((s) => !s)}
                        />
                      </FormGroup>
                    </Grid>
                  )}
                  <Grid
                    item
                    xs={12}
                    sx={{ display: 'flex', flexDirection: 'row', py: 2 }}
                  >
                    {activeStep !== 0 && (
                      <Button
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                        variant="outlined"
                        startIcon={<ArrowLeft />}
                      >
                        Anterior
                      </Button>
                    )}

                    <Box sx={{ flex: '1 1 auto' }} />

                    {activeStep === steps.length - 1 && (
                      <LoadingButton
                        loading={loading}
                        loadingPosition="start"
                        variant="contained"
                        type="submit"
                        disabled={!useTerm}
                        startIcon={<Save />}
                      >
                        Salvar
                      </LoadingButton>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{ display: 'flex', justifyContent: 'flex-end', py: 2 }}
                  >
                    <Typography variant="body1">
                      Já tem cadastro?{' '}
                      <Link component={RouterLink} to={'/login'}>
                        entre aqui
                      </Link>
                    </Typography>
                  </Grid>
                </Grid>
              </React.Fragment>
            )}
          </Form>
        )}
      </Formik>
    </Container>
  )
}
