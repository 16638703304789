/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import { Card, CardContent } from '@mui/material'
import {
  ArcElement,
  Chart as ChartJS,
  ChartOptions,
  Legend,
  Tooltip,
} from 'chart.js'
import { memo } from 'react'
import { Doughnut } from 'react-chartjs-2'

import { moneyFormatter } from 'utils/formatter'
import { SalesResumeResponseSectors } from '../../../components/SalesResume'

ChartJS.register(ArcElement, Tooltip, Legend)

const doughnutText = {
  id: 'doughnutText',
  afterDatasetsDraw(
    chart: {
      ctx: any
      data: any
      options: any
      _active: any
      chartArea: {
        top: any
        bottom: any
        left: any
        right: any
        width: any
        height: any
      }
    },
    _args: any,
    _pluginOptions: any,
  ) {
    const {
      ctx,
      data,
      chartArea: { bottom, width },
    } = chart
    ctx.save()
    ctx.font = 'bold 28px sans-serif'
    ctx.textAlign = 'center'
    ctx.textBaseline = 'center'

    const x = width / 2
    const y = bottom / 2 + 20

    ctx.fillText('Qtd. ' + data.datasets[0].totalQuantity, x, y)
    ctx.fillText(moneyFormatter.format(data.datasets[0].totalValue), x, y + 40)
  },
}

const pieDoughnutLegendClickHandler: any =
  ChartJS.overrides.doughnut.plugins.legend.onClick

const options: ChartOptions<'doughnut'> = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
      onClick: function (e, legendItem, legend) {
        pieDoughnutLegendClickHandler(e, legendItem, legend)

        const ci = legend.chart as unknown as ChartJS<
          'doughnut',
          {
            totalQuantity: number
            totalValue: number
          }[]
        >
        const totalQuantity = ci.data.datasets.reduce((acc, curr) => {
          curr.data.forEach((d, i) => {
            if (ci.getDataVisibility(i)) {
              acc += d.totalQuantity
            }
          })
          return acc
        }, 0)

        const totalValue = ci.data.datasets.reduce((acc, curr) => {
          curr.data.forEach((d, i) => {
            if (ci.getDataVisibility(i)) {
              acc += d.totalValue
            }
          })
          return acc
        }, 0)

        ci.data.datasets = ci.data.datasets.map((dataset: any) => {
          dataset.totalQuantity = totalQuantity
          dataset.totalValue = totalValue
          return dataset
        })
      },
    },
    title: {
      display: true,
      text: 'Total de ingressos por setor',
    },
    tooltip: {
      callbacks: {
        label(context) {
          const data = context.chart.data.datasets[0].data[
            context.dataIndex
          ] as unknown as { totalQuantity: number; totalValue: number }
          return [
            `Qtd. por setor: ${data.totalQuantity}`,
            `Valor por setor: ${moneyFormatter.format(data.totalValue)}`,
          ]
        },
      },
    },
  },
  parsing: {
    key: 'totalQuantity',
  },
}

const plugins: any[] = [doughnutText]

interface SectorChartProps {
  sectors: SalesResumeResponseSectors[]
}

const SectorChart = ({ sectors }: SectorChartProps) => {
  const labels = sectors.map((sector) => sector.setor_titulo)
  const data_tickets = sectors.map((sector) =>
    sector.ingressos.reduce(
      (soma, ingresso) => ({
        totalQuantity: soma.totalQuantity + ingresso.qtd_ingressos,
        totalValue: soma.totalValue + ingresso.valor_total,
      }),
      {
        totalQuantity: 0,
        totalValue: 0,
      },
    ),
  )

  const totalQuantity = data_tickets.reduce((a, b) => a + b.totalQuantity, 0)
  const totalValue = data_tickets.reduce((a, b) => a + b.totalValue, 0)

  const data = {
    labels,
    datasets: [
      {
        label: 'Qtd. por setor',
        data: data_tickets,
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
        hoverOffset: 20,
        cutout: '60%',
        totalQuantity,
        totalValue,
      },
    ],
  }

  return (
    <Card>
      <CardContent>
        <Doughnut
          data={data}
          options={options}
          plugins={plugins}
          style={{ maxHeight: 450 }}
        />
      </CardContent>
    </Card>
  )
}

export default memo(SectorChart)
