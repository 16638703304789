import { IIngresso } from 'types/ingresso'
import clientAdmin from './client'

const getAll = (eventId: number) => {
  return clientAdmin.get<Array<IIngresso>>(`events/${eventId}/tickets`)
}

const get = (eventId: number, ticketId: number) => {
  return clientAdmin.get<IIngresso>(`events/${eventId}/tickets/${ticketId}`)
}

const create = (eventId: number, ticket: IIngresso) => {
  return clientAdmin.post<IIngresso>(`events/${eventId}/tickets`, { ticket })
}

const update = (eventId: number, ticket: IIngresso) => {
  return clientAdmin.put(`events/${eventId}/tickets/${ticket.id}`, { ticket })
}

const updateOrdinations = (eventId: number, tickets: IIngresso[]) => {
  return clientAdmin.patch(`events/${eventId}/tickets/ordinations`, {
    tickets,
  })
}

const toggleVisible = (eventId: number, ticketId: number) => {
  return clientAdmin.patch<IIngresso>(
    `events/${eventId}/tickets/${ticketId}/toggle-visible`,
  )
}

const remove = (eventId: number, ticketId: number) => {
  return clientAdmin.delete<IIngresso>(`events/${eventId}/tickets/${ticketId}`)
}

const ticketService = {
  getAll,
  get,
  create,
  update,
  updateOrdinations,
  toggleVisible,
  remove,
}

export default ticketService
