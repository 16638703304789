import { ILogin } from 'types/login'
import clientAdmin from './client'

const login = (email: string, password: string) => {
  return clientAdmin.post<ILogin>(`login`, {
    email,
    password,
  })
}

const adminService = {
  login,
}

export default adminService
