import { zodResolver } from '@hookform/resolvers/zod'
import { Close, Info } from '@mui/icons-material'
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Skeleton,
  Stack,
  TextField,
  Tooltip,
  darken,
  lighten,
  styled,
} from '@mui/material'
import {
  DataGrid,
  GridColDef,
  GridToolbar,
  GridValidRowModel,
} from '@mui/x-data-grid'
import { DatePicker } from '@mui/x-date-pickers'
import { Typography } from 'components/Typography'
import { useAdmin } from 'contexts/adminContext'
import dayjs from 'dayjs'
import { useCallback, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { apiV1 } from 'services'
import clientAdmin from 'services/api/v1/admin/client'
import { IPromoter } from 'types/promoter'
import { getErrorMessage } from 'utils/AppError'
import { moneyFormatter } from 'utils/formatter'
import { z } from 'zod'

const transactionClosingFormSchema = z.object({
  initialDate: z.date(),
  finalDate: z.date(),
  acertado: z.boolean().optional(),
  em_aberto: z.boolean().optional(),
  cancelado: z.boolean().optional(),
  cod_promoter: z
    .array(z.object({ id: z.number(), label: z.string() }))
    .transform((val) => val.map((x) => x.id))
    .optional(),
})

type TransactionClosingFormInput = z.input<typeof transactionClosingFormSchema>

type EventResponse = {
  id: number
  title: string
  date: string
  producer: string

  totalBilled: number
  totalTickets: number
  totalProducerFee: number
  totalGatewayFee: number
  totalSystemFee: number
  totalInstallmentFee: number
  totalServices: number
  totalDiscountCoupons: number
  totalTransferred: number
  totalLosses: number
  netTotal: number

  warnings: string[]
}
type TransactionClosingReportResponse = {
  events: EventResponse[]
  limitDate: string
}

export function TransactionClosingReport() {
  const [isLoading, setIsLoading] = useState(false)
  const [report, setReport] = useState<TransactionClosingReportResponse>({
    events: [] as EventResponse[],
  } as TransactionClosingReportResponse)
  const [producers, setProducers] = useState<{ id: number; label: string }[]>(
    [],
  )

  const {
    handleSubmit,
    control,
    formState: { isSubmitting, errors },
  } = useForm<TransactionClosingFormInput>({
    resolver: zodResolver(transactionClosingFormSchema),
    defaultValues: {
      initialDate: dayjs().startOf('year').toDate(),
      finalDate: dayjs().endOf('seconds').toDate(),
      acertado: true,
      em_aberto: true,
    },
  })

  const { addErrorMessage } = useAdmin()

  const fetchData = useCallback(
    async (formData: TransactionClosingFormInput) => {
      try {
        setIsLoading(true)
        const { data } = await clientAdmin.post(
          `/reports/transaction-closing`,
          formData,
        )
        setReport(data.report)
      } catch (error) {
        addErrorMessage(getErrorMessage(error))
      } finally {
        setIsLoading(false)
      }
    },
    [addErrorMessage],
  )

  useEffect(() => {
    async function onLoad() {
      const { data } = await apiV1.admin.promoterService.getList()
      setProducers(
        data.promoters.map((x: IPromoter) => ({
          id: x.id,
          label: x.razao_social,
        })),
      )
    }

    onLoad()
  }, [])

  const handleRemoveEventFromReport = useCallback(
    (eventId: number) => {
      if (report?.events && report.events.length > 0) {
        setReport((prev) => {
          if (!prev) return prev
          return {
            ...prev,
            events: prev.events.filter((event) => event.id !== eventId),
          }
        })
      }
    },
    [report?.events],
  )
  const columns: GridColDef<GridValidRowModel>[] = [
    {
      field: 'id',
      headerName: '#',
      width: 140,
      renderCell: (cell) => (
        <Stack direction="row" alignItems="center" gap={1}>
          <IconButton onClick={() => handleRemoveEventFromReport(cell.value)}>
            <Close color="error" />
          </IconButton>
          <Link to={`/admin/events/${cell.value}`} target="_blank">
            #{cell.value}
          </Link>
          {cell.row.warnings.length > 0 && (
            <Tooltip
              title={cell.row.warnings.reduce(
                (info: string, message: string) => `${info}\n- ${message}`,
                '',
              )}
            >
              <Info />
            </Tooltip>
          )}
        </Stack>
      ),
    },
    {
      field: 'title',
      headerName: 'Evento',
      width: 200,
    },
    {
      field: 'date',
      headerName: 'Data evento',
      width: 150,
      renderCell: (cell) =>
        dayjs(cell.value).add(3, 'hours').format('DD/MM/YYYY HH:mm'),
    },
    {
      field: 'producer',
      headerName: 'Produtora',
      width: 300,
    },
    {
      field: 'totalBilled',
      headerName: 'Faturamento',
      width: 120,
      renderCell: (cell) => moneyFormatter.format(cell.value),
    },
    {
      field: 'totalTickets',
      headerName: 'Ingressos',
      width: 120,
      renderCell: (cell) => moneyFormatter.format(cell.value),
    },
    {
      field: 'totalProducerFee',
      headerName: 'Taxa absorvida',
      width: 120,
      renderCell: (cell) => moneyFormatter.format(cell.value),
    },
    {
      field: 'totalGatewayFeeCharged',
      headerName: 'Taxa PDV',
      width: 120,
      renderCell: (cell) => moneyFormatter.format(cell.value),
    },
    // {
    //   field: 'totalInstallmentFee',
    //   headerName: 'Taxa parcelamento',
    //   width: 120,
    //   renderCell: (cell) => moneyFormatter.format(cell.value),
    // },
    {
      field: 'totalDiscountCoupons',
      headerName: 'Cupons',
      width: 120,
      renderCell: (cell) => moneyFormatter.format(cell.value),
    },
    {
      field: 'totalServices',
      headerName: 'Serviços',
      width: 120,
      renderCell: (cell) => moneyFormatter.format(cell.value),
    },
    {
      field: 'totalSystemFee',
      headerName: 'Taxa sistema',
      width: 120,
      renderCell: (cell) => moneyFormatter.format(cell.value),
    },
    // {
    //   field: 'totalLosses',
    //   headerName: 'Prejuízos',
    //   width: 120,
    //   renderCell: (cell) => moneyFormatter.format(cell.value),
    // },
    {
      field: 'totalTransferred',
      headerName: 'Repasse',
      width: 120,
      renderCell: (cell) => moneyFormatter.format(cell.value),
    },
    {
      field: 'totalGatewayFee',
      headerName: 'Gateway',
      width: 120,
      renderCell: (cell) => moneyFormatter.format(cell.value),
    },
    {
      field: 'netTotal',
      headerName: 'Líquido',
      width: 120,
      renderCell: (cell) => moneyFormatter.format(cell.value),
    },
  ]

  const { events, limitDate } = report

  return (
    <Grid container spacing={2} component={Paper} m={2}>
      <Grid item xs={12}>
        <Typography variant="h4">
          Relatório de faturamento por período
        </Typography>
      </Grid>
      <Grid container item xs={12} sm={6} spacing={2}>
        <Grid item xs={6}>
          <Controller
            control={control}
            name="initialDate"
            rules={{ required: true }}
            render={({ field }) => (
              <DatePicker
                label="Início do período"
                value={dayjs(field.value).toDate()}
                inputRef={field.ref}
                onChange={(date) => {
                  field.onChange(date)
                }}
                slotProps={{
                  textField: {
                    error: !!errors.initialDate,
                    helperText: errors.initialDate?.message,
                    fullWidth: true,
                  },
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            control={control}
            name="finalDate"
            rules={{ required: true }}
            render={({ field }) => (
              <DatePicker
                label="Fim do período"
                value={dayjs(field.value).toDate()}
                inputRef={field.ref}
                onChange={(date) => {
                  field.onChange(date)
                }}
                slotProps={{
                  textField: {
                    error: !!errors.finalDate,
                    helperText: errors.finalDate?.message,
                    fullWidth: true,
                  },
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            control={control}
            name="cod_promoter"
            render={({ field, formState: { errors } }) => (
              <Autocomplete
                disablePortal
                multiple
                options={producers}
                getOptionLabel={(option) => option.label}
                id="producers-autocomplete"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    key={params.id}
                    label="Produtora"
                    fullWidth
                    error={!!errors.cod_promoter}
                    helperText={errors.cod_promoter?.message}
                  />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option.id}>
                    {option.label}
                  </li>
                )}
                {...field}
                onChange={(event, value) => field.onChange(value)}
              />
            )}
          />
        </Grid>

        <Grid item xs={6}>
          <Controller
            control={control}
            name="acertado"
            render={({ field: { onChange, value } }) => (
              <FormControlLabel
                control={<Checkbox checked={value} onChange={onChange} />}
                label="Acertado"
              />
            )}
          />

          <Controller
            control={control}
            name="em_aberto"
            render={({ field: { onChange, value } }) => (
              <FormControlLabel
                control={<Checkbox checked={value} onChange={onChange} />}
                label="Em aberto"
              />
            )}
          />

          {/* <Controller
            control={control}
            name="cancelado"
            render={({ field: { onChange, value } }) => (
              <FormControlLabel
                control={<Checkbox checked={value} onChange={onChange} />}
                label="Cancelados"
              />
            )}
          /> */}
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            onClick={handleSubmit(fetchData)}
            disabled={isSubmitting}
          >
            Carregar dados
          </Button>
        </Grid>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Typography variant="body1">
          Faturamento:{' '}
          {moneyFormatter.format(events.reduce((a, b) => a + b.totalBilled, 0))}
        </Typography>
        <Typography variant="body1">
          Ingressos:{' '}
          {moneyFormatter.format(
            events.reduce((a, b) => a + b.totalTickets, 0),
          )}
        </Typography>
        <Typography variant="body1">
          Taxa produtor:{' '}
          {moneyFormatter.format(
            events.reduce((a, b) => a + Number(b.totalProducerFee), 0),
          )}
        </Typography>
        <Typography variant="body1">
          Cupons:{' '}
          {moneyFormatter.format(
            events.reduce((a, b) => a + Number(b.totalDiscountCoupons), 0),
          )}
        </Typography>
        <Typography variant="body1">
          Taxa gateway:{' '}
          {moneyFormatter.format(
            events.reduce((a, b) => a + Number(b.totalGatewayFee), 0),
          )}
        </Typography>
        <Typography variant="body1">
          Repasse:{' '}
          {moneyFormatter.format(
            events.reduce((a, b) => a + Number(b.totalTransferred), 0),
          )}
        </Typography>
        <Typography variant="body1">
          Liquido:{' '}
          {moneyFormatter.format(
            events.reduce((a, b) => a + Number(b.netTotal), 0),
          )}
        </Typography>
      </Grid>
      {!!limitDate && (
        <Grid item xs={12}>
          <Typography variant="h6" color="red">
            Relatório atualizado até dia{' '}
            {dayjs(report.limitDate).format('DD/MM/YYYY')}
          </Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        {isLoading ? (
          <PageLoading />
        ) : (
          <StyledDataGrid
            rows={report?.events ?? []}
            columns={columns}
            getRowClassName={(params) =>
              `super-app-theme--${params.row.warnings.length > 0 ? 'row-error' : 'row-success'}`
            }
            autoHeight
            disableRowSelectionOnClick
            slots={{ toolbar: GridToolbar }}
            density="compact"
          />
        )}
      </Grid>
    </Grid>
  )
}

function PageLoading() {
  return (
    <Grid item xs={12}>
      <Skeleton height={20} />
      <Skeleton />
      <Skeleton />
      <Skeleton />
      <Skeleton />
    </Grid>
  )
}

const getBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7)

const getHoverBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6)

const getSelectedBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5)

const getSelectedHoverBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.4) : lighten(color, 0.4)

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  '& .super-app-theme--Open': {
    backgroundColor: getBackgroundColor(
      theme.palette.info.main,
      theme.palette.mode,
    ),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.info.main,
        theme.palette.mode,
      ),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.info.main,
        theme.palette.mode,
      ),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.info.main,
          theme.palette.mode,
        ),
      },
    },
  },
  '& .super-app-theme--row-success': {
    backgroundColor: getBackgroundColor(
      theme.palette.success.main,
      theme.palette.mode,
    ),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.success.main,
        theme.palette.mode,
      ),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.success.main,
        theme.palette.mode,
      ),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.success.main,
          theme.palette.mode,
        ),
      },
    },
  },
  '& .super-app-theme--PartiallyFilled': {
    backgroundColor: getBackgroundColor(
      theme.palette.warning.main,
      theme.palette.mode,
    ),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.warning.main,
        theme.palette.mode,
      ),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.warning.main,
        theme.palette.mode,
      ),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.warning.main,
          theme.palette.mode,
        ),
      },
    },
  },
  '& .super-app-theme--row-error': {
    backgroundColor: getBackgroundColor(
      theme.palette.error.main,
      theme.palette.mode,
    ),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.error.main,
        theme.palette.mode,
      ),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.error.main,
        theme.palette.mode,
      ),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.error.main,
          theme.palette.mode,
        ),
      },
    },
  },
  '& .super-app-theme--row-warning': {
    backgroundColor: getBackgroundColor(
      theme.palette.warning.main,
      theme.palette.mode,
    ),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.warning.main,
        theme.palette.mode,
      ),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.warning.main,
        theme.palette.mode,
      ),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.warning.main,
          theme.palette.mode,
        ),
      },
    },
  },
}))
