import { Box, Typography } from '@mui/material'
import React from 'react'

const NoMatch: React.FC = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        background: 'url("/img/404.jpg")',
        backgroundSize: 'cover',
        flex: 1,
        height: '50vh',
        width: '100%',
        alignItems: 'center',
      }}
    >
      <Typography variant="h3" sx={{ color: '#FFF', backgroundColor: '#000' }}>
        Página não encontrada
      </Typography>
    </Box>
  )
}

export default NoMatch
