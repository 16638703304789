import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import ManageSearchIcon from '@mui/icons-material/ManageSearch'
import MenuIcon from '@mui/icons-material/Menu'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import {
  Alert,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Snackbar,
} from '@mui/material'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import MuiDrawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import { createTheme, styled, ThemeProvider } from '@mui/material/styles'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { Copyright } from 'components'
import { useAdmin } from 'contexts/adminContext'
import pt from 'date-fns/locale/pt-BR'
import * as React from 'react'
import { Link, useNavigate } from 'react-router-dom'

const drawerWidth = 240

interface AppBarProps extends MuiAppBarProps {
  open?: boolean
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: 0,
    }),
  },
}))

const mdTheme = createTheme({
  palette: {
    primary: {
      main: '#eb4034',
    },
  },
})

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function AdminLayout({ children }: any) {
  const [open, setOpen] = React.useState(false)
  const { admin, logout, errors, closeError, messages, removeMessage } =
    useAdmin()
  const navigate = useNavigate()
  const toggleDrawer = () => {
    setOpen(!open)
  }

  React.useEffect(() => {
    if (window.innerWidth > mdTheme.breakpoints.values.md) {
      setOpen(true)
    }
  }, [])

  return (
    <ThemeProvider theme={mdTheme}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={pt}>
        <Box sx={{ display: 'flex' }}>
          <AppBar position="absolute" open={open}>
            <Toolbar
              sx={{
                pr: '24px', // keep right padding when drawer closed
              }}
            >
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={toggleDrawer}
                sx={{
                  marginRight: '36px',
                  ...(open && { display: 'none' }),
                }}
              >
                <MenuIcon />
              </IconButton>
              <Typography
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
                sx={{ flexGrow: 1 }}
              >
                Painel administrativo
              </Typography>
              <Typography variant="overline">{admin.nome}</Typography>
              <IconButton color="inherit" onClick={logout}>
                <ExitToAppIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <Drawer variant="permanent" open={open}>
            <Toolbar
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                px: [1],
              }}
            >
              <Link to="/admin">
                <img
                  src="https://images.zedoingresso.com.br/zedoingresso/imgs/header-logo.png"
                  alt="Zé do Ingresso"
                  height={50}
                />
              </Link>

              <IconButton onClick={toggleDrawer}>
                <ChevronLeftIcon />
              </IconButton>
            </Toolbar>
            <Divider />
            <List component="nav">
              <ListItemButton
                onClick={() => navigate(`/admin/pedidos-suspeitos`)}
              >
                <ListItemIcon>
                  <StarBorderIcon />
                </ListItemIcon>
                <ListItemText primary="Pedidos suspeitos" />
              </ListItemButton>
              <Divider sx={{ my: 1 }} />
              <ListItemButton onClick={() => navigate(`/admin/events`)}>
                <ListItemIcon>
                  <StarBorderIcon />
                </ListItemIcon>
                <ListItemText primary="Eventos" />
              </ListItemButton>
              <Divider sx={{ my: 1 }} />
              <ListItemButton onClick={() => navigate(`/admin/reports`)}>
                <ListItemIcon>
                  <StarBorderIcon />
                </ListItemIcon>
                <ListItemText primary="Relatórios" />
              </ListItemButton>
              <Divider sx={{ my: 1 }} />
              <ListItemButton onClick={() => navigate(`/admin/search-order`)}>
                <ListItemIcon>
                  <StarBorderIcon />
                </ListItemIcon>
                <ListItemText primary="Pesquisar pedido" />
              </ListItemButton>
              <Divider sx={{ my: 1 }} />
              <ListItemButton
                onClick={() => navigate(`/admin/solicitacoes-de-saque`)}
              >
                <ListItemIcon>
                  <AttachMoneyIcon />
                </ListItemIcon>
                <ListItemText primary="Solicitações de saque" />
              </ListItemButton>
              <Divider sx={{ my: 1 }} />
              <ListItemButton onClick={() => navigate(`/admin/new-events`)}>
                <ListItemIcon>
                  <StarBorderIcon />
                </ListItemIcon>
                <ListItemText primary="Novos eventos" />
              </ListItemButton>
              <Divider sx={{ my: 1 }} />
              <ListItemButton onClick={() => navigate(`/admin/produtoras`)}>
                <ListItemIcon>
                  <StarBorderIcon />
                </ListItemIcon>
                <ListItemText primary="Produtoras" />
              </ListItemButton>
              <Divider sx={{ my: 1 }} />
              <ListItemButton
                onClick={() => navigate(`/admin/pedidos/em-analise`)}
              >
                <ListItemIcon>
                  <ManageSearchIcon />
                </ListItemIcon>
                <ListItemText primary="Pedidos em análise" />
              </ListItemButton>
              <Divider sx={{ my: 1 }} />
            </List>
          </Drawer>
          <Box
            component="main"
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === 'light'
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900],
              flexGrow: 1,
              height: '100vh',
              overflow: 'auto',
            }}
          >
            <Box>
              {errors.map((message, index) => (
                <Alert
                  key={index}
                  severity="error"
                  onClose={() => closeError(index)}
                >
                  {message}
                </Alert>
              ))}
              {messages.map((message, index) => (
                <Snackbar
                  key={index}
                  open
                  autoHideDuration={6000}
                  onClose={() => removeMessage(index)}
                  anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                >
                  <Alert
                    severity={message.type === 'ERROR' ? 'error' : 'success'}
                    onClose={() => removeMessage(index)}
                  >
                    {message.message}
                  </Alert>
                </Snackbar>
              ))}
              {children}
              <Copyright sx={{ pt: 2 }} />
            </Box>
          </Box>
        </Box>
      </LocalizationProvider>
    </ThemeProvider>
  )
}
