import { IEvento } from 'types/evento'
import clientUser from './clientUser'

const get = (eventId: number) => {
  return clientUser.get<IEvento>(`events/${eventId}`)
}

const validateDiscountCoupon = (
  eventId: number,
  discountCouponCode: string,
) => {
  return clientUser.post(`user-website/events/${eventId}/discount-coupon`, {
    discountCouponCode,
  })
}

const eventService = {
  get,
  validateDiscountCoupon,
}

export default eventService
