import * as yup from 'yup'

const validationSchema = yup.object({
  email: yup
    .string()
    .email('É necessário preencher um email válido')
    .required('É necessário preencher o email')
    .nullable(),
  password: yup
    .string()
    // .min(8, "A senha precisa ter no mínimo 8 caracteres")
    .required('É necessário preencher a senha')
    .nullable(),
})

export default validationSchema
