import { Button, Card, CardHeader, Grid, Stack, TextField } from '@mui/material'
import { Loading } from 'components'
import { useCallback, useState } from 'react'
import clientAdmin from 'services/api/v1/admin/client'
import { functionWithLoading } from 'utils'

export function PagSeguro() {
  const [chargeId, setChargeId] = useState('')
  const [order, setOrder] = useState()
  const [isLoading, setIsLoading] = useState(false)

  const submit = useCallback(() => {
    functionWithLoading(
      clientAdmin
        .get('/pagseguro/order/search', { params: { charge_id: chargeId } })
        .then(({ data }) => setOrder(data.order)),
      setIsLoading,
    )
  }, [chargeId])

  if (isLoading) return <Loading />

  return (
    <Grid container spacing={2} p={2}>
      <Grid item xs={12}>
        <Card>
          <CardHeader subheader="Pesquisar pedido pelo código da transação" />
          <Stack direction="row" spacing={2}>
            <TextField
              placeholder="Código da transação"
              variant="outlined"
              onChange={(e) => setChargeId(e.target.value)}
              value={chargeId}
              fullWidth
            />
            <Button
              variant="contained"
              onClick={submit}
              disabled={isLoading || chargeId.length < 1}
            >
              {isLoading ? 'Pesquisando...' : 'Pesquisar'}
            </Button>
          </Stack>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <pre>
          <code>{JSON.stringify(order ?? '{"pedido": "não encontrado"}')}</code>
        </pre>
      </Grid>
    </Grid>
  )
}
