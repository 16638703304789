import { useCountdown } from 'hooks/useCountdown'
import './CountdownTimer.css'

const DateTimeDisplay = ({
  value,
  type,
  isDanger,
}: {
  value: number
  type: string
  isDanger: boolean
}) => {
  return (
    <div className={isDanger ? 'countdown danger' : 'countdown'}>
      <p className="cowntdown-value">
        {value.toLocaleString('pt-BR', { minimumIntegerDigits: 2 })}
      </p>
      <span>{type}</span>
    </div>
  )
}

const ShowCounter = ({
  days,
  hours,
  minutes,
  seconds,
}: {
  days: number
  hours: number
  minutes: number
  seconds: number
}) => {
  return (
    <div className="show-counter">
      <DateTimeDisplay value={days} type={'Dias'} isDanger={days === 0} />
      <p>:</p>
      <DateTimeDisplay
        value={hours}
        type={'Horas'}
        isDanger={days === 0 && hours === 0}
      />
      <p>:</p>
      <DateTimeDisplay
        value={minutes}
        type={'Minutos'}
        isDanger={days === 0 && hours === 0 && minutes === 0}
      />
      <p>:</p>
      <DateTimeDisplay
        value={seconds}
        type={'Segundos'}
        isDanger={days === 0 && hours === 0 && minutes === 0 && seconds === 0}
      />
    </div>
  )
}

type Props = {
  targetDate: string | number | Date
  callback?: () => void
}

const CountdownTimer = ({ targetDate, callback }: Props) => {
  const [days, hours, minutes, seconds] = useCountdown(targetDate)

  if (days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0) {
    if (callback) {
      callback()
    }
    return <ShowCounter days={0} hours={0} minutes={0} seconds={0} />
  }

  return (
    <ShowCounter
      days={days}
      hours={hours}
      minutes={minutes}
      seconds={seconds}
    />
  )
}

export default CountdownTimer
