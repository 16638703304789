import { DocumentScanner, PointOfSale, Print } from '@mui/icons-material'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import BarChartIcon from '@mui/icons-material/BarChart'
import HandshakeIcon from '@mui/icons-material/Handshake'
import LinkIcon from '@mui/icons-material/Link'
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark'
import SpellcheckIcon from '@mui/icons-material/Spellcheck'
import StarIcon from '@mui/icons-material/Star'
import { Tooltip } from '@mui/material'

const getPermissionIcon = (permissionId: number) => {
  switch (permissionId) {
    case 1:
      return (
        <Tooltip title="Vender por link">
          <LinkIcon />
        </Tooltip>
      )
    case 2:
      return (
        <Tooltip title="Produtor/Organizador">
          <StarIcon />
        </Tooltip>
      )
    case 3:
      return (
        <Tooltip title="Vender ingressos via APP">
          <AttachMoneyIcon />
        </Tooltip>
      )
    case 4:
      return (
        <Tooltip title="Validar ingressos">
          <QrCodeScannerIcon />
        </Tooltip>
      )
    case 5:
      return (
        <Tooltip title="Ver relatórios">
          <BarChartIcon />
        </Tooltip>
      )
    case 6:
      return (
        <Tooltip title="Check-in manual">
          <SpellcheckIcon />
        </Tooltip>
      )
    case 7:
      return (
        <Tooltip title="Parceiro">
          <HandshakeIcon />
        </Tooltip>
      )
    case 8:
      return (
        <Tooltip title="Impressora">
          <Print />
        </Tooltip>
      )
    case 9:
      return (
        <Tooltip title="Leitor totem">
          <DocumentScanner />
        </Tooltip>
      )
    case 10:
      return (
        <Tooltip title="PDV">
          <PointOfSale />
        </Tooltip>
      )
    default:
      return (
        <Tooltip title="Permissão não encontrada">
          <QuestionMarkIcon />
        </Tooltip>
      )
  }
}

export { getPermissionIcon }
