import { Container, Paper } from '@mui/material'
import React from 'react'

const PrivacyPolicyPage: React.FC = () => {
  return (
    <Container sx={{ padding: 2, marginY: 2 }} component={Paper}>
      <p>
        Atualizada em 30 de Maio de 2022
        <br />
        <br />
        Segue abaixo a Política de Privacidade utilizada pelo Zé do Ingresso. Se
        mesmo assim tiver dúvidas, favor entrar em contato pelo e-mail
        contato@zedoingresso.com.br
        <br />
        <br />
        <b>Informações coletadas por nós</b>
        <br />
        <br />
        <b> Produtor de Evento:</b>
        <br />
        <br />
        Ao criar uma conta em nossa plataforma, recebemos os dados informados
        por você no momento do cadastro. Esses dados são nome, sobrenome,
        endereço e e-mail. No decorrer da plataforma solicitamos seu telefone de
        contato e dados da sua conta bancária para que possamos realizar o seu
        pagamento. Todos esses dados são de uso único e exclusivamente do Zé do
        Ingresso para a utilização da plataforma.
        <br />
        <br />
        <br />
        <b>Comprador de um evento:</b>
        <br />
        <br />
        Quando você for comprar um ingresso ou realizar inscrição em algum
        evento, pedimos os dados básicos que são nome, sobrenome e endereço e
        e-mail. Além disso, cada produtor em seu respectivo evento poderá
        solicitar alguns dados adicionais seus, como por exemplo: endereço
        residencial, escolaridade, formação, e qualquer outro dado em que seja
        necessário ao evento. Ao finalizar a sua compra, as informações de seu
        pagamento, seja ele no cartão, boleto ou pix não são guardados em nosso
        sistema e sim repassados ao nosso parceiro responsável pelo
        processamento de pagamentos, PagSeguro.
        <br />
        <br />
        <br />
        <b>Cookies:</b>
        <br />
        <br />
        cookies são fragmentos de informações que um site armazena no computador
        do visitante e que o navegador fornece ao site cada vez que o visitante
        retorna. O Zé do Ingresso utiliza cookies para ajudar a identificar os
        visitantes e evitar, por exemplo, que a senha do usuário tenha que ser
        digitada várias vezes durante a utilização do site. Os visitantes que
        não desejam ter os cookies armazenados em seus computadores deverão
        configurar seu navegador para recusar os cookies antes de usar o site Zé
        do Ingresso. Porém, não recomendamos esta ação, já que certas
        características e ferramentas do site poderão não funcionar
        corretamente.
        <br />
        <br />
        <br />
        <b>Dados não-identificáveis:</b>
        <br />
        <br /> ao acessar e interagir com o site, coletamos certas informações
        que não podem identificar direta ou indiretamente um usuário em
        particular, como o endereço IP utilizado, sua localização geográfica
        aproximada, o registro das páginas visitadas, tempos de visita e outras
        informações agregadas.
        <br />
        <br />
        <b> Forma de utilização das informações coletadas</b>
        <br />
        <br />
        Quando você informa os seus dados pessoais, usaremos os mesmos para
        liberar seu acesso à nossa plataforma e suas respectivas
        funcionalidades. Como por exemplo, as informações de conta bancária
        serão exclusivamente usadas para realizar o repasse das vendas
        realizadas do seu evento.
        <br />
        <br />
        Também utilizamos os dados pessoais para facilitar e melhorar as nossas
        operações internas de monitoramento e atendimento ao cliente. Como fazer
        valer os nossos termos de uso e para informar de novidades em nossa
        plataforma.
        <br />
        <br />
        No caso de compradores de ingresso, também poderá receber e-mails de
        nosso sistema já que fornecemos aos organizadores do evento uma
        ferramenta para entrar em contato direto com o seu cliente.
        <br />
        <br />
        <b>Quem tem acesso a essas informações</b>
        <br />
        <br />
        O Zé do Ingresso não vende nem repassa suas informações pessoais a
        terceiros. Exceto no caso de nossos prestadores de serviços parceiros e
        de nossa confiança com o intuito de viabilizar operacionalmente a nossa
        plataforma. E claro, com a total confidencialidade dos seus dados por
        parte desses nossos parceiros. Também podemos divulgar seus dados para o
        cumprimento da lei, para fazer cumprir os nossos termos de uso, para
        proteger nossos direitos de propriedade ou a segurança de outros
        usuários. Informações não identificáveis podem ser fornecidas a
        terceiros para razões de marketing, publicidade ou outros usos similares
        – desde que não se possa inferir o indivíduo a partir destes dados.
        <br />
        <br />
        Quem compra ingressos ou faz inscrições, também estará fornecendo e
        concordando automaticamente que seus dados sejam fornecidos aos
        organizadores do evento. Porém esses organizadores não são obrigados a
        seguir nossa Política de Privacidade.
        <br />
        <br />
        Sendo assim, você concorda que a Zé do Ingresso não é responsável nem
        tem poder de controlar as ações dos organizadores dos eventos perante os
        seus dados pessoais.
        <br />
        <br />
        <br />
        <b>Forma de proteção dos seus dados</b>
        <br />
        <br />
        Nós implementamos e, continuamente monitoramos, uma série de medidas
        para manter a segurança de suas informações pessoais ao criar, enviar,
        ou acessar suas informações pessoais. Nossos servidores são seguros e
        todas as informações confidenciais fornecidas, como a senha de usuário e
        seus dados de cartão de crédito, são transmitidos através da tecnologia
        SSL (Secure Socket Layer). Sua senha é gravada de forma criptografada em
        nosso banco de dados e aqueles dados relativos a pagamento utilizados na
        compra de ingressos nunca são armazenados em nossos servidores.
        <br />
        <br />
        <b>Exclusões</b>
        <br />
        <br />
        Essa Política de Privacidade aplica-se somente ao site do Zé do
        Ingresso. Ocasionalmente, a nosso critério, podemos incluir links para
        serviços de terceiros em nosso site. Estes sites de terceiros têm
        políticas de privacidade independentes.
        <br />
        <br />
        <b>Mudanças na Política de Privacidade</b>
        <br />
        <br />
        A plataforma Zé do Ingresso é constantemente aprimorada com novas
        funcionalidades e novos serviços prestados. Por isso, às vezes é
        necessário alterar nossa Política de Privacidade. Cada vez que isto
        acontecer, publicaremos as modificações nesta página e atualizaremos a
        data da última modificação (disponível no começo da página). O uso
        continuado do site após quaisquer alterações desta Política de
        Privacidade indica sua concordância com os termos revistos.
        <br />
        <br />
        <br />
        <b>Consentimento</b>
        <br />
        <br />
        Ao utilizar nosso site, você concorda com a Política de Privacidade
        contida neste documento.
      </p>
    </Container>
  )
}

export default PrivacyPolicyPage
