import { Box, Button, List, ListItem, Stack } from '@mui/material'
import { X } from '@phosphor-icons/react'
import { Typography } from 'components/Typography'
import { useNavigate } from 'react-router-dom'

type Props = { orderId: string; eventId: number; eventUrl: string }

export function PagSeguroPaymentRejected({
  orderId,
  eventId,
  eventUrl,
}: Props) {
  const navigate = useNavigate()
  return (
    <Stack gap={2}>
      <Box display="flex" justifyContent="center" alignItems="center">
        <X size={72} color="#ce0909" />
      </Box>
      <Typography variant="h5" textAlign="center">
        O pagamento do pedido {orderId} foi recusado!
        <br />
        Verifique as informações abaixo e tente novamente!
      </Typography>
      <List>
        <ListItem>
          As compras dos ingressos realizadas com cartão de crédito passam por
          uma avaliação de segurança feita pelo Mercado Pago e podem ser
          recusadas. Seguem abaixo os motivos mais comuns para a recusa da
          compra:
        </ListItem>
        <ListItem sx={{ ml: 2 }}>
          1 - Utilizar cartões e dados de pagamentos de titularidade divergentes
          do cadastro no site. Ex. comprar em sua conta utilizando cartões de
          amigos ou parentes;
        </ListItem>
        <ListItem sx={{ ml: 2 }}>
          2 - Algum dado foi informado incorretamente no momento do pagamento;
        </ListItem>
        <ListItem sx={{ ml: 2 }}>
          3 - O cartão usado está expirado ou bloqueado;
        </ListItem>
        <ListItem sx={{ ml: 2 }}>4 - Falta de limite no cartão;</ListItem>
        <ListItem sx={{ ml: 2 }}>
          5 - O banco recusou por problemas ou questões de segurança;
        </ListItem>
        <ListItem sx={{ ml: 2 }}>
          6 - A transação não passou na nossa validação de segurança que protege
          cliente e vendedor contra fraudes.
        </ListItem>
        <ListItem>
          Caso a recusa do cartão seja 1 ou 2, basta corrigir os dados e efetuar
          uma nova compra; Se a recusa for 3, 4 ou 5 utilize outro cartão; Se
          mesmo assim o problema persistir utilize outra forma de pagamento (PIX
          ou BOLETO).
        </ListItem>
      </List>

      <Box display="flex" justifyContent="center" alignItems="center">
        <Button
          variant="contained"
          onClick={() => navigate(`/evento/${eventId}/${eventUrl}`)}
        >
          Realizar nova compra
        </Button>
      </Box>
    </Stack>
  )
}
