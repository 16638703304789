/* eslint-disable camelcase */
import { zodResolver } from '@hookform/resolvers/zod'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import { LoadingButton } from '@mui/lab'
import {
  Alert,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { Loading } from 'components'
import { usePromoter } from 'contexts/promoterContext'
import { useCallback, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import ReactInputMask from 'react-input-mask'
import { Link, useNavigate } from 'react-router-dom'
import { apiV1 } from 'services'
import { IPromoter } from 'types/promoter'
import { functionWithLoading } from 'utils'
import { z } from 'zod'

const producerSchema = z.object({
  razao_social: z.string({ required_error: 'Informe o nome da produtora' }),
  email: z
    .string({ required_error: 'Informe o nome da produtora' })
    .email('Informe um e-mail válido'),
  telefone: z.string({
    required_error: 'Informe o telefone para contato da produtora',
  }),
})

export function NewProducer() {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const navigate = useNavigate()
  const { addErrorMessage } = usePromoter()

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    watch,
  } = useForm<IPromoter>({
    resolver: zodResolver(producerSchema),
    defaultValues: {},
  })

  const onSubmit = useCallback(
    ({ razao_social, email, telefone, cnpj }: IPromoter) => {
      if (isSubmitting) {
        return
      }
      functionWithLoading(
        apiV1.producer.promoterService
          .create({
            razao_social,
            email,
            telefone,
            cnpj,
          })
          .then(() => {
            navigate('/organizador/produtoras')
          })
          .catch((err) => {
            addErrorMessage(err.response?.data.message ?? err.message)
          }),
        setLoading,
      )
    },
    [addErrorMessage, isSubmitting, navigate],
  )

  if (loading) return <Loading />

  return (
    <Container maxWidth="md">
      <Grid container spacing={2} p={2} mt={1} component={Paper}>
        <Grid item xs={12}>
          <Typography variant="h6">Cadastrar nova produtora</Typography>
        </Grid>
        <Grid item xs={12}>
          <Alert severity="info">
            Essas informações serão exibidas na página do evento.
          </Alert>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Nome da produtora"
            error={!!errors.razao_social}
            helperText={errors.razao_social?.message}
            fullWidth
            required
            {...register('razao_social')}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="E-mail"
            error={!!errors.email}
            helperText={errors.email?.message}
            fullWidth
            required
            {...register('email')}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="telefone"
            render={({ field }) => (
              <ReactInputMask mask="(99) 99999-9999" {...field}>
                <TextField
                  label="Telefone para contato"
                  error={!!errors.telefone}
                  helperText={errors.telefone?.message}
                />
              </ReactInputMask>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="CNPJ se existir"
            error={!!errors.cnpj}
            helperText={errors.cnpj?.message}
            fullWidth
            {...register('cnpj')}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl component="fieldset" variant="outlined">
            <FormLabel component="legend">Termos de uso</FormLabel>
            <FormGroup sx={{ display: 'flex', flexDirection: 'row' }}>
              <Controller
                name="aceitou_termos"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox {...field} checked={!!field.value} />}
                    label={
                      <Typography>
                        Concordo com os{' '}
                        <Link to={'/termos-de-uso'} target="_blank">
                          termos de uso
                        </Link>{' '}
                        e com a{' '}
                        <Link to={'/politica-de-privacidade'} target="_blank">
                          política de privacidade
                        </Link>
                        .
                      </Typography>
                    }
                  />
                )}
              />
            </FormGroup>
          </FormControl>
        </Grid>
        {error && (
          <Grid item xs={12}>
            <Alert severity="error" onClose={() => setError('')}>
              {error}
            </Alert>
          </Grid>
        )}
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="flex-end">
            <LoadingButton
              loading={loading}
              loadingPosition="start"
              startIcon={<SaveOutlinedIcon />}
              variant="outlined"
              onClick={handleSubmit(onSubmit)}
              disabled={!watch('aceitou_termos')}
            >
              Cadastrar produtora
            </LoadingButton>
          </Stack>
        </Grid>
      </Grid>
    </Container>
  )
}
