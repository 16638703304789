/* eslint-disable @typescript-eslint/no-explicit-any */
import { Card } from '@mui/material'
import {
  CategoryScale,
  ChartData,
  Chart as ChartJS,
  ChartOptions,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js'
import { memo } from 'react'
import { Line } from 'react-chartjs-2'
import { moneyFormatter } from 'utils/formatter'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
)

export const options: ChartOptions<'line'> = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
      text: 'Vendas diárias por setor',
    },
    tooltip: {
      callbacks: {
        label(context) {
          const data = context.chart.data.datasets[context.datasetIndex].data[
            context.dataIndex
          ] as unknown as { x: string; y: number; day: any }
          const batches =
            data.day?.map(
              (batch: any) =>
                `${batch.lote} - Qtd: ${
                  batch.quantidade_ingressos
                } Valor: (${moneyFormatter.format(batch.valor_ingressos)})`,
            ) ?? []
          return [
            ...batches,
            '',
            `Qtd. total: ${
              data.day?.reduce(
                (total: number, item: any) =>
                  total + Number(item.quantidade_ingressos),
                0,
              ) ?? 0
            }`,
            `Valor total: ${moneyFormatter.format(
              data.day?.reduce(
                (total: number, item: any) =>
                  total + Number(item.valor_ingressos),
                0,
              ) ?? 0,
            )}`,
          ]
        },
      },
    },
  },
}

interface SalesByPeriodChartProps {
  salesPerDayAndHour: { dia: number; hora: number; total: number }[]
}

const SalesByHourChart = ({ salesPerDayAndHour }: SalesByPeriodChartProps) => {
  const data: ChartData<'line'> = {
    labels: salesPerDayAndHour.map((item) => `${item.hora}h`),
    datasets: [
      {
        fill: true,
        label: 'Vendas por hora',
        data: salesPerDayAndHour.map((item) => ({
          x: item.hora,
          y: item.total,
        })),
      },
    ],
  }
  return (
    <Card>
      <Line data={data} style={{ maxHeight: 450 }} />
    </Card>
  )
}

export default memo(SalesByHourChart)
