import { Grid, Typography } from '@mui/material'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import { Loading, TicketCard } from 'components'
import { Button } from 'components/button'
import { useAdmin } from 'contexts/adminContext'
import dayjs from 'dayjs'
import { useCallback, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { apiV1 } from 'services'
import { IPedido } from 'types/pedido'
import { functionWithLoading } from 'utils'
import { getErrorMessage } from 'utils/AppError'
import { moneyFormatter } from 'utils/formatter'

export function OrderDetails() {
  const { orderId } = useParams()
  const [order, setOrder] = useState<IPedido>()
  const [loading, setLoading] = useState(true)
  const [isLoadingApproveOrder, setIsLoadingApproveOrder] = useState(false)

  const { addErrorMessage } = useAdmin()

  const fetchData = useCallback(() => {
    if (orderId)
      functionWithLoading(
        apiV1.admin.orderService
          .get(orderId)
          .then(({ data }) => setOrder(data.order as IPedido)),
        setLoading,
      )
  }, [orderId])

  const handleApproveOrder = useCallback(async () => {
    try {
      if (!orderId) return
      setIsLoadingApproveOrder(true)
      await apiV1.admin.orderService.approveOrder(orderId)
      fetchData()
    } catch (error) {
      addErrorMessage(getErrorMessage(error))
    } finally {
      setIsLoadingApproveOrder(false)
    }
  }, [addErrorMessage, fetchData, orderId])

  useEffect(() => {
    fetchData()
  }, [fetchData, orderId])

  if (loading) return <Loading />

  if (!order) return <Typography>Pedido não encontrado</Typography>

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableBody>
              <TableRow>
                <TableCell sx={{ width: 150 }}>Pedido:</TableCell>
                <TableCell>{order.codigo}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ width: 150 }}>Transação:</TableCell>
                <TableCell>
                  <Link
                    to={`/admin/order-details/${order.pedido_transferido_pedido_novo[0]?.pedido_transferido_pedido_antigo?.codigo}`}
                  >
                    {
                      order.pedido_transferido_pedido_novo[0]
                        ?.pedido_transferido_pedido_antigo.codigo
                    }
                  </Link>
                  {order.transacao}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ width: 150 }}>Situação:</TableCell>
                <TableCell>
                  {order.status}{' '}
                  {order.cod_status === 2 && (
                    <Button
                      isLoading={isLoadingApproveOrder}
                      onClick={handleApproveOrder}
                      variant="contained"
                    >
                      Liberar pedido
                    </Button>
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ width: 150 }}>Valor ingressos:</TableCell>
                <TableCell>
                  {moneyFormatter.format(order.valor_ingressos)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ width: 150 }}>Valor pedido:</TableCell>
                <TableCell>
                  {moneyFormatter.format(order.valor_pedido)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ width: 150 }}>Cupom:</TableCell>
                <TableCell>{order.cupom_desconto?.codigo}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ width: 150 }}>Valor cupom:</TableCell>
                <TableCell>
                  {order.cupom_desconto &&
                    moneyFormatter.format(order.valor_desconto ?? 0)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ width: 150 }}>Data do pedido:</TableCell>
                <TableCell>
                  {dayjs(order.data).add(3, 'hours').format('DD/MM/YY HH:mm')}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ width: 150 }}>
                  Ref campanha / promoter:
                </TableCell>
                <TableCell>{order.ref}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ width: 150 }}>Comprador:</TableCell>
                <TableCell>
                  {[order.usuario.nome, order.usuario.sobrenome]
                    .join(' ')
                    .trim()
                    .toUpperCase()}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ width: 150 }}>CPF:</TableCell>
                <TableCell>{order.usuario.cpf}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ width: 150 }}>E-mail:</TableCell>
                <TableCell>{order.usuario.email}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ width: 150 }}>Telefone:</TableCell>
                <TableCell>
                  {order.usuario.ddd}
                  {order.usuario.telefone}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ width: 150 }}>Cidade:</TableCell>
                <TableCell>
                  {order.usuario.brasil_cidade?.cidade} -{' '}
                  {order.usuario.brasil_cidade?.uf}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ width: 150 }}>Evento:</TableCell>
                <TableCell>{order.evento.titulo}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ width: 150 }}>Data do evento:</TableCell>
                <TableCell>
                  {dayjs(order.evento.data)
                    .add(3, 'hours')
                    .format('DD/MM/YY HH:mm')}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ width: 150 }}>Produtora:</TableCell>
                <TableCell>{order.evento.promoter.razao_social}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      {order.pedido_item?.map((ingresso) => (
        <Grid item xs={12} sm={4} key={ingresso.id}>
          {ingresso.cod_pedido_item_status === 3 && (
            <Link
              to={`/admin/order-details/${ingresso.pedido_transferido_original[0]?.pedido_transferido_pedido_novo.codigo}`}
            >
              Novo pedido gerado{' '}
              {
                ingresso.pedido_transferido_original[0]
                  ?.pedido_transferido_pedido_novo.codigo
              }
            </Link>
          )}

          <TicketCard key={ingresso.id} item={ingresso} event={order.evento} />
        </Grid>
      ))}
    </Grid>
  )
}
