/* eslint-disable camelcase */
import { zodResolver } from '@hookform/resolvers/zod'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import { LoadingButton } from '@mui/lab'
import { Alert, Grid, Paper, Skeleton, Stack, TextField } from '@mui/material'
import { Typography } from 'components/Typography'
import { usePromoter } from 'contexts/promoterContext'
import { useCallback, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import ReactInputMask from 'react-input-mask'
import { useParams } from 'react-router-dom'
import { apiV1 } from 'services'
import { IPromoter } from 'types/promoter'
import { functionWithLoading } from 'utils'
import { getErrorMessage } from 'utils/AppError'
import { z } from 'zod'

const producerSchema = z.object({
  razao_social: z.string({ required_error: 'Informe o nome da produtora' }),
  email: z
    .string({ required_error: 'Informe o nome da produtora' })
    .email('Informe um e-mail válido'),
  telefone: z.string({
    required_error: 'Informe o telefone para contato da produtora',
  }),
})

export function Producer() {
  const [isLoading, setIsLoading] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)
  const [success, setSuccess] = useState('')
  const [producer, setProducer] = useState<IPromoter>({} as IPromoter)

  // const [selectedFile, setSelectedFile] = useState<File>();
  // const [imgSrc, setImgSrc] = useState("");
  // const imgRef = useRef<HTMLImageElement>(null);

  const { producerId } = useParams()
  const { addErrorMessage } = usePromoter()

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isSubmitting, isDirty },
    reset,
  } = useForm<IPromoter>({
    resolver: zodResolver(producerSchema),
  })

  const onSubmit = useCallback(
    ({ razao_social, email, telefone, cnpj }: IPromoter) => {
      if (isSubmitting) {
        return
      }
      functionWithLoading(
        apiV1.producer.promoterService
          .update(String(producer.id), {
            razao_social,
            email,
            telefone,
            cnpj,
          })
          .then(() => {
            setSuccess('As informações da produtora foram atualizadas!')
          })
          .catch((err) => {
            addErrorMessage(err.response?.data.message ?? err.message)
          }),
        setIsUpdating,
      )
    },
    [addErrorMessage, isSubmitting, producer.id],
  )

  // function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
  //   if (e.target.files && e.target.files.length > 0) {
  //     const reader = new FileReader();
  //     reader.addEventListener("load", () =>
  //       setImgSrc(reader.result?.toString() || "")
  //     );
  //     reader.readAsDataURL(e.target.files[0]);
  //     setSelectedFile(e.target.files[0]);
  //   }
  // }

  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true)
        const { data } = await apiV1.producer.promoterService.get(producerId)
        setProducer(data)
        reset(data)
      } catch (error) {
        addErrorMessage(getErrorMessage(error))
      } finally {
        setIsLoading(false)
      }
    }
    fetchData()
  }, [addErrorMessage, producerId, reset])

  return (
    <Grid container spacing={2} component={Paper} py={2} pr={2} mt={6}>
      <Grid item xs={12}>
        <Typography variant="h6">Informações da produtora</Typography>
      </Grid>
      {isLoading ? (
        <Grid item xs={12}>
          <PageLoading />
        </Grid>
      ) : (
        <>
          <Grid item xs={12}>
            <Alert severity="info">
              Essas informações serão exibidas na página do evento.
            </Alert>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Nome da produtora"
              error={!!errors.razao_social}
              helperText={errors.razao_social?.message}
              fullWidth
              required
              {...register('razao_social')}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="E-mail"
              error={!!errors.email}
              helperText={errors.email?.message}
              fullWidth
              required
              {...register('email')}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="telefone"
              render={({ field }) => (
                <ReactInputMask mask="(99) 99999-9999" {...field}>
                  <TextField
                    label="Telefone para contato"
                    error={!!errors.telefone}
                    helperText={errors.telefone?.message}
                  />
                </ReactInputMask>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="CNPJ se existir"
              error={!!errors.cnpj}
              helperText={errors.cnpj?.message}
              fullWidth
              {...register('cnpj')}
            />
          </Grid>
          {/* <Grid item xs={12} sm={6}>
            <TextField
              label="Imagem do evento"
              contentEditable={false}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                startAdornment: (
                  <Grid container spacing={2} mt={1} pr={2}>
                    <Grid item xs={12} sm={6}>
                      <img
                        ref={imgRef}
                        alt="Selecione a imagem"
                        src={
                          imgSrc ? imgSrc : constants.URL_FTP + watch("imagem")
                        }
                        style={{ width: "100%", aspectRatio: "1/1" }}
                      />
                      <input
                        hidden
                        accept="image/*"
                        type="file"
                        required
                        {...register("imagem")}
                        onChange={onSelectFile}
                      />
                    </Grid>
                  </Grid>
                ),
                inputProps: {
                  style: { display: "none" },
                },
              }}
              error={!!errors.imagem}
              helperText={errors.imagem?.message}
              required
              fullWidth
            />
          </Grid> */}
          {success && (
            <Grid item xs={12}>
              <Alert severity="success" onClose={() => setSuccess('')}>
                {success}
              </Alert>
            </Grid>
          )}
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="flex-end">
              <LoadingButton
                loading={isUpdating}
                loadingPosition="start"
                startIcon={<SaveOutlinedIcon />}
                variant="outlined"
                onClick={handleSubmit(onSubmit)}
                disabled={!isDirty}
              >
                Atualizar
              </LoadingButton>
            </Stack>
          </Grid>
        </>
      )}
    </Grid>
  )
}

function PageLoading() {
  return (
    <Grid item xs={12}>
      <Skeleton />
      <Skeleton />
      <Skeleton />
    </Grid>
  )
}
