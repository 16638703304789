import { IEvento } from 'types/evento'
import { ILogin } from 'types/login'
import { IUsuario } from 'types/usuario'
import clientPartner from './clientPartner'

const login = (email: string, password: string) => {
  return clientPartner.post<ILogin>(`login`, {
    email,
    password,
  })
}

const addPartnerTeam = (eventId: number, email: string) => {
  return clientPartner.post(`events/${eventId}/partner`, {
    email,
  })
}

const removePartnerTeam = (eventId: number, partnerTeamId: number) => {
  return clientPartner.delete(`events/${eventId}/partner/${partnerTeamId}/`)
}

const getPartnerTeam = (eventId: number, partnerTeamId: number) => {
  return clientPartner.get<{ event: IEvento; partnerTeam: IUsuario }>(
    `events/${eventId}/partner/${partnerTeamId}`,
  )
}

const partnerService = {
  login,
  addPartnerTeam,
  removePartnerTeam,
  getPartnerTeam,
}

export default partnerService
