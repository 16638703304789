import { Box, Button, Link, Paper } from '@mui/material'
import { Typography } from 'components/Typography'
import { useLocation } from 'react-router-dom'

type RouteProps = {
  boletoLink: string
}

export function OrderBoleto() {
  const location = useLocation()
  const { boletoLink } = location.state as RouteProps

  return (
    <Box
      m={1}
      p={1}
      justifyContent="center"
      alignItems="center"
      gap={2}
      component={Paper}
    >
      <Typography variant="h6">
        Para visualizar e pagar o boleto clique no botão abaixo:
      </Typography>
      <Button
        variant="contained"
        LinkComponent={Link}
        href={boletoLink}
        target="_blank"
      >
        Visualizar boleto
      </Button>
    </Box>
  )
}
