import { IEvento } from 'types/evento'
import { IUsuarioPermissaoEvento } from 'types/usuario_permissao_evento'
import clientAdmin from './client'

const getAll = () => {
  return clientAdmin.get(`events`)
}

const get = (eventId: number) => {
  return clientAdmin.get<IEvento>(`events/${eventId}`)
}

const create = (event: IEvento) => {
  return clientAdmin.post<IEvento>(`events`, { event })
}

const update = (eventId: number, event: IEvento) => {
  return clientAdmin.patch(`events/${eventId}`, { event })
}

const getDetails = (eventId: number) => {
  return clientAdmin.get(`events/${eventId}/details`)
}

const getNews = () => {
  return clientAdmin.get(`events/news`)
}

const reviewEvent = (eventId: number, showInHomePage: boolean) => {
  return clientAdmin.post(`events/${eventId}/review`, {
    exibir_na_homepage: showInHomePage,
  })
}

const addUserPermissions = (
  id: number,
  email: string,
  permissions: number[],
) => {
  return clientAdmin.post<IUsuarioPermissaoEvento[]>(
    `/events/${id}/user-permission-event`,
    {
      email,
      permissions,
    },
  )
}

const search = (search: string) => {
  return clientAdmin.get<IEvento[]>(`events/search?search=${search}`)
}

const exportCustomers = (eventId: number, fields: string[]) => {
  return clientAdmin.post(`events/${eventId}/export-customers`, { fields })
}

const checkInsInfo = (eventId: number) =>
  clientAdmin.get(`events/${eventId}/checkins-info`)

const physicalSalesDetails = (eventId: number) => {
  return clientAdmin.get(`events/${eventId}/physical-sales-details`)
}

const eventService = {
  getAll,
  get,
  create,
  update,
  getDetails,
  addUserPermissions,
  search,
  exportCustomers,
  checkInsInfo,
  getNews,
  reviewEvent,
  physicalSalesDetails,
}

export default eventService
