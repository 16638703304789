import { Grid } from '@mui/material'
import { Loading } from 'components'
import { useAdmin } from 'contexts/adminContext'
import React, { useCallback, useEffect, useState } from 'react'
import clientAdmin from 'services/api/v1/admin/client'
import { getErrorMessage } from 'utils/AppError'
import { BlockOrder } from './cards/blockOrder'
import { CloneEvent } from './cards/cloneEvent'
import { EventsToApprove } from './cards/eventsToApprove'
import { EventsWithoutActiveBatches } from './cards/eventsWithoutActiveBatches'
import { OrdersUnderReview } from './cards/ordersUnderReview'
import { ProducersWithoutEvents } from './cards/producersWithoutEvents'
import { RequestWithdrawals } from './cards/requestWithdrawals'
import { SincronizeEDI } from './cards/sincronizeEDI'
import { SincronizeOrderWithGateway } from './cards/sincronizeOrder'
import { SuspectedUsers } from './cards/suspectedUsers'
import { FinalizeSignUp } from './cards/finalizeSignUp'
import { SincronizePOSEDI } from './cards/sincronizePOSEDI'
import { CancelOrder } from './cards/cancelOrder'

const DashboardPage: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [dashboard, setDashboard] = useState<any>({})

  const { addErrorMessage } = useAdmin()

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true)
      const { data } = await clientAdmin.get('/dashboard')
      setDashboard(data)
    } catch (error) {
      addErrorMessage(getErrorMessage(error))
    } finally {
      setIsLoading(false)
    }
  }, [addErrorMessage])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  if (isLoading) return <Loading />

  return (
    <Grid container spacing={2} p={2}>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <SuspectedUsers data={dashboard.suspectedUsers?.length} />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <RequestWithdrawals data={dashboard.newWithdrawalRequests} />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <EventsToApprove data={dashboard.newEventsToApprove} />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <ProducersWithoutEvents
          data={dashboard.newProducersWithoutRegisteringEvent?.length}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <EventsWithoutActiveBatches
          data={dashboard.eventsWithoutActiveBatches}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <OrdersUnderReview data={dashboard.ordersUnderReview} />
      </Grid>
      {/* <Grid item xs={12} sm={6} md={4} lg={3}>
        <CardMUI>
          <CardHeader
            title="Ainda não integrado"
            subheader="Novas contestações"
          />
        </CardMUI>
      </Grid> */}
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <SincronizeOrderWithGateway />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <SincronizeEDI />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <BlockOrder />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <CloneEvent />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <FinalizeSignUp />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <SincronizePOSEDI />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <CancelOrder />
      </Grid>
    </Grid>
  )
}

export default DashboardPage
