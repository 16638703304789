import { Close } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Alert, IconButton, Stack } from '@mui/material'
import {
  ShoppingCartPaymentInfo,
  useShoppingCart,
} from 'contexts/shoppingCartContext'
import { useCallback, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { apiV1 } from 'services'

import { getErrorMessage } from 'utils/AppError'
import { moneyFormatter } from 'utils/formatter'
import { TextField } from './FormItem'
import { Typography } from './Typography'

type ShoppingCartDiscountCouponProps = {
  eventId: number
}

export function ShoppingCartDiscountCoupon({
  eventId,
}: ShoppingCartDiscountCouponProps) {
  const [coupon, setCoupon] = useState('')
  const [message, setMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const { setValue, watch } = useFormContext<ShoppingCartPaymentInfo>()
  const { discountValue } = useShoppingCart()
  const discountCoupon = watch('discountCoupon')

  const handleApplyButtonClick = useCallback(async () => {
    try {
      if (!coupon) return

      setIsLoading(true)
      const { data } = await apiV1.user.eventService.validateDiscountCoupon(
        eventId,
        coupon,
      )
      setValue('discountCoupon', data.discountCoupon)
    } catch (error) {
      setMessage(
        getErrorMessage(
          error,
          'Ocorreu um erro ao processar o cupom, tente novamente!',
        ),
      )
    } finally {
      setIsLoading(false)
    }
  }, [coupon, eventId, setValue])

  const handleRemoveCouponButtonClick = useCallback(() => {
    setCoupon('')
    setValue('discountCoupon', null)
  }, [setValue])

  return (
    <>
      <Stack
        flex={1}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        gap={1}
      >
        <Typography variant="subtitle2" fontWeight="bold" maxWidth={100}>
          Cupom de desconto
        </Typography>
        {discountCoupon && discountCoupon.id ? (
          <>
            <Typography variant="subtitle2" fontWeight="bold">
              {discountCoupon.codigo}
            </Typography>
            <Typography variant="subtitle2" color="red">
              {moneyFormatter.format(discountValue)}
              <IconButton color="error" onClick={handleRemoveCouponButtonClick}>
                <Close />
              </IconButton>
            </Typography>
          </>
        ) : (
          <>
            <TextField
              placeholder="cupom"
              size="small"
              sx={{ flex: 1, minWidth: 146 }}
              value={coupon}
              onChange={(e) => setCoupon(e.target.value)}
            />
            <LoadingButton
              variant="contained"
              title="Aplicar"
              size="medium"
              onClick={handleApplyButtonClick}
              loading={isLoading}
              disabled={!coupon}
            >
              Aplicar
            </LoadingButton>
          </>
        )}
      </Stack>

      {message && (
        <Alert
          onClose={() => setMessage('')}
          severity="error"
          sx={{ width: '100%', mt: 1 }}
        >
          {message}
        </Alert>
      )}
    </>
  )
}
