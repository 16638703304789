import { Box, Button } from '@mui/material'
import { Loading } from 'components'
import { useAdmin } from 'contexts/adminContext'
import { useCallback, useState } from 'react'
import { apiV1 } from 'services'
import { getErrorMessage } from 'utils/AppError'

interface EventReviewButtonProps {
  eventId: number
}

export function EventReviewButton({ eventId }: EventReviewButtonProps) {
  const [isLoading, setIsLoading] = useState(false)
  const [showButton, setShowButton] = useState(true)

  const { addErrorMessage } = useAdmin()

  const handleReviewEvent = useCallback(
    async (eventId: number, showInHomePage: boolean) => {
      try {
        setIsLoading(true)
        await apiV1.admin.eventService.reviewEvent(eventId, showInHomePage)
        setShowButton(false)
      } catch (error) {
        addErrorMessage(getErrorMessage(error))
      } finally {
        setIsLoading(false)
      }
    },
    [addErrorMessage],
  )

  if (isLoading) return <Loading />

  if (!showButton) return <></>

  return (
    <Box>
      <Button
        variant="contained"
        color="success"
        sx={{ mr: 1 }}
        onClick={() => handleReviewEvent(eventId, true)}
      >
        Exibir
      </Button>
      <Button
        variant="contained"
        color="error"
        onClick={() => handleReviewEvent(eventId, false)}
      >
        Ocultar
      </Button>
    </Box>
  )
}
