import { zodResolver } from '@hookform/resolvers/zod'
import { Close } from '@mui/icons-material'
import {
  Alert,
  Card,
  CardContent,
  CardHeader,
  FormHelperText,
  IconButton,
  Snackbar,
  Stack,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { Button } from 'components/button'
import { useCallback, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import clientAdmin from 'services/api/v1/admin/client'
import { getErrorMessage } from 'utils/AppError'
import { z } from 'zod'

const schema = z.object({
  date: z.date({ required_error: 'Informe o dia específico' }).nullish(),
})
type SchemaType = z.infer<typeof schema>

export function SincronizePOSEDI() {
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<SchemaType>({ resolver: zodResolver(schema) })

  const onSubmit = useCallback(
    async ({ date }: SchemaType) => {
      try {
        if (isSubmitting) return
        setIsLoading(true)
        await clientAdmin.post(`/events/update-pos-pagseguro`, {
          date,
        })
      } catch (error) {
        setErrorMessage(getErrorMessage(error))
      } finally {
        setIsLoading(false)
      }
    },
    [isSubmitting],
  )

  return (
    <Card>
      <CardHeader
        title="Sincronizar EDI POS PS4"
        subheader="Sincroniza os as taxas dos pedidos PDV"
      />
      <CardContent>
        <Stack spacing={2}>
          <Stack direction="row">
            <Controller
              name="date"
              control={control}
              render={({ field }) => (
                <DatePicker
                  label="Dia específico"
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                />
              )}
            />
            <IconButton onClick={() => reset()}>
              <Close />
            </IconButton>
          </Stack>
          {!!errors.date && (
            <FormHelperText error>{errors.date.message}</FormHelperText>
          )}
          <Button
            variant="contained"
            onClick={handleSubmit(onSubmit)}
            isLoading={isLoading}
          >
            Atualizar
          </Button>
        </Stack>
      </CardContent>
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={6000}
        onClose={() => setErrorMessage('')}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        <Alert
          onClose={() => setErrorMessage('')}
          severity="error"
          sx={{ width: '100%' }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
    </Card>
  )
}
