import { ArrowRight } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Alert, Grid } from '@mui/material'
import { FormItem } from 'components'
import { useFormikContext } from 'formik'
import React from 'react'
import { apiV1 } from 'services'
import { functionWithLoading } from 'utils'
import { SignupFields } from './validationSchema'
import ReactInputMask from 'react-input-mask'

type Step1Props = {
  handleNext: () => void
}

const Step1 = ({ handleNext }: Step1Props) => {
  const formik = useFormikContext<SignupFields>()
  const [loading, setLoading] = React.useState<boolean>(false)
  const [errorMessage, setErrorMessage] = React.useState('')
  const [emailAlreadyExists, setEmailAlreadyExists] = React.useState(false)
  const [cpfAlreadyExists, setCpfAlreadyExists] = React.useState(false)
  const [phoneAlreadyExists, setPhoneAlreadyExists] = React.useState(false)

  const verifyEmailCpf = React.useCallback(() => {
    formik.validateField('email')
    formik.validateField('confirmarEmail')
    formik.validateField('cpf')
    formik.validateField('telefone')
    formik.setFieldTouched('email')
    formik.setFieldTouched('confirmarEmail')
    formik.setFieldTouched('cpf')
    formik.setFieldTouched('telefone')

    const hasError =
      formik.errors.email ||
      formik.errors.confirmarEmail ||
      formik.errors.cpf ||
      formik.errors.telefone

    if (!hasError) {
      functionWithLoading(
        apiV1.userService
          .existInDatabase({
            email: formik.values.email,
            cpf: formik.values.cpf,
            phone: formik.values.telefone,
          })
          .then((res) => {
            setCpfAlreadyExists(res.data.cpf)
            setEmailAlreadyExists(res.data.email)
            setPhoneAlreadyExists(res.data.phone)
            if (!(res.data.cpf || res.data.email || res.data.phone)) {
              handleNext()
            }
          })
          .catch((err) => {
            setErrorMessage(err.response?.data.message ?? err.message)
          }),
        setLoading,
      )
    }
  }, [formik, handleNext])

  return (
    <React.Fragment>
      <Grid item xs={12}>
        {errorMessage && (
          <Alert
            severity="error"
            sx={{ mt: 1 }}
            onClose={() => setErrorMessage('')}
          >
            {errorMessage}
          </Alert>
        )}
        {emailAlreadyExists && (
          <Alert
            severity="error"
            sx={{ mt: 1 }}
            onClose={() => setEmailAlreadyExists(false)}
          >
            E-mail já cadastrado no site.
          </Alert>
        )}
        {cpfAlreadyExists && (
          <Alert
            severity="error"
            sx={{ mt: 1 }}
            onClose={() => setCpfAlreadyExists(false)}
          >
            CPF já cadastrado no site.
          </Alert>
        )}
        {phoneAlreadyExists && (
          <Alert
            severity="error"
            sx={{ mt: 1 }}
            onClose={() => setPhoneAlreadyExists(false)}
          >
            Telefone já cadastrado no site.
          </Alert>
        )}
      </Grid>

      <Grid item xs={12} md={6}>
        <FormItem.TextField
          label="E-mail"
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          onBlur={() =>
            formik.setFieldValue('email', formik.values.email.trim())
          }
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormItem.TextField
          label="Confirmar e-mail"
          name="confirmarEmail"
          value={formik.values.confirmarEmail}
          onChange={formik.handleChange}
          error={
            (formik.touched.confirmarEmail &&
              Boolean(formik.errors.confirmarEmail)) ||
            emailAlreadyExists
          }
          helperText={
            formik.touched.confirmarEmail && formik.errors.confirmarEmail
          }
          onBlur={() =>
            formik.setFieldValue(
              'confirmarEmail',
              formik.values.confirmarEmail.trim(),
            )
          }
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <ReactInputMask
          mask="999.999.999-99"
          value={formik.values.cpf}
          onChange={formik.handleChange}
          disabled={false}
        >
          <FormItem.CPF
            label="CPF"
            name="cpf"
            error={
              (formik.touched.cpf && Boolean(formik.errors.cpf)) ||
              cpfAlreadyExists
            }
            helperText={formik.touched.cpf && formik.errors.cpf}
          />
        </ReactInputMask>
      </Grid>
      <Grid item xs={12} md={6}>
        <ReactInputMask
          mask="(99) 99999-9999"
          value={formik.values.telefone}
          onChange={formik.handleChange}
          disabled={false}
        >
          <FormItem.TextField
            label="Telefone"
            name="telefone"
            error={
              (formik.touched.telefone && Boolean(formik.errors.telefone)) ||
              phoneAlreadyExists
            }
            helperText={formik.touched.telefone && formik.errors.telefone}
            sx={{ mb: 2 }}
          />
        </ReactInputMask>
      </Grid>
      <Grid item xs={12} display="flex" justifyContent="flex-end">
        <LoadingButton
          loading={loading}
          loadingPosition="end"
          variant="outlined"
          endIcon={<ArrowRight />}
          onClick={verifyEmailCpf}
        >
          Próximo
        </LoadingButton>
      </Grid>
    </React.Fragment>
  )
}

export default Step1
