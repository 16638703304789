import { IBrasilCidade } from 'types/BrasilCidade'
import clientRest from './client'

const getUFs = () => {
  return clientRest.get<IBrasilCidade[]>(`ufs`)
}

const getCities = (uf: string) => {
  return clientRest.get<IBrasilCidade[]>(`cities/${uf}`)
}

const getCitiesSearch = (uf: string, search: string) => {
  return clientRest.get<IBrasilCidade[]>(`cities/${uf}/${search}`)
}
const cityService = { getUFs, getCities, getCitiesSearch }

export default cityService
