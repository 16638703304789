import axios, { AxiosError, AxiosRequestConfig } from 'axios'
import { constants } from 'config'
import { LogoutUser } from 'contexts/userContext'

const clientUser = axios.create({
  baseURL: constants.URL_API + 'api/v1/',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': 'X-Requested-With',
  },
})

clientUser.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error.response.status === 401) {
      LogoutUser()
      const requestConfig = error.config
      return axios(requestConfig)
    }
    return Promise.reject(error)
  },
)

clientUser.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const sessionToken = localStorage.getItem('user_token')
    if (sessionToken && config.headers) {
      config.headers.Authorization = `Bearer ${sessionToken}`
    }
    return config
  },
  (error: AxiosError) => {
    return Promise.reject(error)
  },
)

export default clientUser
