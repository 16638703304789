import { Box, Stack } from '@mui/material'
import { ListMagnifyingGlass } from '@phosphor-icons/react'
import { Typography } from 'components/Typography'

type Props = { orderId: string }

export function PagSeguroPaymentUnderAnalysis({ orderId }: Props) {
  return (
    <Stack gap={2}>
      <Box display="flex" justifyContent="center" alignItems="center">
        <ListMagnifyingGlass size={72} color="#3291a8" />
      </Box>
      <Typography variant="h5" textAlign="center">
        O pagamento do pedido {orderId} está em análise financeira, assim que
        estiver tudo certo você receberá um e-mail com os detalhes de sua
        compra.
      </Typography>
    </Stack>
  )
}
