import { zodResolver } from '@hookform/resolvers/zod'
import { AddCircle, SaveOutlined } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Card,
  Container,
  FormControl,
  FormGroup,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
} from '@mui/material'
import { Button } from 'components/button'
import { useAdmin } from 'contexts/adminContext'
import { useHttp } from 'hooks/useHttp'
import { useCallback, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { getErrorMessage } from 'utils/AppError'
import { z } from 'zod'

const schema = z.object({
  canal: z.enum(['VENDA_DIGITAL', 'VENDA_FISICA']),
  descricao: z.string().min(1),
  valor: z.coerce.number().min(1),
  quantidade: z.coerce.number().min(1),
})

type FormData = z.infer<typeof schema>

type Props = {
  callback: () => void
}

export function NewEventService({ callback }: Props) {
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const eventId = useParams().eventId ?? 0
  const { addMessage } = useAdmin()

  const http = useHttp({
    clientType: 'admin',
    url: `/events/${eventId}/services`,
    method: 'post',
    onSuccess: () => {
      addMessage({ message: 'Serviço cadastrado' })
      setOpen(false)
      callback()
    },
    onError: (error) =>
      addMessage({ message: getErrorMessage(error), type: 'ERROR' }),
  })

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
  } = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: {
      canal: 'VENDA_DIGITAL',
      descricao: '',
      valor: 0,
      quantidade: 1,
    },
  })

  const onSubmit = useCallback(
    async ({ canal, descricao, valor, quantidade }: FormData) => {
      if (isSubmitting) return
      await http.execute({ canal, descricao, valor, quantidade })
    },
    [http, isSubmitting],
  )

  return (
    <div>
      <Button onClick={handleOpen}>
        <AddCircle />
        Novo
      </Button>
      <Modal open={open} onClose={handleClose}>
        <Container
          maxWidth="md"
          sx={{
            bgcolor: 'grey.50',
          }}
        >
          <FormControl
            fullWidth
            component={Card}
            variant="outlined"
            sx={{ p: 1 }}
          >
            <FormLabel component="legend">
              Cadastrar novo serviço prestado
            </FormLabel>
            <FormGroup>
              <Grid container spacing={2} mt={4}>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="setor-select">
                      Canal de vendas
                    </InputLabel>
                    <Controller
                      control={control}
                      name="canal"
                      render={({ field: { onChange, value } }) => (
                        <Select
                          value={value}
                          onChange={onChange}
                          label="Canal de vendas"
                          labelId="setor-select-label"
                          size="small"
                          required
                        >
                          <MenuItem value="VENDA_DIGITAL">
                            VENDA DIGITAL
                          </MenuItem>
                          <MenuItem value="VENDA_FISICA">VENDA FISICA</MenuItem>
                        </Select>
                      )}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    label="Descrição"
                    error={!!errors.descricao}
                    helperText={errors.descricao?.message}
                    {...register('descricao')}
                    fullWidth
                    size="small"
                    required
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Valor"
                    error={!!errors.valor}
                    helperText={errors.valor?.message}
                    fullWidth
                    {...register('valor', {
                      valueAsNumber: true,
                      validate: (value) => value > 0,
                    })}
                    size="small"
                    prefix="R$"
                    required
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Quantidade"
                    error={!!errors.quantidade}
                    helperText={errors.quantidade?.message}
                    fullWidth
                    {...register('quantidade', {
                      valueAsNumber: true,
                      validate: (value) => value > 0,
                    })}
                    size="small"
                    required
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  sx={{ display: 'flex', justifyContent: 'space-between' }}
                  mb={2}
                >
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={handleClose}
                  >
                    Fechar
                  </Button>
                  <LoadingButton
                    loading={http.isLoading}
                    loadingPosition="start"
                    startIcon={<SaveOutlined />}
                    variant="contained"
                    onClick={handleSubmit(onSubmit)}
                  >
                    Salvar
                  </LoadingButton>
                </Grid>
              </Grid>
            </FormGroup>
          </FormControl>
        </Container>
      </Modal>
    </div>
  )
}
