/* eslint-disable camelcase */
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { Card, CardHeader, Collapse, IconButton } from '@mui/material'
import * as React from 'react'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { apiV1 } from 'services'
import { IIngresso } from 'types/ingresso'
import { ISetor } from 'types/setor'
import { BatchItem } from './BatchItem'
import SectorDeleteButton from './SectorDeleteButton'
import SectorEditButton from './SectorEditButton'

interface SectorProps {
  sector: ISetor
  setBatch: (batchId: number, sectorId?: number) => void
  load: () => void
  isAdmin: boolean
  index: number
}

const Sector = ({ sector, setBatch, load, isAdmin }: SectorProps) => {
  const [open, setOpen] = React.useState(true)
  const [batches, setBatches] = React.useState<IIngresso[]>(sector.ingresso)

  const handleClick = () => {
    setOpen(!open)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onDragEnd = (e: any) => {
    if (!e.destination) return
    const tempData = Array.from(batches)
    const [source_data] = tempData.splice(e.source.index, 1)
    tempData.splice(e.destination.index, 0, source_data)
    setBatches(tempData)
    if (isAdmin) {
      apiV1.admin.ticketService
        .updateOrdinations(sector.cod_evento, tempData)
        .then()
    } else {
      apiV1.producer.ticketService
        .updateOrdinations(sector.cod_evento, tempData)
        .then()
    }
  }

  return (
    <Card sx={{ m: 1, bgcolor: 'background.paper' }}>
      <CardHeader
        action={
          <>
            <IconButton aria-label="setor" onClick={handleClick}>
              {open ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
            {/* <Draggable draggableId={'sector_' + sector.id} index={index}>
              {(provided) => (
                <IconButton
                  aria-label="reordenar"
                  // onClick={() => {}}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  ref={provided.innerRef}
                >
                  <DragHandleIcon />
                </IconButton>
              )}
            </Draggable> */}
          </>
        }
        title={
          <>
            {sector.titulo}
            <SectorEditButton
              eventId={sector.cod_evento}
              sectorId={sector.id}
              callback={load}
              isAdmin={isAdmin}
            />
            {!sector.ingresso.length && (
              <SectorDeleteButton
                sector={sector}
                isAdmin={isAdmin}
                callback={load}
              />
            )}
          </>
        }
        subheader={sector.descricao}
      />
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={'sector_' + sector.id}>
          {(provided) => (
            <Collapse
              in={open}
              timeout="auto"
              unmountOnExit
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {batches.map((batch, index) => (
                <BatchItem
                  batch={batch}
                  setBatch={setBatch}
                  isAdmin={isAdmin}
                  callback={load}
                  key={batch.id}
                  index={index}
                />
              ))}
            </Collapse>
          )}
        </Droppable>
      </DragDropContext>
    </Card>
  )
}

export default React.memo(Sector)
