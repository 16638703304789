/* eslint-disable @typescript-eslint/no-explicit-any */
import { ILogin } from 'types/login'
import { IUsuario } from 'types/usuario'
import clientRest from './client'
import clientUser from './clientUser'

const login = (email: string, password: string) => {
  return clientRest.post<ILogin>(`login`, {
    email,
    password,
  })
}

const loginUser = (email: string, password: string) => {
  return clientRest.post<ILogin>(`login`, {
    email,
    password,
    type: 'user',
  })
}

const getUserByToken = () => {
  return clientUser.get<IUsuario>(`users/token`)
}

const getUserByTokenWithToken = (token?: string | undefined) => {
  return clientUser.get<IUsuario>(`users/token`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

const create = (userData: any) => {
  return clientRest.post(`users`, userData)
}

const completeRegistration = (userData: any) => {
  return clientUser.patch<IUsuario>(
    `users/${userData.id}/complete-registration`,
    userData,
  )
}

const finalizeRegistration = (userData: any, token: string) => {
  return clientRest.patch(
    `users/${userData.id}/finalize-registration`,
    userData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  )
}

type ExistInDatabaseResponse = {
  cpf: boolean
  email: boolean
  phone: boolean
}
const existInDatabase = ({
  cpf,
  email,
  phone,
}: {
  cpf?: string
  email?: string
  phone?: string
}) => {
  return clientRest.post<ExistInDatabaseResponse>(`users/exist-in-database`, {
    cpf,
    email,
    phone,
  })
}

const verifyEmail = (email: string) => {
  return clientUser.post(`users/verify-email`, {
    email,
  })
}

const verifyPhoneNumber = (phone: string) => {
  return clientUser.post(`users/verify-phone-number`, {
    phone,
  })
}

const checkPhoneNumberCode = (phoneNumberCode: string) => {
  return clientUser.post(`users/check-phone-number-code`, {
    phoneNumberCode,
  })
}

const isProducer = () => {
  return clientUser.get<boolean>(`users/is-producer`)
}

type createProducerProps = {
  razao_social: string
  cnpj?: string
  email: string
  telefone: string
}
const createProducer = (props: createProducerProps) => {
  return clientUser.post(`users/create-producer`, { ...props })
}

const emailConfirmation = (userId = 'no-id', token = 'no-token') => {
  return clientRest.post(`users/${userId}/email-confirmation/${token}`)
}

const forgotPassword = (email: string) => {
  return clientRest.post(`users/forgot-password`, { email })
}

const recoverPassword = (data: any) => {
  return clientRest.post(`users/recover-password`, data)
}

const userService = {
  checkPhoneNumberCode,
  getUserByToken,
  getUserByTokenWithToken,
  create,
  completeRegistration,
  finalizeRegistration,
  existInDatabase,
  verifyEmail,
  verifyPhoneNumber,
  emailConfirmation,
  login,
  loginUser,
  forgotPassword,
  recoverPassword,
  isProducer,
  createProducer,
}

export default userService
