import { PersonAddAlt, Refresh, Search } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Alert,
  Box,
  Breadcrumbs,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  InputAdornment,
  Link,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { DataGrid, GridColDef, GridRowsProp } from '@mui/x-data-grid'
import { AxiosError } from 'axios'
import { useFormik } from 'formik'
import * as React from 'react'
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom'
import { apiV1 } from 'services'
import { IEvento } from 'types/evento'
import { datagrid, functionWithLoading, icons } from 'utils'
import { moneyFormatter } from 'utils/formatter'
import * as yup from 'yup'

export const MOBILE_COLUMNS = {
  nome: true,
  _quantidade_app: false,
  _quantidade_site: false,
  _quantidade_fisico: false,
  usuario_permissao_evento: true,
  actions: false,
}

export const ALL_COLUMNS = {
  nome: true,
  _quantidade_app: true,
  _quantidade_site: true,
  _quantidade_fisico: true,
  usuario_permissao_evento: true,
  actions: true,
}

const validationSchema = yup.object({
  email: yup
    .string()
    .email('Preencha um e-mail válido')
    .required('É necessário preencher o e-mail'),
  permissions: yup
    .array()
    .of(yup.number())
    .min(1, 'Selecione pelo menos uma permissão antes de adicionar')
    .nullable(),
})

export default function EventStaff() {
  const { eventId } = useParams()
  const [loadingData, setLoadingData] = React.useState(false)
  const [loadingAdd, setLoadingAdd] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState('')
  const [event, setEvent] = React.useState<IEvento>()
  const [searchQuery, setSearchQuery] = React.useState('')

  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('sm'))
  const [columnVisible, setColumnVisible] = React.useState(ALL_COLUMNS)
  React.useEffect(() => {
    const newColumns = matches ? ALL_COLUMNS : MOBILE_COLUMNS
    setColumnVisible(newColumns)
  }, [matches])

  const navigate = useNavigate()

  const [permissions, setPermissions] = React.useState([
    { id: 1, descricao: 'Vender por link', checked: false },
    { id: 2, descricao: 'Produtor', checked: false },
    { id: 3, descricao: 'Vender ingressos via APP', checked: false },
    { id: 4, descricao: 'Validar ingressos', checked: false },
    { id: 5, descricao: 'Ver relatórios de vendas', checked: false },
    { id: 6, descricao: 'Check-in manual', checked: false },
    { id: 7, descricao: 'Parceiro', checked: false },
  ])

  const loadEvent = React.useCallback(() => {
    functionWithLoading(
      apiV1.producer.eventService
        .getDetails(Number(eventId))
        .then((res) => {
          setEvent(res.data)
        })
        .catch((err) =>
          setErrorMessage(err.response?.data.message ?? err.message),
        ),
      setLoadingData,
    )
  }, [eventId])

  React.useEffect(() => {
    loadEvent()
  }, [loadEvent])

  const formik = useFormik({
    initialValues: {
      email: '',
      permissions: [],
    },
    validateOnChange: true,
    validationSchema,
    onSubmit: (values) => {
      if (event)
        functionWithLoading(
          apiV1.eventService
            .addUserPermissions(event?.id, values.email, values.permissions)
            .then(() => {
              loadEvent()
              setErrorMessage('')
              formik.setFieldValue('email', '')
              formik.setFieldTouched('email')
            })
            .catch((err: AxiosError) => {
              setErrorMessage(
                err.response?.data.message ??
                  'Ocorreu um erro, tente novamente! Se o erro persistir entre em contato com o suporte.',
              )
            }),
          setLoadingAdd,
        )
    },
  })

  React.useEffect(() => {
    formik.setFieldValue(
      'permissions',
      permissions.filter((x) => x.checked).map((x) => x.id),
    )
    formik.setFieldTouched('permissions')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissions])

  const list =
    event?._staff?.map((x) => ({
      ...x,
      nome: [x.nome, x.sobrenome].join(' ').trim(),
      telefone: [x.ddd, x.telefone].join(''),
    })) ?? []

  const totalValueApp = React.useMemo(
    () =>
      event?._staff?.reduce(
        (total, user) => total + Number(user._valor_app),
        0,
      ) ?? 0,
    [event?._staff],
  )

  const totalValueSite = React.useMemo(
    () =>
      event?._staff?.reduce(
        (total, user) => total + Number(user._valor_site),
        0,
      ) ?? 0,
    [event?._staff],
  )

  const totalValueFisico = React.useMemo(
    () =>
      event?._staff?.reduce(
        (total, user) => total + Number(user._valor_fisico),
        0,
      ) ?? 0,
    [event?._staff],
  )

  const rows: GridRowsProp =
    searchQuery.length > 0
      ? list.filter(
          (x) =>
            x.nome
              .toLocaleLowerCase()
              .includes(searchQuery.toLocaleLowerCase()) ||
            x.telefone
              .toLocaleLowerCase()
              .includes(searchQuery.toLocaleLowerCase()) ||
            x.email
              ?.toLocaleLowerCase()
              .includes(searchQuery.toLocaleLowerCase()) ||
            x.id.toString().includes(searchQuery),
        )
      : list

  const columns: GridColDef[] = [
    {
      field: 'nome',
      headerName: 'Nome',
      flex: 1,
      renderCell: (cell) => (
        <div
          onClick={() =>
            navigate(`/organizador/eventos/${eventId}/staff/${cell.id}`)
          }
        >
          {[cell.row.nome, cell.row.sobrenome].join(' ').trim().toUpperCase()}
        </div>
      ),
    },
    { field: '_quantidade_app', headerName: 'Qtd. APP' },
    { field: '_quantidade_site', headerName: 'Qtd. Link' },
    { field: '_quantidade_site', headerName: 'Qtd. Físico' },
    {
      field: 'usuario_permissao_evento',
      headerName: 'Permissões',
      renderCell: (cellValues) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return cellValues.value.map(({ permissao }: any) => (
          <React.Fragment key={permissao.id}>
            {icons.getPermissionIcon(permissao.id)}
          </React.Fragment>
        ))
      },
      width: 120,
    },
    {
      field: 'actions',
      headerName: 'Ações',
      renderCell: (cellValues) => {
        return (
          <Link
            component={RouterLink}
            to={`/organizador/eventos/${eventId}/staff/${cellValues.id}`}
          >
            Detalhes
          </Link>
        )
      },
    },
  ]

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChangePermission = (event: any) => {
    const newPermissions = permissions.map((permission) => {
      if (permission.id === Number(event.target.value)) {
        permission.checked = event.target.checked
      }
      return permission
    })
    setPermissions(newPermissions)
  }

  return (
    <React.Fragment>
      <Stack direction="row" justifyContent="space-between">
        <Box role="presentation" sx={{ pt: 2, pb: 2 }}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              component={RouterLink}
              underline="hover"
              color="inherit"
              to="/organizador/eventos"
            >
              Eventos
            </Link>
            <Link
              component={RouterLink}
              underline="hover"
              color="inherit"
              to={`/organizador/eventos/${event?.id}`}
            >
              {event?.titulo}
            </Link>
            <Typography color="text.primary">Staff</Typography>
          </Breadcrumbs>
        </Box>
      </Stack>
      {errorMessage && (
        <Alert severity="error" onClose={() => setErrorMessage('')}>
          {errorMessage}
        </Alert>
      )}
      <Stack>
        <Typography>Total de vendas da equipe</Typography>
        <Stack direction="row">
          <Typography>APP: {moneyFormatter.format(totalValueApp)}</Typography>
          <Typography>Site: {moneyFormatter.format(totalValueSite)}</Typography>
          <Typography>
            Físico: {moneyFormatter.format(totalValueFisico)}
          </Typography>
        </Stack>
      </Stack>
      <Box
        sx={{ p: 2, flex: 0 }}
        component="form"
        noValidate
        onSubmit={formik.handleSubmit}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          <TextField
            id="outlined-basic"
            label="E-mail"
            variant="outlined"
            sx={{ flex: 1, mr: 1 }}
            type="email"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            size="small"
          />
          <LoadingButton
            loading={loadingAdd}
            loadingPosition="start"
            startIcon={<PersonAddAlt />}
            variant="outlined"
            type="submit"
          >
            Adicionar
          </LoadingButton>
        </Box>

        <FormGroup sx={{ display: 'flex', flexDirection: 'row' }}>
          {permissions.map((permission) => (
            <FormControlLabel
              key={permission.id}
              control={<Checkbox />}
              value={permission.id}
              label={
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  {icons.getPermissionIcon(permission.id)}
                  {permission.descricao}
                </Box>
              }
              checked={permission.checked}
              onChange={(e) => {
                handleChangePermission(e)
              }}
            />
          ))}
        </FormGroup>
        {formik.touched.permissions && formik.errors.permissions && (
          <FormHelperText error>{formik.errors.permissions}</FormHelperText>
        )}
      </Box>
      <Grid container spacing={2} p={2}>
        <Grid item xs={12} sm={8}>
          <TextField
            label="Pesquisar"
            variant="outlined"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            onChange={(e) => setSearchQuery(e.target.value)}
            value={searchQuery}
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <LoadingButton
            loading={loadingData}
            loadingPosition="start"
            startIcon={<Refresh />}
            variant="contained"
            onClick={loadEvent}
          >
            Atualizar
          </LoadingButton>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Link
            component={RouterLink}
            underline="hover"
            color="inherit"
            to={`/organizador/eventos/${event?.id}/relatorio/usuario-lote`}
          >
            Relatório por usuário lote
          </Link>
        </Grid>
        <Grid item xs={12}>
          <DataGrid
            rows={rows}
            columns={columns}
            autoHeight
            localeText={datagrid.localizedTextsMap}
            columnVisibilityModel={columnVisible}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
