import React from 'react'
import { Container, Typography } from '@mui/material'
import { usePromoter } from 'contexts/promoterContext'

const DashboardPage: React.FC = () => {
  const { setPageTitle } = usePromoter()
  React.useEffect(() => {
    setPageTitle('Dashboard')
  }, [setPageTitle])

  return (
    <Container>
      <Typography variant="h4" mt={2} mb={2}>
        Dashboard
      </Typography>
    </Container>
  )
}

export default DashboardPage
