import { Stack } from '@mui/material'
import Grid from '@mui/material/Grid'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import {
  ShoppingCartPaymentInfo,
  useShoppingCart,
} from 'contexts/shoppingCartContext'
import { useUser } from 'contexts/userContext'
import * as React from 'react'
import { useFormContext } from 'react-hook-form'
import { IIngresso } from 'types/ingresso'
import { PaymentMethodType } from 'types/shoppingCart'
import { moneyFormatter } from 'utils/formatter'
import { compare } from 'utils/functions'

interface ProductListItemProps {
  product: IIngresso & { amount: number }
}

const ProductListItem = ({ product }: ProductListItemProps) => {
  const serviceFee =
    (Number(product.taxa_percentual) * Number(product.valor)) / 100 +
    Number(product.taxa_fixa)
  const absorbedFee =
    product.taxa_absorvida > 0
      ? (serviceFee * Number(product.taxa_absorvida)) / 100
      : 0
  const totalFees = serviceFee - absorbedFee
  return (
    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <TableCell component="th" scope="row">
        <Typography variant="body2">{product.titulo}</Typography>
        <Typography variant="caption">taxa de serviço</Typography>
      </TableCell>
      <TableCell align="right">
        <Typography variant="body2">
          {moneyFormatter.format(product.valor)}
        </Typography>
        <Typography variant="caption">
          {moneyFormatter.format(totalFees)}
        </Typography>
      </TableCell>
      <TableCell align="right">
        <Typography variant="body2">{product.amount}</Typography>
      </TableCell>
      <TableCell align="right">
        <Typography variant="body2">
          {moneyFormatter.format(product.valor * product.amount)}
        </Typography>
        <Typography variant="caption">
          {moneyFormatter.format(totalFees * product.amount)}
        </Typography>
      </TableCell>
    </TableRow>
  )
}

export default function Review() {
  const { shoppingCart, totalShoppingCartValue, discountValue } =
    useShoppingCart()
  const { user } = useUser()
  const { watch, trigger } = useFormContext<ShoppingCartPaymentInfo>()

  const { discountCoupon, additionalFees } = watch()

  const items = shoppingCart.sort((a, b) =>
    compare(a.titulo.toLowerCase(), b.titulo.toLowerCase()),
  )

  React.useEffect(() => {
    async function main() {
      await trigger()
    }
    main()
  }, [trigger])

  const getPaymentDetails = React.useCallback(
    (paymentType: PaymentMethodType) => {
      switch (paymentType) {
        case 'creditCard':
          return (
            <React.Fragment>
              <Grid item xs={6}>
                <Typography variant="caption" component="p" gutterBottom>
                  Método de pagamento
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="caption" component="p" gutterBottom>
                  Cartão de crédito
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="caption" component="p" gutterBottom>
                  Nome do titular
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="caption" component="p" gutterBottom>
                  {watch('creditCard.holder')}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="caption" component="p" gutterBottom>
                  Número do cartão
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="caption" component="p" gutterBottom>
                  {watch('creditCard.number')}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="caption" component="p" gutterBottom>
                  Vencimento
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="caption" component="p" gutterBottom>
                  {watch('creditCard.expMonth')}/{watch('creditCard.expYear')}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="caption" component="p" gutterBottom>
                  Código de segurança
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="caption" component="p" gutterBottom>
                  {watch('creditCard.securityCode')}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="caption" component="p" gutterBottom>
                  Documento do titular
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="caption" component="p" gutterBottom>
                  {watch('creditCard.taxId')}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="caption" component="p" gutterBottom>
                  Endereço de cobrança
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="caption" component="p" gutterBottom>
                  {watch('address.street')}, {watch('address.number')},{' '}
                  {watch('address.complement')}, {watch('address.locality')} -{' '}
                  {watch('address.city')} - {watch('address.regionCode')} -{' '}
                  {watch('address.postalCode')}
                </Typography>
              </Grid>
            </React.Fragment>
          )
        case 'pix':
          return (
            <Grid item xs={12}>
              <Typography variant="caption" component="p" gutterBottom>
                PIX
              </Typography>
              <Typography variant="caption" component="p" gutterBottom>
                O QR Code será gerado após clicar em FAZER PAGAMENTO
              </Typography>
            </Grid>
          )
        case 'boleto':
          return (
            <Grid item xs={12}>
              <Typography variant="caption" component="p" gutterBottom>
                Boleto
              </Typography>
              <Typography variant="caption" component="p" gutterBottom>
                O boleto será gerado após clicar em FAZER PAGAMENTO
              </Typography>
            </Grid>
          )
        default:
          return <div />
      }
    },
    [watch],
  )

  return (
    <Grid container p={1}>
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
          Resumo do pedido
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Lote</TableCell>
                <TableCell align="right">Preço</TableCell>
                <TableCell align="right">Qtd.</TableCell>
                <TableCell align="right">Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((product, index) => (
                <ProductListItem
                  key={product.id + '_' + index}
                  product={product}
                />
              ))}

              {discountCoupon && discountCoupon.id && (
                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell colSpan={4}>
                    <Stack
                      flex={1}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      gap={2}
                    >
                      <Typography variant="subtitle2" fontWeight="bold">
                        Cupom de desconto
                      </Typography>

                      <Typography variant="subtitle2" fontWeight="bold">
                        {discountCoupon.codigo}
                      </Typography>
                      <Typography variant="subtitle2" color="red">
                        {moneyFormatter.format(discountValue)}{' '}
                      </Typography>
                    </Stack>
                  </TableCell>
                </TableRow>
              )}
              {additionalFees && (
                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell colSpan={4}>
                    <Typography variant="subtitle2" textAlign="right" flex={1}>
                      Taxas de parcelamento:{' '}
                      {moneyFormatter.format(Number(additionalFees))}
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell colSpan={4}>
                  <Typography
                    variant="subtitle1"
                    textAlign="right"
                    sx={{ fontWeight: 700 }}
                    flex={1}
                  >
                    Total:{' '}
                    {moneyFormatter.format(
                      totalShoppingCartValue + Number(additionalFees ?? 0),
                    )}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Grid item xs={12} sm={5}>
        <Typography variant="h6" gutterBottom>
          Pagador
        </Typography>
        <Typography variant="caption" component="p" gutterBottom>
          {[user.nome, user.sobrenome].join(' ').trim().toUpperCase()}
        </Typography>
        <Typography variant="caption" component="p" gutterBottom>
          CPF: {user.cpf}
        </Typography>
        <Typography variant="caption" component="p" gutterBottom>
          Cel: ({user.ddd}) {user.telefone}
        </Typography>
      </Grid>
      <Grid item container direction="column" xs={12} sm={7}>
        <Typography variant="h6" gutterBottom>
          Detalhes de pagamento
        </Typography>
        <Grid container>{getPaymentDetails(watch('paymentType'))}</Grid>
      </Grid>
    </Grid>
  )
}
