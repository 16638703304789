import { validation } from 'utils'
import * as yup from 'yup'

const validationSchema = yup.object({
  email: yup
    .string()
    .email('É necessário preencher um email válido')
    .required('É necessário preencher o email')
    .nullable(),
  confirmarEmail: yup
    .string()
    .required('É necessário preencher a confirmação de e-mail')
    .oneOf([yup.ref('email'), null], 'Os e-mails devem ser iguais')
    .nullable(),
  nome: yup
    .string()
    .min(1)
    .required('É necessário preencher o sobrenome')
    .nullable(),
  cpf: yup
    .string()
    .required('É necessário preencher o CPF')
    .test('cpf', 'CPF Inválido', (value) => validation.isValidCPF(value ?? ''))
    .nullable(),
  data_nascimento: yup
    .string()
    .required('Informe a data de nascimento')
    .nullable(),
  telefone: yup
    .string()
    .length(15, 'O número deve conter 11 digitos')
    .required('É necessário preencher o telefone')
    .nullable(),
  sexo: yup
    .string()
    .not(['0'])
    .required('Por favor selecione o sexo')
    .nullable(),
  cod_brasil_cidade: yup
    .number()
    .not([0])
    .required('É necessário selecionar a cidade')
    .nullable(),
  senha: yup
    .string()
    .min(8, 'A senha precisa ter no mínimo 8 caracteres')
    .required('É necessário preencher a senha')
    .nullable(),
  confirmarSenha: yup
    .string()
    .required('É necessário preencher a confirmação de senha')
    .oneOf([yup.ref('senha'), null], 'As senhas devem ser iguais')
    .nullable(),
})

export type SignupFields = {
  email: string
  confirmarEmail: string
  nome: string
  cpf: string
  data_nascimento: string
  telefone: string
  sexo: string
  cod_brasil_cidade: number
  senha: string
  confirmarSenha: string
}

export default validationSchema
