import { Search } from '@mui/icons-material'
import { Grid, InputAdornment, Paper, Skeleton, TextField } from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { Typography } from 'components/Typography'
import { useAdmin } from 'contexts/adminContext'
import { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { apiV1 } from 'services'
import { IPromoter } from 'types/promoter'
import { getErrorMessage } from 'utils/AppError'

export function Producers() {
  const [isLoading, setIsLoading] = useState(false)
  const [events, setEvents] = useState<IPromoter[]>([])
  const [searchQuery, setSearchQuery] = useState('')

  const { addErrorMessage } = useAdmin()

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true)
      const { data } = await apiV1.admin.promoterService.getList()
      setEvents(data.promoters)
    } catch (error) {
      addErrorMessage(getErrorMessage(error))
    } finally {
      setIsLoading(false)
    }
  }, [addErrorMessage])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  if (isLoading) return <PageLoading />

  const rows = events.filter(
    (producer) =>
      producer.id.toString().includes(searchQuery) ||
      producer.razao_social?.toLowerCase().includes(searchQuery) ||
      producer.telefone?.toLowerCase().includes(searchQuery) ||
      producer.usuario?.nome.toLowerCase().includes(searchQuery) ||
      producer.usuario?.sobrenome?.toLowerCase().includes(searchQuery) ||
      producer.usuario?.telefone?.toLowerCase().includes(searchQuery) ||
      producer.username?.toLowerCase().includes(searchQuery),
  )

  const columns: GridColDef<IPromoter>[] = [
    {
      field: 'id',
      headerName: 'ID',
      width: 50,
      renderCell: (cell) => (
        <Link to={`/admin/produtoras/${cell.value}`}>{cell.value}</Link>
      ),
    },
    {
      field: 'razao_social',
      headerName: 'Razão social',
      flex: 1,
    },
    {
      field: 'email',
      headerName: 'E-mail',
      width: 350,
    },
    {
      field: 'telefone',
      headerName: 'Telefone',
      width: 150,
    },
    {
      field: 'usuario.nome',
      headerName: 'Responsável',
      flex: 1,
      renderCell: (cell) =>
        `${cell.row.usuario.nome} ${cell.row.usuario.sobrenome} - ${cell.row.usuario.ddd}${cell.row.usuario.telefone}`,
    },
  ]

  return (
    <Grid container spacing={2} component={Paper} p={2}>
      <Grid item xs={12}>
        <Typography variant="h5">Lista de produtoras</Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Pesquisar"
          variant="outlined"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          onChange={(e) => setSearchQuery(e.target.value.toLowerCase())}
          value={searchQuery}
          size="small"
        />
      </Grid>
      <Grid item xs={12}>
        <DataGrid
          rows={rows}
          columns={columns}
          autoHeight
          sx={{ overflowX: 'scroll' }}
        />
      </Grid>
    </Grid>
  )
}

function PageLoading() {
  return (
    <Grid item xs={12}>
      <Skeleton height={20} />
      <Skeleton />
      <Skeleton />
      <Skeleton />
      <Skeleton />
    </Grid>
  )
}
