import { Add, Edit } from '@mui/icons-material'
import {
  Alert,
  Box,
  Button,
  Container,
  IconButton,
  Modal,
  Stack,
} from '@mui/material'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { Circle } from '@phosphor-icons/react'
import { Loading } from 'components'
import dayjs from 'dayjs'
import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { apiV1 } from 'services'
import { ICupomDesconto } from 'types/cupom_desconto'
import { getErrorMessage } from 'utils/AppError'
import { moneyFormatter } from 'utils/formatter'
import { DiscountCouponForm } from 'widgets/DiscountCouponForm'

interface DiscountCouponsProps {
  isAdmin?: boolean
}

export function DiscountCoupons({ isAdmin }: DiscountCouponsProps) {
  const [isLoading, setIsLoading] = useState(false)
  const [coupons, setCoupons] = useState<ICupomDesconto[]>([])
  const [error, setError] = useState('')
  const [selectedDiscountCoupon, setSelectedDiscountCoupon] = useState(0)
  const [open, setOpen] = useState(false)

  const params = useParams()
  const eventId = Number(params.eventId)

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true)
      const { data } = isAdmin
        ? await apiV1.admin.discountCouponService.getAll(eventId)
        : await apiV1.producer.discountCouponService.getAll(eventId)
      setCoupons(data.discountCoupons)
    } catch (error) {
      setError(
        getErrorMessage(
          error,
          'Ocorreu um erro ao recuperar os cupons de desconto!',
        ),
      )
    } finally {
      setIsLoading(false)
    }
  }, [eventId, isAdmin])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const showDiscountCouponForm = useCallback((discountCouponId) => {
    setOpen(true)
    setSelectedDiscountCoupon(discountCouponId)
  }, [])

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      {!!error && (
        <Alert severity="error" onClose={() => setError('')} closeText="Fechar">
          {error}
        </Alert>
      )}
      <Stack direction="row" justifyContent="flex-end" my={2}>
        <Button
          variant="contained"
          startIcon={<Add />}
          onClick={() => showDiscountCouponForm(0)}
        >
          Adicionar cupom de desconto
        </Button>
      </Stack>
      <TableContainer>
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Código</TableCell>
              <TableCell align="right">Descrição</TableCell>
              <TableCell align="right">Tipo</TableCell>
              <TableCell align="right">Valor</TableCell>
              <TableCell align="right">Expira em</TableCell>
              <TableCell align="right">Quantidade</TableCell>
              <TableCell align="right">Situação</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={7}>
                  <Loading />
                </TableCell>
              </TableRow>
            ) : (
              coupons.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.codigo}
                  </TableCell>
                  <TableCell align="right">{row.descricao}</TableCell>
                  <TableCell align="right">
                    {row.cupom_desconto_tipo.descricao}
                  </TableCell>
                  <TableCell align="right">
                    {row.cod_tipo === 1
                      ? moneyFormatter.format(row.valor)
                      : row.valor + '%'}
                  </TableCell>
                  <TableCell align="right">
                    {dayjs(row.validade)
                      .add(3, 'hours')
                      .format('DD/MM/YYYY HH:mm')}
                  </TableCell>
                  <TableCell align="right">{row.quantidade}</TableCell>
                  <TableCell align="right">
                    <Circle
                      size={32}
                      color={row.ativo ? '#37ff00' : '#ff0000'}
                      weight="fill"
                    />
                  </TableCell>
                  <TableCell align="right">
                    <IconButton
                      aria-label="editar"
                      onClick={() => showDiscountCouponForm(row.id)}
                    >
                      <Edit />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        sx={{ overflow: 'scroll' }}
      >
        <Container
          maxWidth="md"
          sx={{
            bgcolor: 'grey.50',
          }}
        >
          <Box sx={{ margin: 'auto' }}>
            <DiscountCouponForm
              discountCouponId={selectedDiscountCoupon}
              callback={() => {
                setOpen(false)
                fetchData()
              }}
              isAdmin={isAdmin}
            />
          </Box>
        </Container>
      </Modal>
    </Paper>
  )
}
