import categoriesService from './categoriesService'
import discountCouponService from './discountCouponService'
import eventService from './eventService'
import genderService from './genderService'
import localService from './localService'
import promoterService from './promoterService'
import sectorService from './sectorService'
import ticketService from './ticketService'
import uploadService from './uploadService'
import withdrawalService from './withdrawalService'

const producer = {
  categoriesService,
  discountCouponService,
  genderService,
  eventService,
  localService,
  promoterService,
  sectorService,
  ticketService,
  uploadService,
  withdrawalService,
}

export default producer
