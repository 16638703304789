import * as React from 'react'
import Avatar from '@mui/material/Avatar'
import CssBaseline from '@mui/material/CssBaseline'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { useLocation, useNavigate } from 'react-router-dom'
import { ILocationProps } from 'types/locationProps'
import LoginIcon from '@mui/icons-material/Login'
import LoadingButton from '@mui/lab/LoadingButton'
import { Copyright, FormItem } from 'components'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { Alert } from '@mui/material'
import { useAdmin } from 'contexts/adminContext'

const theme = createTheme()

const validationSchema = yup.object({
  email: yup
    .string()
    .email('Preencha um e-mail válido')
    .required('É necessário preencher o e-mail'),
  password: yup.string().required('É necessário preencher a senha').nullable(),
})

export default function LoginPage() {
  const { login, loading, admin, errorMessage } = useAdmin()
  const location = useLocation() as ILocationProps
  const navigate = useNavigate()

  const from = location.state?.from?.pathname ?? '/admin'

  React.useEffect(() => {
    const token = localStorage.getItem('admin_token')
    const admin = localStorage.getItem('admin')
    if (token && admin) {
      navigate(from, { replace: true })
    }
  }, [from, navigate, admin])

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validateOnChange: true,
    validationSchema,
    onSubmit: (values) => {
      login(values.email, values.password)
    },
  })

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: '100vh' }} maxHeight="xs">
        <CssBaseline />
        <Grid item xs={12} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Painel administrativo
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={formik.handleSubmit}
              sx={{ mt: 1 }}
            >
              <FormItem.TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                autoFocus
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
              <FormItem.TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Senha"
                type="password"
                id="password"
                autoComplete="current-password"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
              />
              {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
              <LoadingButton
                loading={loading}
                loadingPosition="start"
                startIcon={<LoginIcon />}
                variant="outlined"
                type="submit"
                fullWidth
                sx={{ mt: 3, mb: 2 }}
              >
                Entrar
              </LoadingButton>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  )
}
