/* eslint-disable @typescript-eslint/no-explicit-any */
import { Link, Skeleton } from '@mui/material'
import Box from '@mui/material/Box'
import CardMedia from '@mui/material/CardMedia'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import { EventCard } from 'components'
import { constants } from 'config'
import * as React from 'react'
import { Helmet } from 'react-helmet'
import { Link as RouterLink } from 'react-router-dom'
import { apiV1 } from 'services'
import { IBanner } from 'types/banner'
import { IEvento } from 'types/evento'
import { functionWithLoading } from 'utils'

import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import { SearchBar } from './_components/SearchBar'
import { replaceSpecialCharacters } from 'utils/string'
import { Typography } from 'components/Typography'
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
}

export const HomePage = () => {
  const [events, setEvents] = React.useState<IEvento[]>([])
  const [searchQuery, setSearchQuery] = React.useState<string>('')
  const [searchCity, setSearchCity] = React.useState<string>('0')
  const [loadingEvents, setLoadingEvents] = React.useState(true)
  const [banners, setBanners] = React.useState<IBanner[]>([])
  const [loadingBanners, setLoadingBanners] = React.useState(true)

  React.useEffect(() => {
    functionWithLoading(
      apiV1.eventService.getAllForSite().then((res) => {
        setEvents(res.data)
      }),
      setLoadingEvents,
    )
    functionWithLoading(
      apiV1.eventService
        .getBannersForSite()
        .then((res) => setBanners(res.data)),
      setLoadingBanners,
    )
  }, [])

  const contains = React.useCallback(
    ({ titulo, local_evento: local }: IEvento) => {
      const formattedQuery = replaceSpecialCharacters(
        searchQuery.toLowerCase(),
      ).trim()
      if (formattedQuery.length === 0) {
        return true
      }
      if (
        replaceSpecialCharacters(titulo).toLowerCase().includes(formattedQuery)
      ) {
        return true
      }
      if (
        replaceSpecialCharacters(local).toLowerCase().includes(formattedQuery)
      ) {
        return true
      }
      return false
    },
    [searchQuery],
  )

  const filteredEvents = events.filter(
    (event) =>
      contains(event) &&
      ['0', event.brasil_cidade?.cidade].includes(searchCity),
  )

  const cities = [
    ...new Set(
      events
        .map((event) => event.brasil_cidade?.cidade ?? '')
        .filter((city) => city.length > 0)
        .sort(function (a, b) {
          if (a < b) {
            return -1
          }
          if (a > b) {
            return 1
          }
          return 0
        }),
    ),
  ]

  return (
    <React.Fragment>
      <Helmet>
        <title>Zé do Ingresso</title>
      </Helmet>

      <Box
        sx={{
          mt: -10,
          pt: 10,
          pb: 2,
          backgroundImage: `linear-gradient(
            to right bottom, rgba(0, 69, 122, 1), rgba(237, 70, 144,0.8)
            ), url("/img/background-home.jpg")`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <Container maxWidth="lg">
          <Carousel
            responsive={responsive}
            showDots
            autoPlay
            autoPlaySpeed={3000}
            infinite
          >
            {loadingBanners ? (
              <Skeleton variant="rectangular" sx={{ aspectRatio: '20 / 7' }} />
            ) : (
              banners.map((banner) => (
                <Box
                  key={banner.id}
                  component={Link}
                  href={banner.link}
                  overflow="hidden"
                >
                  <Box
                    sx={{
                      backgroundImage: `url(${constants.URL_FTP + banner.imagem})`,
                      backgroundSize: 'cover',
                      aspectRatio: '20 / 7',
                    }}
                  />
                  <CardMedia
                    component="img"
                    image={constants.URL_FTP + banner.imagem}
                    alt={banner.descricao}
                    style={{
                      maxHeight: 600,
                      aspectRatio: '20 / 7',
                      objectFit: 'contain',
                      borderRadius: 10,
                      position: 'absolute',
                      top: 0,
                      bottom: 0,
                      left: 0,
                      right: 0,
                    }}
                  />
                </Box>
              ))
            )}
          </Carousel>
          <SearchBar
            cities={cities}
            selectedCity={searchCity}
            onSelectCity={setSearchCity}
            searchQuery={searchQuery}
            onChangeSearchQuery={setSearchQuery}
          />
        </Container>
      </Box>
      <Container sx={{ mt: 2, py: 2 }} maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h6">Confira os próximos eventos</Typography>
          </Grid>
          {loadingEvents ? (
            <React.Fragment>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Skeleton variant="rectangular" height={100} />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Skeleton variant="rectangular" height={100} />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Skeleton variant="rectangular" height={100} />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Skeleton variant="rectangular" height={100} />
              </Grid>
            </React.Fragment>
          ) : (
            filteredEvents.map((event) => (
              <Grid item key={'event_' + event.id} xs={12} sm={6} md={4} lg={3}>
                <Link
                  component={RouterLink}
                  to={`/evento/${event.id}/${event.id_url}`}
                  underline="none"
                >
                  <EventCard event={event} />
                </Link>
              </Grid>
            ))
          )}
        </Grid>
      </Container>
    </React.Fragment>
  )
}
