import { Paper } from '@mui/material'
import { Loading } from 'components'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { apiV1 } from 'services'
import { IPedido } from 'types/pedido'
import { triggerPurchaseEvents } from 'utils/eventUtils'
import { PagSeguroPaymentPending } from './pagSeguroPaymentPending'
import { PagSeguroPaymentRejected } from './pagSeguroPaymentRejected'
import { PagSeguroPaymentUnderAnalysis } from './pagSeguroPaymentUnderAnalysis'
import { PagSeguroSuccess } from './pagSeguroSuccess'

type PagSeguroProps = {
  orderId: string
}

export function PagSeguro({ orderId }: PagSeguroProps) {
  const navigate = useNavigate()
  const [order, setOrder] = useState<IPedido>()

  useEffect(() => {
    if (!order) {
      apiV1.orderService
        .getOrder(orderId)
        .then(({ data }) => {
          setOrder(data)
        })
        .catch((err) => console.log(err))
    }
  }, [navigate, order, orderId])

  const renderContentFromStatus = useCallback(
    (status: number) => {
      if (!order) return
      switch (status) {
        case 2:
          return <PagSeguroPaymentUnderAnalysis orderId={orderId} />
        case 3:
        case 4: {
          triggerPurchaseEvents(orderId)
          return <PagSeguroSuccess orderId={orderId} />
        }
        case 7:
          return (
            <PagSeguroPaymentRejected
              orderId={orderId}
              eventId={order.evento.id}
              eventUrl={order.evento.id_url}
            />
          )

        default:
          return <PagSeguroPaymentPending orderId={orderId} />
      }
    },
    [order, orderId],
  )

  if (!order) return <Loading />

  return (
    <Paper sx={{ my: 1, p: 2 }}>
      {renderContentFromStatus(order.cod_status)}
    </Paper>
  )
}

export default PagSeguro
