import {
  Alert,
  AlertProps,
  Grid,
  Link,
  Skeleton,
  Typography,
} from '@mui/material'
import { EventCard } from 'components'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { apiV1 } from 'services'
import { IPedido } from 'types/pedido'
import { functionWithLoading } from 'utils'
import { getOrderStatusColor } from 'utils/eventUtils'

const PastEvents: React.FC = () => {
  const [loading, setLoading] = React.useState(true)
  const [orders, setOrders] = React.useState<IPedido[]>([])

  React.useEffect(() => {
    functionWithLoading(
      apiV1.orderService
        .getUserOrdersPastEvents()
        .then((res) => setOrders(res.data)),
      setLoading,
    )
  }, [])

  const getAlertProps = React.useCallback((statusId) => {
    const alertProps: AlertProps = { severity: 'info' }

    switch (statusId) {
      case 0:
      case 1:
        alertProps.severity = 'info'
        break
      case 3:
      case 4:
      case 100:
      case 404:
        alertProps.severity = 'success'
        break
      case 7:
      case 107:
        alertProps.severity = 'error'
        break
      case 2:
      case 6:
      case 101:
        alertProps.severity = 'warning'
        break
      default:
        break
    }
    return alertProps
  }, [])

  if (loading)
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Skeleton height={300} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Skeleton height={300} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Skeleton height={300} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Skeleton height={300} />
        </Grid>
      </Grid>
    )

  return (
    <Grid container spacing={2}>
      {orders.map((order) => (
        <Grid key={order.id} item xs={12} sm={6} md={4} lg={3}>
          <Link
            component={RouterLink}
            to={`/usuario/pedidos/${order.codigo}`}
            underline="none"
          >
            <EventCard
              event={order.evento}
              extra={() => (
                <React.Fragment>
                  <Typography variant="subtitle2" ml={2}>
                    Pedido: {order.codigo}
                  </Typography>
                  <Alert {...getAlertProps(order.cod_status)}>
                    {order.status}
                  </Alert>
                  <Alert severity="info">
                    {order.pedido_item?.length} ingresso(s)
                  </Alert>
                </React.Fragment>
              )}
              backgroundColor={getOrderStatusColor(order.cod_status)}
            />
          </Link>
        </Grid>
      ))}
    </Grid>
  )
}

export default PastEvents
