import { CardContent, Divider, Radio } from '@mui/material'
import Collapse from '@mui/material/Collapse'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import ListSubheader from '@mui/material/ListSubheader'
import { ShoppingCartPaymentInfo } from 'contexts/shoppingCartContext'
import * as React from 'react'
import { useFormContext } from 'react-hook-form'
import { PaymentMethodType } from 'types/shoppingCart'
import { Capitalize } from 'utils/string'
import CreditCard from './PaymentMethods/CreditCard'

function getPaymentContent(paymentType: PaymentMethodType) {
  switch (paymentType) {
    case 'creditCard':
      return <CreditCard />
    default:
      return <React.Fragment />
  }
}

const PaymentCreditCardListItem = () => {
  const { setValue, watch } = useFormContext<ShoppingCartPaymentInfo>()

  const handleClick = (paymentType: PaymentMethodType) => {
    setValue('paymentType', paymentType)
  }

  return (
    <>
      <ListItemButton
        onClick={() => handleClick('creditCard')}
        selected={watch('paymentType') === 'creditCard'}
      >
        <ListItemIcon>
          <Radio checked={watch('paymentType') === 'creditCard'} />
        </ListItemIcon>
        <ListItemText primary="Cartão de crédito" />
      </ListItemButton>
      <Collapse
        in={watch('paymentType') === 'creditCard'}
        timeout="auto"
        unmountOnExit
      >
        <CardContent>{getPaymentContent(watch('paymentType'))}</CardContent>
      </Collapse>
    </>
  )
}

type PaymentListItemProps = {
  paymentMethodType: PaymentMethodType
}
const PaymentListItem = ({ paymentMethodType }: PaymentListItemProps) => {
  const { setValue, resetField, watch } = useFormContext()

  const handleClick = () => {
    resetField('creditCard')
    resetField('address')
    setValue('paymentType', paymentMethodType)
  }

  return (
    <ListItemButton
      onClick={handleClick}
      selected={watch('paymentType') === paymentMethodType}
    >
      <ListItemIcon>
        <Radio checked={watch('paymentType') === paymentMethodType} />
      </ListItemIcon>
      <ListItemText primary={Capitalize(paymentMethodType)} />
    </ListItemButton>
  )
}

export default function PaymentForm() {
  return (
    <List
      sx={{
        bgcolor: 'background.paper',
        border: 'solid #e0e0e0 1px',
      }}
      component="nav"
      subheader={
        <ListSubheader component="div">Formas de pagamento</ListSubheader>
      }
    >
      <PaymentListItem paymentMethodType="pix" />
      <Divider />
      <PaymentListItem paymentMethodType="boleto" />
      <Divider />
      <PaymentCreditCardListItem />
    </List>
  )
}
