import { ISuspectedOrder } from 'types/admin'
import clientAdmin from './client'

const suspectedOrders = (eventId: number) => {
  return clientAdmin.get<ISuspectedOrder[]>(`suspected-orders/${eventId}`)
}

const underReview = () => {
  return clientAdmin.get(`orders/under-review`)
}

const approveOrder = (orderId: string) => {
  return clientAdmin.post(`orders/${orderId}/approve`)
}

const get = (orderId: string) => {
  return clientAdmin.get(`orders/${orderId}`)
}

const orderService = {
  suspectedOrders,
  get,
  underReview,
  approveOrder,
}

export default orderService
