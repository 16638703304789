const constants = {
  URL_API:
    process.env.REACT_APP_URL_API_DEVELOPMENT ??
    'https://api.zedoingresso.com.br/',
  URL_FTP: 'https://storage.zedoingresso.com.br/zedoingresso/',
  PAGSEGURO_PUBLIC_KEY:
    'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAjXPInmY1TauU8EVCNZ14WGQOs097jPsf70YlKgcUHsI80nKJL9QHgK1qqRqId3P7cIz+TEFVQF9i56qKutVsV1/hUW3thv+oSYpjURFZSmLqo0nTNZ8C7N040JYjQ/Hzz0FrS7emFYOHtsd+MkncQtVgDNnDfLCHUgIuKSouUPULU6/8VNVNNPGP6H7kYjq3YBEsO9hxyZwx03HuC75gO7w/RK+aXHbRZeVKQ44mhaeKhsjLndvjXpBJQ70HxuT8Z9Yn3za1110WD9ai4PKaNaZyiJqBmpH2Oiib/XjxcJxp7iMQ267jd33R8ZzLgkb0G/st6ZaSxL7aDz4wgAEKJwIDAQAB',
  SHOW_MAINTENANCE: process.env.REACT_APP_SHOW_MAINTENANCE === 'TRUE',
}

export default constants
