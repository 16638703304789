import { IPedido } from 'types/pedido'
import { IPedidoItem } from 'types/pedido_item'
import clientPromoter from './clientPromoter'
import clientUser from './clientUser'

const downloadTicketPDF = (id: number) => {
  return clientUser.get(`user-website/order-items/${id}/download-ticket-pdf`, {
    responseType: 'blob',
  })
}

const updateParticipant = (item: IPedidoItem) => {
  return clientUser.patch<IPedido>(
    `user-website/order-items/${item.id}/update-participant`,
    {
      participant: item.participante,
      document: item.documento,
    },
  )
}

const transferTicket = (orderItemId: number, email: string) => {
  return clientUser.patch<IPedido>(
    `user-website/order-items/${orderItemId}/transfer-ticket`,
    {
      email,
    },
  )
}

const getOrderItemsByEvent = (eventId: string) => {
  return clientPromoter.get<Array<IPedidoItem>>(
    `/events/${eventId}/order-items`,
  )
}

const orderItemService = {
  downloadTicketPDF,
  updateParticipant,
  transferTicket,
  getOrderItemsByEvent,
}

export default orderItemService
