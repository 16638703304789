import { Grid, Paper, Skeleton } from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { Typography } from 'components/Typography'
import { useAdmin } from 'contexts/adminContext'
import dayjs from 'dayjs'
import { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { apiV1 } from 'services'
import { IEvento } from 'types/evento'
import { getErrorMessage } from 'utils/AppError'
import { EventReviewButton } from './components/EventReviewButton'

export function NewEvents() {
  const [isLoading, setIsLoading] = useState(false)
  const [events, setEvents] = useState<IEvento[]>([])

  const { addErrorMessage } = useAdmin()

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true)
      const { data } = await apiV1.admin.eventService.getNews()
      setEvents(data.events)
    } catch (error) {
      addErrorMessage(getErrorMessage(error))
    } finally {
      setIsLoading(false)
    }
  }, [addErrorMessage])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  if (isLoading) return <PageLoading />

  const columns: GridColDef<IEvento>[] = [
    {
      field: 'id',
      headerName: 'ID',
      width: 100,
      renderCell: (cell) => (
        <Link to={`/admin/events/${cell.value}`} target="_blank">
          #{cell.value}
        </Link>
      ),
    },
    {
      field: 'titulo',
      headerName: 'Evento',
      flex: 1,
    },
    {
      field: 'data',
      headerName: 'Data evento',
      width: 150,
      renderCell: (cell) => dayjs(cell.value).format('DD/MM/YYYY HH:mm'),
    },
    {
      field: 'promoter',
      headerName: 'Produtora',
      flex: 1,
      renderCell: (cell) => cell.row.promoter.razao_social,
    },
    {
      field: 'action',
      headerName: 'Ações',
      type: 'number',
      width: 240,
      renderCell: (cell) => <EventReviewButton eventId={cell.row.id} />,
    },
  ]

  return (
    <Grid container spacing={2} component={Paper} p={2}>
      <Grid item xs={12}>
        <Typography variant="h5">
          Revisão dos últimos eventos cadastrados para serem exibidos na home
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <DataGrid rows={events} columns={columns} autoHeight />
      </Grid>
    </Grid>
  )
}

function PageLoading() {
  return (
    <Grid item xs={12}>
      <Skeleton height={20} />
      <Skeleton />
      <Skeleton />
      <Skeleton />
      <Skeleton />
    </Grid>
  )
}
