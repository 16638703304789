import { IBanner } from 'types/banner'
import { IEvento } from 'types/evento'
import { IPromoter } from 'types/promoter'
import { IUsuarioPermissaoEvento } from 'types/usuario_permissao_evento'
import clientRest from './client'
import clientPromoter from './clientPromoter'
import clientProducer from './producer/clientProducer'

const getAll = (all = false) => {
  return clientPromoter.get<Array<IEvento>>(`promoter/events?all=${all}`)
}

const getOldEvents = () => clientRest.get<Array<IEvento>>('events/old-events')

const addUserPermissions = (
  id: number,
  email: string,
  permissions: number[],
) => {
  return clientPromoter.post<IUsuarioPermissaoEvento[]>(
    `promoter/events/${id}/user-permission-event`,
    {
      email,
      permissions,
    },
  )
}

const changeUserPermissions = (
  eventId: number,
  userStaffId: number,
  permissions: number[],
) => {
  return clientPromoter.put<IUsuarioPermissaoEvento[]>(
    `promoter/events/${eventId}/staff/${userStaffId}/permissions`,
    {
      permissions,
    },
  )
}

const changeUserPermission = (
  eventId: number,
  userStaffId: number,
  permissionId: number,
  status: boolean,
) => {
  return clientPromoter.post(
    `promoter/events/${eventId}/staff/${userStaffId}/permissions/${permissionId}`,
    {
      status,
    },
  )
}

const saveAllowedAmount = (
  eventId: number,
  userStaffId: number,
  allowedAmount: string,
) => {
  return clientPromoter.post(
    `promoter/events/${eventId}/staff/${userStaffId}/allowed-amount`,
    {
      allowedAmount,
    },
  )
}

const getStaffDetails = (eventId: number, userStaffId: number) => {
  return clientPromoter.get(`promoter/events/${eventId}/staff/${userStaffId}`)
}

const getCounter = (eventId: number) => {
  return clientPromoter.get<number>(`promoter/events/${eventId}/counter`)
}

const getAllForSite = () => {
  return clientRest.get<Array<IEvento>>('site/events')
}

type getProducerEventsProps = {
  producer: IPromoter
  events: IEvento[]
  pastEvents: IEvento[]
}
const getProducerEvents = (producer: string) => {
  return clientRest.get<getProducerEventsProps>(
    `site/producer/${producer}/events`,
  )
}

const getForSite = (eventId: number, queryParams: string) => {
  return clientRest.get<IEvento>(`site/events/${eventId}${queryParams}`)
}

const getBannersForSite = () => {
  return clientRest.get<Array<IBanner>>('site/events/banners')
}

const downloadQRCode = (id: number) => {
  return clientProducer.get(`producer/events/${id}/download-qr-code`, {
    responseType: 'blob',
  })
}

const eventService = {
  getAll,
  getStaffDetails,
  addUserPermissions,
  changeUserPermissions,
  changeUserPermission,
  getOldEvents,
  getAllForSite,
  getForSite,
  getBannersForSite,
  getCounter,
  getProducerEvents,
  saveAllowedAmount,
  downloadQRCode,
}

export default eventService
