import {
  Autocomplete,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import { FormItem } from 'components'
import { useFormikContext } from 'formik'
import React from 'react'
import { apiV1 } from 'services'
import { IBrasilCidade } from 'types/BrasilCidade'
import { functionWithLoading } from 'utils'
import { SignupFields } from './validationSchema'

const Step3: React.FC = () => {
  const [selectedUF, setSelectedUF] = React.useState<string>('SP')
  const [ufs, setUfs] = React.useState<IBrasilCidade[]>([])
  const [cities, setCities] = React.useState<IBrasilCidade[]>([])
  const [loadingCity, setLoadingCity] = React.useState(true)
  const formik = useFormikContext<SignupFields>()

  React.useEffect(() => {
    apiV1.cityService
      .getUFs()
      .then((resUF) => {
        setUfs(resUF.data)
        functionWithLoading(
          apiV1.cityService.getCities(selectedUF).then((res) => {
            setCities(res.data)
          }),
          setLoadingCity,
        )
      })
      .catch((error) => console.log(error))
  }, [selectedUF])

  const handleChangeUF = React.useCallback((event) => {
    if (event) {
      setSelectedUF(event.target.value)
      functionWithLoading(
        apiV1.cityService.getCities(event.target.value).then((res) => {
          setCities(res.data)
        }),
        setLoadingCity,
      )
    }
  }, [])

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <FormItem.TextField
          label="Nome completo"
          name="nome"
          value={formik.values.nome}
          onChange={formik.handleChange}
          error={formik.touched.nome && Boolean(formik.errors.nome)}
          helperText={formik.touched.nome && formik.errors.nome}
          autoComplete="on"
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormItem.TextField
          label="Data de nascimento"
          name="data_nascimento"
          value={formik.values.data_nascimento}
          error={
            formik.touched.data_nascimento &&
            Boolean(formik.errors.data_nascimento)
          }
          helperText={
            formik.touched.data_nascimento && formik.errors.data_nascimento
          }
          onChange={formik.handleChange}
          type="date"
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormControl fullWidth>
          <InputLabel id="sexo-select-label">Sexo</InputLabel>
          <Select
            labelId="sexo-select-label"
            id="sexo-select"
            label="Sexo"
            name="sexo"
            value={formik.values.sexo}
            error={formik.touched.sexo && Boolean(formik.errors.sexo)}
            onChange={formik.handleChange}
            defaultValue="0"
          >
            <MenuItem value={'Masculino'}>Masculino</MenuItem>
            <MenuItem value={'Feminino'}>Feminino</MenuItem>
            <MenuItem value={'Prefiro não informar'}>
              Prefiro não informar
            </MenuItem>
            <MenuItem value={'0'}>Selecione</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={2}>
        <FormControl fullWidth>
          <InputLabel id="uf-select-label">UF</InputLabel>
          <Select
            labelId="uf-select-label"
            id="uf-select"
            label="UF"
            value={selectedUF}
            onChange={handleChangeUF}
          >
            {ufs?.map((city) => (
              <MenuItem key={'uf_' + city.uf} value={city.uf}>
                {city.uf}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={10}>
        <Autocomplete
          disablePortal
          id="cod_brasil_cidade-select"
          options={cities}
          getOptionLabel={(option) => option.cidade}
          onChange={(e, value) =>
            formik.setFieldValue(
              'cod_brasil_cidade',
              value?.codigo_cidade || '',
            )
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Cidade"
              name="cod_brasil_cidade"
              value={formik.values.cod_brasil_cidade}
              error={
                formik.touched.cod_brasil_cidade &&
                Boolean(formik.errors.cod_brasil_cidade)
              }
            />
          )}
          loading={loadingCity}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormItem.TextField
          variant="outlined"
          label="Senha"
          name="senha"
          value={formik.values.senha}
          error={formik.touched.senha && Boolean(formik.errors.senha)}
          helperText={formik.touched.senha && formik.errors.senha}
          onChange={formik.handleChange}
          type="password"
          autoComplete="on"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormItem.TextField
          label="Confirmar senha"
          name="confirmarSenha"
          value={formik.values.confirmarSenha}
          error={
            formik.touched.confirmarSenha &&
            Boolean(formik.errors.confirmarSenha)
          }
          helperText={
            formik.touched.confirmarSenha && formik.errors.confirmarSenha
          }
          onChange={formik.handleChange}
          type="password"
          autoComplete="on"
        />
      </Grid>
    </React.Fragment>
  )
}

export default Step3
