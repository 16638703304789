import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Link,
  List,
  Paper,
  Typography,
} from '@mui/material'
import CountdownTimer from 'components/CountdownTimer'
import dayjs from 'dayjs'
import moment from 'moment'
import React, { useCallback, useMemo, useState } from 'react'
import { Navigate, Link as RouterLink, useNavigate } from 'react-router-dom'
import { IEvento } from 'types/evento'
import { IIngresso } from 'types/ingresso'
import { moneyFormatter } from 'utils/formatter'
import { localDate } from 'utils/functions'
import ShoppingCartItem from './ShoppingCartItem'

type ShoppingCartProps = {
  event: IEvento
  refreshEvent: () => void
}

const ShoppingCart = ({ event, refreshEvent }: ShoppingCartProps) => {
  const [bag, setBag] = useState<IIngresso[]>([])
  const navigate = useNavigate()
  const [useTerm, setUseTerm] = React.useState(true)

  const addBagItem = useCallback(
    (ticket: IIngresso) => {
      const item = bag.find((x) => x.id === ticket.id)
      if (item) {
        let amountLimit = 10
        if (item.quantidade_disponivel && item.quantidade_disponivel < 10) {
          amountLimit = item.quantidade_disponivel
        }
        if ((item.amount ?? 0) < amountLimit) {
          setBag(
            bag.map((x) => {
              if (x.id === ticket.id)
                return { ...x, amount: (x.amount ?? 0) + 1 }
              return x
            }),
          )
        }
      } else {
        setBag([...bag, { ...ticket, amount: 1 }])
      }
    },
    [bag],
  )

  const removeBagItem = useCallback(
    (ticket: IIngresso) => {
      const item = bag.find((x) => x.id === ticket.id)
      if (item) {
        if (item.amount && item.amount > 1) {
          setBag(
            bag.map((x) => {
              if (x.id === ticket.id)
                return { ...x, amount: (x.amount ?? 0) - 1 }
              return x
            }),
          )
        } else {
          setBag(bag.filter((x) => x.id !== ticket.id))
        }
      }
    },
    [bag],
  )

  const bagTotal = useMemo(() => {
    let totalValue = 0
    bag.map((x) => {
      if (Number(x.valor) > 0) {
        const serviceFee =
          Number(x.taxa_fixa) +
          (Number(x.taxa_percentual) * Number(x.valor)) / 100
        const valueWithFee = Number(x.valor) + serviceFee
        const absorvedFee =
          Number(x.taxa_absorvida) > 0
            ? (serviceFee * Number(x.taxa_absorvida)) / 100
            : 0
        totalValue = totalValue + (valueWithFee - absorvedFee) * (x.amount ?? 0)
      }
      return x
    })
    return totalValue
  }, [bag])

  const handleBuy = useCallback(() => {
    navigate(`/evento/${event.id}/${event.id_url}/novo-pedido`, {
      state: {
        bag,
      },
    })
  }, [bag, event.id, event.id_url, navigate])

  const renderContent = useCallback(() => {
    if (new Date(event.data_entrar) >= localDate()) {
      return (
        <>
          <Typography variant="h6" textAlign="center">
            As vendas começam em
          </Typography>
          <CountdownTimer
            targetDate={moment(event.data_entrar)
              .add(3, 'hours')
              .toDate()
              .getTime()}
            callback={refreshEvent}
          />
        </>
      )
    }

    if (event.venda_suspensa) {
      return (
        <Typography variant="h6" textAlign="center">
          As vendas estão suspensas!
        </Typography>
      )
    }

    if (new Date(event.data_encerrar_vendas) <= localDate()) {
      return (
        <Typography variant="h6" textAlign="center">
          Vendas on-line encerradas!
        </Typography>
      )
    }

    if (
      event.setor.reduce(
        (quantityTickets, sector) => quantityTickets + sector.ingresso.length,
        0,
      ) === 0
    ) {
      return (
        <Typography variant="h6" textAlign="center">
          Nenhum ingresso disponível
        </Typography>
      )
    }

    return event.setor.map((sector) => (
      <Box key={sector.id}>
        <Typography>{sector.descricao}</Typography>
        {sector.ingresso.map((ticket) => (
          <ShoppingCartItem
            key={ticket.id}
            batch={ticket}
            add={addBagItem}
            remove={removeBagItem}
            bag={bag}
          />
        ))}
      </Box>
    ))
  }, [
    addBagItem,
    bag,
    event.data_encerrar_vendas,
    event.data_entrar,
    event.setor,
    event.venda_suspensa,
    refreshEvent,
    removeBagItem,
  ])

  if (!event.visivel) {
    return <Navigate to="/" />
  }

  const hoursForTheEventToStart = dayjs(event.data).diff(dayjs(), 'hours')

  return (
    <Box>
      <Box component={Paper} sx={{ p: 2 }}>
        <Typography variant="h6">Ingressos</Typography>
        <List sx={{ bgcolor: 'background.paper' }}>{renderContent()}</List>
        {bag.length > 0 && hoursForTheEventToStart < 48 && (
          <Alert severity="warning" title="Atenção">
            Essa compra não poderá ser cancelada pois restam menos de 48 horas
            para começar o evento. De acordo com nossos{' '}
            <Link component={RouterLink} to={'/termos-de-uso'} target="_blank">
              termos de uso
            </Link>
            .
          </Alert>
        )}

        <FormGroup>
          <FormControlLabel
            control={<Checkbox checked={useTerm} />}
            label={
              <Typography variant="caption">
                Concordo com os{' '}
                <Link
                  component={RouterLink}
                  to={'/termos-de-uso'}
                  target="_blank"
                >
                  termos de uso
                </Link>
                .
              </Typography>
            }
            sx={{ alignItems: 'center' }}
            onChange={() => setUseTerm((s) => !s)}
          />
        </FormGroup>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="h6">
            Total {moneyFormatter.format(bagTotal)}
          </Typography>
          <Button
            variant="contained"
            onClick={handleBuy}
            disabled={!useTerm || !bag.length}
          >
            Comprar
          </Button>
        </Box>
      </Box>
      <Alert
        security="primary"
        severity="info"
        sx={{ mt: 2, whiteSpace: 'pre-wrap' }}
      >
        {event.retirada}
      </Alert>
    </Box>
  )
}

export default ShoppingCart
