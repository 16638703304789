import { zodResolver } from '@hookform/resolvers/zod'
import { Send } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Alert,
  Button,
  Container,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@mui/material'
import { useCallback, useEffect, useRef, useState } from 'react'
import AuthCode, { AuthCodeRef } from 'react-auth-code-input'
import { Controller, useForm } from 'react-hook-form'
import ReactInputMask from 'react-input-mask'
import { useLocation, useNavigate } from 'react-router-dom'
import { apiV1 } from 'services'
import { functionWithLoading } from 'utils'
import { AppError, getErrorMessage } from 'utils/AppError'
import { z } from 'zod'
import './index.css'
import { IUsuario } from 'types/usuario'

const schema = z.object({
  telefone: z
    .string()
    .min(11, 'O número deve conter 11 dígitos')
    .max(15, 'O número deve conter 11 dígitos'),
})

type CheckPhoneNumberType = z.infer<typeof schema>

const CheckPhoneNumberPage = () => {
  const AuthInputRef = useRef<AuthCodeRef>(null)
  const [codeSended, setCodeSended] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [authCode, setAuthCode] = useState<string>('')
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const location = useLocation() as any
  const navigate = useNavigate()
  const phoneNumber = location.state?.phoneNumber
  const [errorMessage, setErrorMessage] = useState('')
  const [timeLeft, setTimeLeft] = useState(0)

  const from = location.state?.from?.pathname ?? '/'
  const fromState = location.state?.from?.state

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    watch,
  } = useForm<CheckPhoneNumberType>({
    resolver: zodResolver(schema),
    defaultValues: { telefone: phoneNumber ?? '' },
  })

  const { telefone } = watch()

  const handleSendCode = useCallback(
    (data: CheckPhoneNumberType) => {
      if (isSubmitting) return
      functionWithLoading(
        apiV1.userService
          .verifyPhoneNumber(data.telefone)
          .then((res) => {
            setCodeSended(res.status === 200)
            setTimeLeft(60)
            setAuthCode('')
            AuthInputRef.current?.focus()
          })
          .catch((err) => {
            setErrorMessage(
              getErrorMessage(
                err,
                'Ocorreu um erro ao enviar o SMS, tente novamente ou entre em contato com o suporte!',
              ),
            )
          }),
        setLoading,
      )
    },
    [isSubmitting],
  )

  const handleVerifyCode = useCallback(() => {
    functionWithLoading(
      apiV1.userService
        .checkPhoneNumberCode(authCode)
        .then(({ data }) => {
          if (data.isValidCode) {
            const storageUser = localStorage.getItem('user')
            if (!storageUser)
              throw new AppError(
                'Algo inesperado ocorreu ao recuperar os dados do usuário',
              )
            const localUser: IUsuario = JSON.parse(storageUser)
            localUser.telefone_confirmado = true
            localStorage.setItem('user', JSON.stringify(localUser))
            navigate(from, { replace: true, state: fromState })
          } else {
            setErrorMessage(data.message)
          }
        })
        .catch(() => {
          setErrorMessage(
            'Ocorreu um erro ao verificar o código, tente novamente ou entre em contato com o suporte!',
          )
        }),
      setLoading,
    )
  }, [authCode, from, fromState, navigate])

  const handleChangeNumber = useCallback(() => {
    setCodeSended(false)
  }, [])

  useEffect(() => {
    if (!timeLeft) return
    const intervalId = setInterval(() => {
      if (codeSended && timeLeft >= 1) {
        setTimeLeft(timeLeft - 1)
      }
    }, 1000)
    return () => clearInterval(intervalId)
  }, [codeSended, timeLeft])

  return (
    <Container
      maxWidth="md"
      component={Paper}
      sx={{
        my: 2,
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography variant="h4" mb={2}>
        Por favor verifique seu número!
      </Typography>
      {errorMessage && (
        <Alert severity="error" onClose={() => setErrorMessage('')}>
          {errorMessage}
        </Alert>
      )}
      {codeSended ? (
        <Grid container maxWidth="sm" spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1" textAlign="center">
              Foi enviado um código para o número {telefone}.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" textAlign="center">
              Informe o código para continuar.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <AuthCode
              ref={AuthInputRef}
              onChange={setAuthCode}
              containerClassName="container"
              inputClassName="input"
            />
          </Grid>
          <Grid
            item
            xs={12}
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <Typography variant="caption">Reenviar código</Typography>
            <LoadingButton
              loading={loading}
              loadingPosition="start"
              startIcon={<Send />}
              variant="outlined"
              size="small"
              disabled={timeLeft !== 0}
              onClick={handleSubmit(handleSendCode)}
            >
              {timeLeft === 0 ? 'Reenviar' : `Reenviar em ${timeLeft} segundos`}
            </LoadingButton>
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="space-between">
            <Button size="small" variant="text" onClick={handleChangeNumber}>
              Alterar número?
            </Button>
            <LoadingButton
              loading={loading}
              loadingPosition="start"
              startIcon={<Send />}
              variant="contained"
              onClick={handleVerifyCode}
              size="small"
            >
              Verificar código
            </LoadingButton>
          </Grid>
        </Grid>
      ) : (
        <Grid container maxWidth="sm" spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1">
              Informe um número de celular válido para receber um código de
              segurança.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="telefone"
              render={({ field }) => (
                <ReactInputMask mask="(99) 99999-9999" {...field} required>
                  <TextField
                    label="Telefone"
                    fullWidth
                    error={!!errors.telefone}
                    helperText={errors.telefone?.message}
                  />
                </ReactInputMask>
              )}
            />
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="flex-end">
            <LoadingButton
              loading={loading}
              loadingPosition="start"
              startIcon={<Send />}
              variant="contained"
              onClick={handleSubmit(handleSendCode)}
            >
              Enviar código
            </LoadingButton>
          </Grid>
        </Grid>
      )}
    </Container>
  )
}

export default CheckPhoneNumberPage
