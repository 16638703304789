import { zodResolver } from '@hookform/resolvers/zod'
import { constants } from 'config'
import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import { Helmet } from 'react-helmet'
import { FormProvider, useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import { apiV1 } from 'services'
import { CreateOrderPaymentInfos } from 'services/api/v1/orderService'
import { ICupomDesconto } from 'types/cupom_desconto'
import { IIngresso } from 'types/ingresso'
import { ILocationProps } from 'types/locationProps'
import { ShoppingCart, ShoppingCartContextType } from 'types/shoppingCart'
import { AppError, getErrorMessage } from 'utils/AppError'
import { getDiscountCouponValue } from 'utils/eventUtils'
import { translatePsCcError } from 'utils/translate-ps-cc-error'
import { z } from 'zod'
import { useUser } from './userContext'

export const shoppingCartSchema = z.object({
  paymentType: z.enum(['creditCard', 'pix', 'boleto']),
  creditCard: z
    .object({
      holder: z.string().trim().min(1, 'O nome do titular deve ser preenchido'),
      taxId: z
        .string()
        .trim()
        .min(1, 'O documento do titular deve ser preenchido'),
      number: z
        .string()
        .trim()
        .min(16, 'O número do cartão deve ser preenchido'),
      expMonth: z
        .string()
        .trim()
        .min(1, 'O mês de vencimento deve ser selecionado'),
      expYear: z
        .string()
        .trim()
        .min(1, 'O ano de vencimento deve ser preenchido'),
      securityCode: z
        .string()
        .trim()
        .min(1, 'O código de segurança do cartão deve ser preenchido'),
    })
    .nullish(),
  installments: z
    .number()
    .min(1, 'Selecione uma parcela')
    .optional()
    .nullable(),
  discountCoupon: z
    .object({
      id: z.coerce.number(),
      codigo: z.string(),
      cod_tipo: z.coerce.number(),
      valor: z.coerce.number(),
    })
    .nullable()
    .optional(),
  additionalFees: z.number().optional(),
  session: z.string().optional(),
  address: z
    .object({
      street: z.string().trim().min(1, 'O logradouro/rua deve ser preenchido'),
      number: z.string().trim().min(1, 'O número deve ser preenchido'),
      complement: z.string().optional(),
      locality: z.string().trim().min(1, 'O bairro deve ser preenchido'),
      postalCode: z.string().trim().min(1, 'O CEP deve ser preenchido'),
      city: z.string().trim().min(1, 'A cidade deve ser preenchida'),
      regionCode: z.string().trim().min(1, 'O UF deve ser preenchido'),
    })
    .nullish(),
})

export type ShoppingCartPaymentInfo = z.infer<typeof shoppingCartSchema>

const ShoppingCartContext = createContext<ShoppingCartContextType | null>(null)

const ShoppingCartProvider: FC<ReactNode> = ({ children }) => {
  const [shoppingCart, setShoppingCart] = useState<ShoppingCart[]>([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [eventId, setEventId] = useState(0)
  const methods = useForm<ShoppingCartPaymentInfo>({
    resolver: zodResolver(shoppingCartSchema),
    defaultValues: {
      installments: 1,
      paymentType: 'pix',
    },
  })
  const location = useLocation() as ILocationProps
  const navigate = useNavigate()

  const { user } = useUser()

  const { discountCoupon } = methods.watch()

  const totalShoppingCartValueWithoutDiscount = useMemo(
    () =>
      shoppingCart.reduce((a, b) => {
        const serviceFee =
          Number(b.taxa_fixa) +
          Number((Number(b.taxa_percentual) * Number(b.valor)) / 100)
        const valueWithFee = Number(b.valor) + serviceFee
        const absorbedFee =
          Number(b.taxa_absorvida) > 0
            ? (serviceFee * Number(b.taxa_absorvida)) / 100
            : 0
        const total = a + (valueWithFee - absorbedFee) * b.amount
        return total
      }, 0),
    [shoppingCart],
  )

  const discountValue = useMemo(() => {
    if (discountCoupon) {
      return (
        getDiscountCouponValue(
          discountCoupon as ICupomDesconto,
          totalShoppingCartValueWithoutDiscount,
        ) * -1
      )
    }
    return 0
  }, [discountCoupon, totalShoppingCartValueWithoutDiscount])

  const totalShoppingCartValue = useMemo(() => {
    const total = totalShoppingCartValueWithoutDiscount + discountValue
    if (total > 0) return total
    return 0
  }, [discountValue, totalShoppingCartValueWithoutDiscount])

  const addShoppingCartItem = useCallback(
    (ticket: IIngresso) => {
      if (eventId !== ticket.cod_evento) {
        setShoppingCart([{ ...ticket, amount: 1 }])
      } else {
        const item = shoppingCart.find((x) => x.id === ticket.id)
        if (item) {
          let amountLimit = 10
          if (item.quantidade_disponivel && item.quantidade_disponivel < 10) {
            amountLimit = item.quantidade_disponivel
          }
          if ((item.amount ?? 0) < amountLimit) {
            setShoppingCart(
              shoppingCart.map((x) => {
                if (x.id === ticket.id)
                  return { ...x, amount: (x.amount ?? 0) + 1 }
                return x
              }),
            )
          }
        } else {
          setShoppingCart([...shoppingCart, { ...ticket, amount: 1 }])
        }
      }
      setEventId(ticket.cod_evento)
    },
    [eventId, shoppingCart],
  )

  const removeShoppingCartItem = useCallback(
    (ticket: IIngresso) => {
      if (eventId !== ticket.cod_evento) {
        setShoppingCart([])
      } else {
        const item = shoppingCart.find((x) => x.id === ticket.id)
        if (item) {
          if (item.amount && item.amount > 1) {
            setShoppingCart(
              shoppingCart.map((x) => {
                if (x.id === ticket.id)
                  return { ...x, amount: (x.amount ?? 0) - 1 }
                return x
              }),
            )
          } else {
            setShoppingCart(shoppingCart.filter((x) => x.id !== ticket.id))
          }
        }
      }
      setEventId(ticket.cod_evento)
    },
    [eventId, shoppingCart],
  )

  const placeOrder = useCallback(async () => {
    try {
      setLoading(true)

      const validation = shoppingCartSchema.safeParse(methods.watch())
      if (!validation.success)
        throw new AppError('Verifique os dados de pagamento!')

      const {
        paymentType,
        creditCard,
        installments,
        additionalFees,
        session,
        address,
      } = methods.watch()

      const ref = localStorage.getItem(eventId + '_ref')

      let paymentInfos: CreateOrderPaymentInfos = {
        method: paymentType,
        amount: totalShoppingCartValue + Number(additionalFees ?? 0),
        discountCoupon,
      }

      if (paymentType === 'creditCard') {
        const creditCardSchema = z.object({
          holder: z.string().min(2),
          taxId: z.string().min(11),
          number: z.string().min(16),
          expMonth: z.string().min(1).max(2),
          expYear: z.string().min(4),
          securityCode: z.string(),
        })

        const isValidData = creditCardSchema.safeParse(creditCard)

        if (!creditCard || !address)
          throw new AppError(
            'Erro ao criptografar o cartão de crédito, verifique os dados do cartão!',
          )

        if (!isValidData.success) {
          throw new AppError('Verifique os dados de pagamento!')
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const newWindowObject = window as any
        const PagSeguro = newWindowObject.PagSeguro

        const complement =
          address.complement && address.complement.length > 1
            ? address.complement
            : 'casa'

        const request = {
          data: {
            customer: {
              name: [String(user.nome).trim(), String(user.sobrenome).trim()]
                .join(' ')
                .trim(),
              email: user.email,
              phones: [
                {
                  country: '55',
                  area: user.ddd,
                  number: user.telefone,
                  type: 'MOBILE',
                },
              ],
            },
            paymentMethod: {
              type: 'CREDIT_CARD',
              installments,
              card: {
                number: creditCard.number,
                expMonth: creditCard.expMonth,
                expYear: creditCard.expYear,
                holder: {
                  name: creditCard.holder,
                },
              },
            },
            amount: {
              value: (paymentInfos.amount * 100).toFixed(0),
              currency: 'BRL',
            },
            billingAddress: {
              street: address.street,
              number: address.number,
              complement,
              regionCode: address.regionCode,
              country: 'BRA', // only brazil credit cards
              city: address.city,
              postalCode: Number(address.postalCode.replace(/[^0-9]+/, '')),
            },
            dataOnly: false,
          },
        }

        let authenticationId

        if (session) {
          PagSeguro.setUp({
            session,
            env: 'PROD',
          })

          type PagSeguro3DSResult = {
            status:
              | 'AUTH_FLOW_COMPLETED'
              | 'AUTH_NOT_SUPPORTED'
              | 'CHANGE_PAYMENT_METHOD'
              | 'REQUIRE_CHALLENGE'
            id: string
          }

          const pagSeguro3DSResult: PagSeguro3DSResult =
            await PagSeguro.authenticate3DS(request)

          switch (pagSeguro3DSResult.status) {
            case 'AUTH_FLOW_COMPLETED':
              authenticationId = pagSeguro3DSResult.id
              break
            case 'AUTH_NOT_SUPPORTED':
              throw new AppError(
                'O cartão de crédito não passou nos requisitos de segurança! Tente novamente com outro método de pagamento.',
              )
              break
            case 'CHANGE_PAYMENT_METHOD':
              throw new AppError(
                'O pagamento não foi autorizado! Tente novamente com outro método de pagamento.',
              )
            case 'REQUIRE_CHALLENGE':
              throw new AppError(
                'Erro ao tentar abrir o desafio, entre em contato com o suporte.',
              )
          }
        }

        const card = PagSeguro.encryptCard({
          publicKey: constants.PAGSEGURO_PUBLIC_KEY,
          holder: creditCard.holder,
          number: creditCard.number,
          expMonth: creditCard.expMonth,
          expYear: creditCard.expYear,
          securityCode: creditCard.securityCode,
        })
        if (!card.hasErrors) {
          paymentInfos = {
            ...paymentInfos,

            method: 'creditCard',
            card: {
              encrypted: card.encryptedCard,
              bin: creditCard.number.substring(0, 6),
              '3dsId': authenticationId,
            },

            installments,
          }
        } else {
          throw new AppError(translatePsCcError(card.errors[0].code))
        }
      }

      const { data, status } =
        await apiV1.orderService.createOrderTransparentCheckout({
          eventId,
          items: shoppingCart,
          ref,
          paymentInfos,
        })

      if (status === 201) {
        if (data.order.cod_status === 4) {
          navigate('/processar-pagamento/' + data.order.codigo)
        }
        if (paymentType === 'pix') {
          const qrCode = data.paymentResponse.qr_codes[0]
          navigate('/pagamento-com-pix/' + data.order.codigo, {
            replace: true,
            state: { pix: qrCode },
          })
        } else if (paymentType === 'creditCard') {
          navigate('/processar-pagamento/' + data.order.codigo)
        } else if (paymentType === 'boleto') {
          navigate('/visualizar-boleto/', {
            state: { boletoLink: data.paymentResponse.boletoLink },
          })
        }
      } else {
        navigate('/usuario/completar-cadastro', {
          replace: true,
          state: { from: location },
        })
      }
    } catch (error) {
      setError(
        getErrorMessage(
          error,
          'Ocorreu um erro ao gerar a venda, revise as informações e tente novamente!',
        ),
      )
    } finally {
      setLoading(false)
    }
  }, [
    discountCoupon,
    eventId,
    location,
    methods,
    navigate,
    shoppingCart,
    totalShoppingCartValue,
    user.ddd,
    user.email,
    user.nome,
    user.sobrenome,
    user.telefone,
  ])

  return (
    <ShoppingCartContext.Provider
      value={{
        shoppingCart,
        totalShoppingCartValue,
        discountValue,
        addShoppingCartItem,
        removeShoppingCartItem,
        placeOrder,
        loading,
        error,
        setError,
      }}
    >
      <Helmet>
        <script src="https://assets.pagseguro.com.br/checkout-sdk-js/rc/dist/browser/pagseguro.min.js"></script>
      </Helmet>
      <FormProvider {...methods}>{children}</FormProvider>
    </ShoppingCartContext.Provider>
  )
}

const ERROR_MSG_PROVIDER_NEEDED =
  'useShoppingCart must be used within a ShoppingCartProvider'

export const useShoppingCart = () => {
  const context = useContext(ShoppingCartContext)
  if (!context) {
    throw new Error(ERROR_MSG_PROVIDER_NEEDED)
  }
  return context
}

export default ShoppingCartProvider
