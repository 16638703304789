import { zodResolver } from '@hookform/resolvers/zod'
import CloseIcon from '@mui/icons-material/Close'
import SwapHorizIcon from '@mui/icons-material/SwapHoriz'
import { LoadingButton } from '@mui/lab'
import {
  Alert,
  Button,
  FormHelperText,
  IconButton,
  TextField,
  Tooltip,
} from '@mui/material'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import Typography from '@mui/material/Typography'
import { AxiosError } from 'axios'
import * as React from 'react'
import { useForm } from 'react-hook-form'
import { apiV1 } from 'services'
import { IPedidoItem } from 'types/pedido_item'
import { functionWithLoading } from 'utils'
import { z } from 'zod'

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}

const schema = z
  .object({
    email: z.string({ required_error: 'É necessário preencher o email' }),
    emailConfirm: z.string({
      required_error: 'É necessário preencher a confirmação de e-mail',
    }),
  })
  .refine((data) => data.email === data.emailConfirm, {
    message: 'Os e-mails devem ser iguais',
    path: ['emailConfirm'],
  })

type IFormInputs = z.infer<typeof schema>

type TransferTicketButtonProps = {
  item: IPedidoItem
}

const TransferTicketButton = ({ item }: TransferTicketButtonProps) => {
  const [loading, setLoading] = React.useState(false)
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const [error, setError] = React.useState('')

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInputs>({
    resolver: zodResolver(schema),
  })

  const onSubmit = (data: IFormInputs) =>
    functionWithLoading(
      apiV1.orderItemService
        .transferTicket(item.id, data.email)
        .then(() => {
          handleClose()
          window.location.reload()
        })
        .catch((err: AxiosError) =>
          setError(err.response?.data.message ?? err.message),
        ),
      setLoading,
    )

  return (
    <React.Fragment>
      <Button
        variant="contained"
        onClick={handleOpen}
        startIcon={<SwapHorizIcon />}
        sx={{ width: '100%' }}
      >
        Transferir ingresso
      </Button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography variant="h6" component="h2">
            Transferir ingresso
          </Typography>
          <Typography sx={{ mt: 2 }}>
            Insira o e-mail da pessoa que irá receber o ingresso.
          </Typography>
          {!!error && (
            <Alert severity="error" onClose={() => setError('')}>
              {error}
            </Alert>
          )}
          <TextField
            label="E-mail"
            variant="outlined"
            {...register('email', { required: true })}
            sx={{ mt: 2 }}
            fullWidth
          />
          {errors.email && (
            <FormHelperText error>{errors.email.message}</FormHelperText>
          )}
          <TextField
            label="Confirme o e-mail"
            variant="outlined"
            {...register('emailConfirm', { required: true })}
            sx={{ mt: 2 }}
            fullWidth
          />
          {errors.emailConfirm && (
            <FormHelperText error>{errors.emailConfirm.message}</FormHelperText>
          )}
          <LoadingButton
            loading={loading}
            disabled={loading}
            loadingPosition="start"
            variant="contained"
            onClick={handleSubmit(onSubmit)}
            startIcon={<SwapHorizIcon />}
            sx={{ mt: 2 }}
            fullWidth
          >
            Transferir ingresso
          </LoadingButton>
          <Tooltip
            title="Fechar"
            sx={{ position: 'absolute', top: 0, right: 0 }}
          >
            <IconButton onClick={handleClose} aria-label="Fechar">
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Modal>
    </React.Fragment>
  )
}

export default TransferTicketButton
