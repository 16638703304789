import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import MenuIcon from '@mui/icons-material/Menu'
import {
  Button,
  Container,
  IconButton,
  Link,
  Menu,
  MenuItem,
} from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import Fab from '@mui/material/Fab'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Zoom from '@mui/material/Zoom'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import useScrollTrigger from '@mui/material/useScrollTrigger'
import AccountMenu from 'components/AccountMenu'
import { useUser } from 'contexts/userContext'
import * as React from 'react'
import { Helmet } from 'react-helmet'
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom'
import Footer from './Footer'

const theme = createTheme({
  palette: {
    background: {
      default: '#eeeeee',
    },
    primary: {
      main: '#00457a',
    },
  },
  shape: {
    borderRadius: 4,
  },
})

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window
  children: React.ReactElement
}

function ScrollTop(props: Props) {
  const { children, window } = props
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  })
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const anchor = (
      (event.target as HTMLDivElement).ownerDocument || document
    ).querySelector('#back-to-top-anchor')

    if (anchor) {
      anchor.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
    }
  }

  return (
    <Zoom in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
      >
        {children}
      </Box>
    </Zoom>
  )
}

const pages = [
  {
    title: 'Cadastre seu evento',
    href: '/cadastre-sua-produtora',
  },
  { title: 'Eventos', href: '/', target: '' },
  {
    title: 'Dúvidas',
    href: '/duvidas',
  },
  {
    title: 'Meus ingressos',
    href: '/usuario/pedidos',
  },
  {
    title: 'Contato/Suporte',
    href: 'https://api.whatsapp.com/send?phone=5517996147824&text=Ol%C3%A1,%20vim%20atrav%C3%A9s%20do%20site%20(contato)',
    target: '_blank',
  },
]

// const settings = [
//   // { title: "Minhas informações", href: "/usuario" },
//   { title: 'Meus ingressos', href: '/usuario/pedidos' },
// ]

const UserLayout = (props: Props) => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null)
  // const [, setAnchorElUser] = React.useState<null | HTMLElement>(null)

  const { user } = useUser()
  const location = useLocation()
  const navigate = useNavigate()

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget)
  }
  // const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
  //   setAnchorElUser(event.currentTarget)
  // }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  // const handleCloseUserMenu = () => {
  //   setAnchorElUser(null)
  // }

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        {/* <script src="//code.jivosite.com/widget/zF66XpNqh8" async></script> */}
      </Helmet>
      <CssBaseline />

      <AppBar
        position={location.pathname === '/' ? 'absolute' : 'fixed'}
        style={{
          background:
            location.pathname === '/'
              ? 'transparent'
              : theme.palette.primary.main,
          boxShadow: 'none',
          zIndex: 999999,
        }}
      >
        <Container maxWidth="lg">
          <Toolbar disableGutters>
            <Typography
              variant="h6"
              noWrap
              component={RouterLink}
              to="/"
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              <img
                src="https://images.zedoingresso.com.br/zedoingresso/imgs/header-logo-white.png"
                alt="Zé do Ingresso"
                height={50}
              />
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {pages.map((page) => (
                  <MenuItem key={page.title} onClick={handleCloseNavMenu}>
                    <Link
                      {...(page.target
                        ? { target: page.target, href: page.href }
                        : {
                            component: RouterLink,
                            to: page.href,
                          })}
                      variant="button"
                      textAlign="center"
                      sx={{ my: 2, mx: 1, display: 'block' }}
                      underline="hover"
                    >
                      {page.title}
                    </Link>
                    {/* <Link
                      component={RouterLink}
                      to={page.href}
                      target={page.target ?? "_parent"}
                      variant="button"
                      textAlign="center"
                    >
                    {page.title}
                    </Link> */}
                  </MenuItem>
                ))}
              </Menu>
            </Box>

            <Typography
              variant="h5"
              noWrap
              component={RouterLink}
              to="/"
              sx={{
                mr: 2,
                display: { xs: 'flex', md: 'none' },
                flexGrow: 1,
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              <img
                src="https://images.zedoingresso.com.br/zedoingresso/imgs/header-logo-white.png"
                alt="Zé do Ingresso"
                height={50}
              />
            </Typography>
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: 'none', md: 'flex' },
                justifyContent: 'flex-end',
              }}
            >
              {pages.map((page) => (
                <Link
                  {...(page.target
                    ? { target: page.target, href: page.href }
                    : {
                        component: RouterLink,
                        to: page.href,
                      })}
                  variant="button"
                  textAlign="center"
                  sx={{ my: 2, mx: 1, color: 'white', display: 'block' }}
                  underline="hover"
                  key={page.title}
                >
                  {page.title}
                </Link>
              ))}
            </Box>

            <Box sx={{ flexGrow: 0, ml: 2 }}>
              {user.id ? (
                <AccountMenu />
              ) : (
                <Button
                  variant="outlined"
                  sx={{ color: '#FFF', borderColor: '#FFF' }}
                  onClick={() => navigate('/login')}
                >
                  Entrar
                </Button>
              )}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          paddingTop: 8,
        }}
      >
        <div id="back-to-top-anchor" />
        <main>
          <Box
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === 'light'
                  ? theme.palette.grey[200]
                  : theme.palette.grey[800],
            }}
          >
            {props.children}
          </Box>
        </main>

        <Footer />
      </Box>

      <ScrollTop {...props}>
        <Fab color="primary" size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </ThemeProvider>
  )
}

export default UserLayout
