import { Box, Button } from '@mui/material'
import { Loading } from 'components'
import { useAdmin } from 'contexts/adminContext'
import { useCallback, useState } from 'react'
import clientAdmin from 'services/api/v1/admin/client'
import { getErrorMessage } from 'utils/AppError'

interface EventReviewButtonProps {
  eventId: number
}

export function EventMarkAsPayButton({ eventId }: EventReviewButtonProps) {
  const [isLoading, setIsLoading] = useState(false)
  const [showButton, setShowButton] = useState(true)

  const { addErrorMessage } = useAdmin()

  const handleMarkAsPayEvent = useCallback(
    async (eventId: number) => {
      try {
        setIsLoading(true)
        await clientAdmin.post(`events/${eventId}/mark-as-pay`)
        setShowButton(false)
      } catch (error) {
        addErrorMessage(getErrorMessage(error))
      } finally {
        setIsLoading(false)
      }
    },
    [addErrorMessage],
  )

  if (isLoading) return <Loading />

  if (!showButton) return <></>

  return (
    <Box>
      <Button
        variant="contained"
        color="success"
        onClick={() => handleMarkAsPayEvent(eventId)}
      >
        Acertado
      </Button>
    </Box>
  )
}
