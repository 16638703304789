import { Box } from '@mui/material'
import { CalendarX } from '@phosphor-icons/react'
import { Typography } from 'components/Typography'
import { Card } from 'pages/admin/components/card'
import { Link } from 'react-router-dom'
import { IEvento } from 'types/evento'

type Props = {
  data?: IEvento[]
}

export function EventsWithoutActiveBatches({ data = [] }: Props) {
  return (
    <Card
      title="Eventos sem lotes ativos"
      color="#f11111"
      icon={<CalendarX size={48} />}
    >
      <Box flex={1}>
        <Typography variant="h4" color="inherit" flex={1} textAlign="right">
          {data.length}
        </Typography>

        {data.map((event: IEvento) => (
          <Typography key={event.id} color="inherit">
            <Link to={`/admin/events/${event.id}`} target="_blank">
              #{event.id} - {event.titulo}
            </Link>
          </Typography>
        ))}
      </Box>
    </Card>
  )
}
