import {
  Alert,
  Checkbox,
  CircularProgress,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from '@mui/material'
import { memo, useState } from 'react'
import { apiV1 } from 'services'
import { functionWithLoading } from 'utils'
import { getPermissionIcon } from 'utils/icons'

type PermissionListItemProps = {
  id: number
  title: string
  value: boolean
  onChange: (permissionId: number, newValue: boolean) => void
  eventId: number
  userId: number
}

const PermissionListItem = ({
  id,
  title,
  value,
  onChange,
  eventId,
  userId,
}: PermissionListItemProps) => {
  const labelId = `checkbox-list-secondary-label-${id}`

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const handleChange = () => {
    functionWithLoading(
      apiV1.eventService
        .changeUserPermission(eventId, userId, id, !value)
        .then(() => onChange(id, !value))
        .catch((err) => setError(err.response?.data.message ?? err.message)),
      setLoading,
    )
  }

  return (
    <>
      {error && (
        <Alert severity="error" onClose={() => setError('')}>
          {error}
        </Alert>
      )}
      <ListItem
        key={id}
        secondaryAction={
          loading ? (
            <CircularProgress color="inherit" />
          ) : (
            <Checkbox
              edge="end"
              onChange={handleChange}
              checked={value}
              inputProps={{ 'aria-labelledby': labelId }}
            />
          )
        }
        disablePadding
      >
        <ListItemButton>
          <ListItemAvatar>{getPermissionIcon(id)}</ListItemAvatar>
          <ListItemText id={labelId} primary={title} />
        </ListItemButton>
      </ListItem>
    </>
  )
}

export default memo(PermissionListItem)
