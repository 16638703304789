import { Container } from '@mui/material'
import React, { useCallback } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { triggerPurchaseEvents } from 'utils/eventUtils'
import MercadoPagoFailure from './MercadoPagoFailure'
import MercadoPagoPending from './MercadoPagoPending'
import MercadoPagoSuccess from './MercadoPagoSuccess'
import { PagSeguro } from './pagSeguro/PagSeguro'

const PaymentGatewayReturn: React.FC = () => {
  const { paymentGateway, status } = useParams()
  const [searchParams] = useSearchParams()
  const externalReference = searchParams.get('external_reference')

  const renderPaymentGatewayTemplate = useCallback(() => {
    if (paymentGateway === 'mercadopago') {
      switch (status) {
        case 'success': {
          if (externalReference) {
            triggerPurchaseEvents(externalReference)
          }
          return <MercadoPagoSuccess />
        }
        case 'failure':
        case 'reject':
          return <MercadoPagoFailure />
        case 'pending':
          return <MercadoPagoPending />

        default:
          break
      }
    } else if (paymentGateway === 'pagseguro') {
      return <PagSeguro orderId={status ?? ''} />
    }
  }, [externalReference, paymentGateway, status])

  return <Container maxWidth="md">{renderPaymentGatewayTemplate()}</Container>
}

export default PaymentGatewayReturn
