import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded'
import RemoveCircleOutlineRoundedIcon from '@mui/icons-material/RemoveCircleOutlineRounded'
import {
  Box,
  Chip,
  Divider,
  IconButton,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material'
import React, { memo } from 'react'
import { IIngresso } from 'types/ingresso'
import { moneyFormatter } from 'utils/formatter'

type Props = {
  batch: IIngresso
  add: (batch: IIngresso) => void
  remove: (batch: IIngresso) => void
  bag: IIngresso[]
}

const ShoppingCartItem = ({ batch, add, remove, bag }: Props) => {
  let fee = 0

  if (batch.valor > 0) {
    fee =
      (Number(batch.taxa_percentual) * Number(batch.valor)) / 100 +
      parseFloat(batch.taxa_fixa.toString())
    if (Number(batch.taxa_absorvida) > 0) {
      fee = fee - fee * (Number(batch.taxa_absorvida) / 100)
    }
  }

  const soldOut =
    batch.esgotado &&
    Boolean(batch.valida_quantidade) &&
    Number(batch.quantidade_disponivel) <= 0

  return (
    <React.Fragment>
      <ListItem
        sx={{ pl: 0, paddingRight: 12 }}
        alignItems="flex-start"
        secondaryAction={
          soldOut ? (
            <Chip label="esgotado" color="error" />
          ) : (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                flex: 1,
              }}
            >
              <IconButton
                edge="start"
                aria-label="remover"
                onClick={() => remove(batch)}
                disabled={
                  (bag.find((x) => x.id === batch.id)?.amount ?? 0) === 0
                }
              >
                <RemoveCircleOutlineRoundedIcon />
              </IconButton>
              <Typography variant="button">
                {bag.find((x) => x.id === batch.id)?.amount ?? 0}
              </Typography>
              <IconButton
                edge="end"
                aria-label="adicionar"
                onClick={() => add(batch)}
                disabled={(() => {
                  const bagItem = bag.find((x) => x.id === batch.id)
                  if (bagItem) {
                    const isGte10 = (bagItem.amount ?? 0) >= 10
                    const isGteAvailableAmount =
                      (bagItem.amount ?? 0) >=
                      (bagItem.quantidade_disponivel ?? 0)
                    return bagItem.valida_quantidade
                      ? isGte10 || isGteAvailableAmount
                      : isGte10
                  }
                  return false
                })()}
              >
                <AddCircleOutlineRoundedIcon />
              </IconButton>
            </Box>
          )
        }
      >
        <ListItemText
          primary={batch.titulo}
          secondary={
            <Box display="flex" flexDirection="column">
              {!!batch.descricao && (
                <Typography
                  sx={{ display: 'inline' }}
                  component="span"
                  variant="caption"
                >
                  {batch.descricao}
                </Typography>
              )}
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                {moneyFormatter.format(batch.valor ?? 0)}
                <Typography variant="caption" component="span" px={1}>
                  + {moneyFormatter.format(fee)} (taxa de serviço)
                </Typography>
              </Typography>
            </Box>
          }
          secondaryTypographyProps={{ component: 'span' }}
          sx={{ width: '80%' }}
        />
      </ListItem>
      <Divider variant="inset" component="li" />
    </React.Fragment>
  )
}

export default memo(ShoppingCartItem)
