import { Search } from '@mui/icons-material'
import {
  Box,
  Button,
  Card,
  Grid,
  InputAdornment,
  Skeleton,
  TextField,
} from '@mui/material'
import { DataGrid, GridColDef, GridRowsProp } from '@mui/x-data-grid'
import filter from 'lodash/filter'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { apiV1 } from 'services'
import { IEvento } from 'types/evento'
import { datagrid, functionWithLoading } from 'utils'

export default function EventList() {
  const [events, setEvents] = React.useState<IEvento[]>([])
  const [fullEvents, setFullEvents] = React.useState<IEvento[]>()
  const [loading, setLoading] = React.useState(true)
  const [searchQuery, setSearchQuery] = React.useState('')

  const navigate = useNavigate()

  React.useEffect(() => {
    functionWithLoading(
      apiV1.partner.eventService.getAll().then((res) => {
        setEvents(res.data)
        setFullEvents(res.data)
      }),
      setLoading,
    )
  }, [])

  const loadAllEvents = React.useCallback(() => {
    functionWithLoading(
      apiV1.eventService.getAll(true).then((res) => {
        setEvents(res.data)
        setFullEvents(res.data)
      }),
      setLoading,
    )
  }, [])

  const onChangeSearch = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setSearchQuery(e.target.value),
    [],
  )

  const contains = React.useCallback(
    ({ titulo }: IEvento, queryText: string) => {
      if (titulo.toLocaleLowerCase().includes(queryText)) {
        return true
      }
      return false
    },
    [],
  )

  React.useEffect(() => {
    const formattedQuery = searchQuery.toLowerCase()
    const filteredData = filter(fullEvents, (order) => {
      return contains(order, formattedQuery)
    })
    setEvents(filteredData)
  }, [contains, fullEvents, searchQuery])

  const rows: GridRowsProp =
    events.map((x) => ({
      ...x,
    })) ?? []

  const columns: GridColDef[] = [
    { field: 'titulo', headerName: 'Nome do evento', flex: 1 },
    { field: 'data', headerName: 'Início', flex: 1 },
    { field: 'local_evento', headerName: 'Local', flex: 1 },
    {
      field: 'actions',
      headerName: 'Ações',
      renderCell: (values) => {
        return (
          <Button
            variant="contained"
            onClick={() => navigate(`/parceiro/eventos/${values.id}`)}
          >
            Gerenciar
          </Button>
        )
      },
      width: 120,
    },
  ]

  return (
    <Card sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box
        sx={{
          p: 2,
          display: 'flex',
        }}
      >
        <TextField
          label="Pesquisar"
          variant="outlined"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          onChange={onChangeSearch}
          value={searchQuery}
        />
        <Button variant="outlined" onClick={loadAllEvents} sx={{ ml: 2 }}>
          Ver todos
        </Button>
      </Box>
      <Box sx={{ p: 2, flex: 1 }}>
        {loading ? (
          <Grid container spacing={2}>
            <Grid item sm={12}>
              <Skeleton variant="rectangular" height={400} />
            </Grid>
          </Grid>
        ) : (
          <DataGrid
            rows={rows}
            columns={columns}
            autoHeight
            localeText={datagrid.localizedTextsMap}
          />
        )}
      </Box>
    </Card>
  )
}
