import {
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from '@mui/material'
import { ExpiredToken, FormItem, Loading } from 'components'
import { useFormik } from 'formik'
import { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { apiV1 } from 'services'
import { IBrasilCidade } from 'types/BrasilCidade'
import { ILocationProps } from 'types/locationProps'
import { IUsuario } from 'types/usuario'
import { functionWithLoading, validation } from 'utils'
import * as yup from 'yup'

const validationSchema = yup.object({
  nome: yup
    .string()
    .min(1)
    .required('É necessário preencher o nome')
    .nullable(),
  sobrenome: yup
    .string()
    .min(1)
    .required('É necessário preencher o sobrenome')
    .nullable(),
  cpf: yup
    .string()
    .required('É necessário preencher o CPF')
    .test('cpf', 'CPF Inválido', (value) => validation.isValidCPF(value ?? ''))
    .nullable(),
  ddd: yup
    .string()
    .length(2)
    .required('É necessário preencher o DDD')
    .nullable(),
  telefone: yup
    .string()
    .min(8)
    .max(9)
    .required('É necessário preencher o telefone')
    .nullable(),
  sexo: yup.string().required('Por favor selecione o sexo').nullable(),
  cod_brasil_cidade: yup
    .number()
    .required('É necessário selecionar a cidade')
    .nullable(),
  senha: yup
    .string()
    .min(8, 'A senha precisa ter no mínimo 8 caracteres')
    .required('É necessário preencher a senha')
    .nullable(),
  confirmarSenha: yup
    .string()
    .oneOf([yup.ref('senha'), null], 'As senhas devem ser iguais')
    .nullable(),
})

const FinalizeRegistrationPage = () => {
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token') ?? ''
  const [loading, setLoading] = useState(true)
  const [expired, setExpired] = useState(false)
  const [user, setUser] = useState<IUsuario>({} as IUsuario)
  const [selectedUF, setSelectedUF] = useState<string>()
  const [ufs, setUfs] = useState<IBrasilCidade[]>()
  const [cities, setCities] = useState<IBrasilCidade[]>()
  const [loadingCity, setLoadingCity] = useState(true)
  const location = useLocation() as ILocationProps
  const navigate = useNavigate()

  const from = location.state?.from?.pathname ?? '/'

  useEffect(() => {
    if (token)
      functionWithLoading(
        apiV1.userService
          .getUserByTokenWithToken(token)
          .then((res) => {
            setUser(res.data)
            setSelectedUF(res.data.brasil_cidade?.uf ?? 'SP')
            apiV1.cityService
              .getUFs()
              .then((resUF) => {
                setUfs(resUF.data)
                functionWithLoading(
                  apiV1.cityService
                    .getCities(res.data.brasil_cidade?.uf ?? 'SP')
                    .then((res) => {
                      setCities(res.data)
                    }),
                  setLoadingCity,
                )
              })
              .catch((error) => console.log(error))
          })
          .catch((error) => {
            if (error.response.status === 401) setExpired(true)
          }),
        setLoading,
      )
  }, [token])

  const formik = useFormik({
    initialValues: user,
    enableReinitialize: true,
    validateOnChange: true,
    validationSchema,
    onSubmit: (values) => {
      functionWithLoading(
        apiV1.userService
          .finalizeRegistration(values, token ?? '')
          .then(() => {
            navigate(from, { replace: true })
          })
          .catch((error) => console.log(error)),
        setLoading,
      )
    },
  })

  const handleChangeUF = useCallback((event) => {
    if (event) {
      setSelectedUF(event.target.value)
      functionWithLoading(
        apiV1.cityService.getCities(event.target.value).then((res) => {
          setCities(res.data)
        }),
        setLoadingCity,
      )
    }
  }, [])

  if (expired) return <ExpiredToken />

  if (loading) return <Loading />

  return (
    <Container maxWidth="md">
      <Box sx={{ p: 2, m: 2 }} component={Paper}>
        <Typography variant="h4" mt={2} mb={2}>
          Finalize seu cadastro
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormItem.TextField
                label="Nome"
                name="nome"
                value={formik.values.nome}
                onChange={formik.handleChange}
                error={formik.touched.nome && Boolean(formik.errors.nome)}
                helperText={formik.touched.nome && formik.errors.nome}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormItem.TextField
                label="Sobrenome"
                name="sobrenome"
                value={formik.values.sobrenome}
                onChange={formik.handleChange}
                error={
                  formik.touched.sobrenome && Boolean(formik.errors.sobrenome)
                }
                helperText={formik.touched.sobrenome && formik.errors.sobrenome}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormItem.CPF
                label="CPF"
                name="cpf"
                value={formik.values.cpf}
                error={formik.touched.cpf && Boolean(formik.errors.cpf)}
                helperText={formik.touched.cpf && formik.errors.cpf}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormItem.TextField
                label="Data de nascimento"
                name="data_nascimento"
                value={formik.values.data_nascimento}
                error={
                  formik.touched.data_nascimento &&
                  Boolean(formik.errors.data_nascimento)
                }
                helperText={
                  formik.touched.data_nascimento &&
                  formik.errors.data_nascimento
                }
                onChange={formik.handleChange}
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={4} md={2}>
              <FormItem.TextField
                label="DDD"
                name="ddd"
                value={formik.values.ddd}
                error={formik.touched.ddd && Boolean(formik.errors.ddd)}
                helperText={formik.touched.ddd && formik.errors.ddd}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item xs={8} md={4}>
              <FormItem.TextField
                label="Telefone"
                name="telefone"
                value={formik.values.telefone}
                error={
                  formik.touched.telefone && Boolean(formik.errors.telefone)
                }
                helperText={formik.touched.telefone && formik.errors.telefone}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id="sexo-select-label">Sexo</InputLabel>
                <Select
                  labelId="sexo-select-label"
                  id="sexo-select"
                  label="Sexo"
                  name="sexo"
                  value={formik.values.sexo}
                  error={formik.touched.sexo && Boolean(formik.errors.sexo)}
                  onChange={formik.handleChange}
                  defaultValue="Selecione"
                >
                  <MenuItem value={'Masculino'}>Masculino</MenuItem>
                  <MenuItem value={'Feminino'}>Feminino</MenuItem>{' '}
                  <MenuItem value={'Prefiro não informar'}>
                    Prefiro não informar
                  </MenuItem>
                  <MenuItem value={'Selecione'}>Selecione</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id="uf-select-label">UF</InputLabel>
                <Select
                  labelId="uf-select-label"
                  id="uf-select"
                  label="UF"
                  value={selectedUF}
                  onChange={handleChangeUF}
                >
                  {ufs?.map((city) => (
                    <MenuItem key={'uf_' + city.uf} value={city.uf}>
                      {city.uf}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id="cod_brasil_cidade-select-label">
                  Cidade
                </InputLabel>
                {loadingCity ? (
                  <CircularProgress />
                ) : (
                  <Select
                    labelId="cod_brasil_cidade-select-label"
                    id="cod_brasil_cidade-select"
                    name="cod_brasil_cidade"
                    label="Cidade"
                    value={formik.values.cod_brasil_cidade}
                    error={
                      formik.touched.cod_brasil_cidade &&
                      Boolean(formik.errors.cod_brasil_cidade)
                    }
                    onChange={formik.handleChange}
                  >
                    <MenuItem value={0}>Selecione a cidade</MenuItem>
                    {cities?.map((city) => (
                      <MenuItem
                        key={'city_' + city.codigo_cidade}
                        value={city.codigo_cidade}
                      >
                        {city.cidade}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12}>
              <FormItem.TextField
                variant="outlined"
                label="E-mail"
                value={formik.values.email}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormItem.TextField
                variant="outlined"
                label="Senha"
                name="senha"
                value={formik.values.senha}
                error={formik.touched.senha && Boolean(formik.errors.senha)}
                helperText={formik.touched.senha && formik.errors.senha}
                onChange={formik.handleChange}
                type="password"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormItem.TextField
                label="Confirmar senha"
                name="confirmarSenha"
                value={formik.values.confirmarSenha}
                error={
                  formik.touched.confirmarSenha &&
                  Boolean(formik.errors.confirmarSenha)
                }
                helperText={
                  formik.touched.confirmarSenha && formik.errors.confirmarSenha
                }
                onChange={formik.handleChange}
                type="password"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Button variant="contained" fullWidth type="submit">
                Salvar
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Container>
  )
}

export default FinalizeRegistrationPage
