/* eslint-disable camelcase */
import ReactPixel from 'react-facebook-pixel'
import ReactGA from 'react-ga'
import TagManager from 'react-gtm-module'
import { apiV1 } from 'services'
import TiktokPixel from 'tiktok-pixel'
import { ICupomDesconto } from 'types/cupom_desconto'

export const getOrderStatusColor = (statusId: number) => {
  switch (statusId) {
    case 0:
    case 1:
      return 'rgb(229, 246, 253)'
    case 3:
    case 4:
    case 100:
    case 101:
    case 404:
      return 'rgb(237, 247, 237)'
    case 7:
    case 107:
      return 'rgb(253, 237, 237)'
    case 2:
    case 6:
      return 'rgb(255, 244, 229)'
    default:
      return 'white'
  }
}

export const getOrderItemStatusColor = (statusId: number) => {
  switch (statusId) {
    case 1:
      return 'rgb(237, 247, 237)'
    case 2:
      return 'rgb(253, 237, 237)'
    case 3:
      return 'rgb(229, 246, 253)'
    default:
      return 'black'
  }
}

export const getOrderItemStatusTextColor = (statusId: number) => {
  switch (statusId) {
    case 1:
      return 'green'
    case 2:
      return 'red'
    case 3:
      return 'blue'
    default:
      return 'black'
  }
}

export const triggerPurchaseEvents = (orderId: string) => {
  apiV1.orderService
    .getOrder(orderId)
    .then((res) => {
      const order = res.data
      if (!order) return
      const facebook_pixel = order.evento.facebook_pixel
      const google_analytics = order.evento.google_analytics
      const google_tag_manager = order.evento.google_tag_manager
      const tiktok_pixel = order.evento.tiktok_pixel

      if (facebook_pixel) {
        const options = {
          autoConfig: true,
          debug: true,
        }
        ReactPixel.grantConsent()
        ReactPixel.init(facebook_pixel, undefined, options)
        ReactPixel.trackSingle(facebook_pixel, 'Purchase', {
          value: order.valor_ingressos,
          currency: 'BRL',
        })
      }
      if (google_analytics) {
        ReactGA.initialize(google_analytics)
        ReactGA.pageview(window.location.pathname + window.location.search)
      }
      if (google_tag_manager) {
        TagManager.initialize({
          gtmId: google_tag_manager,
        })
      }
      if (tiktok_pixel) {
        TiktokPixel.init(tiktok_pixel)
        TiktokPixel.track('CompletePayment', {
          content_type: 'product',
          quantity: order.pedido_item?.length,
          description: order.pedido_item?.reduce(
            (text, item) => `${text} - ${item.ingresso.titulo} `,
            '',
          ),
          content_id: order.codigo,
          currency: 'BRL',
          value: order.valor_ingressos,
        })
      }
    })
    .catch(() => console.log('erro ao disparar eventos de compra'))
}

export function getDiscountCouponValue(
  discountCoupon: ICupomDesconto,
  value: number,
) {
  switch (discountCoupon.cod_tipo) {
    case 1: {
      return discountCoupon.valor
    }
    case 2: {
      return (value * discountCoupon.valor) / 100
    }
    default: {
      return 0
    }
  }
}
