interface ErrorMessage {
  code: string
  description: string
  parameterName: string
}

interface PagSeguroErrorDetail {
  httpStatus: number
  traceId: string
  message: string
  errorMessages: ErrorMessage[]
}

export class PagSeguroError {
  name: string
  detail: PagSeguroErrorDetail

  constructor(detail: PagSeguroErrorDetail) {
    this.name = 'PagSeguroError'
    this.detail = detail
  }
}

interface CustomPagSeguroError {
  name: string
  detail: {
    httpStatus: number
    traceId: string
    message: string
    errorMessages: ErrorMessage[]
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isPagSeguroError(obj: any): obj is CustomPagSeguroError {
  return (
    obj &&
    obj.name === 'PagSeguroError' &&
    obj.detail &&
    typeof obj.detail.httpStatus === 'number' &&
    typeof obj.detail.traceId === 'string' &&
    typeof obj.detail.message === 'string' &&
    Array.isArray(obj.detail.errorMessages) &&
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    obj.detail.errorMessages.every((error: any) =>
      ['code', 'description', 'parameterName'].every(
        (key) => typeof error[key] === 'string',
      ),
    )
  )
}
