import { SaqueDTO } from 'dto/SaqueDTO'
import clientProducer from './clientProducer'

const getAll = (eventId: number | string) => {
  return clientProducer.get(`events/${eventId}/withdrawals`)
}

const get = (eventId: number | string, withdrawalId: number | string) => {
  return clientProducer.get(`events/${eventId}/withdrawals/${withdrawalId}`)
}

const create = (eventId: number | string, withdrawal: SaqueDTO) => {
  return clientProducer.post<SaqueDTO>(`events/${eventId}/withdrawals`, {
    withdrawal,
  })
}

const cancel = (eventId: number | string, withdrawalId: number | string) => {
  return clientProducer.delete(`events/${eventId}/withdrawals/${withdrawalId}`)
}

const withdrawalService = { getAll, get, create, cancel }

export default withdrawalService
