import { Container } from '@mui/material'
import React from 'react'

const ExpiredToken: React.FC = () => {
  return (
    <Container>
      <h1>O token expirou! :(</h1>
    </Container>
  )
}

export default ExpiredToken
