import { Box, Stack } from '@mui/material'
import { Warning } from '@phosphor-icons/react'
import { Typography } from 'components/Typography'

type Props = { orderId: string }

export function PagSeguroPaymentPending({ orderId }: Props) {
  return (
    <Stack gap={2}>
      <Box display="flex" justifyContent="center" alignItems="center">
        <Warning size={72} color="#c7ce09" />
      </Box>
      <Typography variant="h5" textAlign="center">
        O seu pagamento do pedido {orderId} ainda está pendente!
      </Typography>
      <Typography variant="h5" textAlign="center">
        Se o pagamento já foi realizado aguarde até ser atualizado na
        plataforma.
      </Typography>
    </Stack>
  )
}
