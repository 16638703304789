import React from 'react'
import { Box, Container, Paper, Tab } from '@mui/material'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import CurrentEvents from './CurrentEvents'
import PastEvents from './PastEvents'
import { Helmet } from 'react-helmet'

const UserOrdersPage: React.FC = () => {
  const [value, setValue] = React.useState('1')

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }

  return (
    <Container>
      <Helmet>
        <title>Meus ingressos - Zé do Ingresso</title>
      </Helmet>
      <Box component={Paper} sx={{ width: '100%', typography: 'body1', my: 2 }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="Listagem de pedidos">
              <Tab label="Eventos ativos" value="1" />
              <Tab label="Eventos que já aconteceram" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <CurrentEvents />{' '}
          </TabPanel>
          <TabPanel value="2">
            <PastEvents />{' '}
          </TabPanel>
        </TabContext>
      </Box>
    </Container>
  )
}

export default UserOrdersPage
