import {
  Alert,
  Box,
  Breadcrumbs,
  Button,
  Card,
  Grid,
  Link,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Tooltip as TooltipMUI,
  Typography,
} from '@mui/material'
import * as React from 'react'
import { Link as RouterLink, useParams } from 'react-router-dom'
import { apiV1 } from 'services'
import { IUsuario } from 'types/usuario'
import { functionWithLoading } from 'utils'
// import {
//   Area,
//   AreaChart,
//   CartesianGrid,
//   Legend,
//   ResponsiveContainer,
//   Tooltip,
//   XAxis,
//   YAxis,
// } from "recharts";
import InfoIcon from '@mui/icons-material/Info'
import { IEvento } from 'types/evento'
import { moneyFormatter, normalizeDateTime } from 'utils/formatter'
import { getDaysArray, localDate } from 'utils/functions'

export default function EventStaffDetails() {
  const { eventId, partnerTeamId } = useParams()
  const [event, setEvent] = React.useState<IEvento>()
  const [partnerTeam, setPartnerTeam] = React.useState<IUsuario>()
  const [loadingData, setLoadingData] = React.useState(true)
  // const [loadingAction, setLoadingAction] = React.useState(false);
  const [error, setError] = React.useState('')
  const [success, setSuccess] = React.useState('')
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [, setDays] = React.useState<any>()

  const partnerTeamStatus =
    partnerTeam
      ?.evento_parceiro_vendedor_evento_parceiro_vendedor_cod_vendedorTousuario[0]
      .ativo

  const load = React.useCallback(() => {
    functionWithLoading(
      apiV1.partner.partnerService
        .getPartnerTeam(Number(eventId), Number(partnerTeamId))
        .then(({ data }) => {
          setEvent(data.event)
          setPartnerTeam(data.partnerTeam)
        })
        .catch((err) => setError(err.response?.data.message ?? err.message)),
      setLoadingData,
    )
  }, [eventId, partnerTeamId])

  React.useEffect(() => {
    load()
  }, [load])

  React.useEffect(() => {
    if (event && partnerTeam) {
      const rangeDates = getDaysArray(
        event.data_entrar,
        event.data_encerrar_vendas > localDate()
          ? localDate()
          : event.data_encerrar_vendas,
      ).map((v) => ({
        dia: [normalizeDateTime(v.toISOString()).format('DD/MM/YYYY')],
        quantidade_ingressos:
          partnerTeam._vendas_por_dia.find(
            (x) =>
              x.dia === normalizeDateTime(v.toISOString()).format('YYYY-MM-DD'),
          )?.quantidade_ingressos ?? 0,
      }))
      setDays(rangeDates)
    }
  }, [event, partnerTeam])

  const handleTogglePartnerTeam = React.useCallback(() => {
    if (partnerTeamStatus) {
      functionWithLoading(
        apiV1.partner.partnerService
          .removePartnerTeam(event?.id ?? 0, partnerTeam?.id ?? 0)
          .then(() => {
            load()
            setSuccess(
              `O usuário ${partnerTeam?.email} foi desativado na equipe!`,
            )
          })
          .catch((err) => setError(err.response?.data.message ?? err.message)),
        setLoadingData,
      )
    } else {
      functionWithLoading(
        apiV1.partner.partnerService
          .addPartnerTeam(event?.id ?? 0, partnerTeam?.email ?? '')
          .then(() => {
            load()
            setSuccess(`O usuário ${partnerTeam?.email} foi ativado na equipe!`)
          })
          .catch((err) => setError(err.response?.data.message ?? err.message)),
        setLoadingData,
      )
    }
  }, [event?.id, load, partnerTeam?.email, partnerTeam?.id, partnerTeamStatus])

  return (
    <Card sx={{ display: 'flex', flexDirection: 'column', p: 2 }}>
      <Box role="presentation">
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            component={RouterLink}
            underline="hover"
            color="inherit"
            to="/parceiro/eventos"
          >
            Eventos
          </Link>
          <Link
            component={RouterLink}
            underline="hover"
            color="inherit"
            to={`/parceiro/eventos/${event?.id}`}
          >
            {event?.titulo}
          </Link>
          <Typography color="text.primary">Equipe</Typography>
          <Typography color="text.primary">Detalhes</Typography>
        </Breadcrumbs>
      </Box>
      {loadingData || !partnerTeam ? (
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item sm={12} md={4}>
            <Skeleton variant="rectangular" height={100} />
          </Grid>
          <Grid item sm={12} md={8}>
            <Skeleton variant="rectangular" height={100} />
          </Grid>
          <Grid item sm={12}>
            <Skeleton variant="rectangular" height={200} />
          </Grid>
          <Grid item sm={12}>
            <Skeleton variant="rectangular" height={300} />
          </Grid>
        </Grid>
      ) : (
        <React.Fragment>
          {error && (
            <Alert severity="error" sx={{ mt: 1 }} onClose={() => setError('')}>
              {error}
            </Alert>
          )}
          {success && (
            <Alert
              severity="success"
              sx={{ mt: 1 }}
              onClose={() => setSuccess('')}
            >
              {success}
            </Alert>
          )}
          <Grid container sx={{ mt: 1, p: 2, pb: 0 }}>
            <Grid item xs={12} sm={8}>
              <Typography variant="h6">
                {[partnerTeam.nome, partnerTeam.sobrenome].join(' ')}
              </Typography>
              <Typography variant="body1">{partnerTeam.email}</Typography>
              <Typography variant="body1">
                {partnerTeam.ddd} {partnerTeam.telefone}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              sx={{ justifyContent: 'end', display: '-webkit-box' }}
            >
              <Button
                variant="contained"
                onClick={handleTogglePartnerTeam}
                color={partnerTeamStatus ? 'error' : 'success'}
              >
                {partnerTeamStatus ? 'Desativar' : 'Ativar'} usuário
              </Button>
            </Grid>
          </Grid>

          <TableContainer sx={{ mb: 2 }}>
            <Typography sx={{ p: 2, pb: 0 }} variant="h6">
              Vendas
            </Typography>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Ingresso</TableCell>
                  <TableCell align="right">Valor</TableCell>
                  <TableCell align="right">Qtd. App</TableCell>
                  <TableCell align="right">
                    Qtd. Link
                    <TooltipMUI title="Vendas por link não estão sendo contabilizadas no total, apenas para informação">
                      <InfoIcon />
                    </TooltipMUI>
                  </TableCell>
                  <TableCell align="right">Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {partnerTeam._vendas.ingressos?.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {row.titulo}
                    </TableCell>
                    <TableCell align="right">
                      {moneyFormatter.format(row.valor ?? 0)}
                    </TableCell>
                    <TableCell align="right">{row.quantidade_app}</TableCell>
                    <TableCell align="right">{row.quantidade_site}</TableCell>
                    <TableCell align="right">
                      {moneyFormatter.format(row.total)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              {partnerTeam._vendas.total.quantidade_app > 0 && (
                <TableFooter>
                  <TableRow>
                    <TableCell>Total</TableCell>
                    <TableCell></TableCell>
                    <TableCell align="right">
                      {partnerTeam._vendas.total.quantidade_app}
                    </TableCell>
                    <TableCell align="right">
                      {partnerTeam._vendas.total.quantidade_site}
                    </TableCell>
                    <TableCell align="right">
                      {moneyFormatter.format(partnerTeam._vendas.total.valor)}
                    </TableCell>
                  </TableRow>
                </TableFooter>
              )}
            </Table>
          </TableContainer>

          {/* <Box sx={{ mb: 2, p: 2, height: 300 }}>
            <Typography variant="h6">
              Vendas durante o período de vendas
            </Typography>

            <ResponsiveContainer width="100%" height="100%">
              <AreaChart height={300} data={days}>
                <defs>
                  <linearGradient id="colorDia" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                  </linearGradient>
                </defs>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="dia" />
                <YAxis dataKey="quantidade_ingressos" />
                <Tooltip />
                <Legend />
                <Area
                  type="monotone"
                  dataKey="quantidade_ingressos"
                  stroke="#8884d8"
                  fillOpacity={1}
                  fill="url(#colorDia)"
                />
              </AreaChart>
            </ResponsiveContainer>
          </Box> */}
        </React.Fragment>
      )}
    </Card>
  )
}
