import { IGenero } from 'types/genero'
import clientAdmin from './client'

const getList = () => {
  return clientAdmin.get<Array<IGenero>>(`genders`)
}

const genderService = {
  getList,
}

export default genderService
