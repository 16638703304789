import { AxiosInstance } from 'axios'
import { useCallback, useState } from 'react'
import clientAdmin from 'services/api/v1/admin/client'
import clientPromoter from 'services/api/v1/clientPromoter'
import clientUser from 'services/api/v1/clientUser'
import { AppError } from 'utils/AppError'

type UseHttpProps<T> = {
  clientType: 'admin' | 'event-producer' | 'customer'
  url: string
  method?: 'get' | 'post'
  payload?: object
  onSuccess?: (data: T) => void
  onError?: (error: unknown) => void
}

type UseHttpReturn<T> = {
  execute: (customPayload?: unknown) => Promise<boolean>
  isLoading: boolean
  data?: T
  error?: unknown
}

export function useHttp<T = unknown>({
  clientType,
  url,
  method = 'get',
  payload,
  onSuccess,
  onError,
}: UseHttpProps<T>): UseHttpReturn<T> {
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState<T>()
  const [error, setError] = useState<unknown>()

  const execute = useCallback(
    async (customPayload: unknown) => {
      try {
        setIsLoading(true)

        let api: AxiosInstance
        switch (clientType) {
          case 'admin':
            api = clientAdmin
            break
          case 'event-producer':
            api = clientPromoter
            break
          case 'customer':
            api = clientUser
            break
          default:
            throw new AppError('cliente http não configurado')
        }

        const response = await api.request({
          url,
          method,
          data: customPayload ?? payload,
        })
        setData(response.data)

        if (onSuccess) onSuccess(response.data as T)

        return true
      } catch (error) {
        setError(error)
        if (onError) onError(error)
        return false
      } finally {
        setIsLoading(false)
      }
    },
    [clientType, method, onError, onSuccess, payload, url],
  )

  return {
    execute,
    isLoading,
    data,
    error,
  }
}
