import { zodResolver } from '@hookform/resolvers/zod'
import {
  Alert,
  Card,
  CardContent,
  CardHeader,
  Snackbar,
  Stack,
  TextField,
} from '@mui/material'
import { Button } from 'components/button'
import { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import clientAdmin from 'services/api/v1/admin/client'
import { getErrorMessage } from 'utils/AppError'
import { z } from 'zod'

const schema = z.object({
  eventId: z.coerce.number().min(1, 'Informe o código do evento a ser clonado'),
  title: z.coerce.string().min(1, 'Informe o título do novo evento'),
})
type SchemaType = z.infer<typeof schema>

export function CloneEvent() {
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    resetField,
  } = useForm<SchemaType>({ resolver: zodResolver(schema) })

  const onSubmit = useCallback(
    async ({ eventId, title }: SchemaType) => {
      try {
        if (isSubmitting) return
        setIsLoading(true)
        const { data } = await clientAdmin.post(`events/clone`, {
          eventId,
          title,
        })
        window.open(`/admin/events/${data.event.id}`, '_blank')
        resetField('eventId')
      } catch (error) {
        setErrorMessage(getErrorMessage(error))
      } finally {
        setIsLoading(false)
      }
    },
    [isSubmitting, resetField],
  )

  return (
    <Card>
      <CardHeader
        title="Clonar evento"
        subheader="Clona o evento, setores, lotes, taxas e equipe"
      />
      <CardContent>
        <Stack spacing={2}>
          <TextField
            placeholder="Código do evento"
            variant="outlined"
            {...register('eventId')}
            error={!!errors.eventId}
            helperText={errors.eventId?.message}
            required
          />
          <TextField
            placeholder="Título do novo evento"
            variant="outlined"
            {...register('title')}
            error={!!errors.title}
            helperText={errors.title?.message}
            required
          />
          <Button
            variant="contained"
            onClick={handleSubmit(onSubmit)}
            isLoading={isLoading}
          >
            Clonar
          </Button>
        </Stack>
      </CardContent>
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={6000}
        onClose={() => setErrorMessage('')}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        <Alert
          onClose={() => setErrorMessage('')}
          severity="error"
          sx={{ width: '100%' }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
    </Card>
  )
}
