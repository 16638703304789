import clientAdmin from './client'

const getList = () => {
  return clientAdmin.get(`promoters`)
}

const promoterService = {
  getList,
}

export default promoterService
