import { Alert, Container, Grid, Paper, Typography } from '@mui/material'
import axios from 'axios'
import { Loading } from 'components'
import { Button } from 'components/button'
import { FC, useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { apiV1 } from 'services'

const CheckEmailPage: FC = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const navigate = useNavigate()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const location = useLocation() as any
  const email = location.state?.email

  const from = location.state?.from?.pathname ?? '/'
  const fromState = location.state?.from?.state

  const handleContinueBuy = useCallback(() => {
    navigate(from, { replace: true, state: fromState })
  }, [from, fromState, navigate])

  const handleSendLink = useCallback(async (emailToSendLink: string) => {
    try {
      setIsLoading(true)
      await apiV1.userService.verifyEmail(emailToSendLink)
    } catch (error) {
      let message =
        'Ocorreu um erro ao enviar o link de confirmação, tente novamente ou entre em contato com o suporte!'
      if (axios.isAxiosError(error))
        message = error.response?.data.message ?? message
      setErrorMessage(message)
      setHasError(true)
    } finally {
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    async function main() {
      await handleSendLink(email)
    }
    main()
  }, [email, handleSendLink])

  if (isLoading) return <Loading />

  return (
    <Container
      maxWidth="sm"
      component={Paper}
      sx={{
        my: 2,
        p: 2,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography variant="h4" mb={2}>
        Por favor verifique seu e-mail!
      </Typography>
      {errorMessage && (
        <Alert severity="error" onClose={() => setErrorMessage('')}>
          {errorMessage}
        </Alert>
      )}
      {!hasError && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1">
              Foi enviado um link de confirmação para o e-mail{' '}
              <strong>{email ?? 'cadastrado na conta'}</strong>.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              Entre nesse link para validar seu e-mail e continuar a compra.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption">
              Caso não localize o e-mail na caixa de entrada, verifique seu lixo
              eletrônico ou spam.
            </Typography>
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="flex-end">
            <Button
              variant="contained"
              onClick={handleContinueBuy}
              size="small"
            >
              Já validei meu e-mail
            </Button>
          </Grid>
        </Grid>
      )}
    </Container>
  )
}

export default CheckEmailPage
