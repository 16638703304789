import { SaveOutlined } from '@mui/icons-material'
import EditIcon from '@mui/icons-material/Edit'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import { LoadingButton } from '@mui/lab'
import {
  Alert,
  AlertTitle,
  Box,
  Card,
  CardContent,
  Grid,
  IconButton,
  Modal,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { GridCloseIcon } from '@mui/x-data-grid'
import { useFormik } from 'formik'
import moment from 'moment'
import QrCodeWithLogo from 'qrcode-with-logos'
import React, { useCallback, useEffect, useState } from 'react'
import { apiV1 } from 'services'
import { IEvento } from 'types/evento'
import { IPedidoItem } from 'types/pedido_item'
import { functionWithLoading } from 'utils'
import { getErrorMessage } from 'utils/AppError'
import { getOrderItemStatusColor } from 'utils/eventUtils'
import { moneyFormatter } from 'utils/formatter'
import * as yup from 'yup'
import TransferTicketButton from './TransferTicketButton'

const validationSchema = yup.object({
  participante: yup
    .string()
    .required('É necessário preencher o participante')
    .nullable(),
  documento: yup
    .string()
    .required('É necessário preencher o documento de identificação')
    .nullable(),
})

type TicketCardProps = {
  item: IPedidoItem
  event: IEvento
}

const TicketCard = ({ item, event }: TicketCardProps) => {
  const [loading, setLoading] = useState(false)
  const [loadingDownloadTicket, setLoadingDownloadTicket] = useState(false)
  const [success, setSuccess] = useState('')
  const [error, setError] = useState('')
  const [open, setOpen] = React.useState(false)

  const handleClick = () => {
    if (item.cod_pedido_item_status === 1) {
      setOpen(!open)
    }
  }

  const formik = useFormik({
    initialValues: item,
    enableReinitialize: true,
    validateOnChange: true,
    validationSchema,
    onSubmit: (values) => {
      functionWithLoading(
        apiV1.orderItemService
          .updateParticipant(values)
          .then(() => {
            setSuccess('Participante atualizado com sucesso')
            handleClick()
          })
          .catch((err) =>
            setError(
              getErrorMessage(
                err,
                'Ocorreu um erro ao atualizar o participante',
              ),
            ),
          ),
        setLoading,
      )
    },
  })

  const handleDownloadTicket = useCallback(() => {
    functionWithLoading(
      apiV1.orderItemService.downloadTicketPDF(item.id).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${event.titulo}_${item.id}.pdf`)
        document.body.appendChild(link)
        link.click()
      }),
      setLoadingDownloadTicket,
    )
  }, [event.titulo, item.id])

  useEffect(() => {
    if (item && [1, 2].includes(item.cod_pedido_item_status)) {
      new QrCodeWithLogo({
        canvas: document.getElementById(
          `canvas_item_${item.id}`,
        ) as HTMLCanvasElement,
        content: item.hash_code,
        width: 250,
        // logo: {
        //   src: icon,
        // },
        // nodeQrCodeOptions: {
        //   margin: 0,
        // },
      })
        .getCanvas()
        .then()
    }
  }, [item])

  return (
    <>
      <Card
        key={item.id}
        variant="outlined"
        sx={{
          backgroundColor: getOrderItemStatusColor(item.cod_pedido_item_status),
          p: 1,
        }}
      >
        <Typography
          gutterBottom
          variant="h6"
          component="div"
          textAlign="center"
        >
          {event.titulo}
        </Typography>
        {item.pedido_item_status.id !== 1 && (
          <>
            <Typography variant="subtitle2" component="div" textAlign="center">
              {item.pedido_item_status.descricao}
            </Typography>
            <Typography variant="caption" component="div" textAlign="center">
              {moment(item.data_status)
                .add(3, 'hours')
                .format('DD/MM/YYYY HH:mm:ss')}
            </Typography>
          </>
        )}

        {item.cod_pedido_item_status === 1 && (
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            sx={{ borderBottomWidth: 1 }}
          >
            <canvas id={`canvas_item_${item.id}`}></canvas>
          </Box>
        )}

        {item.cod_pedido_item_status === 3 && (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            sx={{ borderBottomWidth: 1, my: 2 }}
          >
            <Typography variant="caption" component="div" textAlign="center">
              Transferido para:
            </Typography>
            <Typography variant="subtitle1" component="div" textAlign="center">
              {
                item.pedido_transferido_original[0]
                  ?.pedido_transferido_pedido_novo.usuario.email
              }
            </Typography>
            <Typography
              variant="subtitle1"
              component="div"
              textAlign="center"
              mb={2}
            >
              {item.pedido_transferido_original[0]?.pedido_transferido_pedido_novo.usuario.nome?.toUpperCase()}{' '}
              {item.pedido_transferido_original[0]?.pedido_transferido_pedido_novo.usuario.sobrenome?.toUpperCase()}
            </Typography>

            <Alert severity="warning" icon={false} sx={{ textAlign: 'center' }}>
              <AlertTitle>Pedido transferido</AlertTitle>O ingresso foi
              transferido e pode ser acessado em &quot;Meus ingressos&quot; no
              cadastro de quem recebeu o ingresso.
            </Alert>
          </Box>
        )}

        <CardContent sx={{ mb: 0, pb: 0 }}>
          <Typography
            gutterBottom
            variant="subtitle2"
            component="div"
            textAlign="center"
          >
            #{item.id} - {item.ingresso.titulo}
          </Typography>
          <Typography
            gutterBottom
            variant="subtitle2"
            component="div"
            textAlign="center"
          >
            {moneyFormatter.format(item.valor_ingresso)}
          </Typography>

          {!!success && (
            <Alert severity="success" onClose={() => setSuccess('')}>
              {success}
            </Alert>
          )}
          {item.cod_pedido_item_status !== 3 && (
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              sx={{ backgroundColor: 'white', p: 2 }}
              onClick={handleClick}
            >
              <Box>
                <Typography variant="body1">
                  {formik.values.participante ?? ''}
                </Typography>
                <Typography variant="body2">
                  {formik.values.documento ?? ''}
                </Typography>
              </Box>

              <Box justifyContent="center">
                <EditIcon />{' '}
                <Typography variant="body1" fontSize={8}>
                  Editar
                </Typography>
              </Box>
            </Box>
          )}

          {item.cod_pedido_item_status === 1 && (
            <Grid container spacing={1} mt={1}>
              <Grid item xs={12}>
                <TransferTicketButton item={item} />
              </Grid>
              <Grid item xs={12}>
                <LoadingButton
                  loading={loadingDownloadTicket}
                  disabled={loadingDownloadTicket}
                  loadingPosition="start"
                  startIcon={<FileDownloadIcon />}
                  variant="contained"
                  onClick={handleDownloadTicket}
                  sx={{ width: '100%' }}
                >
                  Baixar ingresso PDF
                </LoadingButton>
              </Grid>
            </Grid>
          )}
        </CardContent>
      </Card>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style}
          component="form"
          onSubmit={formik.handleSubmit}
          display="flex"
          flexDirection="column"
        >
          {!!error && (
            <Alert severity="error" onClose={() => setError('')}>
              {error}
            </Alert>
          )}
          <TextField
            label="Participante"
            variant="outlined"
            sx={{ mt: 1 }}
            name="participante"
            value={formik.values.participante ?? ''}
            onChange={formik.handleChange}
            error={
              formik.touched.participante && Boolean(formik.errors.participante)
            }
            helperText={
              formik.touched.participante && formik.errors.participante
            }
            onBlur={() =>
              formik.setFieldValue(
                'participante',
                formik.values.participante.trim(),
              )
            }
          />
          <TextField
            label="CPF"
            variant="outlined"
            sx={{ mt: 1 }}
            name="documento"
            value={formik.values.documento ?? ''}
            onChange={formik.handleChange}
            error={formik.touched.documento && Boolean(formik.errors.documento)}
            helperText={formik.touched.documento && formik.errors.documento}
            onBlur={() =>
              formik.setFieldValue('documento', formik.values.documento.trim())
            }
          />
          <LoadingButton
            loading={loading}
            disabled={loading}
            loadingPosition="start"
            startIcon={<SaveOutlined />}
            variant="contained"
            type="submit"
            sx={{ mt: 1 }}
            size="small"
          >
            Salvar alterações
          </LoadingButton>
          <Tooltip
            title="Fechar"
            sx={{ position: 'absolute', top: 0, right: 0 }}
          >
            <IconButton onClick={() => setOpen(false)} aria-label="Fechar">
              <GridCloseIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Modal>
    </>
  )
}

export default TicketCard

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}
