import { ICategoria } from 'types/categoria'
import clientAdmin from './client'

const getList = () => {
  return clientAdmin.get<Array<ICategoria>>(`categories`)
}

const categoriesService = {
  getList,
}

export default categoriesService
