import AddIcon from '@mui/icons-material/Add'
import { Box, Container, Grid, Modal, Stack, Typography } from '@mui/material'
import Fab from '@mui/material/Fab'
import Sector from 'components/Sector'
import React, { memo, useCallback, useEffect, useState } from 'react'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { apiV1 } from 'services'
import { ISetor } from 'types/setor'
import { functionWithLoading } from 'utils'
import TicketForm from './TicketForm'

type SectorBatchesProps = {
  eventId: number
  isAdmin?: boolean
}

const SectorBatches = ({
  eventId = 0,
  isAdmin = false,
}: SectorBatchesProps) => {
  const [sectors, setSectors] = useState<ISetor[]>([])
  const [loading, setLoading] = useState(true)
  const [selectedTicket, setSelectedTicket] = useState(0)
  const [open, setOpen] = React.useState(false)

  const showTicketForm = useCallback((ticketId) => {
    setOpen(true)
    setSelectedTicket(ticketId)
  }, [])

  const load = useCallback(() => {
    if (eventId) {
      if (isAdmin) {
        functionWithLoading(
          apiV1.admin.sectorService
            .getAll(eventId)
            .then(({ data }) => setSectors(data)),
          setLoading,
        )
      } else {
        functionWithLoading(
          apiV1.producer.sectorService
            .getAll(eventId)
            .then(({ data }) => setSectors(data)),
          setLoading,
        )
      }
    }
  }, [eventId, isAdmin])

  useEffect(() => {
    load()
  }, [load])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onDragEnd = (e: any) => {
    if (!e.destination) return
    const tempData = Array.from(sectors)
    // eslint-disable-next-line camelcase
    const [source_data] = tempData.splice(e.source.index, 1)
    tempData.splice(e.destination.index, 0, source_data)
    setSectors(tempData)
    if (isAdmin) {
      apiV1.admin.sectorService.updateOrdinations(eventId, tempData).then()
    } else {
      apiV1.producer.sectorService.updateOrdinations(eventId, tempData).then()
    }
  }

  if (loading) return <Typography variant="body2">carregando...</Typography>

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="flex-end" mt={2} mr={2}>
            <Fab
              color="primary"
              aria-label="add"
              variant="extended"
              onClick={() => showTicketForm(0)}
            >
              <AddIcon sx={{ mr: 1 }} />
              Novo lote
            </Fab>
          </Stack>
        </Grid>

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={'event_' + eventId}>
            {(provided) => (
              <Grid item container xs={12}>
                {sectors.map((sector, index) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    key={sector.id}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    <Sector
                      sector={sector}
                      setBatch={showTicketForm}
                      load={load}
                      isAdmin={isAdmin}
                      index={index}
                    />
                  </Grid>
                ))}
              </Grid>
            )}
          </Droppable>
        </DragDropContext>
      </Grid>

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        sx={{ overflow: 'scroll' }}
      >
        <Container
          maxWidth="md"
          sx={{
            bgcolor: 'grey.50',
          }}
        >
          <Box sx={{ margin: 'auto' }}>
            {isAdmin && (
              <TicketForm.Admin
                eventId={eventId}
                ticketId={selectedTicket}
                callback={() => {
                  setOpen(false)
                  load()
                }}
              />
            )}
            {!isAdmin && (
              <TicketForm.Producer
                eventId={eventId}
                ticketId={selectedTicket}
                callback={() => {
                  setOpen(false)
                  load()
                }}
              />
            )}
          </Box>
        </Container>
      </Modal>
    </>
  )
}

export default memo(SectorBatches)
