import { IEvento } from 'types/evento'
import { IPartnerTeamPage } from 'types/partner'
import clientPartner from './clientPartner'

const get = (eventId: number) => {
  return clientPartner.get<IPartnerTeamPage>(`events/${eventId}`)
}

const getAll = () => {
  return clientPartner.get<IEvento[]>(`events/`)
}

const eventService = {
  get,
  getAll,
}

export default eventService
