import { Button, Stack } from '@mui/material'
import { FileCsv } from '@phosphor-icons/react'
import { Loading } from 'components'
import { useState } from 'react'
import { apiV1 } from 'services'
import { functionWithLoading } from 'utils'

type UploadOrdersCSVProps = { eventId: number | string }

export function UploadOrdersCSV({ eventId }: UploadOrdersCSVProps) {
  const [selectedFile, setSelectedFile] = useState<File>()
  const [isLoading, setIsLoading] = useState(false)

  function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedFile(e.target.files[0])
    }
  }

  async function handleButtonSendFileClick() {
    if (selectedFile) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const formData: any = new FormData()
      formData.append('orders', selectedFile)
      functionWithLoading(
        apiV1.admin.uploadService.importOrdersFromCsv(eventId, formData),
        setIsLoading,
      )
    }
  }

  if (isLoading) return <Loading />

  return (
    <Stack direction="row" spacing={2}>
      <Button variant="text" component="label" endIcon={<FileCsv />}>
        Selecionar CSV
        <input
          hidden
          accept=".csv"
          type="file"
          onChange={onSelectFile}
          required
        />
      </Button>
      <Button variant="contained" onClick={handleButtonSendFileClick}>
        Enviar arquivo
      </Button>
    </Stack>
  )
}
