import admin from './admin'
import cityService from './cityService'
import eventService from './eventService'
import orderItemService from './orderItemService'
import orderService from './orderService'
import partner from './partner'
import producer from './producer'
import promoterService from './promoterService'
import user from './user'
import userService from './userService'

const apiV1 = {
  admin,
  cityService,
  eventService,
  orderItemService,
  orderService,
  partner,
  producer,
  promoterService,
  user,
  userService,
}

export default apiV1
