import { ILocal } from 'types/local'
import clientProducer from './clientProducer'

const getList = () => {
  return clientProducer.get<Array<ILocal>>(`locals`)
}

const localService = {
  getList,
}

export default localService
