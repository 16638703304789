import { ICupomDesconto } from 'types/cupom_desconto'
import clientAdmin from './client'

const getAll = (eventId: number) => {
  return clientAdmin.get(`events/${eventId}/discount-coupons`)
}

const create = (eventId: number, discountCoupon: ICupomDesconto) => {
  return clientAdmin.post(`events/${eventId}/discount-coupons`, {
    discountCoupon,
  })
}

const get = (eventId: number, discountCouponId: number) => {
  return clientAdmin.get(
    `events/${eventId}/discount-coupons/${discountCouponId}`,
  )
}

const update = (eventId: number, discountCoupon: ICupomDesconto) => {
  return clientAdmin.patch(
    `events/${eventId}/discount-coupons/${discountCoupon.id}`,
    { discountCoupon },
  )
}

const discountCouponService = {
  getAll,
  create,
  get,
  update,
}

export default discountCouponService
