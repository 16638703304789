import AddIcon from '@mui/icons-material/Add'
import { Box, Container, Modal } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import * as React from 'react'
import SectorForm from './SectorForm'

interface SectorAddButtonProps {
  eventId: number
  callback: () => void
  isAdmin?: boolean
}

const SectorAddButton = ({
  eventId,
  callback,
  isAdmin = false,
}: SectorAddButtonProps) => {
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <React.Fragment>
      <IconButton aria-label="adicionar" onClick={handleClickOpen}>
        <AddIcon />
      </IconButton>
      <Modal open={open} onClose={handleClose} sx={{ overflow: 'scroll' }}>
        <Container
          maxWidth="md"
          sx={{
            bgcolor: 'background.paper',
          }}
        >
          <Box sx={{ margin: 'auto' }}>
            <SectorForm
              eventId={eventId}
              sectorId={0}
              callback={() => {
                setOpen(false)
                callback()
              }}
              isAdmin={isAdmin}
            />
          </Box>
        </Container>
      </Modal>
    </React.Fragment>
  )
}

export default React.memo(SectorAddButton)
