import React from 'react'
import { apiV1 } from 'services'
import { functionWithLoading } from 'utils'
import PaymentsIcon from '@mui/icons-material/Payments'
import { AxiosError } from 'axios'
import { LoadingButton } from '@mui/lab'
import { Alert } from '@mui/material'

type PayWithMercadoPagoButtonProps = {
  orderId: number
}

const PayWithMercadoPagoButton = ({
  orderId,
}: PayWithMercadoPagoButtonProps) => {
  const [loading, setLoading] = React.useState<boolean>(false)
  const [message, setMessage] = React.useState('')

  const payWithMercadoPago = React.useCallback(() => {
    functionWithLoading(
      apiV1.orderService
        .payWithMercadoPago(orderId)
        .then((res) => res.data && window.location.replace(res.data))
        .catch((err: AxiosError) =>
          setMessage(err.response?.data.message ?? err.message),
        ),
      setLoading,
    )
  }, [orderId])

  return (
    <React.Fragment>
      {!!message && (
        <Alert severity="error" sx={{ my: 1 }} onClose={() => setMessage('')}>
          {message}
        </Alert>
      )}
      <LoadingButton
        loadingPosition="start"
        startIcon={<PaymentsIcon />}
        loading={loading}
        disabled={loading}
        onClick={payWithMercadoPago}
        variant="contained"
      >
        Finalizar pagamento
      </LoadingButton>
    </React.Fragment>
  )
}

export default React.memo(PayWithMercadoPagoButton)
