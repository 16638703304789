import { Search } from '@mui/icons-material'
import { Alert, InputAdornment, TextField, Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { Title } from 'components'
import { BreadcrumbItem, Breadcrumbs } from 'components/Breadcrumbs'
import { usePromoter } from 'contexts/promoterContext'
import dayjs from 'dayjs'
import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { apiV1 } from 'services'
import { IPedido } from 'types/pedido'
import { datagrid, functionWithLoading } from 'utils'
import { getOrderStatusColor } from 'utils/eventUtils'
import { moneyFormatter } from 'utils/formatter'

export function TransactionList() {
  const [error, setError] = useState('')
  const [loadingData, setLoadingData] = useState(true)
  const [data, setData] = useState<IPedido[]>([])
  const [searchQuery, setSearchQuery] = useState('')

  const { eventId } = useParams()
  const { selectedEvent } = usePromoter()

  const breadcrumbs: BreadcrumbItem[] = [
    { title: 'Eventos', to: '/organizador/eventos' },
    {
      title: selectedEvent?.titulo ?? '...',
      to: `/organizador/eventos/${eventId}`,
    },
    { title: 'Lista de pedidos' },
  ]

  const fetchData = useCallback(() => {
    functionWithLoading(
      apiV1.producer.eventService
        .getTransactions(String(eventId))
        .then(({ data }) => {
          setData(data)
        })
        .catch((err) => setError(err.response?.data.message ?? err.message)),
      setLoadingData,
    )
  }, [eventId])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const columns: GridColDef<IPedido>[] = [
    {
      field: 'codigo',
      headerName: 'Pedido',
      width: 120,
    },
    {
      field: 'data',
      headerName: 'Data de compra',
      flex: 1,
      renderCell: ({ row }) =>
        dayjs(row.data).add(3, 'hours').format('DD/MM/YY HH:mm'),
    },
    {
      field: 'nome',
      headerName: 'Comprador',
      flex: 2,
      renderCell: (cell) =>
        [cell.row.usuario.nome, cell.row.usuario.sobrenome]
          .join(' ')
          .trim()
          .toUpperCase(),
    },
    {
      field: 'status',
      headerName: 'Situação',
      flex: 1,
      renderCell: (cell) => {
        return (
          <Typography
            bgcolor={getOrderStatusColor(cell.row.cod_status)}
            flex={1}
          >
            {cell.row.status}
          </Typography>
        )
      },
    },
    // {
    //   field: "data_status",
    //   headerName: "Data da situação",
    //   flex: 1,
    //   renderCell: ({ row }) => dayjs(row.data_status).format("DD/MM/YY HH:mm"),
    // },
    // {
    //   field: "metodo_pagamento",
    //   headerName: "Forma de pagamento",
    //   flex: 1,
    //   renderCell: (cell) => cell.row.metodo_pagamento.descricao,
    // },
    {
      field: 'valor_ingressos',
      headerName: 'Valor',
      width: 100,
      type: 'number',
      renderCell: (cell) => moneyFormatter.format(cell.row.valor_ingressos),
    },
  ]

  const filteredData = data.filter((pedido) => {
    const fullName = [pedido.usuario.nome, pedido.usuario.sobrenome]
      .join(' ')
      .trim()
      .toLowerCase()
    return pedido.codigo.includes(searchQuery) || fullName.includes(searchQuery)
  })

  return (
    <Grid container spacing={2} sx={{ p: 2 }} component={Paper}>
      <Grid item xs={12}>
        <Breadcrumbs items={breadcrumbs} />
      </Grid>
      {error && (
        <Grid item xs={12}>
          <Alert severity="error" onClose={() => setError('')}>
            {error}
          </Alert>
        </Grid>
      )}
      <Grid item xs={12}>
        <Title>Lista de pedidos</Title>
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Pesquisar"
          variant="outlined"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          onChange={(e) => setSearchQuery(e.target.value)}
          value={searchQuery}
          size="small"
        />
      </Grid>
      <Grid item xs={12}>
        <DataGrid
          // slots={{
          //   loadingOverlay: LinearProgress,
          // }}
          loading={loadingData}
          columns={columns}
          rows={filteredData}
          autoHeight
          localeText={datagrid.localizedTextsMap}
        />
      </Grid>
    </Grid>
  )
}
