import PixIcon from '@mui/icons-material/Pix'
import { LoadingButton } from '@mui/lab'
import {
  Alert,
  Chip,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Skeleton,
  TextField,
} from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { Typography } from 'components/Typography'
import { SaqueDTO } from 'dto/SaqueDTO'
import { useCallback, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Link, useParams } from 'react-router-dom'
import { apiV1 } from 'services'
import { moneyFormatter } from 'utils/formatter'

import { zodResolver } from '@hookform/resolvers/zod'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import { Info } from '@phosphor-icons/react'
import axios from 'axios'
import { BootstrapTooltip } from 'components/BootstrapTooltip'
import { BreadcrumbItem, Breadcrumbs } from 'components/Breadcrumbs'
import { usePromoter } from 'contexts/promoterContext'
import dayjs from 'dayjs'
import { IEvento } from 'types/evento'
import { getErrorMessage } from 'utils/AppError'
import { z } from 'zod'
import { TableRowItem } from './_components/TableRowItem'

const requestWithdrawalFormSchema = z.object({
  tipo_saque: z.enum(['VENDA_DIGITAL', 'VENDA_FISICA']),
  beneficiario: z.string().trim().min(1, 'Preencha o beneficiário'),
  tipo_chave: z.enum(['CPF', 'CNPJ', 'Telefone', 'E-mail', 'Chave aleatória']),
  chave: z.string().trim().min(1, { message: 'Preencha a chave Pix' }),
  banco: z.string().trim().min(1, 'Preencha o banco'),
  valor: z.coerce.number().min(1, 'O valor mínimo para solicitação é R$1,00'),
})

export type ProducerData = {
  responsible: string
  taxId: string
  totalSalesValue: number
  amountRequested: number
  totalAmountWithdrawn: number
  balanceAvailable: number
  sold: number
  checkIns: number
}

type OtherExpensesItemType = {
  id: number
  description: string
  quantity: number
  value: number
  total: number
}

type WithdrawType = {
  id: number
  date: string
  recipient: string
  bank: string
  key: string
  value: number
}

type ResumeType = {
  responsible: {
    name: string
    taxId: string
  }
  eventIsClosed: boolean
  digital: {
    amount: number
    amountBlockedForWithdraw: number
    amountWithdrawn: number
    advancePercentage: number
    daysToRetainTheWithdraw: number
    absorbedFees: number
    discountCoupons: number
    installmentInterestAbsorbed: number
    services: {
      total: number
      items: OtherExpensesItemType[]
    }
    withdraws: { total: number; items: WithdrawType[] }
  }
  physical: {
    amount: number
    sales: {
      money: number
      creditCard: number
      debitCard: number
      pix: number
    }
    fees: {
      money: number
      creditCard: number
      debitCard: number
      pix: number
      total: number
    }
    amountBlockedForWithdraw: number
    amountWithdrawn: number
    advancePercentage: number
    daysToRetainTheWithdraw: number
    services: {
      total: number
      items: OtherExpensesItemType[]
    }
    withdraws: { total: number; items: WithdrawType[] }
    absorbedFees: number
    installmentInterestAbsorbed: number
  }
}

export function RequestWithdrawal() {
  const [isLoading, setIsLoading] = useState(false)
  const [isRequesting, setIsRequesting] = useState(false)
  const [withdrawals, setWithdrawals] = useState<SaqueDTO[]>([])
  const [resume, setResume] = useState<ResumeType>({} as ResumeType)
  const [event, setEvent] = useState<IEvento>({} as IEvento)

  const { eventId } = useParams()
  const { addErrorMessage } = usePromoter()

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<SaqueDTO>({
    resolver: zodResolver(requestWithdrawalFormSchema),
    defaultValues: { tipo_saque: 'VENDA_DIGITAL', valor: 0, tipo_chave: 'CPF' },
  })

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true)
      const { data } = await apiV1.producer.withdrawalService.getAll(
        Number(eventId),
      )
      setWithdrawals(data.withdrawals)
      setResume({
        responsible: data.responsible,
        eventIsClosed: data.eventIsClosed,
        digital: data.digital,
        physical: data.physical,
      })
      setEvent(data.event)
    } catch (error) {
      addErrorMessage(getErrorMessage(error))
    } finally {
      setIsLoading(false)
    }
  }, [addErrorMessage, eventId])

  const onSubmit = useCallback(
    async (withdrawal: SaqueDTO) => {
      if (isSubmitting) {
        return
      }
      try {
        setIsRequesting(true)
        await apiV1.producer.withdrawalService.create(
          Number(eventId),
          withdrawal,
        )
        reset()
        fetchData()
      } catch (error) {
        let message =
          'Ocorreu um erro ao processar a solicitação, tente novamente!'
        const isAxiosError = axios.isAxiosError(error)
        if (isAxiosError) {
          message = error.response?.data.message ?? error.message
        }
        addErrorMessage(message)
      } finally {
        setIsRequesting(false)
      }
    },
    [addErrorMessage, eventId, fetchData, isSubmitting, reset],
  )

  useEffect(() => {
    fetchData()
  }, [fetchData])

  if (isLoading || !resume.responsible) return <PageLoading />

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      width: 80,
      renderCell: (cell) => (
        <Link
          to={`/organizador/eventos/${eventId}/solicitacao-de-saque/${cell.value}`}
        >
          #{cell.value}
        </Link>
      ),
    },
    {
      field: 'beneficiario',
      headerName: 'Beneficiário',
      flex: 1,
      minWidth: 120,
    },
    {
      field: 'tipo_saque',
      headerName: 'Canal de vendas',
      width: 130,
      renderCell: ({ value }) =>
        value === 'VENDA_FISICA' ? 'Físico' : 'Digital',
    },
    {
      field: 'valor',
      headerName: 'Valor',
      width: 120,
      type: 'number',
      renderCell: (cell) => moneyFormatter.format(Number(cell.value)),
    },
    {
      field: 'data_situacao',
      headerName: 'Data',
      width: 150,
      type: 'number',
      renderCell: ({ value, row }) =>
        value
          ? dayjs(value).format('DD/MM/YYYY HH:mm')
          : dayjs(row.data_solicitacao).format('DD/MM/YYYY HH:mm'),
    },
    {
      field: 'cod_saque_status',
      headerName: 'Situação',
      type: 'number',
      width: 120,
      renderCell: (cell) => (
        <Chip
          label={cell.row.saque_status.titulo}
          color={
            cell.value === 1 ? 'info' : cell.value === 2 ? 'success' : 'error'
          }
        />
      ),
    },
  ]

  const requestedDigitalWithdrawals = withdrawals
    .filter((x) => x.tipo_saque === 'VENDA_DIGITAL' && x.cod_saque_status === 1)
    .reduce((total, withdrawal) => total + Number(withdrawal.valor), 0)

  const requestedPhysicalWithdrawals = withdrawals
    .filter((x) => x.tipo_saque === 'VENDA_FISICA' && x.cod_saque_status === 1)
    .reduce((total, withdrawal) => total + Number(withdrawal.valor), 0)

  const breadcrumbs: BreadcrumbItem[] = [
    { title: 'Eventos', to: '/organizador/eventos' },
    {
      title: event.titulo ?? '...',
      to: `/organizador/eventos/${event.id}`,
    },
    { title: 'Solicitação de repasse' },
  ]

  // const { physical } = resume

  // const physicalAmount =
  //   physical.sales.creditCard + physical.sales.debitCard + physical.sales.pix
  // const physicalFees =
  //   physical.fees.creditCard + physical.fees.debitCard + physical.fees.pix

  return (
    <Grid container spacing={2} component={Paper} p={2}>
      <Grid item xs={12}>
        <Breadcrumbs items={breadcrumbs} />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h4">{event.titulo ?? '...'}</Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sm={7}
        sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
      >
        <Typography variant="h5">Resumo de movimentações</Typography>

        <TableContainer component={Paper}>
          <Typography sx={{ flex: '1 1 100%', pt: 2, pl: 2 }} variant="h6">
            Vendas digitais (Site)
          </Typography>
          {resume.eventIsClosed ? (
            <Table size="small">
              <TableBody>
                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Valor de vendas (A)
                  </TableCell>
                  <TableCell align="right">
                    {moneyFormatter.format(resume.digital.amount)}
                  </TableCell>
                </TableRow>

                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Valor sacado (B)
                  </TableCell>
                  <TableCell align="right">
                    {moneyFormatter.format(resume.digital.withdraws.total)}
                  </TableCell>
                </TableRow>

                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Valores solicitados (C){' '}
                    <BootstrapTooltip title="Saques solicitados ainda em análise">
                      <Info color="#000" size={16} />
                    </BootstrapTooltip>
                  </TableCell>
                  <TableCell align="right">
                    {moneyFormatter.format(requestedDigitalWithdrawals)}
                  </TableCell>
                </TableRow>

                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Taxas absorvidas (D){' '}
                    <BootstrapTooltip title="Taxas absorvidas dos lotes">
                      <Info color="#000" size={16} />
                    </BootstrapTooltip>
                  </TableCell>
                  <TableCell align="right">
                    {moneyFormatter.format(resume.digital.absorbedFees)}
                  </TableCell>
                </TableRow>

                <TableRowItem
                  title="Cupons de desconto (E)"
                  value={moneyFormatter.format(resume.digital.discountCoupons)}
                />

                <TableRowItem
                  title="Juros de parcelamento absorvidos (F)"
                  value={moneyFormatter.format(
                    resume.digital.installmentInterestAbsorbed,
                  )}
                />

                <TableRowItem
                  title="Outras despesas (G)"
                  value={moneyFormatter.format(resume.digital.services.total)}
                  items={resume.digital.services.items.map(
                    ({ description, total }) => ({
                      description,
                      value: moneyFormatter.format(total),
                    }),
                  )}
                />

                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ fontWeight: 'bold' }}
                  >
                    Valor liberado para saque = (A - B - C - D - E - F - G)
                  </TableCell>
                  <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                    {moneyFormatter.format(
                      resume.digital.amount -
                        resume.digital.withdraws.total -
                        requestedDigitalWithdrawals -
                        resume.digital.discountCoupons -
                        resume.digital.absorbedFees -
                        resume.digital.installmentInterestAbsorbed -
                        resume.digital.services.total,
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          ) : (
            <Table size="small">
              <TableBody>
                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Valor de vendas (A)
                  </TableCell>
                  <TableCell align="right">
                    {moneyFormatter.format(resume.digital.amount)}
                  </TableCell>
                </TableRow>

                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Valor bloqueado {resume.digital.daysToRetainTheWithdraw}{' '}
                    dias (B)
                  </TableCell>
                  <TableCell align="right">
                    {moneyFormatter.format(
                      resume.digital.amountBlockedForWithdraw,
                    )}
                  </TableCell>
                </TableRow>

                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Valor disponível (C) = (A - B) *{' '}
                    {resume.digital.advancePercentage}%
                  </TableCell>
                  <TableCell align="right">
                    {moneyFormatter.format(
                      ((resume.digital.amount -
                        resume.digital.amountBlockedForWithdraw) *
                        resume.digital.advancePercentage) /
                        100,
                    )}
                  </TableCell>
                </TableRow>

                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Valor sacado (D)
                  </TableCell>
                  <TableCell align="right">
                    {moneyFormatter.format(resume.digital.withdraws.total)}
                  </TableCell>
                </TableRow>

                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Valores solicitados (E){' '}
                    <BootstrapTooltip title="Saques solicitados ainda em análise">
                      <Info color="#000" size={16} />
                    </BootstrapTooltip>
                  </TableCell>
                  <TableCell align="right">
                    {moneyFormatter.format(requestedDigitalWithdrawals)}
                  </TableCell>
                </TableRow>

                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Taxas absorvidas (F){' '}
                    <BootstrapTooltip title="Taxas absorvidas dos lotes">
                      <Info color="#000" size={16} />
                    </BootstrapTooltip>
                  </TableCell>
                  <TableCell align="right">
                    {moneyFormatter.format(resume.digital.absorbedFees)}
                  </TableCell>
                </TableRow>

                <TableRowItem
                  title="Juros de parcelamento absorvidos (G)"
                  value={moneyFormatter.format(
                    resume.digital.installmentInterestAbsorbed,
                  )}
                />

                <TableRowItem
                  title="Outras despesas (H)"
                  value={moneyFormatter.format(resume.digital.services.total)}
                  items={resume.digital.services.items.map(
                    ({ description, value }) => ({
                      description,
                      value: moneyFormatter.format(value),
                    }),
                  )}
                />

                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ fontWeight: 'bold' }}
                  >
                    Valor liberado para saque = (C - D - E - F - G - H)
                  </TableCell>
                  <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                    {moneyFormatter.format(
                      ((resume.digital.amount -
                        resume.digital.amountBlockedForWithdraw) *
                        resume.digital.advancePercentage) /
                        100 -
                        resume.digital.withdraws.total -
                        requestedDigitalWithdrawals -
                        resume.digital.absorbedFees -
                        resume.digital.installmentInterestAbsorbed -
                        resume.digital.services.total,
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          )}
        </TableContainer>

        <TableContainer component={Paper}>
          <Typography sx={{ flex: '1 1 100%', pt: 2, pl: 2 }} variant="h6">
            Vendas físicas (PDV/POS)
          </Typography>
          {resume.eventIsClosed ? (
            <Table size="small">
              <TableBody>
                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Valor de vendas (A)
                  </TableCell>
                  <TableCell align="right">
                    {moneyFormatter.format(resume.physical.amount)}
                  </TableCell>
                </TableRow>

                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Valor em dinheiro (B){' '}
                    <BootstrapTooltip title="Valor de vendas que já está com o produtor">
                      <Info color="#000" size={16} />
                    </BootstrapTooltip>
                  </TableCell>
                  <TableCell align="right">
                    {moneyFormatter.format(resume.physical.sales.money)}
                  </TableCell>
                </TableRow>

                <TableRowItem
                  title="Taxas de formas de pagamento (C)"
                  value={moneyFormatter.format(resume.physical.fees.total)}
                  items={[
                    {
                      description: 'Crédito',
                      value: moneyFormatter.format(
                        resume.physical.fees.creditCard,
                      ),
                    },
                    {
                      description: 'Débito',
                      value: moneyFormatter.format(
                        resume.physical.fees.debitCard,
                      ),
                    },
                    {
                      description: 'Pix',
                      value: moneyFormatter.format(resume.physical.fees.pix),
                    },
                    {
                      description: 'Dinheiro',
                      value: moneyFormatter.format(resume.physical.fees.money),
                    },
                  ]}
                />

                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Valor sacado (D)
                  </TableCell>
                  <TableCell align="right">
                    {moneyFormatter.format(resume.physical.amountWithdrawn)}
                  </TableCell>
                </TableRow>

                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Valores solicitados (E){' '}
                    <BootstrapTooltip title="Saques solicitados ainda em análise">
                      <Info color="#000000" size={16} />
                    </BootstrapTooltip>
                  </TableCell>
                  <TableCell align="right">
                    {moneyFormatter.format(requestedPhysicalWithdrawals)}
                  </TableCell>
                </TableRow>

                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Taxas absorvidas (F){' '}
                    <BootstrapTooltip title="Taxas absorvidas dos lotes">
                      <Info color="#000" size={16} />
                    </BootstrapTooltip>
                  </TableCell>
                  <TableCell align="right">
                    {moneyFormatter.format(resume.physical.absorbedFees)}
                  </TableCell>
                </TableRow>

                <TableRowItem
                  title="Juros de parcelamento absorvidos (G)"
                  value={moneyFormatter.format(
                    resume.physical.installmentInterestAbsorbed,
                  )}
                />

                <TableRowItem
                  title="Outras despesas (H)"
                  value={moneyFormatter.format(resume.physical.services.total)}
                  items={resume.physical.services.items.map(
                    ({ description, total }) => ({
                      description,
                      value: moneyFormatter.format(total),
                    }),
                  )}
                />

                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ fontWeight: 'bold' }}
                  >
                    Valor liberado para saque = (A - B - C - D - E - F)
                  </TableCell>
                  <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                    {moneyFormatter.format(
                      resume.physical.amount -
                        resume.physical.sales.money -
                        resume.physical.fees.total -
                        resume.physical.amountWithdrawn -
                        requestedPhysicalWithdrawals -
                        resume.physical.absorbedFees -
                        resume.physical.installmentInterestAbsorbed -
                        resume.physical.services.total,
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          ) : (
            <Table size="small">
              <TableBody>
                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Valor de vendas (A)
                  </TableCell>
                  <TableCell align="right">
                    {moneyFormatter.format(resume.physical.amount)}
                  </TableCell>
                </TableRow>

                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Valor em dinheiro (B){' '}
                    <BootstrapTooltip title="Valor de vendas que já está com o produtor">
                      <Info color="#000" size={16} />
                    </BootstrapTooltip>
                  </TableCell>
                  <TableCell align="right">
                    {moneyFormatter.format(resume.physical.sales.money)}
                  </TableCell>
                </TableRow>

                <TableRowItem
                  title="Taxas de formas de pagamento (C)"
                  value={moneyFormatter.format(resume.physical.fees.total)}
                  items={[
                    {
                      description: 'Crédito',
                      value: moneyFormatter.format(
                        resume.physical.fees.creditCard,
                      ),
                    },
                    {
                      description: 'Débito',
                      value: moneyFormatter.format(
                        resume.physical.fees.debitCard,
                      ),
                    },
                    {
                      description: 'Pix',
                      value: moneyFormatter.format(resume.physical.fees.pix),
                    },
                    {
                      description: 'Dinheiro',
                      value: moneyFormatter.format(resume.physical.fees.money),
                    },
                  ]}
                />

                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Valor bloqueado {resume.physical.daysToRetainTheWithdraw}{' '}
                    dias (D)
                  </TableCell>
                  <TableCell align="right">
                    {moneyFormatter.format(
                      resume.physical.amountBlockedForWithdraw,
                    )}
                  </TableCell>
                </TableRow>

                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Valor disponível (E) = (A - B - C - D) *{' '}
                    {resume.physical.advancePercentage}%
                  </TableCell>
                  <TableCell align="right">
                    {moneyFormatter.format(
                      ((resume.physical.amount -
                        resume.physical.sales.money -
                        resume.physical.amountBlockedForWithdraw) *
                        resume.physical.advancePercentage) /
                        100,
                    )}
                  </TableCell>
                </TableRow>

                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Valor sacado (D)
                  </TableCell>
                  <TableCell align="right">
                    {moneyFormatter.format(resume.physical.amountWithdrawn)}
                  </TableCell>
                </TableRow>

                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Valores solicitados (E){' '}
                    <BootstrapTooltip title="Saques solicitados ainda em análise">
                      <Info color="#000000" size={16} />
                    </BootstrapTooltip>
                  </TableCell>
                  <TableCell align="right">
                    {moneyFormatter.format(requestedPhysicalWithdrawals)}
                  </TableCell>
                </TableRow>

                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Taxas absorvidas (F){' '}
                    <BootstrapTooltip title="Taxas absorvidas dos lotes">
                      <Info color="#000" size={16} />
                    </BootstrapTooltip>
                  </TableCell>
                  <TableCell align="right">
                    {moneyFormatter.format(resume.physical.absorbedFees)}
                  </TableCell>
                </TableRow>

                <TableRowItem
                  title="Juros de parcelamento absorvidos (G)"
                  value={moneyFormatter.format(
                    resume.physical.installmentInterestAbsorbed,
                  )}
                />

                <TableRowItem
                  title="Outras despesas (H)"
                  value={moneyFormatter.format(resume.physical.services.total)}
                  items={resume.physical.services.items.map(
                    ({ description, total }) => ({
                      description,
                      value: moneyFormatter.format(total),
                    }),
                  )}
                />

                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ fontWeight: 'bold' }}
                  >
                    Valor liberado para saque = (A - B - C - D - E - F - H)
                  </TableCell>
                  <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                    {moneyFormatter.format(
                      resume.physical.amount -
                        resume.physical.sales.money -
                        ((resume.physical.amount -
                          resume.physical.sales.money -
                          resume.physical.amountBlockedForWithdraw) *
                          resume.physical.advancePercentage) /
                          100 -
                        resume.physical.fees.total -
                        resume.physical.amountWithdrawn -
                        requestedPhysicalWithdrawals -
                        resume.physical.absorbedFees -
                        resume.physical.installmentInterestAbsorbed -
                        resume.physical.services.total,
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          )}
        </TableContainer>
      </Grid>

      <Grid container spacing={2} item xs={12} sm={5}>
        <Grid item xs={12}>
          <Typography variant="h5">Solicitar de saque</Typography>
        </Grid>
        <Grid item xs={12}>
          <Alert severity="info">
            Os dados do beneficiário devem ser os mesmos do titular da
            responsável da produtora.
            <p>Nome: {resume.responsible?.name}</p>
            <p>Documento: {resume.responsible?.taxId}</p>
          </Alert>
        </Grid>
        <Grid item xs={12}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Canal de vendas</FormLabel>
            <Controller
              control={control}
              name="tipo_saque"
              render={({ field }) => (
                <RadioGroup {...field} row>
                  <FormControlLabel
                    value="VENDA_DIGITAL"
                    control={<Radio />}
                    label="Digital"
                  />
                  <FormControlLabel
                    value="VENDA_FISICA"
                    control={<Radio />}
                    label="Físico"
                  />
                </RadioGroup>
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Nome do beneficiário"
            error={!!errors.beneficiario}
            helperText={errors.beneficiario?.message}
            fullWidth
            required
            size="small"
            {...register('beneficiario')}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id="key-type-select-label">
              Tipo da chave Pix
            </InputLabel>
            <Controller
              control={control}
              name="tipo_chave"
              render={({ field }) => (
                <Select
                  label="Tipo da chave Pix"
                  error={!!errors.tipo_chave}
                  required
                  size="small"
                  {...field}
                >
                  <MenuItem value="CPF">CPF</MenuItem>
                  <MenuItem value="CNPJ">CNPJ</MenuItem>
                  <MenuItem value="Telefone">Telefone</MenuItem>
                  <MenuItem value="E-mail">E-mail</MenuItem>
                  <MenuItem value="Chave aleatória">Chave aleatória</MenuItem>
                </Select>
              )}
            />

            <FormHelperText error={!!errors.tipo_chave}>
              {errors.tipo_chave?.message}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Chave Pix"
            error={!!errors.chave}
            helperText={errors.chave?.message}
            fullWidth
            required
            size="small"
            {...register('chave')}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Banco"
            error={!!errors.banco}
            helperText={errors.banco?.message}
            fullWidth
            required
            size="small"
            {...register('banco')}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Valor"
            error={!!errors.valor}
            helperText={errors.valor?.message}
            fullWidth
            required
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">R$</InputAdornment>
              ),
            }}
            {...register('valor', {
              valueAsNumber: true,
              validate: (value) => value > 0,
            })}
          />
        </Grid>

        <Grid item xs={12}>
          <Alert severity="info">
            O prazo previsto para pagamento é de até 48h úteis após a
            solicitação.
          </Alert>
        </Grid>

        <Grid item xs={12} display="flex" justifyContent="flex-end">
          <LoadingButton
            loading={isRequesting}
            loadingPosition="start"
            startIcon={<PixIcon />}
            onClick={handleSubmit(onSubmit)}
            variant="contained"
          >
            Solicitar saque
          </LoadingButton>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h5">Histórico de solicitações de saque</Typography>
      </Grid>
      <Grid item xs={12}>
        <DataGrid rows={withdrawals} columns={columns} autoHeight />
      </Grid>
    </Grid>
  )
}

function PageLoading() {
  return (
    <Grid item xs={12}>
      <Skeleton />
      <Skeleton />
      <Skeleton />
      <Skeleton />
      <Skeleton />
    </Grid>
  )
}
