import { Chip } from '@mui/material'
import { addHours, isFuture, isPast } from 'date-fns'
import * as React from 'react'
import { IIngresso } from 'types/ingresso'

interface BatchItemStatusProps {
  batch: IIngresso
}

const BatchItemStatus = ({ batch }: BatchItemStatusProps) => {
  let label = 'vendendo'
  let color:
    | 'default'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning' = 'success'

  const startAt = addHours(new Date(batch.data_entrar), 3)
  const endAt = addHours(new Date(batch.data_sair), 3)

  if (isFuture(startAt)) {
    label = 'aguardando'
    color = 'info'
  }

  if (isPast(new Date(endAt))) {
    label = 'expirado'
    color = 'error'
  }

  if (batch.valida_quantidade && batch.quantidade_disponivel < 1) {
    label = 'esgotado'
    color = 'error'
  }

  if (!batch.visivel) {
    label = 'somente produtor'
    color = 'warning'
  }

  if (!batch.visivel && !batch.app_organizador) {
    label = 'oculto'
    color = 'secondary'
  }

  return <Chip label={label} color={color} size="small" sx={{ height: 18 }} />
}

export default React.memo(BatchItemStatus)
