import { Alert } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Stepper from '@mui/material/Stepper'
import Typography from '@mui/material/Typography'
import PaymentButton from 'components/PaymentButton'
import {
  ShoppingCartPaymentInfo,
  shoppingCartSchema,
  useShoppingCart,
} from 'contexts/shoppingCartContext'
import * as React from 'react'
import ReactPixel from 'react-facebook-pixel'
import ReactGA from 'react-ga'
import TagManager from 'react-gtm-module'
import { useFormContext } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import Checkout from './Checkout'
import PaymentForm from './PaymentForm'
import Review from './Review'
import { userUtils } from 'utils'
import { IUsuario } from 'types/usuario'

const steps = ['Itens', 'Pagamento', 'Revisão']

function getStepContent(step: number) {
  switch (step) {
    case 0:
      return <Checkout />
    case 1:
      return <PaymentForm />
    case 2:
      return <Review />
    default:
      throw new Error('Unknown step')
  }
}

const CheckoutPage: React.FC = () => {
  const [activeStep, setActiveStep] = React.useState(0)
  const { placeOrder, shoppingCart, loading, error, setError } =
    useShoppingCart()
  const { watch, trigger } = useFormContext<ShoppingCartPaymentInfo>()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const location = useLocation() as any
  const navigate = useNavigate()
  const { googleAnalytics, googleTagManager, metaPixel } = location.state

  const handleNext = () => {
    if (activeStep === 1) {
      const data = watch()
      const { success } = shoppingCartSchema.safeParse(data)
      if (!success) {
        trigger()
        return
      }
    }
    setActiveStep(activeStep + 1)
  }

  const handleBack = () => {
    setActiveStep(activeStep - 1)
  }

  const handlePaymentButtonClick = React.useCallback(() => {
    placeOrder()
  }, [placeOrder])

  React.useEffect(() => {
    if (shoppingCart.length === 0) {
      navigate('/', { replace: true })
    }

    const user = localStorage.getItem('user')
    const userParsed: IUsuario = JSON.parse(user ?? '')
    if (!userUtils.itsCompleteRegistration(userParsed)) {
      navigate(`/usuario/completar-cadastro`, {
        replace: true,
        state: { from: location },
      })
    } else {
      if (metaPixel) {
        const options = {
          autoConfig: true,
          debug: true,
        }
        ReactPixel.grantConsent()
        ReactPixel.init(metaPixel, undefined, options)
        ReactPixel.trackSingle(metaPixel, 'InitiateCheckout')
      }
      if (googleAnalytics) {
        ReactGA.initialize(googleAnalytics)
        ReactGA.pageview(window.location.pathname + window.location.search)
      }
      if (googleTagManager) {
        TagManager.initialize({
          gtmId: googleTagManager,
        })
      }
    }
  }, [
    googleAnalytics,
    googleTagManager,
    location,
    metaPixel,
    navigate,
    shoppingCart.length,
  ])

  return (
    <Container
      component="main"
      maxWidth="md"
      sx={{ mb: 4, p: 0, minHeight: '100%' }}
    >
      <Paper
        variant="outlined"
        sx={{ my: { xs: 0, md: 6 }, p: { xs: 0, md: 3 } }}
      >
        <Typography component="h1" variant="h4" align="center">
          Checkout
        </Typography>
        <Stepper activeStep={activeStep} sx={{ py: 3 }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {activeStep === steps.length ? (
          <React.Fragment>
            <Typography variant="h5" gutterBottom>
              Thank you for your order.
            </Typography>
            <Typography variant="subtitle1">
              Your order number is #2001539. We have emailed your order
              confirmation, and will send you an update when your order has
              shipped.
            </Typography>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {getStepContent(activeStep)}
            {error && (
              <Alert severity="error" onClose={() => setError('')}>
                {JSON.stringify(error)}
              </Alert>
            )}
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
              {activeStep !== 0 && (
                <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                  Voltar
                </Button>
              )}
              {activeStep === steps.length - 1 ? (
                watch('paymentType') && (
                  <PaymentButton
                    disabled={loading}
                    loading={loading}
                    onClick={handlePaymentButtonClick}
                    sx={{ mt: 3, ml: 1 }}
                  />
                )
              ) : (
                <Button
                  variant="contained"
                  onClick={handleNext}
                  sx={{ mt: 3, ml: 1 }}
                >
                  Próximo
                </Button>
              )}
            </Box>
          </React.Fragment>
        )}
      </Paper>
    </Container>
  )
}

export default CheckoutPage
