import { zodResolver } from '@hookform/resolvers/zod'
import { PersonAddAlt, Search } from '@mui/icons-material'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import Person from '@mui/icons-material/Person'
import { LoadingButton } from '@mui/lab'
import {
  Alert,
  Box,
  Breadcrumbs,
  Chip,
  CircularProgress,
  Collapse,
  Divider,
  Grid,
  InputAdornment,
  Link,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import { DataGrid, GridColDef, GridRowsProp } from '@mui/x-data-grid'
import { AxiosError } from 'axios'
import * as React from 'react'
import { useForm } from 'react-hook-form'
import { Link as RouterLink, useParams } from 'react-router-dom'
import { apiV1 } from 'services'
import { IPartnerTeamPage } from 'types/partner'
import { datagrid, functionWithLoading } from 'utils'
import { moneyFormatter } from 'utils/formatter'
import { z } from 'zod'

const schema = z.object({
  email: z
    .string({ required_error: 'É necessário preencher o e-mail' })
    .email('Preencha um e-mail válido'),
})

type FormInput = z.infer<typeof schema>

export default function EventStaff() {
  const { eventId } = useParams()
  const [loadingData, setLoadingData] = React.useState(false)
  const [loadingAdd, setLoadingAdd] = React.useState(false)
  const [error, setError] = React.useState('')
  const [success, setSuccess] = React.useState('')
  const [response, setResponse] = React.useState<IPartnerTeamPage>()
  const [searchQuery, setSearchQuery] = React.useState('')
  const [open, setOpen] = React.useState(false)

  const handleClick = () => {
    setOpen(!open)
  }

  const event = response?.evento
  const partnerTeam = response?.equipe_parceiro ?? []
  const partner = response?.parceiro[0] ?? undefined

  const loadEvent = React.useCallback(() => {
    functionWithLoading(
      apiV1.partner.eventService
        .get(Number(eventId))
        .then(({ data }) => setResponse(data)),
      setLoadingData,
    )
  }, [eventId])

  React.useEffect(() => {
    loadEvent()
  }, [loadEvent])

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    resetField,
  } = useForm<FormInput>({
    resolver: zodResolver(schema),
    defaultValues: {
      email: '',
    },
  })

  const onSubmit = async (data: FormInput) => {
    if (isSubmitting) {
      return
    }
    if (event)
      functionWithLoading(
        apiV1.partner.partnerService
          .addPartnerTeam(event.id, data.email)
          .then(() => {
            loadEvent()
            setSuccess(`O usuário ${data.email} foi adicionado na equipe!`)
            resetField('email')
          })
          .catch((err: AxiosError) => {
            setError(
              err.response?.data.message ??
                'Ocorreu um erro, tente novamente! Se o erro persistir entre em contato com o suporte.',
            )
          }),
        setLoadingAdd,
      )
  }

  const rows: GridRowsProp =
    searchQuery.length > 0
      ? partnerTeam.filter(
          (x) =>
            x.nome
              .toLocaleLowerCase()
              .includes(searchQuery.toLocaleLowerCase()) ||
            x.id.toString().includes(searchQuery),
        )
      : partnerTeam

  const columns: GridColDef[] = [
    { field: 'nome', headerName: 'Nome', flex: 1, minWidth: 150 },
    { field: 'qtd_app', headerName: 'Qtd. APP' },
    { field: 'qtd_site', headerName: 'Qtd. Link' },
    {
      field: 'ativo',
      headerName: 'Situação',
      renderCell: (cellValues) =>
        cellValues.value ? (
          <Chip label="Ativo" color="success" />
        ) : (
          <Chip label="Inativo" color="error" />
        ),
    },
    {
      field: 'actions',
      headerName: 'Ações',
      renderCell: (cellValues) => {
        return (
          <Link
            component={RouterLink}
            to={`/parceiro/eventos/${eventId}/equipe/${cellValues.id}`}
            state={event}
          >
            Detalhes
          </Link>
        )
      },
    },
  ]

  if (loadingData)
    return (
      <Box
        sx={{
          height: '70vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress color="inherit" />
      </Box>
    )

  return (
    <Grid container spacing={2} p={2} component={Paper}>
      <Grid item xs={12}>
        <Box role="presentation" sx={{ pt: 2, pb: 2 }}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              component={RouterLink}
              underline="hover"
              color="inherit"
              to="/parceiro/eventos"
            >
              Eventos
            </Link>
            <Typography color="text.primary">{event?.titulo}</Typography>
            <Typography color="text.primary">Equipe</Typography>
          </Breadcrumbs>
        </Box>
      </Grid>

      {partner && (
        <>
          <Grid item xs={12}>
            <Typography variant="h6">{partner.nome}</Typography>
          </Grid>
          <Grid item xs={12}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell align="right">App</TableCell>
                    <TableCell align="right">Link</TableCell>
                    <TableCell align="right">Total</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>Qtd</TableCell>
                    <TableCell align="right">{partner.qtd_app}</TableCell>
                    <TableCell align="right">{partner.qtd_site}</TableCell>
                    <TableCell align="right">{partner.qtd_total}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Valor</TableCell>
                    <TableCell align="right">
                      {moneyFormatter.format(partner.valor_app)}
                    </TableCell>
                    <TableCell align="right">
                      {moneyFormatter.format(partner.valor_site)}
                    </TableCell>
                    <TableCell align="right">
                      {moneyFormatter.format(partner.valor_total)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </>
      )}

      {error && (
        <Grid item xs={12}>
          <Alert severity="error" onClose={() => setError('')}>
            {error}
          </Alert>
        </Grid>
      )}
      {success && (
        <Grid item xs={12}>
          <Alert severity="success" onClose={() => setSuccess('')}>
            {success}
          </Alert>
        </Grid>
      )}

      <Grid item xs={12}>
        <List>
          <ListItemButton onClick={handleClick}>
            <ListItemIcon>
              <Person />
            </ListItemIcon>
            <ListItemText primary="Adicionar novo membro" />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div">
              <Grid container spacing={2}>
                <Grid item xs={7} sm={10}>
                  <TextField
                    variant="outlined"
                    label="E-mail"
                    error={!!errors.email}
                    helperText={errors.email?.message}
                    {...register('email')}
                    fullWidth
                    required
                    size="small"
                  />
                </Grid>
                <Grid item xs={5} sm={2}>
                  <LoadingButton
                    loading={loadingAdd}
                    loadingPosition="start"
                    startIcon={<PersonAddAlt />}
                    variant="outlined"
                    onClick={handleSubmit(onSubmit)}
                  >
                    Adicionar
                  </LoadingButton>
                </Grid>
              </Grid>
            </List>
          </Collapse>
        </List>
      </Grid>

      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Pesquisar"
          variant="outlined"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          onChange={(e) => setSearchQuery(e.target.value)}
          value={searchQuery}
          size="small"
        />
      </Grid>
      <Grid item xs={12}>
        <DataGrid
          rows={rows}
          columns={columns}
          autoHeight
          localeText={datagrid.localizedTextsMap}
        />
      </Grid>
    </Grid>
  )
}
