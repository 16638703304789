/* eslint-disable react/no-unescaped-entities */
import LockResetIcon from '@mui/icons-material/LockReset'
import { LoadingButton } from '@mui/lab'
import {
  Alert,
  Button,
  Container,
  Grid,
  Paper,
  Typography,
} from '@mui/material'
import { FormItem } from 'components'
import { useFormik } from 'formik'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { apiV1 } from 'services'
import { functionWithLoading } from 'utils'
import * as yup from 'yup'

const validationSchema = yup.object({
  email: yup
    .string()
    .email('É necessário preencher um email válido')
    .required('É necessário preencher o email')
    .nullable(),
})

const ForgetPasswordPage: React.FC = () => {
  const [loading, setLoading] = React.useState(false)
  const [sendedEmail, setSendedEmail] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState('')
  const navigate = useNavigate()

  const formik = useFormik({
    initialValues: { email: '' },
    validateOnChange: true,
    validationSchema,
    onSubmit: (values) => {
      functionWithLoading(
        apiV1.userService
          .forgotPassword(values.email)
          .then((res) => {
            setSendedEmail(res.data.sended)
          })
          .catch((err) => {
            setErrorMessage(err.response?.data.message ?? err.message)
          }),
        setLoading,
      )
    },
  })

  if (sendedEmail)
    return (
      <Container maxWidth="md" sx={{ my: 2 }}>
        <Paper sx={{ p: 2 }}>
          <Typography variant="h4">Prontinho!</Typography>
          <Typography variant="body1" sx={{ mt: 2 }}>
            Foram enviadas as instruções para o e-mail informado, vai lá no
            e-mail e sigas as instruções :)
          </Typography>
        </Paper>
      </Container>
    )

  return (
    <Container maxWidth="md" sx={{ my: 2 }}>
      <Paper
        sx={{ p: 2 }}
        component="form"
        onSubmit={formik.handleSubmit}
        noValidate
      >
        <Typography variant="h4">Esqueci a senha! E agora Zé?</Typography>
        <Typography variant="caption" sx={{ mt: 2 }}>
          Preencha seu e-mail e clique em "RECUPERAR MINHA SENHA" que vou te
          enviar um e-mail com as instruções para você colocar uma senha nova na
          sua conta para poder comprar e ver seus ingressos :)
        </Typography>
        {errorMessage && (
          <Alert severity="error" onClose={() => setErrorMessage('')}>
            {errorMessage}
          </Alert>
        )}
        <Grid container spacing={2} my={2}>
          <Grid item xs={12}>
            <FormItem.TextField
              label="E-mail"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              onBlur={() =>
                formik.setFieldValue('email', formik.values.email.trim())
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} display="flex" justifyContent="flex-start">
            <Button
              variant="outlined"
              onClick={() => navigate('/signup')}
              size="small"
            >
              Criar conta
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} display="flex" justifyContent="flex-end">
            <LoadingButton
              loading={loading}
              loadingPosition="start"
              variant="contained"
              type="submit"
              disabled={loading}
              startIcon={<LockResetIcon />}
            >
              Recuperar minha senha
            </LoadingButton>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  )
}

export default ForgetPasswordPage
