import { Search } from '@mui/icons-material'
import {
  Alert,
  Checkbox,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { Title } from 'components'
import { BreadcrumbItem, Breadcrumbs } from 'components/Breadcrumbs'
import { usePromoter } from 'contexts/promoterContext'
import dayjs from 'dayjs'
import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { apiV1 } from 'services'
import { IPedidoItem } from 'types/pedido_item'
import { datagrid, functionWithLoading } from 'utils'
import { getOrderItemStatusTextColor } from 'utils/eventUtils'

export function ParticipantsList() {
  const [error, setError] = useState('')
  const [loadingData, setLoadingData] = useState(true)
  const [data, setData] = useState<IPedidoItem[]>([])
  const [searchQuery, setSearchQuery] = useState('')
  const [ticketStatus, setTicketStatus] = useState([1, 2])

  const { eventId } = useParams()
  const { selectedEvent } = usePromoter()

  const breadcrumbs: BreadcrumbItem[] = [
    { title: 'Eventos', to: '/organizador/eventos' },
    {
      title: selectedEvent?.titulo ?? '...',
      to: `/organizador/eventos/${eventId}`,
    },
    { title: 'Lista de participantes' },
  ]

  const fetchData = useCallback(() => {
    functionWithLoading(
      apiV1.orderItemService
        .getOrderItemsByEvent(String(eventId))
        .then(({ data }) => {
          setData(data)
        })
        .catch((err) => setError(err.response?.data.message ?? err.message)),
      setLoadingData,
    )
  }, [eventId])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const columns: GridColDef<IPedidoItem>[] = [
    {
      field: 'pedido',
      headerName: 'Participante',
      flex: 2,
      renderCell: (cell) =>
        [cell.row.pedido?.usuario.nome, cell.row.pedido?.usuario.sobrenome]
          .join(' ')
          .trim()
          .toUpperCase(),
    },
    {
      field: 'pedido.codigo',
      headerName: 'Pedido',
      width: 100,
      renderCell: (cell) => cell.row.pedido?.codigo,
    },
    {
      field: 'ingresso.titulo',
      headerName: 'Ingresso',
      flex: 1,
      renderCell: ({ row }) => `#${row.id} - ${row.ingresso.titulo}`,
    },
    {
      field: 'pedido_item_status.id',
      headerName: 'Situação',
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <Typography
            color={getOrderItemStatusTextColor(row.pedido_item_status.id)}
            flex={1}
          >
            {`${row.pedido_item_status.descricao} ${
              row.pedido_item_status.id === 2
                ? ' - ' + dayjs(row.data_status).format('DD/MM/YY HH:mm')
                : ''
            }`}
          </Typography>
        )
      },
    },
  ]

  const filteredData = data.filter((x) => {
    const fullName = [x.pedido?.usuario.nome, x.pedido?.usuario.sobrenome]
      .join(' ')
      .trim()
      .toLocaleLowerCase()

    return (
      ticketStatus.includes(x.cod_pedido_item_status) &&
      (x.participante?.toLocaleLowerCase().includes(searchQuery) ||
        fullName.includes(searchQuery) ||
        x.pedido_item_status.descricao.includes(searchQuery) ||
        x.pedido?.usuario.cpf?.includes(searchQuery) ||
        x.documento?.toLocaleLowerCase().includes(searchQuery) ||
        x.pedido?.codigo.toLocaleLowerCase().includes(searchQuery) ||
        x.id.toString().toLocaleLowerCase().includes(searchQuery))
    )
  })

  return (
    <Grid container spacing={2} sx={{ p: 2 }} component={Paper}>
      <Grid item xs={12}>
        <Breadcrumbs items={breadcrumbs} />
      </Grid>
      {error && (
        <Grid item xs={12}>
          <Alert severity="error" onClose={() => setError('')}>
            {error}
          </Alert>
        </Grid>
      )}
      <Grid item xs={12}>
        <Title>Lista de pedidos</Title>
      </Grid>
      <Grid item xs={12} sm={8}>
        <TextField
          label="Pesquisar"
          variant="outlined"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          onChange={(e) => setSearchQuery(e.target.value)}
          value={searchQuery}
          size="small"
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                checked={ticketStatus.includes(1)}
                onChange={() =>
                  setTicketStatus((prevState) =>
                    prevState.includes(1)
                      ? prevState.filter((x) => x !== 1)
                      : [...prevState, 1],
                  )
                }
              />
            }
            label="Ativo"
          />
          <FormControlLabel
            control={<Checkbox checked={ticketStatus.includes(2)} />}
            onChange={() =>
              setTicketStatus((prevState) =>
                prevState.includes(2)
                  ? prevState.filter((x) => x !== 2)
                  : [...prevState, 2],
              )
            }
            label="Utilizado"
          />
        </FormGroup>
      </Grid>
      <Grid item xs={12}>
        <DataGrid
          loading={loadingData}
          columns={columns}
          rows={filteredData}
          autoHeight
          localeText={datagrid.localizedTextsMap}
          density="compact"
          disableRowSelectionOnClick
        />
      </Grid>
    </Grid>
  )
}
