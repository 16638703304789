import { zodResolver } from '@hookform/resolvers/zod'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import { LoadingButton } from '@mui/lab'
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import axios from 'axios'
import { Loading } from 'components'
import { usePromoter } from 'contexts/promoterContext'
import dayjs from 'dayjs'
import { EditorState, convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import React, { useEffect, useState } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { Controller, useForm } from 'react-hook-form'
import 'react-image-crop/dist/ReactCrop.css'
import { useNavigate } from 'react-router-dom'
import { apiV1 } from 'services'
import { IEvento } from 'types/evento'
import { functionWithLoading } from 'utils'
import { AppError } from 'utils/AppError'
import { z } from 'zod'

const schema = z
  .object({
    titulo: z.string().min(1, 'Preencha o título do evento!'),
    data: z.coerce.date({
      required_error: 'Preencha a data de início do evento!',
    }),
    data_final: z.coerce.date({
      required_error: 'Preencha a data de fim do evento!',
    }),
    data_entrar: z.coerce.date({
      required_error: 'Preencha a data de início das vendas!',
    }),
    data_encerrar_vendas: z.coerce.date({
      required_error: 'Preencha a data de fim das vendas!',
    }),
    imagem: z.string(),
    descricao: z.string(),
    local_evento: z.string().min(1, 'Preencha o local do evento!'),
    oculto: z.coerce.boolean(),
    pode_transferir: z.boolean().optional(),
    retirada: z.string().min(1, 'Preencha a observação!'),
    cod_brasil_cidade: z.coerce
      .number()
      .min(1, 'Selecione a cidade do evento!'),
    cod_promoter: z.coerce.number().min(1, 'Selecione a produtora de evento!'),
    uf: z.string().min(1, 'Selecione a UF!'),
    id_url: z.string().min(1, 'O link necessita ter pelo menos 1 caractere'),
    facebook_pixel: z.string().optional().nullable(),
    google_analytics: z.string().optional().nullable(),
    google_tag_manager: z.string().optional().nullable(),
    tiktok_pixel: z.string().optional().nullable(),
  })
  .refine((data) => data.data_final > data.data, {
    message:
      'Data de fim do evento deve ser maior que a data de início do evento.',
    path: ['data_final'],
  })
  .refine((data) => data.data_encerrar_vendas > data.data_entrar, {
    message:
      'Data de fim das vendas dever ser maior que a data de início das vendas.',
    path: ['data_encerrar_vendas'],
  })
  .refine((data) => data.data_encerrar_vendas <= data.data_final, {
    message:
      'Data de fim das vendas dever ser menor ou igual a data de fim do evento.',
    path: ['data_encerrar_vendas'],
  })

type IFormInputs = z.infer<typeof schema>

export function CreateEventPage() {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty(),
  )
  const [selectedFile, setSelectedFile] = React.useState<File>()
  const navigate = useNavigate()
  const { addErrorMessage } = usePromoter()

  const [selectedUF, setSelectedUF] = useState<string | null>('SP')
  const [ufs, setUfs] = useState<string[]>([])
  const [cities, setCities] = useState<{ id: number; label: string }[]>([])
  const [loadingCity, setLoadingCity] = useState(true)
  const [promoters, setPromoters] = useState<{ id: number; label: string }[]>(
    [],
  )

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    watch,
    setValue,
    setError: setErrorForm,
    reset,
    resetField,
  } = useForm<IFormInputs>({
    resolver: zodResolver(schema),
    defaultValues: {
      imagem: '',
      titulo: '',
      descricao: '<p></p>\n',
      id_url: 'gerado-automático',

      data: new Date(),
      data_final: new Date(),
      data_entrar: new Date(),
      data_encerrar_vendas: new Date(),

      cod_promoter: 0,
      cod_brasil_cidade: 0,
      uf: 'SP',

      retirada:
        'Haverá uma equipe realizando a conferência do Ingresso com QRCode na portaria do evento. Proibida a entrada de menores de 18 anos.',
      oculto: false,

      facebook_pixel: '',
      google_analytics: '',
      google_tag_manager: '',
      tiktok_pixel: '',
      local_evento: '',
    },
    shouldFocusError: true,
  })

  const onSubmit = async (data: IFormInputs) => {
    if (isSubmitting) {
      return
    }

    try {
      setIsLoading(true)

      if (!selectedFile) {
        throw new AppError('Selecione a imagem do evento!')
      }

      const formData = new FormData()
      formData.append('imagem', selectedFile)
      data.imagem = (
        await apiV1.producer.uploadService.eventImage(formData)
      ).data

      const description = draftToHtml(
        convertToRaw(editorState.getCurrentContent()),
      )
      if (!description || description === '<p></p>\n') {
        setErrorForm('descricao', {
          message: 'Preencha a descrição do evento!',
        })
        return
      }
      data.descricao = description

      const response = await apiV1.producer.eventService.create(
        data as unknown as IEvento,
      )

      setSuccess('Evento cadastrado!')
      navigate('/organizador/eventos/' + response.data.id)
    } catch (err) {
      let message = 'Ocorreu um erro, tente novamente!'
      if (axios.isAxiosError(err)) {
        message = err.response?.data.message ?? err.message
      }
      if (err instanceof AppError) {
        message = err.message
      }
      addErrorMessage(message)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    apiV1.cityService.getUFs().then(({ data }) => {
      setUfs(data.map((x) => x.uf))
    })

    apiV1.producer.promoterService.getAll().then(({ data }) =>
      setPromoters(
        data.map((x) => ({
          id: x.id,
          label: x.razao_social,
        })),
      ),
    )
  }, [reset, resetField])

  useEffect(() => {
    if (selectedUF)
      functionWithLoading(
        apiV1.cityService.getCities(selectedUF).then(({ data }) => {
          setCities(
            data.map((x) => ({
              id: x.id,
              label: x.cidade,
            })),
          )
          resetField('cod_brasil_cidade')
        }),
        setLoadingCity,
      )
  }, [resetField, selectedUF, setValue])

  if (isLoading) return <Loading />

  return (
    <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)}>
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError('')}
      >
        <Alert
          onClose={() => setError('')}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          {error}
        </Alert>
      </Snackbar>
      <Snackbar
        open={!!success}
        autoHideDuration={6000}
        onClose={() => setSuccess('')}
      >
        <Alert
          onClose={() => setSuccess('')}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          {success}
        </Alert>
      </Snackbar>

      <Grid container component={Paper} spacing={2} sx={{ p: 2 }}>
        <Grid item xs={12}>
          <Typography variant="h6">Cadastrar evento</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            control={control}
            name="imagem"
            render={({ field: { onChange } }) => (
              <React.Fragment>
                <Button variant="contained" component="label" fullWidth>
                  Selecionar imagem do evento
                  <input
                    hidden
                    accept="image/*"
                    type="file"
                    onChange={(event) => {
                      const files = event.target.files
                      if (files && files.length > 0) {
                        const file = files[0]
                        onChange(file)
                        const reader = new FileReader()
                        reader.onload = (e) => {
                          if (e.target && e.target.result) {
                            setValue('imagem', e.target.result.toString())
                          }
                        }
                        reader.readAsDataURL(file)
                        setSelectedFile(file)
                      }
                    }}
                  />
                </Button>
                {watch('imagem') && (
                  <Box mt={2} textAlign="center">
                    <img
                      src={watch('imagem')}
                      alt="Preview"
                      style={{ maxWidth: '100%', height: 'auto' }}
                    />
                  </Box>
                )}
              </React.Fragment>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            label="Título"
            error={!!errors.titulo}
            helperText={errors.titulo?.message}
            fullWidth
            required
            {...register('titulo')}
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <Controller
            control={control}
            name="data"
            rules={{ required: true }}
            render={({ field }) => (
              <DateTimePicker
                label="Início do evento"
                value={dayjs(field.value).toDate()}
                inputRef={field.ref}
                onChange={(date) => {
                  field.onChange(date)
                }}
                slotProps={{
                  textField: {
                    error: !!errors.data,
                    helperText: errors.data?.message,
                    fullWidth: true,
                  },
                }}
                disablePast
              />
            )}
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <Controller
            control={control}
            name="data_final"
            rules={{ required: true }}
            render={({ field }) => (
              <DateTimePicker
                label="Fim do evento"
                value={dayjs(field.value).toDate()}
                inputRef={field.ref}
                onChange={(date) => {
                  field.onChange(date)
                }}
                slotProps={{
                  textField: {
                    error: !!errors.data_final,
                    helperText: errors.data_final?.message,
                    fullWidth: true,
                  },
                }}
                disablePast
              />
            )}
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <Controller
            control={control}
            name="data_entrar"
            rules={{ required: true }}
            render={({ field }) => (
              <DateTimePicker
                label="Início das vendas"
                value={dayjs(field.value).toDate()}
                inputRef={field.ref}
                onChange={(date) => {
                  field.onChange(date)
                }}
                slotProps={{
                  textField: {
                    error: !!errors.data_entrar,
                    helperText: errors.data_entrar?.message,
                    fullWidth: true,
                  },
                }}
                disablePast
              />
            )}
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <Controller
            control={control}
            name="data_encerrar_vendas"
            rules={{ required: true }}
            render={({ field }) => (
              <DateTimePicker
                label="Fim das vendas"
                value={dayjs(field.value).toDate()}
                inputRef={field.ref}
                onChange={(date) => {
                  field.onChange(date)
                }}
                slotProps={{
                  textField: {
                    error: !!errors.data_encerrar_vendas,
                    helperText: errors.data_encerrar_vendas?.message,
                    fullWidth: true,
                  },
                }}
                disablePast
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            variant="outlined"
            label="Local do evento"
            error={!!errors.local_evento}
            helperText={errors.local_evento?.message}
            {...register('local_evento')}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            control={control}
            name="uf"
            render={({ field: { onChange, value } }) => (
              <FormControl fullWidth>
                <InputLabel>UF</InputLabel>
                <Select
                  value={ufs.length > 0 ? value : ''}
                  label="UF"
                  onChange={(event) => {
                    onChange(event)
                    setSelectedUF(event.target.value)
                  }}
                >
                  {ufs.map((uf) => (
                    <MenuItem key={uf} value={uf}>
                      {uf}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            control={control}
            name="cod_brasil_cidade"
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                options={cities}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      onChange={onChange}
                      label="Cidade"
                      error={!!errors.cod_brasil_cidade}
                      helperText={errors.cod_brasil_cidade?.message}
                      fullWidth
                      required
                      disabled={!selectedUF}
                    />
                  )
                }}
                onChange={(event, values) => onChange(values?.id)}
                getOptionLabel={(item) => item.label}
                value={cities.find((x) => x.id === value) || null}
                loading={loadingCity}
                loadingText="Carregando..."
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="cod_promoter"
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                options={promoters}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      onChange={onChange}
                      label="Produtora"
                      error={!!errors.cod_promoter}
                      helperText={errors.cod_promoter?.message}
                      fullWidth
                      required
                    />
                  )
                }}
                onChange={(event, values) => onChange(values?.id)}
                getOptionLabel={(item) => item.label}
                value={promoters.find((x) => x.id === value) || null}
              />
            )}
          />
        </Grid>

        {editorState && (
          <Grid item xs={12}>
            <TextField
              label="Descrição do evento"
              contentEditable={false}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                startAdornment: (
                  <Editor
                    editorState={editorState}
                    onEditorStateChange={setEditorState}
                    editorStyle={{
                      minHeight: 100,
                      maxHeight: 500,
                      overflow: 'scroll',
                    }}
                    toolbar={{
                      options: [
                        'inline',
                        'blockType',
                        'fontSize',
                        'fontFamily',
                        'textAlign',
                        'list',
                        'colorPicker',
                        'link',
                        'embedded',
                        'emoji',
                        'image',
                        'history',
                      ],
                      inline: {
                        inDropdown: false,
                        options: [
                          'bold',
                          'italic',
                          'underline',
                          'strikethrough',
                        ],
                      },
                      fontFamily: {
                        isDropdown: true,
                      },
                      textAlign: {
                        options: ['left', 'center', 'right', 'justify'],
                      },
                    }}
                    toolbarStyle={{ marginLeft: -14 }}
                  />
                ),
                inputProps: {
                  style: { display: 'none' },
                },
              }}
              error={!!errors.descricao}
              helperText={errors.descricao?.message}
              required
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <TextField
            label="Observação"
            multiline
            rows={4}
            error={!!errors.retirada}
            helperText={errors.retirada?.message}
            fullWidth
            {...register('retirada')}
            required
          />
        </Grid>

        <Grid item container xs={12} spacing={1} p={4}>
          <Grid item xs={12}>
            <Typography variant="h6">
              Integrações com tráfego digital (Opcional)
            </Typography>
            <Typography variant="subtitle1">
              Informar somente o identificador da Tag/Pixel, caso tenha dúvidas
              entrar em contato com o suporte.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4} lg={3}>
            <TextField
              variant="outlined"
              label="Pixel Facebook"
              error={!!errors.facebook_pixel}
              helperText={errors.facebook_pixel?.message}
              {...register('facebook_pixel')}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={4} lg={3}>
            <TextField
              variant="outlined"
              label="Google Analytics"
              error={!!errors.google_analytics}
              helperText={errors.google_analytics?.message}
              {...register('google_analytics')}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={4} lg={3}>
            <TextField
              variant="outlined"
              label="Google Tag Manager"
              error={!!errors.google_tag_manager}
              helperText={errors.google_tag_manager?.message}
              {...register('google_tag_manager')}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={4} lg={3}>
            <TextField
              variant="outlined"
              label="Pixel TikTok"
              error={!!errors.tiktok_pixel}
              helperText={errors.tiktok_pixel?.message}
              {...register('tiktok_pixel')}
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <FormControl component="fieldset" variant="outlined">
            <FormLabel component="legend">Validações</FormLabel>
            <FormGroup sx={{ display: 'flex', flexDirection: 'row' }}>
              <Controller
                name="oculto"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox {...field} checked={!!field.value} />}
                    label="Privado (remover da página principal e buscas)"
                  />
                )}
              />

              <Controller
                name="pode_transferir"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox {...field} checked={!!field.value} />}
                    label="Pode transferir ingressos"
                  />
                )}
              />
            </FormGroup>
          </FormControl>
        </Grid>
        {error && (
          <Grid item xs={12}>
            <Alert severity="error" sx={{ mt: 1 }} onClose={() => setError('')}>
              {error}
            </Alert>
          </Grid>
        )}
        {success && (
          <Grid item xs={12}>
            <Alert
              severity="success"
              sx={{ mt: 1 }}
              onClose={() => setSuccess('')}
            >
              {success}
            </Alert>
          </Grid>
        )}
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <LoadingButton
            loading={isLoading}
            loadingPosition="start"
            startIcon={<SaveOutlinedIcon />}
            variant="contained"
            type="submit"
            sx={{ mt: 3, mb: 2 }}
          >
            Cadastrar evento
          </LoadingButton>
        </Grid>
      </Grid>
    </Box>
  )
}
