import { zodResolver } from '@hookform/resolvers/zod'
import CancelIcon from '@mui/icons-material/Cancel'
import { LoadingButton } from '@mui/lab'
import { Alert, AlertTitle, Grid, Paper, Skeleton } from '@mui/material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import { Typography } from 'components/Typography'
import { usePromoter } from 'contexts/promoterContext'
import dayjs from 'dayjs'
import { SaqueDTO } from 'dto/SaqueDTO'
import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { apiV1 } from 'services'
import { getErrorMessage } from 'utils/AppError'
import { moneyFormatter } from 'utils/formatter'
import { z } from 'zod'

const schema = z.object({
  observacoes: z.string(),
})

type WithdrawalSchemaFormType = z.infer<typeof schema>

export function Withdrawal() {
  const [isLoading, setIsLoading] = useState(false)
  const [isRequesting, setIsRequesting] = useState(false)
  const [withdrawal, setWithdrawal] = useState<SaqueDTO>({} as SaqueDTO)

  const { addErrorMessage } = usePromoter()
  const { eventId, withdrawalId } = useParams()

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<WithdrawalSchemaFormType>({
    resolver: zodResolver(schema),
    defaultValues: { observacoes: 'Cancelado pelo solicitante.' },
  })

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true)
      const { data } = await apiV1.producer.withdrawalService.get(
        Number(eventId),
        Number(withdrawalId),
      )
      setWithdrawal(data.withdrawal)
    } catch (error) {
      addErrorMessage(getErrorMessage(error))
    } finally {
      setIsLoading(false)
    }
  }, [addErrorMessage, eventId, withdrawalId])

  const handleCancel = useCallback(async () => {
    if (isSubmitting) {
      return
    }
    try {
      setIsRequesting(true)
      const { data } = await apiV1.producer.withdrawalService.cancel(
        Number(eventId),
        Number(withdrawalId),
      )
      setWithdrawal(data)
      fetchData()
    } catch (error) {
      addErrorMessage(getErrorMessage(error))
    } finally {
      setIsRequesting(false)
    }
  }, [addErrorMessage, eventId, fetchData, isSubmitting, withdrawalId])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  if (isLoading) return <PageLoading />

  return (
    <Grid container spacing={2} component={Paper} p={2}>
      <Grid container spacing={2} item xs={12} sm={6}>
        <Grid item xs={12}>
          <Typography variant="h5">Detalhes da solicitação</Typography>
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table size="small" aria-label="detalhes da solicitação">
              <TableBody>
                <TableRow>
                  <TableCell>Evento</TableCell>
                  <TableCell>
                    {withdrawal.evento?.id} - {withdrawal.evento?.titulo}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Data do fim do evento</TableCell>
                  <TableCell>
                    {dayjs(withdrawal.evento?.data_final).format(
                      'DD/MM/YYYY [às] HH:mm',
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Nome do beneficiário</TableCell>
                  <TableCell>{withdrawal.beneficiario}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Tipo da chave</TableCell>
                  <TableCell>{withdrawal.tipo_chave}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Chave Pix</TableCell>
                  <TableCell>{withdrawal.chave}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Banco</TableCell>
                  <TableCell>{withdrawal.banco}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Valor solicitado</TableCell>
                  <TableCell>
                    {moneyFormatter.format(withdrawal.valor)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        {withdrawal.cod_saque_status !== 1 && (
          <Grid item xs={12}>
            <Alert
              severity={withdrawal.cod_saque_status === 2 ? 'success' : 'error'}
            >
              <AlertTitle>{withdrawal.saque_status?.titulo}</AlertTitle>
              {withdrawal.observacoes}
            </Alert>
          </Grid>
        )}

        {withdrawal.cod_saque_status === 1 && (
          <Grid item xs={12} display="flex" justifyContent="flex-end">
            <LoadingButton
              loading={isRequesting}
              loadingPosition="start"
              startIcon={<CancelIcon />}
              onClick={handleSubmit(handleCancel)}
              variant="contained"
              color="error"
              sx={{ mr: 2 }}
            >
              Cancelar solicitação
            </LoadingButton>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

function PageLoading() {
  return (
    <Grid item xs={12}>
      <Skeleton height={20} />
      <Skeleton />
      <Skeleton />
      <Skeleton />
      <Skeleton />
    </Grid>
  )
}
