/* eslint-disable camelcase */
import { zodResolver } from '@hookform/resolvers/zod'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import { LoadingButton } from '@mui/lab'
import {
  Alert,
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  Skeleton,
  Stack,
  TextField,
} from '@mui/material'
import { Typography } from 'components/Typography'
import { useAdmin } from 'contexts/adminContext'
import { useCallback, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import clientAdmin from 'services/api/v1/admin/client'
import { IPromoter } from 'types/promoter'
import { getErrorMessage } from 'utils/AppError'
import { z } from 'zod'

const schema = z.object({
  razao_social: z.string({ required_error: 'Informe o nome da produtora' }),
  email: z
    .string({ required_error: 'Informe o nome da produtora' })
    .email('Informe um e-mail válido'),
  telefone: z.string().optional(),
  exibir_na_home: z.boolean(),
})

export function Producer() {
  const [isLoading, setIsLoading] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)
  const [success, setSuccess] = useState('')
  const [, setProducer] = useState<IPromoter>({} as IPromoter)

  // const [selectedFile, setSelectedFile] = useState<File>();
  // const [imgSrc, setImgSrc] = useState("");
  // const imgRef = useRef<HTMLImageElement>(null);

  const { producerId } = useParams()
  const { addErrorMessage } = useAdmin()

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isDirty },
    reset,
  } = useForm<IPromoter>({
    resolver: zodResolver(schema),
  })

  const onSubmit = useCallback(
    async ({
      razao_social,
      email,
      telefone,
      cnpj,
      exibir_na_home,
    }: IPromoter) => {
      if (isSubmitting) {
        return
      }
      try {
        setIsUpdating(true)
        const { data } = await clientAdmin.patch(`promoters/${producerId}`, {
          razao_social,
          email,
          telefone,
          cnpj,
          exibir_na_home,
        })
        setProducer(data.promoter)
        reset(data.promoter)
      } catch (error) {
        addErrorMessage(getErrorMessage(error))
      } finally {
        setIsUpdating(false)
      }
    },
    [addErrorMessage, isSubmitting, producerId, reset],
  )

  // function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
  //   if (e.target.files && e.target.files.length > 0) {
  //     const reader = new FileReader();
  //     reader.addEventListener("load", () =>
  //       setImgSrc(reader.result?.toString() || "")
  //     );
  //     reader.readAsDataURL(e.target.files[0]);
  //     setSelectedFile(e.target.files[0]);
  //   }
  // }

  useEffect(() => {
    async function main() {
      try {
        setIsLoading(true)
        const { data } = await clientAdmin.get(`promoters/${producerId}`)
        setProducer(data.promoter)
        reset(data.promoter)
      } catch (error) {
        addErrorMessage(getErrorMessage(error))
      } finally {
        setIsLoading(false)
      }
    }
    main()
  }, [addErrorMessage, producerId, reset])

  if (isLoading) return <PageLoading />

  return (
    <Grid
      container
      spacing={2}
      component={Paper}
      m={2}
      p={2}
      mt={6}
      maxWidth="md"
    >
      <Grid item xs={12}>
        <Typography variant="h6">Informações da produtora</Typography>
      </Grid>

      <Grid item xs={12}>
        <TextField
          label="Nome da produtora"
          error={!!errors.razao_social}
          helperText={errors.razao_social?.message}
          fullWidth
          required
          {...register('razao_social')}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label="E-mail"
          error={!!errors.email}
          helperText={errors.email?.message}
          fullWidth
          required
          {...register('email')}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label="Telefone para contato"
          error={!!errors.telefone}
          helperText={errors.telefone?.message}
          fullWidth
          required
          {...register('telefone')}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="CNPJ se existir"
          error={!!errors.cnpj}
          helperText={errors.cnpj?.message}
          fullWidth
          {...register('cnpj')}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="exibir_na_home"
          control={control}
          render={({ field }) => (
            <FormControlLabel
              control={<Checkbox {...field} checked={!!field.value} />}
              label="Exibir automático eventos na home"
            />
          )}
        />
      </Grid>

      {/* <Grid item xs={12} sm={6}>
            <TextField
              label="Imagem do evento"
              contentEditable={false}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                startAdornment: (
                  <Grid container spacing={2} mt={1} pr={2}>
                    <Grid item xs={12} sm={6}>
                      <img
                        ref={imgRef}
                        alt="Selecione a imagem"
                        src={
                          imgSrc ? imgSrc : constants.URL_FTP + watch("imagem")
                        }
                        style={{ width: "100%", aspectRatio: "1/1" }}
                      />
                      <input
                        hidden
                        accept="image/*"
                        type="file"
                        required
                        {...register("imagem")}
                        onChange={onSelectFile}
                      />
                    </Grid>
                  </Grid>
                ),
                inputProps: {
                  style: { display: "none" },
                },
              }}
              error={!!errors.imagem}
              helperText={errors.imagem?.message}
              required
              fullWidth
            />
          </Grid> */}
      {success && (
        <Grid item xs={12}>
          <Alert severity="success" onClose={() => setSuccess('')}>
            {success}
          </Alert>
        </Grid>
      )}
      <Grid item xs={12}>
        <Stack direction="row" justifyContent="flex-end">
          <LoadingButton
            loading={isUpdating}
            loadingPosition="start"
            startIcon={<SaveOutlinedIcon />}
            variant="outlined"
            onClick={handleSubmit(onSubmit)}
            disabled={!isDirty}
          >
            Atualizar
          </LoadingButton>
        </Stack>
      </Grid>
    </Grid>
  )
}

function PageLoading() {
  return (
    <Grid item xs={12}>
      <Skeleton />
      <Skeleton />
      <Skeleton />
    </Grid>
  )
}
