/* eslint-disable camelcase */
import { Search } from '@mui/icons-material'
import {
  Box,
  Button,
  Card,
  Grid,
  InputAdornment,
  Skeleton,
  TextField,
} from '@mui/material'
import { DataGrid, GridColDef, GridRowsProp } from '@mui/x-data-grid'
import filter from 'lodash/filter'
import * as React from 'react'
import { Link } from 'react-router-dom'
import { apiV1 } from 'services'
import { ISuspectedOrder } from 'types/admin'
import { datagrid, functionWithLoading } from 'utils'

export default function PedidosSuspeitos() {
  const [eventId, setEventId] = React.useState(872)
  const [suspectedOrders, setSuspectedOrders] = React.useState<
    ISuspectedOrder[]
  >([])
  const [fullData, setFullData] = React.useState<ISuspectedOrder[]>()
  const [loading, setLoading] = React.useState(false)
  const [searchQuery, setSearchQuery] = React.useState('')

  function loadOrders() {
    if (eventId)
      functionWithLoading(
        apiV1.admin.orderService.suspectedOrders(eventId).then((res) => {
          setSuspectedOrders(res.data)
          setFullData(res.data)
        }),
        setLoading,
      )
  }

  const onChangeSearch = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setSearchQuery(e.target.value),
    [],
  )

  const contains = React.useCallback(
    ({ usuario_nome }: ISuspectedOrder, queryText: string) => {
      if (usuario_nome.toLocaleLowerCase().includes(queryText)) {
        return true
      }
      return false
    },
    [],
  )

  React.useEffect(() => {
    const formattedQuery = searchQuery.toLowerCase()
    const filteredData = filter(fullData, (order) => {
      return contains(order, formattedQuery)
    })
    setSuspectedOrders(filteredData)
  }, [contains, fullData, searchQuery])

  const rows: GridRowsProp =
    suspectedOrders.map((x) => ({
      ...x,
    })) ?? []

  const columns: GridColDef[] = [
    { field: 'pedido_codigo', headerName: 'Código pedido', flex: 1 },
    { field: 'pedido_data', headerName: 'Data compra', flex: 1 },
    { field: 'transferido', headerName: 'Ingresso transferido', flex: 1 },
    { field: 'usuario_nome', headerName: 'Comprador', flex: 1 },
    { field: 'usuario_telefone', headerName: 'Telefone', flex: 1 },
    {
      field: 'actions',
      headerName: 'Ações',
      renderCell: (values) => {
        return (
          <Link to={`/admin/order-details/${values.row.pedido_codigo}`}>
            Ver pedido
          </Link>
        )
      },
    },
  ]

  return (
    <Card sx={{ display: 'flex', flexDirection: 'column' }}>
      <Grid container spacing={2} mt={2}>
        <Grid item xs={12} sm={9}>
          <TextField
            label="ID evento"
            variant="outlined"
            fullWidth
            onChange={(e) => setEventId(Number(e.target.value))}
            value={eventId}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Button variant="contained" onClick={loadOrders}>
            Carregar
          </Button>
        </Grid>
      </Grid>

      <Box
        justifyContent="center"
        alignContent="center"
        sx={{
          p: 2,
          display: 'flex',
        }}
      >
        <TextField
          label="Pesquisar"
          variant="outlined"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          onChange={onChangeSearch}
          value={searchQuery}
        />
      </Box>
      <Box sx={{ p: 2, flex: 1 }}>
        {loading ? (
          <Grid container spacing={2}>
            <Grid item sm={12}>
              <Skeleton variant="rectangular" height={400} />
            </Grid>
          </Grid>
        ) : (
          <DataGrid
            rows={rows}
            columns={columns}
            autoHeight
            getRowId={(row) => row.pedido_codigo}
            localeText={datagrid.localizedTextsMap}
          />
        )}
      </Box>
    </Card>
  )
}
