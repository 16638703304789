import { IEvento } from 'types/evento'
import clientProducer from './clientProducer'

const getDetails = (eventId: number) => {
  return clientProducer.get(`events/${eventId}/details`)
}

const get = (eventId: number) => {
  return clientProducer.get<IEvento>(`events/${eventId}`)
}

const create = (event: IEvento) => {
  return clientProducer.post<IEvento>(`events`, { event })
}

const update = (eventId: number, event: IEvento) => {
  return clientProducer.patch(`events/${eventId}`, { event })
}

const salesResume = (eventId: number) => {
  return clientProducer.get(`events/${eventId}/sales-resume`)
}

const getTransactions = (eventId: string) => {
  return clientProducer.get(`events/${eventId}/transactions`)
}

const physicalSalesDetails = (eventId: number) => {
  return clientProducer.get(`events/${eventId}/physical-sales-details`)
}

const eventService = {
  get,
  create,
  update,
  getDetails,
  salesResume,
  getTransactions,
  physicalSalesDetails,
}

export default eventService
