import { createTheme, CssBaseline, ThemeProvider } from '@mui/material'
import ReactDOM from 'react-dom/client'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import PartnerRouter from 'routers/partnerRouter'
import './index.css'
import reportWebVitals from './reportWebVitals'
import { AdminRouter, PromoterRouter, UserRouter } from './routers'

import moment from 'moment'
import 'moment/locale/pt-br'

import { constants } from 'config'
import dayjs from 'dayjs'
import ptBr from 'dayjs/locale/pt-br'
import { Maintenance } from 'pages/user/Maintenance'
moment.locale('pt-br')
dayjs.locale(ptBr)

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLDivElement,
)

const theme = createTheme({
  palette: {
    primary: {
      main: '#24292f',
    },
  },
  typography: {
    allVariants: {
      fontFamily: "'Montserrat', sans-serif",
      textTransform: 'none',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Montserrat';
        }
      `,
    },
  },
})

root.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <BrowserRouter>
      {constants.SHOW_MAINTENANCE ? (
        <Routes>
          <Route path="*" element={<Maintenance />} />
        </Routes>
      ) : (
        <Routes>
          <Route path="admin/*" element={<AdminRouter />} />
          <Route path="organizador/*" element={<PromoterRouter />} />
          <Route path="parceiro/*" element={<PartnerRouter />} />
          <Route path="*" element={<UserRouter />} />
        </Routes>
      )}
    </BrowserRouter>
  </ThemeProvider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
