import { Box, Button, Typography, Paper } from '@mui/material'
import { Loading } from 'components'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { apiV1 } from 'services'
import { functionWithLoading } from 'utils'
import MarkEmailReadOutlinedIcon from '@mui/icons-material/MarkEmailReadOutlined'
import { AxiosError } from 'axios'

const EmailConfirmation: React.FC = () => {
  const { userId, token } = useParams()
  const [loading, setLoading] = useState(true)
  const [success, setSuccess] = useState(true)
  const [message, setMessage] = useState('')

  useEffect(() => {
    functionWithLoading(
      apiV1.userService
        .emailConfirmation(userId, token)
        .then(({ data }) => {
          if (data.isValidEmail) {
            setSuccess(true)
            setMessage('E-mail verificado com sucesso!')
          } else {
            setMessage(data.message)
          }
        })
        .catch((err: AxiosError) => {
          setMessage(
            err.response?.data.message ?? 'Ocorreu um erro, tente novamente!',
          )
        }),
      setLoading,
    )
  }, [token, userId])

  if (loading) return <Loading />

  return (
    <Box
      component={Paper}
      sx={{
        m: 2,
        p: 2,
        textAlign: 'center',
      }}
    >
      <MarkEmailReadOutlinedIcon sx={{ width: 100, height: 100 }} />
      <Typography variant="h4">{message}</Typography>
      {success && (
        <Button
          variant="outlined"
          href="https://zedoingresso.com.br/login"
          sx={{ mt: 2 }}
        >
          Clique aqui para entrar
        </Button>
      )}
    </Box>
  )
}

export default EmailConfirmation
