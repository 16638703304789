/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
/* eslint-disable react/jsx-no-duplicate-props */
import { Box, Button, Container, Grid, Typography } from '@mui/material'
import { Loading } from 'components'
import CountdownTimer from 'components/CountdownTimer'
import { constants } from 'config'
import dayjs from 'dayjs'
import moment from 'moment'
import Image from 'mui-image'
import { useCallback, useEffect, useState } from 'react'
import ReactPixel from 'react-facebook-pixel'
import ReactGA from 'react-ga'
import TagManager from 'react-gtm-module'
import { apiV1 } from 'services'
import TiktokPixel from 'tiktok-pixel'
import { IEvento } from 'types/evento'
import { functionWithLoading } from 'utils'
import ShoppingCart from './EventPage/ShoppingCart'

import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
}

const lineup = [
  'https://images.zedoingresso.com.br/uploads/armazemfest/atracoes/01.png',
  'https://images.zedoingresso.com.br/uploads/armazemfest/atracoes/02.png',
  'https://images.zedoingresso.com.br/uploads/armazemfest/atracoes/03.png',
  'https://images.zedoingresso.com.br/uploads/armazemfest/atracoes/04.png',
  'https://images.zedoingresso.com.br/uploads/armazemfest/atracoes/05.png',
  'https://images.zedoingresso.com.br/uploads/armazemfest/atracoes/06.png',
  'https://images.zedoingresso.com.br/uploads/armazemfest/atracoes/07.png',
  'https://images.zedoingresso.com.br/uploads/armazemfest/atracoes/08.png',
]

const openBar = [
  'https://images.zedoingresso.com.br/uploads/armazemfest/openbar/01.png',
  'https://images.zedoingresso.com.br/uploads/armazemfest/openbar/02.png',
  'https://images.zedoingresso.com.br/uploads/armazemfest/openbar/03.png',
  'https://images.zedoingresso.com.br/uploads/armazemfest/openbar/04.png',
  'https://images.zedoingresso.com.br/uploads/armazemfest/openbar/05.png',
  'https://images.zedoingresso.com.br/uploads/armazemfest/openbar/06.png',
]

export function ArmazemFest() {
  const [event, setEvent] = useState<IEvento>()
  const [loading, setLoading] = useState(true)

  const facebook_pixel = event?.facebook_pixel
  const google_analytics = event?.google_analytics
  const google_tag_manager = event?.google_tag_manager
  const tiktok_pixel = event?.tiktok_pixel

  const handleGoToBuy = (event: any) => {
    const anchor = (
      (event.target as HTMLButtonElement).ownerDocument || document
    ).querySelector('#go-to-buy')

    if (anchor) {
      anchor.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
    }
  }

  const loadEvent = useCallback(
    () =>
      functionWithLoading(
        apiV1.eventService
          .getForSite(1238, window.location.search)
          .then((res) => setEvent(res.data)),
        setLoading,
      ),
    [],
  )

  useEffect(() => {
    loadEvent()
    if (facebook_pixel) {
      const options = {
        autoConfig: true,
        debug: true,
      }
      ReactPixel.grantConsent()
      ReactPixel.init(facebook_pixel, undefined, options)
      ReactPixel.pageView()
    }
    if (google_analytics) {
      ReactGA.initialize(google_analytics)
      ReactGA.pageview(window.location.pathname + window.location.search)
    }
    if (google_tag_manager) {
      TagManager.initialize({
        gtmId: google_tag_manager,
      })
    }
    if (tiktok_pixel) {
      TiktokPixel.init(tiktok_pixel)
      TiktokPixel.pageView()
    }
  }, [
    facebook_pixel,
    google_analytics,
    google_tag_manager,
    loadEvent,
    tiktok_pixel,
  ])

  if (!event || loading) {
    return <Loading />
  }

  return (
    <Box display="flex" flexDirection="column" justifyContent="center">
      <Box
        style={{
          backgroundImage: `url("${constants.URL_FTP}${event.imagem}")`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPositionX: 'center',
          backgroundPositionY: 'bottom',
          backgroundColor: '#000000',
          filter: 'drop-shadow(0px 0px 0px black) blur(8px)',
          position: 'fixed',
          inset: '0px -30px -30px -30px',
        }}
      />
      <Container maxWidth="md" sx={{ zIndex: 9999 }}>
        <Grid container spacing={2} mt={1} mb={3}>
          {dayjs().isBefore(event.data_entrar) && (
            <Grid item xs={12}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                style={{
                  backgroundColor: '#D9D9D9',
                  minHeight: 55,
                  padding: 8,
                }}
              >
                <Typography
                  variant="subtitle1"
                  textAlign="center"
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  flexWrap="wrap"
                  justifyContent="center"
                >
                  FALTAM{' '}
                  <CountdownTimer
                    targetDate={moment(event.data_entrar)
                      .add(3, 'hours')
                      .toDate()
                      .getTime()}
                    callback={loadEvent}
                  />{' '}
                  PARA O INÍCIO DAS VENDAS
                </Typography>
              </Box>
            </Grid>
          )}

          <Grid item xs={12}>
            <Box
              style={{
                aspectRatio: '16/9',
              }}
            >
              <Image src={constants.URL_FTP + event.imagem} alt="banner" />
              <Box
                py={2}
                px={4}
                style={{
                  backgroundColor: '#EAEAEA',
                  borderBottomLeftRadius: 8,
                  borderBottomRightRadius: 8,
                }}
              >
                <Typography variant="subtitle1">{event.titulo}</Typography>
                <Typography variant="subtitle1">
                  Data:{' '}
                  {dayjs(event.data)
                    .add(3, 'hours')
                    .format('DD [de] MMM [de] YYYY [às] HH:mm')}
                </Typography>
                <Typography variant="subtitle1">
                  Local: {event.local_evento} - {event.brasil_cidade?.cidade} -{' '}
                  {event.brasil_cidade?.uf}
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="contained"
              fullWidth
              onClick={handleGoToBuy}
              style={{ height: 56 }}
            >
              COMPRAR AGORA
            </Button>
          </Grid>

          <Grid item xs={12}>
            <Box
              py={2}
              px={4}
              style={{
                backgroundColor: '#EAEAEA',
              }}
              dangerouslySetInnerHTML={{
                __html: event?.descricao ?? '',
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Box
              py={2}
              px={4}
              display="flex"
              alignItems="center"
              justifyContent="center"
              style={{
                backgroundColor: '#EAEAEA',
              }}
            >
              <Typography
                variant="subtitle2"
                fontWeight="bold"
                textAlign="center"
              >
                ATRAÇÕES CONFIRMADAS
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} mb={2}>
            <Carousel
              responsive={responsive}
              showDots
              autoPlay
              autoPlaySpeed={3000}
              infinite
            >
              {lineup.map((item) => (
                <Box key={item}>
                  <Image
                    src={item}
                    alt="lineup"
                    style={{
                      paddingLeft: 8,
                      paddingRight: 8,
                    }}
                  />
                </Box>
              ))}
            </Carousel>
          </Grid>

          <Grid item xs={12}>
            <Box
              py={2}
              px={4}
              display="flex"
              alignItems="center"
              justifyContent="center"
              style={{
                backgroundColor: '#EAEAEA',
              }}
            >
              <Typography
                variant="subtitle2"
                fontWeight="bold"
                textAlign="center"
              >
                OPEN BAR DIVERSIFICADO
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} mb={2}>
            <Carousel
              responsive={responsive}
              showDots
              autoPlay
              autoPlaySpeed={3000}
              infinite
            >
              {openBar.map((item) => (
                <Box key={item}>
                  <Image
                    src={item}
                    alt="openBar"
                    style={{
                      paddingLeft: 8,
                      paddingRight: 8,
                    }}
                  />
                </Box>
              ))}
            </Carousel>
          </Grid>

          {!!event.imagem_mapa && (
            <>
              <Grid item xs={12}>
                <Box
                  py={2}
                  px={4}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  style={{
                    backgroundColor: '#EAEAEA',
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    fontWeight="bold"
                    textAlign="center"
                  >
                    MAPA DO EVENTO
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Image src={constants.URL_FTP + event.imagem_mapa} alt="mapa" />
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            <Box
              py={2}
              px={4}
              display="flex"
              alignItems="center"
              justifyContent="center"
              style={{
                backgroundColor: '#EAEAEA',
              }}
            >
              <Typography
                variant="subtitle2"
                fontWeight="bold"
                textAlign="center"
              >
                COMPRE SEU INGRESSO AGORA
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} id="go-to-buy">
            <ShoppingCart event={event} refreshEvent={loadEvent} />
          </Grid>

          <Grid item xs={12}>
            <Box
              py={2}
              px={4}
              style={{
                backgroundColor: '#EAEAEA',
              }}
            >
              <p>
                <strong>INFORMA&Ccedil;&Otilde;ES IMPORTANTES:</strong>
              </p>

              <p>
                <strong>
                  🔴 N&Atilde;O SER&Aacute; PERMITIDO A ENTRADA DE PESSOAS
                  PORTANDO QUALQUER TIPO DE DROGA.
                </strong>
              </p>

              <p>
                <strong>
                  🔴 &Eacute; OBRIGAT&Oacute;RIO A APRESENTA&Ccedil;&Atilde;O DO
                  INGRESSO COM QR CODE JUNTAMENTE COM UM DOCUMENTO DE IDENTIDADE
                  COM FOTO (RG OU CNH).
                </strong>
              </p>

              <p>
                <strong>
                  🔴 O ROL&Ecirc; &Eacute; MOMENTO DE DIVERS&Atilde;O
                  ENT&Atilde;O BEBA COM MODERA&Ccedil;&Atilde;O E RESPEITE O
                  ESPA&Ccedil;O DAS PESSOAS!
                </strong>
              </p>

              <p>
                <strong>
                  🔴 N&Atilde;O DIRIJA SE BEBER E LEMBRE-SE QUE PESSOAS
                  IMPORTANTES ESPERAM O SEU RETORNO PRA CASA.
                </strong>
              </p>

              <p>
                <strong>
                  🟢 O EVENTO CONTA COM UMA EQUIPE DE SEGURAN&Ccedil;A E
                  BOMBEIROS QUE VOC&Ecirc; PODE SOLICITAR AJUDA SE PRECISAR.
                </strong>
              </p>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box
              py={2}
              px={4}
              style={{
                backgroundColor: '#EAEAEA',
              }}
            >
              <Typography variant="subtitle1" textAlign="center">
                {event.promoter?.razao_social} - {event.promoter?.telefone}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}
