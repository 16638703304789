import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import Save from '@mui/icons-material/Save'
import Security from '@mui/icons-material/Security'
import { LoadingButton } from '@mui/lab'
import {
  Alert,
  Box,
  Breadcrumbs,
  Button,
  Collapse,
  Grid,
  Link,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import TextField from 'components/FormItem/TextField'
import PermissionListItem from 'components/PermissionListItem'
import { usePromoter } from 'contexts/promoterContext'
import moment from 'moment'
import * as React from 'react'
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom'
import {
  Area,
  AreaChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { apiV1 } from 'services'
import { IUsuario, IngressoReport } from 'types/usuario'
import { functionWithLoading } from 'utils'
import { moneyFormatter } from 'utils/formatter'
import { getDaysArray } from 'utils/functions'

type StaffType = {
  usuario: IUsuario
  _vendas: {
    ingressos: IngressoReport[]
    total: {
      quantidade_app: number
      valor_app: number
      quantidade_site: number
      valor_site: number
      valor_fisico: number
      quantidade_fisico: number
      valor_total: number
      valor: number
    }
  }
  _vendas_por_dia: [
    {
      dia: string
      quantidade_ingressos: number
    },
  ]
  _vendas_por_forma_de_pagamento_app: [
    {
      forma_pagamento: string
      total: number
    },
  ]
  _vendas_por_forma_de_pagamento_pdv: [
    {
      forma_pagamento: string
      total: number
    },
  ]
}

export default function EventStaffDetails() {
  const { eventId, userStaffId } = useParams()
  const [staffUser, setStaffUser] = React.useState<StaffType>()
  const [loadingData, setLoadingData] = React.useState(true)
  const [allowedAmount, setAllowedAmount] = React.useState('')
  const [error, setError] = React.useState('')
  const [userPermissions, setUserPermissions] = React.useState<number[]>([])
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [days, setDays] = React.useState<any>()
  const [open, setOpen] = React.useState(false)

  const handleClick = () => {
    setOpen(!open)
  }

  const { selectedEvent, promoter } = usePromoter()
  const navigate = useNavigate()

  const permissions = [
    { id: 1, descricao: 'Vender por link' },
    { id: 2, descricao: 'Produtor' },
    { id: 3, descricao: 'Vender ingressos via APP' },
    { id: 4, descricao: 'Validar ingressos' },
    { id: 5, descricao: 'Ver relatórios de vendas' },
    { id: 6, descricao: 'Check-in manual' },
    { id: 7, descricao: 'Parceiro' },
    { id: 10, descricao: 'POS' },
  ]

  const loadEvent = React.useCallback(() => {
    functionWithLoading(
      apiV1.eventService
        .getStaffDetails(Number(eventId), Number(userStaffId))
        .then(({ data }) => {
          setStaffUser(data)
          setUserPermissions(
            data.usuario.usuario_permissao_evento.map(
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (x: { cod_permissao: any }) => x.cod_permissao,
            ),
          )
          setAllowedAmount(
            data.usuario.usuario_permissao_evento.find(
              (x: { cod_permissao: number }) => x.cod_permissao === 3,
            )?.quantidade ?? '',
          )
        })
        .catch((err) => setError(err.response?.data.message ?? err.message)),
      setLoadingData,
    )
  }, [eventId, userStaffId])

  const handleChangePermission = React.useCallback(
    (permissionId: number, newValue: boolean) => {
      if (newValue) setUserPermissions((x) => [...x, permissionId])
      else setUserPermissions((x) => x.filter((y) => y !== permissionId))
    },
    [],
  )

  React.useEffect(() => {
    loadEvent()
  }, [loadEvent])

  React.useEffect(() => {
    if (selectedEvent) {
      if (staffUser) {
        const rangeDates = getDaysArray(
          new Date(selectedEvent.data_entrar),
          new Date(selectedEvent.data_encerrar_vendas) > moment().toDate()
            ? moment().toDate()
            : new Date(selectedEvent.data_encerrar_vendas),
        ).map((v) => ({
          dia: [moment(v).format('DD/MM/YYYY')],
          quantidade_ingressos:
            staffUser._vendas_por_dia.find(
              (x) => x.dia === moment(v).format('YYYY-MM-DD'),
            )?.quantidade_ingressos ?? 0,
        }))
        setDays(rangeDates)
      }
    }
  }, [selectedEvent, staffUser])

  const handleSaveAllowedAmount = React.useCallback(() => {
    functionWithLoading(
      apiV1.eventService
        .saveAllowedAmount(Number(eventId), Number(userStaffId), allowedAmount)
        .then()
        .catch((err) => setError(err.response?.data.message ?? err.message)),
      setLoadingData,
    )
  }, [allowedAmount, eventId, userStaffId])

  const showPermissions = React.useMemo(() => {
    if (userStaffId) {
      if ([11, 2397].includes(Number(userStaffId))) {
        return true
      }
      if (promoter.id === Number(userStaffId)) {
        return false
      }
      if (selectedEvent?.promoter.responsavelId !== Number(userStaffId)) {
        return true
      }
    }
    return false
  }, [promoter.id, selectedEvent?.promoter.responsavelId, userStaffId])

  if (loadingData) {
    ;<Grid container spacing={2} sx={{ mt: 2 }}>
      <Grid item sm={12} md={4}>
        <Skeleton variant="rectangular" height={100} />
      </Grid>
      <Grid item sm={12} md={8}>
        <Skeleton variant="rectangular" height={100} />
      </Grid>
      <Grid item sm={12}>
        <Skeleton variant="rectangular" height={200} />
      </Grid>
      <Grid item sm={12}>
        <Skeleton variant="rectangular" height={300} />
      </Grid>
    </Grid>
  }

  if (!staffUser) return null

  return (
    <>
      <Grid container spacing={2} gap={2} sx={{ p: 2 }} component={Paper}>
        <Grid item xs={12}>
          <Box role="presentation">
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                component={RouterLink}
                underline="hover"
                color="inherit"
                to="/organizador/eventos"
              >
                Eventos
              </Link>
              <Link
                component={RouterLink}
                underline="hover"
                color="inherit"
                to={`/organizador/eventos/${selectedEvent?.id}`}
              >
                {selectedEvent?.titulo}
              </Link>
              <Link
                component={RouterLink}
                underline="hover"
                color="inherit"
                to={`/organizador/eventos/${selectedEvent?.id}/staff`}
              >
                Staff
              </Link>
              <Typography color="text.primary">Detalhes</Typography>
            </Breadcrumbs>
          </Box>
        </Grid>
        {error && (
          <Grid item xs={12}>
            <Alert severity="error" onClose={() => setError('')}>
              {error}
            </Alert>
          </Grid>
        )}
        <Grid item sm={12} md={4}>
          <Typography variant="h6">
            {[staffUser.usuario.nome, staffUser.usuario.sobrenome].join(' ')}
          </Typography>
          <Typography variant="body1">{staffUser.usuario.email}</Typography>
          <Typography variant="body1">
            {staffUser.usuario.ddd} {staffUser.usuario.telefone}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mt: 2 }} component={Paper}>
        {showPermissions && (
          <Grid item xs={12}>
            <List>
              <ListItemButton onClick={handleClick}>
                <ListItemIcon>
                  <Security />
                </ListItemIcon>
                <ListItemText primary="Permissões" />
                {open ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div">
                  {permissions.map((permission) => (
                    <PermissionListItem
                      key={permission.id}
                      id={permission.id}
                      title={permission.descricao}
                      value={userPermissions.includes(permission.id)}
                      onChange={handleChangePermission}
                      eventId={Number(eventId)}
                      userId={Number(userStaffId)}
                    />
                  ))}
                </List>
              </Collapse>
            </List>
          </Grid>
        )}
      </Grid>

      {userPermissions.includes(3) && (
        <Grid container spacing={2} sx={{ mt: 2, p: 2 }} component={Paper}>
          <Grid item xs={12} md={8}>
            <TextField
              type="number"
              label="Quantidade de ingressos disponíveis para venda"
              value={allowedAmount}
              onChange={(e) => setAllowedAmount(e.target.value)}
              helperText="Deixar em branco para não validar"
              size="small"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <LoadingButton
              loading={loadingData}
              loadingPosition="start"
              startIcon={<Save />}
              variant="contained"
              onClick={handleSaveAllowedAmount}
            >
              Salvar
            </LoadingButton>
          </Grid>
        </Grid>
      )}

      <Grid
        container
        spacing={2}
        gap={2}
        sx={{ mt: 2, p: 2 }}
        component={Paper}
      >
        <Grid item xs={12}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6">Vendas</Typography>
            <Button
              variant="contained"
              onClick={() =>
                navigate(
                  `/organizador/eventos/${eventId}/staff/${userStaffId}/lista-vendas`,
                )
              }
            >
              Lista de vendas
            </Button>
          </Box>

          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Lote</TableCell>
                  <TableCell align="right">Valor</TableCell>
                  <TableCell align="right">Qtd. App</TableCell>
                  <TableCell align="right">Total App</TableCell>
                  <TableCell align="right">Qtd. Link</TableCell>
                  <TableCell align="right">Total Link</TableCell>
                  <TableCell align="right">Qtd. Físico</TableCell>
                  <TableCell align="right">Total Físico</TableCell>
                  <TableCell align="right">Qtd. Total Vendas</TableCell>
                  <TableCell align="right">Total Vendas</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {staffUser._vendas.ingressos?.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {row.titulo}
                    </TableCell>
                    <TableCell align="right">
                      {moneyFormatter.format(row.valor ?? 0)}
                    </TableCell>
                    <TableCell align="right">{row.quantidade_app}</TableCell>
                    <TableCell align="right">
                      {moneyFormatter.format(row.valor_app)}
                    </TableCell>
                    <TableCell align="right">{row.quantidade_site}</TableCell>
                    <TableCell align="right">
                      {moneyFormatter.format(row.valor_site)}
                    </TableCell>
                    <TableCell align="right">{row.quantidade_fisico}</TableCell>
                    <TableCell align="right">
                      {moneyFormatter.format(row.valor_fisico)}
                    </TableCell>
                    <TableCell align="right">
                      {Number(row.quantidade_app) +
                        Number(row.quantidade_site) +
                        Number(row.quantidade_fisico)}
                    </TableCell>
                    <TableCell align="right">
                      {moneyFormatter.format(
                        Number(row.valor_app) +
                          Number(row.valor_site) +
                          Number(row.valor_fisico),
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell>Total</TableCell>
                  <TableCell></TableCell>
                  <TableCell align="right">
                    {staffUser._vendas.total.quantidade_app}
                  </TableCell>
                  <TableCell align="right">
                    {moneyFormatter.format(staffUser._vendas.total.valor_app)}
                  </TableCell>
                  <TableCell align="right">
                    {staffUser._vendas.total.quantidade_site}
                  </TableCell>
                  <TableCell align="right">
                    {moneyFormatter.format(staffUser._vendas.total.valor_site)}
                  </TableCell>
                  <TableCell align="right">
                    {staffUser._vendas.total.quantidade_fisico}
                  </TableCell>
                  <TableCell align="right">
                    {moneyFormatter.format(
                      staffUser._vendas.total.valor_fisico,
                    )}
                  </TableCell>
                  <TableCell align="right">
                    {Number(staffUser._vendas.total.quantidade_app) +
                      Number(staffUser._vendas.total.quantidade_site) +
                      Number(staffUser._vendas.total.quantidade_fisico)}
                  </TableCell>
                  <TableCell align="right">
                    {moneyFormatter.format(
                      Number(staffUser._vendas.total.valor_app) +
                        Number(staffUser._vendas.total.valor_site) +
                        Number(staffUser._vendas.total.valor_fisico),
                    )}
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Grid>
        {staffUser._vendas_por_forma_de_pagamento_app.length > 0 && (
          <Grid item xs={12} mt={2}>
            <Typography variant="h6">
              Total de venda por forma de pagamento no APP
            </Typography>
            <TableContainer>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Forma de pagamento</TableCell>
                    <TableCell align="right">Valor</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {staffUser._vendas_por_forma_de_pagamento_app.map((row) => (
                    <TableRow
                      key={row.forma_pagamento}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {row.forma_pagamento}
                      </TableCell>
                      <TableCell align="right">
                        {moneyFormatter.format(row.total)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}

        {staffUser._vendas_por_forma_de_pagamento_pdv.length > 0 && (
          <Grid item xs={12}>
            <Typography variant="h6">
              Total de venda por forma de pagamento no PDV
            </Typography>
            <TableContainer>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Forma de pagamento</TableCell>
                    <TableCell align="right">Valor</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {staffUser._vendas_por_forma_de_pagamento_pdv.map((row) => (
                    <TableRow
                      key={row.forma_pagamento}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {row.forma_pagamento}
                      </TableCell>
                      <TableCell align="right">
                        {moneyFormatter.format(row.total)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}

        <Grid item xs={12}>
          <Typography variant="h6">
            Vendas durante o período de vendas
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ mb: 2, p: 2, height: 300 }}>
            <ResponsiveContainer width="100%" height="100%">
              <AreaChart height={300} data={days}>
                <defs>
                  <linearGradient id="colorDia" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                  </linearGradient>
                </defs>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="dia" />
                <YAxis dataKey="quantidade_ingressos" />
                <Tooltip />
                <Legend />
                <Area
                  type="monotone"
                  dataKey="quantidade_ingressos"
                  stroke="#8884d8"
                  fillOpacity={1}
                  fill="url(#colorDia)"
                />
              </AreaChart>
            </ResponsiveContainer>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}
