import {
  Alert,
  Backdrop,
  Box,
  Breadcrumbs,
  Button,
  Card,
  CircularProgress,
  Link,
  Typography,
} from '@mui/material'
import { Loading } from 'components'
import { constants } from 'config'
import React, { useEffect, useState } from 'react'
import { Link as RouterLink, useParams } from 'react-router-dom'
import { apiV1 } from 'services'
import { IEvento } from 'types/evento'
import { functionWithLoading } from 'utils'

const EventCounter = () => {
  const { eventId } = useParams()
  const [loadingCounter, setLoadingCounter] = React.useState(true)
  const [loadingEvent, setLoadingEvent] = React.useState(true)
  const [counter, setCounter] = useState(0)
  const [event, setEvent] = useState<IEvento>()
  const [errorMessage, setErrorMessage] = React.useState('')
  const [open, setOpen] = React.useState(true)
  const handleClose = () => {
    setOpen(false)
  }
  const handleToggle = () => {
    setOpen(!open)
  }

  const loadCounter = React.useCallback(() => {
    functionWithLoading(
      apiV1.eventService
        .getCounter(Number(eventId))
        .then(({ data }) => {
          setCounter(data)
        })
        .catch((err) =>
          setErrorMessage(err.response?.data.message ?? err.message),
        ),
      setLoadingCounter,
    )
  }, [eventId])

  useEffect(() => {
    functionWithLoading(
      apiV1.producer.eventService
        .get(Number(eventId))
        .then((res) => {
          setEvent(res.data)
        })
        .catch((err) =>
          setErrorMessage(err.response?.data.message ?? err.message),
        ),
      setLoadingEvent,
    )
  }, [eventId])

  useEffect(() => {
    loadCounter()
    const interval = setInterval(() => {
      loadCounter()
    }, 5000)

    return () => clearInterval(interval)
  }, [loadCounter])

  return (
    <Card sx={{ display: 'flex', flexDirection: 'column', p: 2 }}>
      <Box role="presentation">
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            component={RouterLink}
            underline="hover"
            color="inherit"
            to="/organizador/eventos"
          >
            Eventos
          </Link>
          <Link
            component={RouterLink}
            underline="hover"
            color="inherit"
            to={`/organizador/eventos/${event?.id}`}
          >
            {loadingEvent ? <Loading /> : event?.titulo}
          </Link>
          <Typography color="text.primary">Contador</Typography>
        </Breadcrumbs>
        {errorMessage && (
          <Alert
            severity="error"
            sx={{ mt: 1 }}
            onClose={() => setErrorMessage('')}
          >
            {errorMessage}
          </Alert>
        )}
      </Box>

      <Box m={2} p={2}>
        <Button variant="contained" onClick={handleToggle}>
          Abrir contador
        </Button>
      </Box>
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundImage: `linear-gradient(
            to right bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0,0.9)
            ), url("${constants.URL_FTP}${event?.imagem}")`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          display: 'flex',
          flexDirection: 'column',
          backdropFilter: 'blur(5px)',
        }}
        open={open}
        onClick={handleClose}
      >
        <Typography
          variant="h1"
          textAlign="center"
          fontSize="15rem"
          color="white"
        >
          {counter}
        </Typography>
        <Typography variant="h1" textAlign="center" color="white">
          Qtd. pessoas que entraram
        </Typography>
        {loadingCounter && (
          <Typography
            variant="h6"
            textAlign="center"
            sx={{ color: 'white', position: 'absolute', bottom: 10, right: 10 }}
          >
            Atualizando <CircularProgress color="secondary" />
          </Typography>
        )}
      </Backdrop>
    </Card>
  )
}

export default EventCounter
