import { Container, Paper } from '@mui/material'
import { Loading } from 'components'
import { Typography } from 'components/Typography'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { apiV1 } from 'services'

export function PaymentProcessPage() {
  const { orderId } = useParams()
  const navigate = useNavigate()
  const [count, setCount] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      if (orderId) {
        apiV1.orderService
          .verifyPayment(orderId)
          .then(({ data }) => {
            const { order } = data
            if (order.cod_status !== 0) {
              navigate('/payment-gateway-return/pagseguro/' + order.codigo, {
                state: { order },
              })
            }
          })
          .catch((err) => console.log(err))
        if (count > 20) {
          clearInterval(interval)
        }
        setCount((s) => s + 1)
      } else {
        clearInterval(interval)
      }
    }, 3000)

    return () => clearInterval(interval)
  }, [count, navigate, orderId])

  return (
    <Container maxWidth="sm" sx={{ py: 2, alignItems: 'center' }}>
      <Paper sx={{ p: 2, gap: 2 }}>
        <Loading />
        <Typography variant="subtitle1">
          Seu pagamento está sendo processado, esse processo poderá levar alguns
          minutos...
        </Typography>
        <Typography variant="subtitle1">Aguarde por favor.</Typography>
      </Paper>
    </Container>
  )
}
