/* eslint-disable @typescript-eslint/no-explicit-any */
import { Card } from '@mui/material'
import {
  CategoryScale,
  ChartData,
  Chart as ChartJS,
  ChartOptions,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js'
import dayjs from 'dayjs'
import { memo } from 'react'
import { Line } from 'react-chartjs-2'
import { moneyFormatter } from 'utils/formatter'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
)

export const options: ChartOptions<'line'> = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
      text: 'Vendas diárias por setor',
    },
    tooltip: {
      callbacks: {
        label(context) {
          const data = context.chart.data.datasets[context.datasetIndex].data[
            context.dataIndex
          ] as unknown as { x: string; y: number; day: any }
          const batches =
            data.day?.map(
              (batch: any) =>
                `${batch.lote} - Qtd: ${
                  batch.quantidade_ingressos
                } Valor: (${moneyFormatter.format(batch.valor_ingressos)})`,
            ) ?? []
          return [
            ...batches,
            '',
            `Qtd. total: ${
              data.day?.reduce(
                (total: number, item: any) =>
                  total + Number(item.quantidade_ingressos),
                0,
              ) ?? 0
            }`,
            `Valor total: ${moneyFormatter.format(
              data.day?.reduce(
                (total: number, item: any) =>
                  total + Number(item.valor_ingressos),
                0,
              ) ?? 0,
            )}`,
          ]
        },
      },
    },
  },
}

interface SalesByPeriodChartProps {
  sectors: any[]
  days: Date[]
}

const SalesByPeriodChart = ({ sectors, days }: SalesByPeriodChartProps) => {
  const data: ChartData<'line', { x: string; y: number; day: any }[]> = {
    labels: days.map((day) => dayjs(day).add(3, 'hours').format('DD-MM-YYYY')),
    datasets: [
      ...sectors.map((sector) => {
        const color = `${(Math.random() * 256) | 0}, ${
          (Math.random() * 256) | 0
        }, ${(Math.random() * 256) | 0}`
        return {
          fill: true,
          label: sector.setor,
          data: days.map((day) => {
            const dayToIndex = dayjs(day).add(3, 'hours').format('YYYY-MM-DD')
            const data = {
              x: dayjs(day).add(3, 'hours').format('DD-MM-YYYY'),
              y: Number(
                sector.days[dayToIndex]?.reduce(
                  (total: number, item: any) =>
                    total + Number(item.quantidade_ingressos),
                  0,
                ) ?? 0,
              ),
              day: sector.days[dayToIndex],
            }
            return data
          }),
          borderColor: `rgb(${color})`,
          backgroundColor: `rgb(${color}, 0.5)`,
        }
      }),
      {
        fill: true,
        label: 'TODOS',
        data: days.map((day) => {
          const dayToIndex = dayjs(day).add(3, 'hours').format('YYYY-MM-DD')
          const data = {
            x: dayjs(day).add(3, 'hours').format('DD-MM-YYYY'),
            y: Number(
              sectors.reduce(
                (all, sector) =>
                  all +
                  (sector.days[dayToIndex]?.reduce(
                    (total: number, item: any) =>
                      total + Number(item.quantidade_ingressos),
                    0,
                  ) ?? 0),
                0,
              ),
            ),
            day: [
              {
                lote: 'TODOS',
                quantidade_ingressos: sectors.reduce(
                  (all, sector) =>
                    all +
                    (sector.days[dayToIndex]?.reduce(
                      (total: number, item: any) =>
                        total + Number(item.quantidade_ingressos),
                      0,
                    ) ?? 0),
                  0,
                ),
                valor_ingressos: sectors.reduce(
                  (all, sector) =>
                    all +
                    (sector.days[dayToIndex]?.reduce(
                      (total: number, item: any) =>
                        total + Number(item.valor_ingressos),
                      0,
                    ) ?? 0),
                  0,
                ),
              },
            ],
          }
          return data
        }),
        borderColor: `rgb(0,0,0)`,
        backgroundColor: `rgb(0,0,0, 0.5)`,
      },
    ],
  }
  return (
    <Card>
      <Line options={options} data={data} style={{ maxHeight: 450 }} />
    </Card>
  )
}

export default memo(SalesByPeriodChart)
