import * as React from 'react'
import { apiV1 } from 'services'
import { functionWithLoading } from 'utils'
import { PartnerContextType } from 'types/partner'
import { AxiosError } from 'axios'
import { useNavigate } from 'react-router-dom'
import { IEvento } from 'types/evento'
import { IUsuario } from 'types/usuario'

export const PartnerContext = React.createContext<PartnerContextType | null>(
  null,
)

export const LogoutPartner = () => {
  localStorage.removeItem('partner')
  localStorage.removeItem('partner_token')
  window.location.reload()
}

const PartnerProvider: React.FC<React.ReactNode> = ({ children }) => {
  const [partner, setPartner] = React.useState<IUsuario>({} as IUsuario)
  const [selectedEvent, setSelectedEvent] = React.useState<IEvento>()
  const [loading, setLoading] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState('')
  const [pageTitle, setPageTitle] = React.useState('Painel do parceiro')

  const navigate = useNavigate()

  React.useEffect(() => {
    const sessionPartner = localStorage.getItem('partner')
    const sessionSelectedEvent = localStorage.getItem('selectedEvent')
    if (sessionPartner) {
      setPartner(JSON.parse(sessionPartner))
    }
    if (sessionSelectedEvent) {
      setSelectedEvent(JSON.parse(sessionSelectedEvent))
    }
  }, [])

  const login = (username: string, password: string) => {
    functionWithLoading(
      apiV1.partner.partnerService
        .login(username, password)
        .then((res) => {
          if (res.data.auth) {
            localStorage.setItem('partner', JSON.stringify(res.data.usuario))
            localStorage.setItem('partner_token', res.data.token)
            setPartner(res.data.usuario)
            setErrorMessage('')
          } else {
            setErrorMessage('Usuário ou senha incorretos!')
          }
        })
        .catch((error: AxiosError) =>
          setErrorMessage(error.response?.data.message ?? error.message),
        ),
      setLoading,
    )
  }

  const logout = React.useCallback(() => {
    setPartner({} as IUsuario)
    localStorage.removeItem('partner')
    localStorage.removeItem('partner_token')
    navigate('/parceiro/login')
  }, [navigate])

  return (
    <PartnerContext.Provider
      value={{
        partner,
        login,
        logout,
        loading,
        errorMessage,
        pageTitle,
        setPageTitle,
        selectedEvent,
        setSelectedEvent,
      }}
    >
      {children}
    </PartnerContext.Provider>
  )
}

const ERROR_MSG_PROVIDER_NEEDED =
  'usePartner must be used within a PartnerProvider'

export const usePartner = () => {
  const context = React.useContext(PartnerContext)
  if (!context) {
    throw new Error(ERROR_MSG_PROVIDER_NEEDED)
  }
  return context
}

export default PartnerProvider
