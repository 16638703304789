import { Container, Link, Stack, Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import { EventCard, Loading } from 'components'
import * as React from 'react'
import { Helmet } from 'react-helmet'
import { Link as RouterLink } from 'react-router-dom'
import clientRest from 'services/api/v1/client'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import { IEvento } from 'types/evento'
import EventPage from './EventPage'

type ProducerEventsCustomProps = {
  pageTitle: string
}

const ProducerEventsCustom = ({ pageTitle }: ProducerEventsCustomProps) => {
  const [events, setEvents] = React.useState<IEvento[]>([])
  const [searchQuery] = React.useState('')
  const [loadingEvents, setLoadingEvents] = React.useState(true)

  React.useEffect(() => {
    async function fetchEvents() {
      try {
        setLoadingEvents(true)
        const response = await clientRest.get(`site/custom-pages/carnavrau`)
        setEvents(response.data.events)
      } catch (error) {
        console.log(error)
      } finally {
        setLoadingEvents(false)
      }
    }
    fetchEvents()
  }, [])

  if (loadingEvents) return <Loading />

  const filteredEvents =
    searchQuery.length > 0
      ? events.filter(
          (x) =>
            x.titulo
              .toLocaleLowerCase()
              .includes(searchQuery.toLocaleLowerCase()) ||
            x.local_evento
              .toLocaleLowerCase()
              .includes(searchQuery.toLocaleLowerCase()) ||
            x.id.toString().includes(searchQuery),
        )
      : events

  return (
    <>
      <Helmet>
        <title>{pageTitle} - Zé do Ingresso</title>
      </Helmet>

      {events.length === 1 ? (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <EventPage id={events[0].id} />
          </Grid>
        </Grid>
      ) : (
        <Container maxWidth="lg">
          <Grid item xs={12} sm={7}>
            <Stack
              sx={(theme) => ({
                [theme.breakpoints.only('xs')]: {
                  alignItems: 'center',
                  pb: 2,
                },
                [theme.breakpoints.up('sm')]: {
                  justifyContent: 'center',
                  height: '100%',
                },
              })}
            >
              <Typography ml={2} variant="h6">
                {pageTitle}
              </Typography>
            </Stack>
          </Grid>
          <Grid container spacing={2} mt={1}>
            {filteredEvents.map((event) => (
              <Grid item key={'event_' + event.id} xs={12} sm={6} md={4} lg={3}>
                <Link
                  component={RouterLink}
                  to={`/evento/${event.id}/${event.id_url}`}
                  underline="none"
                >
                  <EventCard event={event} />
                </Link>
              </Grid>
            ))}
          </Grid>
        </Container>
      )}
    </>
  )
}

export default ProducerEventsCustom
