import { Login } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Alert, Button, Paper } from '@mui/material'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useUser } from 'contexts/userContext'
import { useFormik } from 'formik'
import * as React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ILocationProps } from 'types/locationProps'
import validationSchema from './validationSchema'

export default function SignInPage() {
  const { login, loading, user, errorMessage } = useUser()
  const location = useLocation() as ILocationProps
  const navigate = useNavigate()

  const from = location.state?.from?.pathname ?? '/'
  const fromState = location.state?.from?.state

  React.useEffect(() => {
    const token = localStorage.getItem('user_token')
    const storageUser = localStorage.getItem('user')
    if (token && storageUser && user) {
      navigate(from, { replace: true, state: fromState })
    }
  }, [from, fromState, navigate, user])

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validateOnChange: true,
    validationSchema,
    onSubmit: (values) => {
      login(values.email.trim(), values.password)
    },
  })

  return (
    <Container component={Paper} maxWidth="xs">
      <Box
        sx={{
          my: 2,
          py: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <img
          src="https://images.zedoingresso.com.br/zedoingresso/imgs/header-logo.png"
          alt="Zé do Ingresso"
          height={50}
        />
        <Typography component="h1" variant="h5" sx={{ mt: 2 }}>
          Informe o e-mail e senha
        </Typography>
        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
        <Box
          component="form"
          onSubmit={formik.handleSubmit}
          noValidate
          sx={{ mt: 1 }}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="E-mail"
            name="email"
            autoComplete="email"
            autoFocus
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            onBlur={() =>
              formik.setFieldValue('email', formik.values.email.trim())
            }
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Senha"
            type="password"
            id="password"
            autoComplete="current-password"
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />
          <LoadingButton
            loading={loading}
            loadingPosition="end"
            endIcon={<Login />}
            type="submit"
            fullWidth
            variant="contained"
            size="large"
            sx={{ mt: 3, mb: 2 }}
          >
            Entrar
          </LoadingButton>
          <Grid container sx={{ justifyContent: 'space-between' }}>
            <Grid item>
              <Button
                variant="outlined"
                onClick={() => navigate('/esqueci-senha')}
                size="small"
              >
                Esqueci a senha
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                onClick={() => navigate('/signup')}
                size="small"
              >
                Criar conta
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  )
}
