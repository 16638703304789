/* eslint-disable react/no-unescaped-entities */
import { Container, Paper } from '@mui/material'
import React from 'react'

const UseTermsPage: React.FC = () => {
  return (
    <Container sx={{ padding: 2, marginY: 2 }} component={Paper}>
      <p>
        O Z&eacute; do ingresso &eacute; uma plataforma online de venda de
        ingressos, r&aacute;pida, intuitiva e aberta 24 horas por dia.
        Est&aacute; presente no mercado desde 2014 oferecendo venda de ingressos
        online para produtores de eventos. Com as mudan&ccedil;as da tecnologia,
        em 2022 fizemos uma atualiza&ccedil;&atilde;o na plataforma, para melhor
        atend&ecirc;-los e trazer mais seguran&ccedil;a a suas compras online e
        seus dados pessoais. Sua compra &eacute; totalmente protegida pois
        possu&iacute;mos o certificado PCI ( Padr&atilde;o internacional de
        seguran&ccedil;a de dados ) al&eacute;m de possuir um sistema
        antifraude, que gera um QRCode &uacute;nico em cada ingressos
        dispon&iacute;vel no site.
        <br />
        <br />
        Z&eacute; do Ingresso n&atilde;o &eacute; a produtora, organizadora,
        promotora, tampouco propriet&aacute;ria dos eventos catalogados em sua
        plataforma. Z&eacute; do Ingresso &eacute; apenas a plataforma de venda
        online que presta servi&ccedil;os para os reais produtores dos shows e
        demais eventos cadastrados em nosso site.
        <br />
        <strong>TERMO DE USO</strong>
        <br />
        <br />
        <strong>DADOS PESSOAIS</strong>
        <br />
        - O cadastro na plataforma do Z&eacute; do ingresso &eacute; gratuito,
        no entanto, os servi&ccedil;os dentro do site ser&atilde;o cobrados de
        acordo com o estabelecido em cada evento, exceto eventos gratuitos, os
        quais conter&atilde;o essa informa&ccedil;&atilde;o.
        <br />
        - Ao realizar o cadastro no Z&eacute; do ingresso, voc&ecirc; autoriza a
        coleta de informa&ccedil;&otilde;es de dados pessoais, resguardando o
        sigilo das informa&ccedil;&otilde;es de acorda com a LGPD (Lei Geral de
        Prote&ccedil;&atilde;o de Dados Pessoais). Bem como dados de
        estat&iacute;sticas de navega&ccedil;&atilde;o.
        <br />
        - Os dados de pagamento s&atilde;o direcionados atrav&eacute;s da
        integra&ccedil;&atilde;o com a plataforma tercerizada MERCADO PAGO, que
        tamb&eacute;m possui uma r&iacute;gida seguran&ccedil;a de
        prote&ccedil;&atilde;o de dados. Assim, o Z&eacute; do ingresso,
        n&atilde;o mantem armazenado, os dados banc&aacute;rios ou de pagamento,
        em nosso banco de dados e n&atilde;o possui responsabilidade por
        eventuais preju&iacute;zos decorrentes da utiliza&ccedil;&atilde;o do
        servi&ccedil;o de terceiros.
        <br />
        - O acesso a plataforma ser&aacute; apenas pelo site oficial
        www.zedoingresso.com.br. Com seu Login e senha, ou pelo aplicativo
        oficial disponibilizado para organizadores e usu&aacute;rios.
        <br />
        - Nunca disponibilize sua senha! O &uacute;nico que pode ter acesso a
        sua senha ou alterar/recuperar sua senha &eacute; voc&ecirc;. A
        seguran&ccedil;a e manuten&ccedil;&atilde;o da conta est&aacute;
        associada ao e-mail de cadastro no Z&eacute; do Ingresso. Que
        enviar&aacute; um e-mail para recupera&ccedil;&atilde;o da mesma, caso
        seja necess&aacute;rio.
        <br />
        <br />
        <strong>SUSPENS&Atilde;O OU ENCERRAMENTO DA CONTA</strong>
        <br />
        Voc&ecirc; declara ter ci&ecirc;ncia de que dever&aacute; inserir dados
        verdadeiros quando realizar o cadastro no site. Caso seja identificado
        dados n&atilde;o verdadeiros o Z&eacute; do Ingresso se reserva no
        direito de suspender e/ou encerrar a conta.
        <br />
        Sua conta pode ser suspensa ou cancelada nos casos de:
        <br />
        - Descumprimento dos termos de uso e condutas ilegais;
        <br />
        - Pr&aacute;tica de atos fraudulentos ou dolosos, il&iacute;citos,
        criminosos, ou que enseje atitudes tidas como contraven&ccedil;&atilde;o
        penal;
        <br />
        - Les&atilde;o volunt&aacute;ria, involunt&aacute;ria, consciente ou
        inconsciente &agrave; produtores, organizadores, usu&aacute;rios e
        parceiros do Site.
        <br />
        - Danos ao pr&oacute;prio Site;
        <br />
        - Atua&ccedil;&atilde;o como intermediador irregular de venda de
        ingresso (cambista)
        <br />
        - Inser&ccedil;&atilde;o e utiliza&ccedil;&atilde;o de dados falsos e
        pagamentos de propriedade alheia, que poder&aacute; ser julgado como
        crime de falsa identidade, crime de falsidade ideol&oacute;gica e crime
        de estelionato.
        <br />
        <br />
        <strong>PAGAMENTOS</strong>
        <br />
        O consumidor se compromete a utilizar a plataforma Z&eacute; do Ingresso
        de maneira &eacute;tica obedecendo as leis brasileiras.
        <br />
        Ao utilizar a plataforma, o consumidor declara ci&ecirc;ncia de que sua
        compra est&aacute; sujeita &agrave; aprova&ccedil;&atilde;o de
        cr&eacute;dito da administradora financeira e a disponibilidade de
        ingressos; e que este processo pode levar até 48h. E tem
        obriga&ccedil;&atilde;o de:
        <br />
        - Inserir dados completos, atualizados e verdadeiros;
        <br />
        - Conferir atentamente os dados do pedido antes de finalizar a compra;
        <br />
        - Conferir o ingresso adquirido, incluindo setores, poltronas, mesas e
        etc, pois ele ser&aacute; v&aacute;lido somente para o evento
        selecionado no momento da compra, n&atilde;o podendo ser alterado;
        <br />
        - Estar ciente que a taxa de servi&ccedil;o (10%) n&atilde;o ser&aacute;
        estornada. O estorno ser&aacute; apenas do valor do ingresso, pois as
        taxas de servi&ccedil;os s&atilde;o referentes a
        operacionaliza&ccedil;&atilde;o do site.
        <br />
        - Ao adquirir os ingressos via promomoter, comiss&aacute;rio,
        atl&eacute;tica, ponto de vendas ou qualquer outro meio que n&atilde;o
        seja diretamente ao site, em caso de d&uacute;vida da veracidade do
        parceiro, o usu&aacute;rio se compromete a entrar em contato com a
        empresa Z&eacute; do Ingresso para consulta e o mesmo n&atilde;o se
        responsabilizar&aacute; por compras realizadas com revendedores
        n&atilde;o autorizados.
        <br />
        -&nbsp;Estar ciente que compras realizadas via promomoter,
        comiss&aacute;rio, atl&eacute;tica, ponto de vendas ou qualquer outro
        meio que n&atilde;o seja diretamente ao site, ficam sobe
        resopons&aacute;bilidade da organiza&ccedil;&atilde;o do evento em
        rela&ccedil;&atilde;o &agrave; quest&otilde;es financeiras como,
        realizar pagamentos, estornos, reembolso e outros.
        <br />
        <br />
        <strong>
          RESPONSABILIDADE E OBRIGA&Ccedil;&Otilde;ES DO USU&Aacute;RIO
        </strong>
        <br />
        Ao utilizarem a plataforma da Z&eacute; do Ingresso os usu&aacute;rios
        se obrigam a:
        <br />
        - Fornecer todas as informa&ccedil;&otilde;es e documentos que forem
        solicitados atrav&eacute;s dos canais oficiais, caso seja
        necess&aacute;ria alguma valida&ccedil;&atilde;o da compra por parte do
        Z&eacute; do Ingresso.
        <br />
        - Apresentar, sempre que requerido, na portaria do evento, um documento
        de identidade com a foto do titular do ingresso.
        <br />
        - Cuidar e promover a seguran&ccedil;a do ingresso, a partir do momento
        em que o receber.
        <br />
        - Arcar com a taxa eventualmente cobrada pelo cart&atilde;o de
        cr&eacute;dito;
        <br />
        - Isentar o Z&eacute; do ingresso de qualquer responsabilidade sobre a
        realiza&ccedil;&atilde;o dos eventos para os quais foram adquiridos os
        ingressos
        <br />
        <br />
        <strong>INGRESSOS</strong>
        <br />- O link de acesso e o PDF do ingresso ser&atilde;o enviados ao
        e-mail indicado no cadastro quando o pagamento for aprovado e
        identificado. Al&eacute;m de estar dispon&iacute;vel dentro da
        plataforma www.zedoingresso.com.br na &aacute;rea ➔ &quot;
        <a href="/usuario/pedidos">Meus Pedidos</a>
        &quot;.
        <br />
        - Os ingressos s&atilde;o associados a um c&oacute;digo QR CODE que
        ter&aacute; sua leitura &Uacute;NICA de valida&ccedil;&atilde;o na
        entrada do evento. Nunca divulgue publicamente ou permita o acesso a
        terceiros. Os ingressos s&atilde;o nominais, a cabe ao organizador
        decidir como ser&aacute; feito a confer&ecirc;ncia dessa
        informa&ccedil;&atilde;o no momento de acesso ao evento.
        <br />
        - O Z&eacute; do ingresso n&atilde;o se responsabiliza por ingressos
        perdidos, furtados, extraviados, roubados, dentre outras
        situa&ccedil;&otilde;es, ou seja, n&atilde;o haver&aacute; qualquer
        reposi&ccedil;&atilde;o do ingresso, devendo o consumidor, caso queira,
        efetuar outra compra.
        <br />
        Em caso de perda, extravio, furto, ou roubo do ingresso o consumidor se
        compromete a notificar imediatamente o Z&eacute; do Ingresso, informando
        os dados da compra, como dados pessoais e outras
        informa&ccedil;&otilde;es importantes para localizar e invalidar o
        ingresso anterior, gerando um novo QrCode.
        <br />
        - De acordo com a Lei n&deg; 13.455, de 25 de junho de 2017, os
        pre&ccedil;os dos ingressos poder&atilde;o ser diferenciados em
        fun&ccedil;&atilde;o do prazo ou do instrumento de pagamento utilizado.
        Da mesma forma, a taxa de servi&ccedil;o poder&aacute; ser diferenciada
        em raz&atilde;o do prazo e do instrumento utilizado para o pagamento.
        <br />
        - Ser&aacute; cobrada taxa de conveni&ecirc;ncia para
        aquisi&ccedil;&atilde;o de ingressos na plataforma, cujo
        percentual/valor ser&aacute; indicado no momento anterior a
        confirma&ccedil;&atilde;o da compra.
        <br />
        - Ap&oacute;s a confirma&ccedil;&atilde;o de aprova&ccedil;&atilde;o do
        pagamento, o Z&eacute; do ingresso recolher&aacute; o valor
        correspondente, deduzir&aacute; a taxa de servi&ccedil;o e
        repassar&aacute; ao organizador o valor restante, de acordo com o modelo
        de recebimento escolhido.
        <br />
        - Ingressos de meia entrada e/ou outros descontos estar&aacute; sujeita
        &agrave;s regras dos organizadores de cada evento. Consulte a
        p&aacute;gina oficial do evento. O Z&eacute; do ingresso n&atilde;o se
        responsabiliza pelo cumprimento de tais pol&iacute;ticas por parte dos
        organizadores.
        <br />
        <br />
        <p>
          <strong>CANCELAMENTO E REEMBOLSO</strong>
        </p>
        <p>
          - Conforme previsto no artigo 49, do Código de Defesa do Consumidor,
          eventual pedido de cancelamento da compra deverá ser feito no prazo de
          até 7 dias da data da compra. Após esse prazo, ou no caso do pedido de
          cancelamento ser realizado nas 48 horas anteriores ao evento, a opção
          "CANCELAR PEDIDO" não estará disponível.
          <br />
          - Passado o prazo citado acima, e ap&oacute;s a
          realiza&ccedil;&atilde;o do evento, o Z&eacute; do ingresso n&atilde;o
          far&aacute;, sob hip&oacute;tese alguma, devolu&ccedil;&atilde;o dos
          valores dos ingressos adquiridos.
          <br />
          - Pedidos transferidos ou solicitações efetuados após o inicio do
          evento não serão realizados, independente do motivo.
          <br />
          - Somente o usu&aacute;rio que realizou a compra do pedido
          poder&aacute; solicitar reembolso do ingresso. O Pedido que houver
          mais de um ingresso, s&oacute; poder&aacute; ser reembolsado em sua
          totalidade, ou seja, se o ingresso tiver sido transferido em partes
          para outro usu&aacute;rio, o mesmo n&atilde;o conseguir&aacute;
          realizar o reembolso parcial.
          <br />
          - O Z&eacute; do Ingresso somente emite Nota Fiscal para organizadores
          e/ou produtores, tendo em vista serem estes os tomadores dos
          servi&ccedil;os prestados. Qualquer documento fiscal dever&aacute; ser
          solicitado diretamente ao propriet&aacute;rio do evento.
          <br />
          -&nbsp;Em caso de cancelamento e/ou reagendamento de eventos devido
          &agrave; caso fortuito ou for&ccedil;a maior, nos termos do 393 do
          C&oacute;digo Civil Brasileiro, por se tratar de eventos
          extraordin&aacute;rios, o Z&eacute; do Ingresso n&atilde;o se
          responsabilizar&aacute; pelo estorno dos valores dos ingressos,
          devendo o usu&aacute;rio aguardar a manifesta&ccedil;&atilde;o do
          organizador do evento para devolu&ccedil;&atilde;o ou reagendamento da
          data do evento. Para estes casos a respos&aacute;bilidade da
          devolu&ccedil;&atilde;o dos valores ficam por conta da
          organiza&ccedil;&atilde;o do evento, exceto&nbsp;para
          solicita&ccedil;&otilde;es efetuadas dentro dos 7 dias ap&oacute;s a
          data da compra, estes sim ter&atilde;o o estorno realizado direto pelo
          site.
          <br />
          - Compras realizadas via promomoter, comiss&aacute;rio,
          atl&eacute;tica, ponto de vendas ou qualquer outro meio que n&atilde;o
          seja diretamente ao site, n&atilde;o se enquadram nos termos acima
          ficando sobe resopons&aacute;bilidade da organiza&ccedil;&atilde;o do
          evento em realizar o cancelamento e reembolso.
          <br />- Após a solicitação realizada, o prazo de estorno dos valores
          &eacute; de at&eacute; 30 dias ap&oacute;s a
          solicita&ccedil;&atilde;o.
          <br />- Em caso de cancelamento do evento, os valores serão estornados
          para os titularidades originários da compra, ou seja, pedidos
          derivados de transferência não receberão estorno dos valores através
          da plataforma Zé do Ingresso.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>
            TRANSFER&Ecirc;NCIA E NOMEA&Ccedil;&Atilde;O DO INGRESSO
          </strong>
          <br />- Se você estiver vendendo ou comprando um ingresso de alguém
          desconhecido, por segurança será necessário transferir a titularidade
          através do botão "Transferir Ingresso" encontrado logo abaixo do
          QrCode do mesmo. Após realizar a transferência, o novo usuário
          receberá um email com a confirmação e também poderá visualizá-lo em
          <a href="/usuario/pedidos"> Meus ingressos</a>.
          <br />
          - O ingresso transferido ao novo usu&aacute;rio, receber&aacute; um
          novo QRCode, impedindo assim, que o ingresso seja utilizado duas vezes
          por usu&aacute;rios diferentes.
          <br />- O Zé do ingresso não se responsabiliza por ingressos comprados
          e transferidos de terceiros, pois os mesmos podem ser cancelados caso
          o comprador original não cumpra os termos do site, conteste o
          pagamento ou dentre outras situações. Não haverá qualquer reposição do
          ingresso, devendo o consumidor, caso queira, efetuar outra compra em
          pontos oficiais.
        </p>
        <br />
        <p>
          <strong>RESPONSABILIDADE DOS ORGANIZADORES</strong>
          <br />
          Os organizadores, produtores, promotores e propriet&aacute;rios dos
          eventos s&atilde;o os &uacute;nicos respons&aacute;veis por garantir
          que seus eventos esteja de acordo com a todas as leis, regras,
          circulares e regulamentos, tanto em &acirc;mbito nacional, estadual e
          municipal e que sejam apresentados conforme descritos, inclusive a
          emiss&atilde;o de notas fiscais referente &agrave;s vendas de
          ingresso. O Z&eacute; do ingresso n&atilde;o assume qualquer
          responsabilidade pelas informa&ccedil;&otilde;es divulgadas ou pela
          organiza&ccedil;&atilde;o do evento.
        </p>
        <p>
          Para d&uacute;vidas relacionadas a valores, locais,
          atra&ccedil;&otilde;es, hor&aacute;rios e outras quest&otilde;es
          relativas ao ao evento, entre em contato diretamente com os
          organizadores atrav&eacute;s do telefone no final da
          descri&ccedil;&atilde;o do evento ou redes sociais.&nbsp;
        </p>
        <p>
          O z&eacute; do ingresso realizar&aacute; a emiss&atilde;o de nota
          fiscal ao organizador referente &agrave; taxa de servi&ccedil;o (10%)
          sobre as vendas, o valor referente ao ingresso fica por
          responsabilidade do organizador em emitir a nota fical; visto que esse
          valor &eacute; repassado integralmente ao respons&aacute;vel pela
          organiza&ccedil;&atilde;o.&nbsp;
          <br />
          Em caso de cancelamento e/ou reagendamento de eventos devido &agrave;
          caso fortuito ou for&ccedil;a maior, nos termos do 393 do
          C&oacute;digo Civil Brasileiro, por se tratar de eventos
          extraordin&aacute;rios, o Z&eacute; do Ingresso n&atilde;o se
          responsabilizar&aacute; pelo estorno valores dos ingressos, ficando
          sobe a responsabildiade do organizador do evento em realizar a
          devolu&ccedil;&atilde;o e comunicar o consumidor.
        </p>
        <p>
          <br />
          Ao se cadastrar e utilizar o Z&eacute; do Ingresso, voc&ecirc;{' '}
          <strong>
            {' '}
            declara ter conhecimento de que o servi&ccedil;o &eacute; prestado
            de acordo com os termos e condi&ccedil;&otilde;es presente neste
            Termos de Uso.{' '}
          </strong>
        </p>
      </p>
    </Container>
  )
}

export default UseTermsPage
