import { Link, Typography } from '@mui/material'
import React from 'react'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Copyright = (props: any) => {
  return (
    <React.Fragment>
      <hr />
      <Typography
        variant="body2"
        color="inherit"
        align="center"
        sx={{ mt: 1 }}
        {...props}
      >
        <Link color="inherit" href="https://zedoingresso.com.br/">
          Zé do Ingresso
        </Link>{' '}
        2014 - {new Date().getFullYear()}
        {'.'}
      </Typography>
    </React.Fragment>
  )
}

export default Copyright
