/* eslint-disable @typescript-eslint/no-explicit-any */
import { IIngresso } from 'types/ingresso'
import { IPedido } from 'types/pedido'
import { PaymentMethodType } from 'types/shoppingCart'
import clientUser from './clientUser'

export interface CreateOrderPaymentInfos {
  method: PaymentMethodType
  card?: {
    encrypted: string
    bin: string
    '3dsId'?: string
  }
  installments?: number | null
  amount: number
  discountCoupon: undefined | any
}

export type CreateOrderServiceProps = {
  eventId: number
  tickets: IIngresso[]
  ref: string | null
  paymentInfos?: CreateOrderPaymentInfos
}

export type CreateOrderTCProps = {
  eventId: number
  items: IIngresso[]
  ref: string | null
  paymentInfos: CreateOrderPaymentInfos
}

const createOrder = (props: CreateOrderServiceProps) => {
  return clientUser.post(`user-website/events/${props.eventId}/orders`, {
    tickets: props.tickets,
    ref: props.ref,
  })
}

const createOrderTransparentCheckout = ({
  eventId,
  items,
  paymentInfos,
  ref,
}: CreateOrderTCProps) => {
  return clientUser.post(`user-website/events/${eventId}/orders-tc`, {
    items,
    ref,
    paymentInfos,
  })
}

const payWithMercadoPago = (orderId: number) => {
  return clientUser.post<string>(
    `user-website/orders/${orderId}/pay-with-mercadopago`,
  )
}

const payWithPagSeguro = (orderId: number | string) => {
  // const client = axios.create({
  //   baseURL: "https://zedoingresso.com.br/",
  //   headers: {
  //     "Content-Type": "application/json",
  //     Accept: "application/json",
  //     "Access-Control-Allow-Origin": "*",
  //     "Access-Control-Allow-Headers": "X-Requested-With",
  //   },
  // });
  // return client.post<string>(`pagar-com-pagseguro.php`, { orderId });
  return clientUser.post<string>(
    `user-website/orders/${orderId}/pay-with-pagseguro`,
  )
}

const getUserOrders = () => {
  return clientUser.get<IPedido[]>(`users/orders`)
}

const getUserOrdersPastEvents = () => {
  return clientUser.get<IPedido[]>(`users/orders/past-events`)
}

const getOrder = (orderId: string) => {
  return clientUser.get<IPedido>(`users/orders/${orderId}`)
}

const getOrderByTransaction = (transaction: string) => {
  return clientUser.get<IPedido>(`users/orders/transaction/${transaction}`)
}

const cancelOrder = (orderId: number) => {
  return clientUser.post<string>(`user-website/orders/${orderId}/cancel`)
}

const verifyPayment = (orderId: string) =>
  clientUser.get(`users/orders/${orderId}/verify-payment`)

const updateOrderFromMP = (orderId: string) =>
  clientUser.post(`notifications/order/${orderId}`)

const orderService = {
  createOrder,
  payWithMercadoPago,
  payWithPagSeguro,
  getUserOrders,
  getUserOrdersPastEvents,
  getOrder,
  getOrderByTransaction,
  cancelOrder,
  verifyPayment,
  updateOrderFromMP,
  createOrderTransparentCheckout,
}

export default orderService
