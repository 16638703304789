import { Box, Paper, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

const MercadoPagoPending: React.FC = () => {
  const [searchParams] = useSearchParams()
  const externalReference = searchParams.get('external_reference')

  const navigate = useNavigate()

  useEffect(() => {
    if (externalReference) navigate('/usuario/pedidos/' + externalReference)
  }, [externalReference, navigate])

  return (
    <Paper sx={{ my: 1, p: 2 }}>
      <Box sx={{ backgroundColor: 'yellow', height: 50, mb: 2 }}></Box>
      <Typography variant="h5">
        O seu pagamento do pedido {externalReference} ainda está pendente!
      </Typography>
    </Paper>
  )
}

export default MercadoPagoPending
