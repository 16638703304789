/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
import moment from 'moment'
import { Dispatch, SetStateAction } from 'react'

const functionWithLoading = async (
  fn: Promise<unknown> | unknown,
  fnLoading: Dispatch<SetStateAction<boolean>>,
) => {
  try {
    fnLoading(true)
    await fn
  } catch (error) {
    console.log(error)
  } finally {
    fnLoading(false)
  }
}

const getDaysArray = function (
  start: string | number | Date,
  end: string | number | Date,
) {
  const dates = []
  for (
    let date = new Date(start);
    date <= new Date(end);
    date.setDate(date.getDate() + 1)
  ) {
    dates.push(new Date(date))
  }
  return dates
}

const localDate = () => moment().add(-3, 'hours').toDate()

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay)
  })
}

function deepCopy(obj: any | any[]) {
  return JSON.parse(JSON.stringify(obj))
}

function compare(a: any, b: any) {
  if (a < b) {
    return -1
  }
  if (a > b) {
    return 1
  }
  return 0
}

export {
  compare,
  deepCopy,
  functionWithLoading,
  getDaysArray,
  localDate,
  sleep
}

