import { Close, LocationOn } from '@mui/icons-material'
import Search from '@mui/icons-material/Search'
import {
  Box,
  Divider,
  FormControl,
  IconButton,
  Input,
  MenuItem,
  Select,
} from '@mui/material'

type Props = {
  cities: string[]
  selectedCity: string
  onSelectCity: (city: string) => void
  searchQuery: string
  onChangeSearchQuery: (searchQuery: string) => void
}

export function SearchBar({
  cities,
  selectedCity,
  onSelectCity,
  searchQuery,
  onChangeSearchQuery,
}: Props) {
  return (
    <Box
      sx={{
        backgroundColor: '#FFFFFF',
        borderRadius: 2,
        display: 'flex',
        marginTop: 2,
      }}
    >
      <Input
        onChange={(e) => onChangeSearchQuery(e.target.value)}
        value={searchQuery}
        startAdornment={
          <Search sx={{ height: 30, width: 30, color: '#5A5A5A', mr: 1 }} />
        }
        endAdornment={
          !!searchQuery && (
            <IconButton
              sx={{ color: 'white' }}
              aria-label="close search"
              component="span"
              onClick={() => onChangeSearchQuery('')}
            >
              <Close sx={{ color: '#000000' }} />
            </IconButton>
          )
        }
        sx={{ flex: 1, fontSize: 16, p: 1 }}
        placeholder="Pesquisar eventos, locais, produtores..."
      />
      <Divider orientation="vertical" flexItem />
      <FormControl sx={{ width: '35%' }}>
        <Select
          value={selectedCity}
          label="Cidade"
          onChange={(e) => onSelectCity(e.target.value as string)}
          variant="standard"
          sx={{ fontSize: 16, p: 2, color: '#5A5A5A' }}
          startAdornment={
            <LocationOn
              sx={{ height: 30, width: 30, color: '#5A5A5A', mr: 1 }}
            />
          }
        >
          <MenuItem value={'0'}>TODAS</MenuItem>
          {cities.map((city) => (
            <MenuItem key={city} value={city}>
              {city}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}
