import { Grid, Paper } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { Loading } from 'components'
import { Breadcrumbs } from 'components/Breadcrumbs'
import { Typography } from 'components/Typography'
import { useAdmin } from 'contexts/adminContext'
import { useHttp } from 'hooks/useHttp'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { IEvento } from 'types/evento'
import { IEventoServico } from 'types/evento_servico'
import { getErrorMessage } from 'utils/AppError'
import { NewEventService } from './_components/new-event-service'
import { moneyFormatter } from 'utils/formatter'

type HttpResponse = {
  event: IEvento
  services: IEventoServico[]
}

export function EventServicesPage() {
  const eventId = useParams().eventId ?? 0

  const { addErrorMessage } = useAdmin()

  const http = useHttp<HttpResponse>({
    clientType: 'admin',
    url: `/events/${eventId}/services`,
    onError: (error) => addErrorMessage(getErrorMessage(error)),
  })

  useEffect(() => {
    http.execute()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (http.isLoading || !http.data) return <Loading />

  const { event, services } = http.data

  return (
    <Grid container maxWidth="md">
      <Grid item xs={12}>
        <Breadcrumbs
          items={[
            { title: 'Eventos', to: '/admin/eventos' },
            {
              title: event.titulo ?? '...',
              to: `/admin/eventos/${eventId}`,
            },
            { title: 'Serviços adicionais do evento' },
          ]}
        />
      </Grid>

      <Grid item container component={Paper} p={2} gap={2}>
        <Grid item xs={12} display="flex" justifyContent="space-between">
          <Typography variant="h6">Serviços adicionais do evento</Typography>
          <NewEventService
            callback={async () => {
              await http.execute()
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <DataGrid
            columns={[
              { field: 'id' },
              { field: 'descricao', flex: 1 },
              {
                field: 'valor',
                align: 'right',
                renderCell: ({ value }) => moneyFormatter.format(value),
              },
              { field: 'quantidade', align: 'right' },
              {
                field: 'total',
                align: 'right',
                renderCell: ({ value }) => moneyFormatter.format(value),
              },
            ]}
            rows={services}
            autoHeight
          />
        </Grid>
      </Grid>
    </Grid>
  )
}
