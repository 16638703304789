import { IIngresso } from 'types/ingresso'
import clientProducer from './clientProducer'

const getAll = (eventId: number) => {
  return clientProducer.get<Array<IIngresso>>(`events/${eventId}/tickets`)
}

const get = (eventId: number, ticketId: number) => {
  return clientProducer.get<IIngresso>(`tickets/${ticketId}`, {
    params: { eventId },
  })
}

const create = (eventId: number, ticket: IIngresso) => {
  return clientProducer.post<IIngresso>(`tickets`, { ticket, eventId })
}

const update = (eventId: number, ticket: IIngresso) => {
  return clientProducer.put(`tickets/${ticket.id}`, { ticket, eventId })
}

const updateOrdinations = (eventId: number, tickets: IIngresso[]) => {
  return clientProducer.patch(`events/${eventId}/tickets/ordinations`, {
    tickets,
  })
}

const toggleVisible = (eventId: number, ticketId: number) => {
  return clientProducer.patch<IIngresso>(
    `events/${eventId}/tickets/${ticketId}/toggle-visible`,
  )
}

const remove = (eventId: number, ticketId: number) => {
  return clientProducer.delete<IIngresso>(
    `events/${eventId}/tickets/${ticketId}`,
  )
}

const ticketService = {
  getAll,
  get,
  create,
  update,
  updateOrdinations,
  toggleVisible,
  remove,
}

export default ticketService
