/* eslint-disable @typescript-eslint/no-explicit-any */
import { Search } from '@mui/icons-material'
import {
  Box,
  Button,
  Card,
  Grid,
  InputAdornment,
  Skeleton,
  TextField,
} from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { CalendarX, Circle, EyeSlash, Warning } from '@phosphor-icons/react'
import dayjs from 'dayjs'
import { useCallback, useEffect, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { apiV1 } from 'services'
import { datagrid, functionWithLoading } from 'utils'

export default function EventsPage() {
  const [events, setEvents] = useState<any[]>([])
  const [loading, setLoading] = useState(true)
  const [searchQuery, setSearchQuery] = useState('')

  const renderStatus = useCallback((row: any) => {
    let colorBall
    let icon
    let hidden
    let hiddenAdmin

    if (row.cancelado === 1) {
      icon = <CalendarX size={32} color="#ff0000" />
    } else if (row.venda_suspensa === 1) {
      icon = <Warning size={32} color="#ff5900" />
    }

    if (row.acertado === 1) {
      colorBall = <Circle size={32} color="#1cb02d" weight="fill" />
    } else if (dayjs(row.data_final).isBefore(dayjs())) {
      if (row.valor_pago > 0) {
        colorBall = <Circle size={32} color="#ffea00" weight="fill" />
      } else {
        colorBall = <Circle size={32} color="#fc0303" weight="fill" />
      }
    } else {
      if (row.valor_pago > 0) {
        colorBall = <Circle size={32} color="#ffea00" weight="fill" />
      }
    }

    if (row.oculto === 1) {
      hidden = <EyeSlash size={32} color="#000000" weight="fill" />
    }

    if (row.exibir_na_homepage === 0) {
      hiddenAdmin = <EyeSlash size={32} color="#ff0000" weight="fill" />
    }

    return (
      <div>
        {colorBall} {icon} {hidden} {hiddenAdmin}
      </div>
    )
  }, [])

  useEffect(() => {
    functionWithLoading(
      apiV1.admin.eventService.getAll().then((res) => {
        setEvents(res.data.events)
      }),
      setLoading,
    )
  }, [])

  const rows = events.filter(
    (event) =>
      event.id.toString().includes(searchQuery) ||
      event.titulo.toLowerCase().includes(searchQuery) ||
      event.produtora.toLowerCase().includes(searchQuery),
  )

  const columns: GridColDef[] = [
    { field: 'id', headerName: '#' },
    { field: 'titulo', headerName: 'Nome do evento', flex: 1 },
    {
      field: 'data',
      headerName: 'Data',
      renderCell: (values) =>
        dayjs(values.value).add(3, 'hours').format('DD/MM/YYYY [às] HH:mm'),
      width: 150,
    },
    {
      field: 'produtora',
      headerName: 'Produtora',
      flex: 1,
    },
    {
      field: 'status',
      headerName: 'Situação',
      flex: 1,
      renderCell: ({ row }) => renderStatus(row),
    },
    {
      field: 'actions',
      headerName: '',
      renderCell: (values) => {
        return (
          <Button
            color="info"
            aria-label="cadastrar novo evento"
            component={RouterLink}
            to={`/admin/events/${values.id}`}
            variant="contained"
            target="_blank"
            sx={{ ml: 1 }}
          >
            Detalhes
          </Button>
        )
      },
      width: 150,
    },
  ]

  return (
    <Card sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box
        sx={{
          p: 2,
          display: 'flex',
        }}
      >
        <TextField
          label="Pesquisar"
          variant="outlined"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          onChange={(e) => setSearchQuery(e.target.value.toLowerCase())}
          value={searchQuery}
          size="small"
        />
        <Button
          color="success"
          aria-label="cadastrar novo evento"
          component={RouterLink}
          to="/admin/events/novo"
          variant="contained"
          sx={{ ml: 1 }}
        >
          Cadastrar
        </Button>
      </Box>
      <Box sx={{ p: 2, flex: 1 }}>
        {loading ? (
          <Grid container spacing={2}>
            <Grid item sm={12}>
              <Skeleton variant="rectangular" height={400} />
            </Grid>
          </Grid>
        ) : (
          <DataGrid
            rows={rows}
            columns={columns}
            autoHeight
            localeText={datagrid.localizedTextsMap}
          />
        )}
      </Box>
    </Card>
  )
}
