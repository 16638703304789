import clientAdmin from './client'

const getExtract = () => {
  return clientAdmin.get(`withdrawals`)
}

const getNews = () => {
  return clientAdmin.get(`withdrawals/news`)
}

const get = (withdrawalId: number | string | undefined) => {
  return clientAdmin.get(`withdrawals/${withdrawalId}`)
}

const pay = (withdrawalId: number, observacoes: string) => {
  return clientAdmin.patch(`withdrawals/${withdrawalId}/pay`, {
    observacoes,
  })
}

const refuse = (withdrawalId: number, observacoes: string) => {
  return clientAdmin.patch(`withdrawals/${withdrawalId}/refuse`, {
    observacoes,
  })
}

const withdrawalService = { getExtract, getNews, get, pay, refuse }

export default withdrawalService
