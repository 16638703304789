import { Box, Button, Stack } from '@mui/material'
import { CheckCircle } from '@phosphor-icons/react'
import { Typography } from 'components/Typography'
import { useNavigate } from 'react-router-dom'

type Props = { orderId: string }

export function PagSeguroSuccess({ orderId }: Props) {
  const navigate = useNavigate()
  return (
    <Stack gap={2}>
      <Box display="flex" justifyContent="center" alignItems="center">
        <CheckCircle size={72} color="#16ce09" />
      </Box>
      <Typography variant="h5" textAlign="center">
        O seu pagamento do pedido {orderId} foi concluído com sucesso!
      </Typography>
      <Box display="flex" justifyContent="center" alignItems="center">
        <Button
          variant="contained"
          onClick={() => navigate(`/usuario/pedidos/${orderId}`)}
        >
          Ver meus ingressos
        </Button>
      </Box>
    </Stack>
  )
}
