import { Box, Button, Paper, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

const MercadoPagoSuccess: React.FC = () => {
  const [searchParams] = useSearchParams()
  const externalReference = searchParams.get('external_reference')
  const navigate = useNavigate()

  useEffect(() => {
    if (externalReference) navigate('/usuario/pedidos/' + externalReference)
  }, [externalReference, navigate])

  return (
    <Paper sx={{ my: 1, p: 2 }}>
      <Box sx={{ backgroundColor: 'green', height: 50, mb: 2 }}></Box>
      <Typography variant="h5">
        O seu pagamento do pedido {externalReference} foi concluído com sucesso!{' '}
      </Typography>
      <Button
        variant="outlined"
        onClick={() => navigate(`/usuario/pedidos/${externalReference}`)}
      >
        Clique aqui para ver seus ingressos
      </Button>
    </Paper>
  )
}

export default MercadoPagoSuccess
