import {
  Alert,
  CircularProgress,
  FormHelperText,
  InputAdornment,
  Snackbar,
  Stack,
} from '@mui/material'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import { Typography } from 'components/Typography'
import {
  ShoppingCartPaymentInfo,
  useShoppingCart,
} from 'contexts/shoppingCartContext'
import { useCallback, useEffect, useState } from 'react'
import Cards from 'react-credit-cards-2'
import { Controller, useFormContext } from 'react-hook-form'
import clientUser from 'services/api/v1/clientUser'
import { moneyFormatter } from 'utils/formatter'

import { Loading } from 'components'
import 'react-credit-cards-2/dist/es/styles-compiled.css'
import ReactInputMask from 'react-input-mask'
import { useParams } from 'react-router-dom'
import addressService from 'services/address'
import { AppError } from 'utils/AppError'

const years = (function () {
  let startYear = new Date().getFullYear()
  const years = []
  const finishYear = startYear + 20
  while (startYear <= finishYear) {
    years.push(startYear++)
  }
  return years
})()

interface Instalment {
  installments: number
  installment_value: number
  interest_free: boolean
  amount: {
    value: number
    fees: {
      buyer: {
        interest: {
          total: number
          installments: number
        }
      }
    }
    currency: string
  }
}

const CreditCard = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingCEP, setIsLoadingCEP] = useState(false)
  const [messageError, setMessageError] = useState('')
  const [installments, setInstallments] = useState<Instalment[]>([])

  const {
    register,
    watch,
    control,
    setValue,
    formState: { errors },
  } = useFormContext<ShoppingCartPaymentInfo>()
  const { totalShoppingCartValue } = useShoppingCart()
  const { eventId } = useParams()

  const { creditCard, address } = watch()
  const creditCardBin = creditCard?.number.substring(0, 6) ?? ''

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return
    }

    setMessageError('')
  }

  const handleSearchCEP = useCallback(async () => {
    try {
      const cep = watch('address.postalCode')?.replace(/[^0-9]+/, '')
      if (!cep || cep.length !== 8) return
      setIsLoadingCEP(true)
      const { data } = await addressService.findAddressByCEP(cep)
      setValue('address.street', data.logradouro, { shouldDirty: true })
      setValue('address.locality', data.bairro, { shouldDirty: true })
      setValue('address.city', data.localidade, { shouldDirty: true })
      setValue('address.regionCode', data.uf, { shouldDirty: true })
    } catch (error) {
      const isAppError = error instanceof AppError
      setMessageError(
        isAppError
          ? error.message
          : 'Ocorreu um erro ao consultar o CEP, verifique e tente novamente!',
      )
    } finally {
      setIsLoadingCEP(false)
    }
  }, [setValue, watch])

  const getInstallments = useCallback(async () => {
    try {
      setIsLoading(true)

      if (creditCardBin.length < 6) {
        return
      }

      const { data } = await clientUser.post('/fees/calculate', {
        value: totalShoppingCartValue * 100,
        creditCardBin,
        eventId,
      })

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const firstObject: any = Object.values(
        data.installments.payment_methods.credit_card,
      )

      setInstallments(firstObject[0].installment_plans)
      setValue('session', data.session)
    } catch (error) {
      setMessageError('Ocorreu um erro ao calcular as parcelas!')
    } finally {
      setIsLoading(false)
    }
  }, [creditCardBin, eventId, setValue, totalShoppingCartValue])

  useEffect(() => {
    getInstallments()
  }, [getInstallments])

  useEffect(() => {
    setValue('creditCard', {
      holder: '',
      taxId: '',
      number: '',
      expMonth: '',
      expYear: '',
      securityCode: '',
    })
    setValue('address', {
      postalCode: '',
      street: ' ',
      locality: ' ',
      number: '',
      city: ' ',
      regionCode: '',
    })
  }, [setValue])

  if (!creditCard || !address) return null

  const { expMonth, expYear, holder, number, securityCode } = creditCard

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} mt={2}>
        <Typography variant="subtitle2">Dados do cartão</Typography>
      </Grid>
      <Grid item xs={12} sm={6} container spacing={1}>
        <Grid item xs={12}>
          <TextField
            required
            label="Número do cartão"
            fullWidth
            autoComplete="cc-number"
            variant="outlined"
            type="number"
            {...register('creditCard.number')}
            onBlur={getInstallments}
            error={!!errors.creditCard?.number}
            helperText={errors.creditCard?.number?.message}
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <FormControl fullWidth>
            <InputLabel htmlFor="month-select">Mês</InputLabel>
            <Controller
              control={control}
              name="creditCard.expMonth"
              render={({ field: { onChange, value } }) => (
                <>
                  <Select
                    value={value}
                    onChange={onChange}
                    label="Mês"
                    required
                    autoComplete="cc-exp-month"
                    MenuProps={{ style: { maxHeight: 300 } }}
                    error={!!errors.installments}
                  >
                    <MenuItem value="1">1</MenuItem>
                    <MenuItem value="2">2</MenuItem>
                    <MenuItem value="3">3</MenuItem>
                    <MenuItem value="4">4</MenuItem>
                    <MenuItem value="5">5</MenuItem>
                    <MenuItem value="6">6</MenuItem>
                    <MenuItem value="7">7</MenuItem>
                    <MenuItem value="8">8</MenuItem>
                    <MenuItem value="9">9</MenuItem>
                    <MenuItem value="10">10</MenuItem>
                    <MenuItem value="11">11</MenuItem>
                    <MenuItem value="12">12</MenuItem>
                  </Select>
                  {!!errors.creditCard?.expMonth && (
                    <FormHelperText error>
                      {errors.creditCard.expMonth.message}
                    </FormHelperText>
                  )}
                </>
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6} md={4}>
          <FormControl fullWidth>
            <InputLabel htmlFor="year-select">Ano</InputLabel>
            <Controller
              control={control}
              name="creditCard.expYear"
              render={({ field: { onChange, value } }) => (
                <>
                  <Select
                    value={value}
                    onChange={onChange}
                    label="Ano"
                    required
                    autoComplete="cc-exp-year"
                    MenuProps={{ style: { maxHeight: 300 } }}
                    error={!!errors.creditCard?.expYear}
                  >
                    {years.map((year) => (
                      <MenuItem key={year} value={year.toString()}>
                        {year}
                      </MenuItem>
                    ))}
                  </Select>
                  {!!errors.creditCard?.expYear && (
                    <FormHelperText error>
                      {errors.creditCard.expYear.message}
                    </FormHelperText>
                  )}
                </>
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            required
            label="CCV"
            fullWidth
            autoComplete="cc-csc"
            variant="outlined"
            {...register('creditCard.securityCode')}
            error={!!errors.creditCard?.securityCode}
            helperText={errors.creditCard?.securityCode?.message}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            label="Nome do titular como aparece no cartão"
            fullWidth
            autoComplete="cc-name"
            variant="outlined"
            {...register('creditCard.holder')}
            error={!!errors.creditCard?.holder}
            helperText={errors.creditCard?.holder?.message}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            label="Documento do titular do cartão (CPF, CNPJ, etc.)"
            fullWidth
            autoComplete="on"
            variant="outlined"
            {...register('creditCard.taxId')}
            error={!!errors.creditCard?.taxId}
            helperText={errors.creditCard?.taxId?.message}
          />
        </Grid>
      </Grid>
      <Grid container item sm={6} spacing={1}>
        <Grid
          item
          justifyContent="center"
          alignItems="center"
          width="100%"
          sx={{ display: { xs: 'none', sm: 'flex' } }}
        >
          <Cards
            number={`${number}`}
            expiry={`${expMonth}/${expYear}`}
            cvc={`${securityCode}`}
            name={`${holder}`}
            placeholders={{
              name: '•••••• ••••••',
            }}
          />
        </Grid>
        <Grid item xs={12}>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Controller
              control={control}
              name="installments"
              render={({ field: { value, onChange, ...field } }) => (
                <FormControl fullWidth>
                  <InputLabel>Parcelas</InputLabel>
                  <Select
                    label="Selecione as parcelas"
                    value={installments.length > 0 ? value : ''}
                    onChange={(event) => {
                      onChange(event)
                      const installment = installments.find(
                        (installment) =>
                          installment.installments === event.target.value,
                      )
                      if (installment) {
                        if (installment.installments === 1) {
                          setValue('additionalFees', 0)
                        } else {
                          setValue(
                            'additionalFees',
                            installment.amount.fees.buyer.interest.total / 100,
                          )
                        }
                      }
                    }}
                    {...field}
                    required
                    error={!!errors.installments}
                  >
                    {installments.map((installment) => (
                      <MenuItem
                        key={installment.installments}
                        value={installment.installments}
                      >
                        <Stack justifyContent="space-between" direction="row">
                          <Typography>
                            ({installment.installments} x{' '}
                            {moneyFormatter.format(
                              installment.installment_value / 100,
                            )}
                            )
                          </Typography>
                          <Typography>
                            {moneyFormatter.format(
                              installment.amount.value / 100,
                            )}
                          </Typography>
                        </Stack>
                      </MenuItem>
                    ))}
                  </Select>
                  {!!errors.installments && (
                    <FormHelperText error>
                      {errors.installments.message}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
          )}
        </Grid>
      </Grid>

      <Grid item xs={12} mt={2}>
        <Typography variant="subtitle2">Endereço de cobrança</Typography>
      </Grid>

      <Grid item xs={12} sm={2}>
        <Controller
          name="address.postalCode"
          control={control}
          rules={{ required: true }}
          render={({ field: { onBlur, ...rest } }) => (
            <ReactInputMask
              {...rest}
              required
              mask="99999-999"
              placeholder={'CEP'}
              onBlur={() => {
                onBlur()
                handleSearchCEP()
              }}
            >
              <TextField
                label="CEP"
                fullWidth
                InputProps={{
                  endAdornment: isLoadingCEP && (
                    <InputAdornment position="end">
                      <Loading />
                    </InputAdornment>
                  ),
                }}
                error={!!errors.address?.postalCode}
                helperText={errors.address?.postalCode?.message}
              />
            </ReactInputMask>
          )}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          label="Logradouro"
          variant="outlined"
          fullWidth
          {...register('address.street')}
          required
          error={!!errors.address?.street}
          helperText={errors.address?.street?.message}
        />
      </Grid>

      <Grid item xs={12} sm={2}>
        <TextField
          label="Número"
          variant="outlined"
          fullWidth
          {...register('address.number')}
          required
          error={!!errors.address?.number}
          helperText={errors.address?.number?.message}
        />
      </Grid>

      <Grid item xs={12} sm={2}>
        <TextField
          label="Complemento"
          variant="outlined"
          fullWidth
          {...register('address.complement')}
          error={!!errors.address?.complement}
          helperText={errors.address?.complement?.message}
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <TextField
          label="Bairro"
          variant="outlined"
          fullWidth
          {...register('address.locality')}
          required
          error={!!errors.address?.locality}
          helperText={errors.address?.locality?.message}
        />
      </Grid>

      <Grid item xs={12} sm={2}>
        <Controller
          control={control}
          name="address.regionCode"
          render={({ field }) => (
            <ReactInputMask mask="aa" {...field}>
              <TextField
                label="UF"
                fullWidth
                required
                error={!!errors.address?.regionCode}
                helperText={errors.address?.regionCode?.message}
              />
            </ReactInputMask>
          )}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          label="Cidade"
          variant="outlined"
          fullWidth
          {...register('address.city')}
          required
          error={!!errors.address?.city}
          helperText={errors.address?.city?.message}
        />
      </Grid>

      <Snackbar
        open={!!messageError}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          {messageError}
        </Alert>
      </Snackbar>
    </Grid>
  )
}

export default CreditCard
