import { zodResolver } from '@hookform/resolvers/zod'
import CloseIcon from '@mui/icons-material/Close'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import { LoadingButton } from '@mui/lab'
import {
  Alert,
  Button,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography,
} from '@mui/material'
import { Loading } from 'components'
import React, { memo, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { apiV1 } from 'services'
import { ISetor } from 'types/setor'
import { functionWithLoading } from 'utils'
import { z } from 'zod'

const schema = z.object({
  titulo: z.string().min(1, 'Preencha o título do setor!'),
})

type SectorFormProps = {
  eventId: number
  sectorId: number
  callback: () => void
  isAdmin: boolean
}

const SectorForm = ({
  eventId,
  sectorId = 0,
  callback,
  isAdmin,
}: SectorFormProps) => {
  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState('')
  const [success, setSuccess] = React.useState('')

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<ISetor>({
    resolver: zodResolver(schema),
    defaultValues: {
      titulo: '',
    },
  })

  useEffect(() => {
    if (sectorId !== 0) {
      if (isAdmin) {
        functionWithLoading(
          apiV1.admin.sectorService
            .get(eventId, sectorId)
            .then(({ data }) => reset(data))
            .catch((err) => {
              setError(err.response?.data.message ?? err.message)
            }),
          setLoading,
        )
      } else {
        functionWithLoading(
          apiV1.producer.sectorService
            .get(eventId, sectorId)
            .then(({ data }) => reset(data))
            .catch((err) => {
              setError(err.response?.data.message ?? err.message)
            }),
          setLoading,
        )
      }
    } else {
      setLoading(false)
    }
  }, [eventId, isAdmin, reset, sectorId])

  const onSubmit = (data: ISetor) => {
    if (isSubmitting) {
      return
    }
    if (sectorId === 0) {
      if (isAdmin) {
        functionWithLoading(
          apiV1.admin.sectorService
            .create(eventId, data)
            .then(() => {
              setSuccess('Setor cadastrado!')
              callback()
            })
            .catch((err) => {
              setError(err.response?.data.message ?? err.message)
            }),
          setLoading,
        )
      } else {
        functionWithLoading(
          apiV1.producer.sectorService
            .create(eventId, data)
            .then(() => {
              setSuccess('Setor cadastrado!')
              callback()
            })
            .catch((err) => {
              setError(err.response?.data.message ?? err.message)
            }),
          setLoading,
        )
      }
    } else {
      if (isAdmin) {
        functionWithLoading(
          apiV1.admin.sectorService
            .update(eventId, { ...data, id: sectorId })
            .then(({ data }) => {
              setSuccess('Setor atualizado!')
              reset(data)
            })
            .catch((err) => {
              setError(err.response?.data.message ?? err.message)
            }),
          setLoading,
        )
      } else {
        functionWithLoading(
          apiV1.producer.sectorService
            .update(eventId, { ...data, id: sectorId })
            .then(({ data }) => {
              setSuccess('Setor atualizado!')
              reset(data)
            })
            .catch((err) => {
              setError(err.response?.data.message ?? err.message)
            }),
          setLoading,
        )
      }
    }
  }

  if (loading) return <Loading />

  return (
    <Grid container component={Paper} spacing={2} sx={{ p: 2 }}>
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h6">
          {sectorId === 0 ? 'Cadastrar' : 'Atualizar'} setor
        </Typography>
        <IconButton
          aria-label="delete"
          size="large"
          color="primary"
          onClick={callback}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      </Grid>
      <Grid item xs={12}>
        <TextField
          variant="outlined"
          label="Título"
          error={!!errors.titulo}
          helperText={errors.titulo?.message}
          {...register('titulo')}
          fullWidth
          size="small"
        />
      </Grid>
      {error && (
        <Grid item xs={12}>
          <Alert severity="error" sx={{ mt: 1 }} onClose={() => setError('')}>
            {error}
          </Alert>
        </Grid>
      )}
      {success && (
        <Grid item xs={12}>
          <Alert
            severity="success"
            sx={{ mt: 1 }}
            onClose={() => setSuccess('')}
          >
            {success}
          </Alert>
        </Grid>
      )}
      <Grid
        item
        xs={12}
        sx={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <Button
          variant="outlined"
          color="error"
          onClick={callback}
          sx={{ mt: 3, mb: 2 }}
        >
          Fechar
        </Button>
        <LoadingButton
          loading={loading}
          loadingPosition="start"
          startIcon={<SaveOutlinedIcon />}
          variant="outlined"
          onClick={handleSubmit(onSubmit)}
          sx={{ mt: 3, mb: 2 }}
        >
          Salvar
        </LoadingButton>
      </Grid>
    </Grid>
  )
}

export default memo(SectorForm)
