import { Facebook, Instagram, Mail, WhatsApp } from '@mui/icons-material'
import {
  Button,
  CardMedia,
  Container,
  Grid,
  IconButton,
  Link,
  TextField,
  useTheme,
} from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Copyright } from 'components'
import * as React from 'react'
import { Link as RouterLink } from 'react-router-dom'

const Footer: React.FC = () => {
  const theme = useTheme()

  return (
    <Box
      component="footer"
      sx={{
        pt: 3,
        pb: 1,
        px: 2,
        mt: 'auto',
        backgroundColor: theme.palette.primary.main,
        color: '#FFF',
      }}
      position="sticky"
    >
      <Container maxWidth="lg">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <CardMedia
              component="img"
              image="https://images.zedoingresso.com.br/zedoingresso/imgs/header-logo-white.png"
              alt="Zé do Ingresso"
              height={50}
              sx={{ mb: 1, width: 'auto' }}
            />
            <Typography variant="caption" component="p" sx={{ pr: 2 }}>
              O Zé do Ingresso é uma plataforma de venda de ingressos segura,
              intuitiva e rápida. Oferecendo venda de ingressos físicos e online
              para produtores de eventos.
            </Typography>
            <Box display="flex">
              <IconButton
                size="large"
                href="https://www.instagram.com/zedoingresso"
                target="_blank"
                color="inherit"
              >
                <Instagram />
              </IconButton>
              <IconButton
                size="large"
                href="https://www.facebook.com/zedoingresso"
                target="_blank"
                color="inherit"
              >
                <Facebook />
              </IconButton>
              <IconButton
                size="large"
                href="https://api.whatsapp.com/send?phone=5517996147824&text=Olá,%20vim através do site (contato)"
                target="_blank"
                color="inherit"
              >
                <WhatsApp />
              </IconButton>
              <IconButton
                size="large"
                href="mailto://contato@zedoingresso.com.br"
                target="_blank"
                color="inherit"
              >
                <Mail />
              </IconButton>
            </Box>
          </Grid>
          <Grid item xs={6} sm={3} md={2}>
            <Typography variant="body1" sx={{ mb: 2 }} gutterBottom>
              Links úteis
            </Typography>
            <Typography
              variant="caption"
              to="/organizador"
              component={RouterLink}
              color="inherit"
              gutterBottom
            >
              Área do produtor
            </Typography>
            <br />
            <Typography
              variant="caption"
              to="/parceiro"
              component={RouterLink}
              color="inherit"
              gutterBottom
            >
              Área do parceiro
            </Typography>
            <br />
            <Typography
              variant="caption"
              href="https://api.whatsapp.com/send?phone=5517996147824&text=Ol%C3%A1%2C%20gostaria%20de%20cadastrar%20meu%20evento%20no%20site."
              component={Link}
              sx={{ textDecoration: 'underline' }}
              color="inherit"
              gutterBottom
            >
              Cadastre seu evento
            </Typography>
            <br />
            <Typography
              variant="caption"
              href="https://api.whatsapp.com/send?phone=5517996147824&text=Ol%C3%A1,%20vim%20atrav%C3%A9s%20do%20site%20(contato)."
              component={Link}
              sx={{ textDecoration: 'underline' }}
              color="inherit"
              gutterBottom
            >
              Contato / Suporte
            </Typography>
          </Grid>
          <Grid item xs={6} sm={3} md={2}>
            <Typography variant="body1" sx={{ mb: 2 }}>
              Zé do Ingresso
            </Typography>
            <Typography
              variant="caption"
              component={RouterLink}
              to="/duvidas"
              color="inherit"
              gutterBottom
            >
              Dúvidas
            </Typography>
            <br />
            <Typography
              variant="caption"
              component={RouterLink}
              to="/usuario/pedidos"
              color="inherit"
              gutterBottom
            >
              Meus ingressos
            </Typography>
            <br />
            {/* <Typography
              variant="caption"
              component={RouterLink}
              to="/"
              color="inherit"
            >
              Minhas informações
            </Typography>
            <br /> */}
            <Typography
              variant="caption"
              component={RouterLink}
              to="/termos-de-uso"
              color="inherit"
            >
              Termos de uso
            </Typography>
            <br />
            <Typography
              variant="caption"
              component={RouterLink}
              to="/politica-de-privacidade"
              color="inherit"
            >
              Política de Privacidade
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="body1" sx={{ mb: 2 }}>
              Fique por dentro
            </Typography>
            <Typography variant="caption" component="p" sx={{ mb: 2 }}>
              Se inscreva na nossa lista de e-mail e fique por dentro dos
              próximos eventos da sua região.
            </Typography>
            <Box
              sx={{
                backgroundColor: '#FFF',
                borderRadius: 10,
                p: 1,
                alignItems: 'center',
                display: 'flex',
              }}
            >
              <TextField
                hiddenLabel
                placeholder="Digite se e-mail..."
                variant="standard"
                sx={{ color: '#FFF', ml: 1 }}
                size="small"
                fullWidth
              />
              <Button variant="contained" sx={{ borderRadius: 10, px: 3 }}>
                Inscrever
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Copyright />
      </Container>
    </Box>
  )
}

export default Footer
