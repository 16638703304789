import { Box } from '@mui/material'
import { Warning } from '@phosphor-icons/react'
import { Typography } from 'components/Typography'
import { Card } from 'pages/admin/components/card'
import { useNavigate } from 'react-router-dom'

type Props = {
  data?: number | null
}

export function SuspectedUsers({ data = 0 }: Props) {
  const navigate = useNavigate()

  return (
    <Card
      title="Usuários suspeitos"
      color="#fff000"
      icon={<Warning size={48} />}
      onClick={() => navigate('/admin/usuarios-suspeitos')}
    >
      <Box flex={1}>
        <Typography variant="h4" color="inherit" flex={1} textAlign="right">
          {data}
        </Typography>
      </Box>
    </Card>
  )
}
