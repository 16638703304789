import { Search } from '@mui/icons-material'
import { Grid, InputAdornment, Paper, TextField } from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { Loading } from 'components'
import { BreadcrumbItem, Breadcrumbs } from 'components/Breadcrumbs'
import { Typography } from 'components/Typography'
import { usePromoter } from 'contexts/promoterContext'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import clientProducer from 'services/api/v1/producer/clientProducer'
import { moneyFormatter } from 'utils/formatter'

type ReportItem = {
  id: number
  name: string
  email: string
  money: number
  credit: number
  debit: number
  pix: number
  total: number
}

type ReportType = {
  digital: ReportItem[]
  physical: ReportItem[]
}

export function GeneralReportPerUser() {
  const [isLoading, setIsLoading] = useState(false)
  const [report, setReport] = useState<ReportType>()
  const [query, setQuery] = useState('')

  const { eventId } = useParams()
  const { selectedEvent } = usePromoter()

  const breadcrumbs: BreadcrumbItem[] = [
    { title: 'Eventos', to: '/organizador/eventos' },
    {
      title: selectedEvent?.titulo ?? '...',
      to: `/organizador/eventos/${eventId}`,
    },
    { title: 'Relatório de vendas geral por usuário' },
  ]

  useEffect(() => {
    async function bootstrap() {
      try {
        setIsLoading(true)
        const { data } = await clientProducer.get(
          `/events/${eventId}/reports/general-report-per-user`,
        )
        setReport(data)
      } catch (error) {
      } finally {
        setIsLoading(false)
      }
    }
    bootstrap()
  }, [eventId])

  if (isLoading) return <Loading />

  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Usuário', flex: 1, minWidth: 200 },
    { field: 'email', headerName: 'E-mail', flex: 1, minWidth: 200 },
    {
      field: 'money',
      headerName: 'Dinheiro',
      type: 'number',
      width: 130,
      renderCell: ({ value }) => moneyFormatter.format(value),
    },
    {
      field: 'debit',
      headerName: 'Débito',
      type: 'number',
      width: 130,
      renderCell: ({ value }) => moneyFormatter.format(value),
    },
    {
      field: 'credit',
      headerName: 'Crédito',
      type: 'number',
      width: 130,
      renderCell: ({ value }) => moneyFormatter.format(value),
    },
    {
      field: 'pix',
      headerName: 'Pix',
      type: 'number',
      width: 130,
      renderCell: ({ value }) => moneyFormatter.format(value),
    },
    {
      field: 'total',
      headerName: 'Total',
      type: 'number',
      width: 130,
      renderCell: ({ value }) => moneyFormatter.format(value),
    },
  ]

  const rowsDigital =
    report?.digital.filter((row) =>
      row.name.toLowerCase().includes(query.toLowerCase()),
    ) ?? []

  const rowsPhysical =
    report?.physical.filter((row) =>
      row.name.toLowerCase().includes(query.toLowerCase()),
    ) ?? []

  return (
    <Grid container spacing={2} p={2} component={Paper}>
      <Grid item xs={12}>
        <Breadcrumbs items={breadcrumbs} />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h4">
          Relatório de vendas geral por usuário
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Pesquisar"
          variant="outlined"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          onChange={(e) => setQuery(e.target.value)}
          value={query}
          size="small"
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6" component="p">
          Vendas no APP
        </Typography>
        <DataGrid rows={rowsDigital} columns={columns} autoHeight />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6" component="p">
          Vendas no PDV
        </Typography>
        <DataGrid rows={rowsPhysical} columns={columns} autoHeight />
      </Grid>
    </Grid>
  )
}
