import Typography from '@mui/material/Typography'
import { useShoppingCart } from 'contexts/shoppingCartContext'
import { IIngresso } from 'types/ingresso'
import { moneyFormatter } from 'utils/formatter'
import { compare } from 'utils/functions'

import { Grid } from '@mui/material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { ShoppingCartDiscountCoupon } from 'components/ShoppingCartDiscountCoupon'
import { useParams } from 'react-router-dom'

interface ProductListItemProps {
  product: IIngresso & { amount: number }
}

const ProductListItem = ({ product }: ProductListItemProps) => {
  const serviceFee =
    (Number(product.taxa_percentual) * Number(product.valor)) / 100 +
    Number(product.taxa_fixa)
  const absorbedFee =
    product.taxa_absorvida > 0
      ? (serviceFee * Number(product.taxa_absorvida)) / 100
      : 0
  const totalFees = serviceFee - absorbedFee
  return (
    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <TableCell component="th" scope="row">
        <Typography variant="body2">{product.titulo}</Typography>
        <Typography variant="caption">taxa de serviço</Typography>
      </TableCell>
      <TableCell align="right">
        <Typography variant="body2">
          {moneyFormatter.format(product.valor)}
        </Typography>
        <Typography variant="caption">
          {moneyFormatter.format(totalFees)}
        </Typography>
      </TableCell>
      <TableCell align="right">
        <Typography variant="body2">{product.amount}</Typography>
      </TableCell>
      <TableCell align="right">
        <Typography variant="body2">
          {moneyFormatter.format(product.valor * product.amount)}
        </Typography>
        <Typography variant="caption">
          {moneyFormatter.format(totalFees * product.amount)}
        </Typography>
      </TableCell>
    </TableRow>
  )
}

export default function Checkout() {
  const { shoppingCart, totalShoppingCartValue } = useShoppingCart()
  const items = shoppingCart.sort((a, b) =>
    compare(a.titulo.toLowerCase(), b.titulo.toLowerCase()),
  )
  const { eventId } = useParams()

  return (
    <Grid container>
      <Grid item xs={12} p={1}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Lote</TableCell>
                <TableCell align="right">Preço</TableCell>
                <TableCell align="right">Qtd.</TableCell>
                <TableCell align="right">Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((product, index) => (
                <ProductListItem
                  key={product.id + '_' + index}
                  product={product}
                />
              ))}
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell colSpan={4}>
                  <ShoppingCartDiscountCoupon eventId={Number(eventId)} />
                </TableCell>
              </TableRow>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell colSpan={4}>
                  <Typography
                    variant="subtitle1"
                    textAlign="right"
                    sx={{ fontWeight: 700 }}
                    flex={1}
                  >
                    Total: {moneyFormatter.format(totalShoppingCartValue)}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  )
}
