import { Box, Card, CardProps, Typography } from '@mui/material'
import { constants } from 'config'
import moment from 'moment'
import { IEvento } from 'types/evento'
import { normalizeDateTime } from 'utils/formatter'

type EventCardProps = CardProps & {
  event: IEvento
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  extra?: any
  backgroundColor?: string
}

const EventCard = ({
  event,
  extra,
  backgroundColor,
  onClick,
  ...rest
}: EventCardProps) => {
  return (
    <Card
      sx={{
        bgcolor: backgroundColor ?? '#FFFFFF',
        cursor: onClick ? 'pointer' : 'default',
      }}
      elevation={10}
      onClick={onClick}
      {...rest}
    >
      <img
        src={constants.URL_FTP + event.imagem}
        style={{ aspectRatio: '16 / 9', width: '100%', objectFit: 'cover' }}
        alt=""
      />

      <Box sx={{ display: 'flex', pt: 1, px: 2, paddingBottom: 0 }}>
        <Box
          sx={{
            pr: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="subtitle2"
            component="span"
            sx={{ textTransform: 'uppercase' }}
          >
            {normalizeDateTime(moment(event.data).format()).format('MMM')}
          </Typography>
          <Typography variant="h6" component="span">
            {normalizeDateTime(moment(event.data).format()).format('DD')}
          </Typography>
          <Typography variant="caption" gutterBottom>
            {normalizeDateTime(moment(event.data).format()).format('ddd')}
          </Typography>
        </Box>
        <Box flex={1} sx={{ px: 1, overflow: 'hidden' }}>
          <Typography variant="subtitle2" gutterBottom component="div" noWrap>
            {event.titulo}
          </Typography>
          <Typography
            variant="subtitle2"
            gutterBottom
            component="div"
            fontWeight={100}
            noWrap
          >
            {event?.local?.nome ?? event.local_evento}
          </Typography>
          <Typography
            variant="subtitle2"
            gutterBottom
            component="div"
            fontWeight={100}
            noWrap
          >
            {`${event.local?.cidade?.nome ?? event.brasil_cidade?.cidade} - ${
              event.local?.cidade?.uf ?? event.brasil_cidade?.uf
            }`}
          </Typography>
        </Box>
        {/* {event.ingresso && event.ingresso?.length > 0 && (
      <Tooltip title="Ingresso com taxa ZERO">
        <IconButton color="success">
          <LocalOffer />
        </IconButton>
      </Tooltip>
    )} */}
      </Box>
      {extra && extra()}
    </Card>
  )
}

export default EventCard
