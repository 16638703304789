import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  useTheme,
} from '@mui/material'
import Logo from 'assets/img/logo.jpg'
import { Loading } from 'components'
import { Breadcrumbs } from 'components/Breadcrumbs'
import { Typography } from 'components/Typography'
import { useAdmin } from 'contexts/adminContext'
import dayjs from 'dayjs'
import { useHttp } from 'hooks/useHttp'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { IEvento } from 'types/evento'
import { getErrorMessage } from 'utils/AppError'
import { moneyFormatter } from 'utils/formatter'

type ServiceItemType = {
  id: number
  description: string
  quantity: number
  value: number
  total: number
}

type WithdrawType = {
  id: number
  date: string
  recipient: string
  bank: string
  key: string
  value: number
}

type HttpResponse = {
  event: IEvento
  digital: {
    amount: number
    amountBlockedForWithdraw: number
    amountWithdrawn: number
    advancePercentage: number
    daysToRetainTheWithdraw: number
    absorbedFees: number
    discountCoupons: number
    services: {
      total: number
      items: ServiceItemType[]
    }
    withdraws: { total: number; items: WithdrawType[] }
  }
  app: {
    amount: number
    quantity: number
  }
  physical: {
    amount: number
    sales: {
      money: number
      creditCard: number
      debitCard: number
      pix: number
      total: number
    }
    fees: {
      money: number
      creditCard: number
      debitCard: number
      pix: number
      total: number
    }
    absorbedFees: number
    amountBlockedForWithdraw: number
    amountWithdrawn: number
    advancePercentage: number
    daysToRetainTheWithdraw: number
    services: {
      total: number
      items: ServiceItemType[]
    }
    withdraws: { total: number; items: WithdrawType[] }
  }
}

export function EventClosingReportPage() {
  const eventId = useParams().eventId ?? 0

  const { addErrorMessage } = useAdmin()
  const theme = useTheme()

  const http = useHttp<HttpResponse>({
    clientType: 'admin',
    url: `/events/${eventId}/reports/event-completion`,
    onError: (error) => addErrorMessage(getErrorMessage(error)),
  })

  useEffect(() => {
    http.execute()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (http.isLoading || !http.data) return <Loading />

  const { event, digital, physical, app } = http.data

  const digitalExpenses = digital.absorbedFees + digital.discountCoupons

  const digitalAmount =
    (digital.amount ?? 0) - digitalExpenses - digital.services.total

  const physicalExpenses = physical.absorbedFees

  let physicalAmount = 0

  if (event.bar) {
    physicalAmount =
      (physical.sales.total ?? 0) -
      (physical.sales.money ?? 0) -
      (physical.fees.total ?? 0) -
      physical.services.total -
      physicalExpenses
  } else {
    physicalAmount =
      (physical.amount ?? 0) - physicalExpenses - physical.services.total
  }

  const totalValue =
    (digitalAmount ?? 0) -
    (digital.withdraws.total ?? 0) +
    ((physicalAmount ?? 0) - (physical.withdraws.total ?? 0))

  return (
    <Grid container maxWidth="md">
      <Grid item xs={12}>
        <Breadcrumbs
          items={[
            { title: 'Eventos', to: '/admin/events' },
            {
              title: event.titulo ?? '...',
              to: `/admin/events/${eventId}`,
            },
            { title: 'Relatório de finalização' },
          ]}
        />
      </Grid>

      <Grid item container component={Paper} p={2} gap={2}>
        <Grid item xs={12} display="flex" justifyContent="center">
          <img
            src={Logo}
            alt=""
            style={{ width: 200, height: 100, objectFit: 'cover' }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography
            style={{
              backgroundColor: '#003971',
              color: theme.palette.common.white,
              textAlign: 'center',
              fontWeight: 'bold',
            }}
          >
            {event.id} - {event.titulo}
          </Typography>
        </Grid>
        <Grid item container xs={12} spacing={1}>
          <Grid item xs={12} sm={4}>
            <Typography
              style={{
                backgroundColor: '#F8bd00',
                color: theme.palette.common.black,
                textAlign: 'center',
              }}
            >
              TOTAL DE VENDAS DIGITAL (SITE)
              <Typography variant="h6" fontWeight="bold" component="p">
                {moneyFormatter.format(digital.amount)}
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography
              style={{
                backgroundColor: '#F8bd00',
                color: theme.palette.common.black,
                textAlign: 'center',
              }}
            >
              TOTAL DE VENDAS DIGITAL (APP)
              <Typography variant="h6" fontWeight="bold" component="p">
                {moneyFormatter.format(app.amount)}
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography
              style={{
                backgroundColor: '#F8bd00',
                color: theme.palette.common.black,
                textAlign: 'center',
              }}
            >
              TOTAL DE VENDAS FÍSICAS (PDV)
              <Typography variant="h6" fontWeight="bold" component="p">
                {moneyFormatter.format(physical.amount)}
              </Typography>
            </Typography>
          </Grid>
        </Grid>

        {digital.amount > 0 && (
          <Grid item xs={12} border={1}>
            <Typography
              style={{
                backgroundColor: '#003971',
                color: theme.palette.common.white,
                textAlign: 'center',
                fontWeight: 'bold',
              }}
            >
              VENDAS DIGITAL
            </Typography>

            <Grid container justifyContent="space-between" p={1} gap={1}>
              <Grid item xs={12} border="solid 1px gray">
                <TableContainer>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Custos de venda</TableCell>
                        <TableCell align="right">Valor</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>TAXAS ABSORVIDAS</TableCell>
                        <TableCell align="right">
                          {moneyFormatter.format(digital.absorbedFees)}
                        </TableCell>
                      </TableRow>
                      {/* <TableRow>
                        <TableCell>TAXAS PARCELAMENTO</TableCell>
                        <TableCell align="right">
                          {moneyFormatter.format(0)}
                        </TableCell>
                      </TableRow> */}
                      <TableRow>
                        <TableCell>CUPONS DESCONTO</TableCell>
                        <TableCell align="right">
                          {moneyFormatter.format(digital.discountCoupons)}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TableCell>TOTAL</TableCell>
                        <TableCell align="right">
                          {moneyFormatter.format(digitalExpenses)}
                        </TableCell>
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={12} border="solid 1px gray">
                <TableContainer>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Serviço</TableCell>
                        <TableCell align="right">Valor</TableCell>
                        <TableCell align="right">Qtd.</TableCell>
                        <TableCell align="right">Total</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {digital.services.items.map((service) => (
                        <TableRow key={service.id}>
                          <TableCell>{service.description}</TableCell>
                          <TableCell align="right">
                            {moneyFormatter.format(service.value ?? 0)}
                          </TableCell>
                          <TableCell align="right">
                            {service.quantity}
                          </TableCell>
                          <TableCell align="right">
                            {moneyFormatter.format(service.total ?? 0)}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TableCell>Total</TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell align="right">
                          {moneyFormatter.format(digital.services.total ?? 0)}
                        </TableCell>
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              </Grid>

              <Grid item xs={12}>
                <Typography
                  style={{
                    backgroundColor: theme.palette.success.dark,
                    color: theme.palette.common.white,
                    display: 'flex',
                    justifyContent: 'space-between',
                    fontWeight: 'bold',
                    paddingInline: 4,
                  }}
                >
                  VALOR DE REPASSE
                  <Typography style={{ fontWeight: 'bold' }}>
                    {moneyFormatter.format(digitalAmount)}
                  </Typography>
                </Typography>
                <Typography
                  variant="caption"
                  display="flex"
                  justifyContent="flex-end"
                  component="p"
                >
                  * Repasse = (Valor de vendas) - (taxas) - (serviços)
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}

        {physical.amount > 0 && (
          <Grid item xs={12} border={1}>
            <Typography
              style={{
                backgroundColor: '#003971',
                color: theme.palette.common.white,
                textAlign: 'center',
                fontWeight: 'bold',
              }}
            >
              VENDAS FÍSICAS
            </Typography>

            <Grid container justifyContent="space-between" p={1} gap={1}>
              <Grid item xs={12} border="solid 1px gray">
                <TableContainer>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Custos de venda</TableCell>
                        <TableCell align="right">Valor</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>TAXAS ABSORVIDAS</TableCell>
                        <TableCell align="right">
                          {moneyFormatter.format(physical.absorbedFees)}
                        </TableCell>
                      </TableRow>
                      {/* <TableRow>
                        <TableCell>TAXAS PARCELAMENTO</TableCell>
                        <TableCell align="right">
                          {moneyFormatter.format(0)}
                        </TableCell>
                      </TableRow> */}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TableCell>TOTAL</TableCell>
                        <TableCell align="right">
                          {moneyFormatter.format(physicalExpenses)}
                        </TableCell>
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              </Grid>

              {event.bar && (
                <Grid item xs={12} border="solid 1px gray">
                  <TableContainer>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Taxa de pagamento</TableCell>
                          <TableCell align="right">Recita bruta</TableCell>
                          <TableCell align="right">Taxa (%)</TableCell>
                          <TableCell align="right">Taxa (R$)</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>Crédito</TableCell>
                          <TableCell align="right">
                            {moneyFormatter.format(physical.sales.creditCard)}
                          </TableCell>
                          <TableCell align="right">
                            {event.evento_taxa && event.evento_taxa[0]
                              ? `${event.evento_taxa[0].credito_a_vista}%`
                              : 'N/A'}
                          </TableCell>
                          <TableCell align="right">
                            {moneyFormatter.format(physical.fees.creditCard)}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Débito</TableCell>
                          <TableCell align="right">
                            {moneyFormatter.format(physical.sales.debitCard)}
                          </TableCell>
                          <TableCell align="right">
                            {event.evento_taxa && event.evento_taxa[0]
                              ? `${event.evento_taxa[0].debito}%`
                              : 'N/A'}
                          </TableCell>
                          <TableCell align="right">
                            {moneyFormatter.format(physical.fees.debitCard)}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Pix</TableCell>
                          <TableCell align="right">
                            {moneyFormatter.format(physical.sales.pix)}
                          </TableCell>
                          <TableCell align="right">
                            {event.evento_taxa && event.evento_taxa[0]
                              ? `${event.evento_taxa[0].pix}%`
                              : 'N/A'}
                          </TableCell>
                          <TableCell align="right">
                            {moneyFormatter.format(physical.fees.pix)}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Dinheiro</TableCell>
                          <TableCell align="right">
                            {moneyFormatter.format(physical.sales.money)}
                          </TableCell>
                          <TableCell align="right">
                            {event.evento_taxa && event.evento_taxa[0]
                              ? `${event.evento_taxa[0].dinheiro}%`
                              : 'N/A'}
                          </TableCell>
                          <TableCell align="right">
                            {moneyFormatter.format(physical.fees.money)}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                      <TableFooter>
                        <TableRow>
                          <TableCell>TOTAL</TableCell>
                          <TableCell align="right">
                            {moneyFormatter.format(physical.sales.total)}
                          </TableCell>
                          <TableCell />
                          <TableCell align="right">
                            {moneyFormatter.format(physical.fees.total)}
                          </TableCell>
                        </TableRow>
                      </TableFooter>
                    </Table>
                  </TableContainer>
                </Grid>
              )}

              <Grid item xs={12} border="solid 1px gray">
                <TableContainer>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Serviço</TableCell>
                        <TableCell align="right">Valor</TableCell>
                        <TableCell align="right">Qtd.</TableCell>
                        <TableCell align="right">Total</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {physical.services.items.map((service) => (
                        <TableRow key={service.id}>
                          <TableCell>{service.description}</TableCell>
                          <TableCell align="right">
                            {moneyFormatter.format(service.value)}
                          </TableCell>
                          <TableCell align="right">
                            {service.quantity}
                          </TableCell>
                          <TableCell align="right">
                            {moneyFormatter.format(service.total)}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TableCell colSpan={3}>TOTAL</TableCell>
                        <TableCell align="right">
                          {moneyFormatter.format(physical.services.total)}
                        </TableCell>
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              </Grid>

              <Grid item xs={12}>
                <Typography
                  style={{
                    backgroundColor: theme.palette.success.dark,
                    color: theme.palette.common.white,
                    display: 'flex',
                    justifyContent: 'space-between',
                    fontWeight: 'bold',
                    paddingInline: 4,
                  }}
                >
                  VALOR DE REPASSE
                  <Typography style={{ fontWeight: 'bold' }}>
                    {moneyFormatter.format(physicalAmount)}
                  </Typography>
                </Typography>
                <Typography
                  variant="caption"
                  display="flex"
                  justifyContent="flex-end"
                  component="p"
                >
                  * Repasse = (Valor de vendas) - (vendas em dinheiro) - (taxas)
                  - (serviços)
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}

        <Grid item xs={12} border={1}>
          <Typography
            style={{
              backgroundColor: '#003971',
              color: theme.palette.common.white,
              textAlign: 'center',
              fontWeight: 'bold',
            }}
          >
            RESUMO GERAL
          </Typography>

          <Grid item xs={12}>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>CANAL DE VENDA</TableCell>
                    <TableCell align="right">SALDO</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>SITE</TableCell>
                    <TableCell align="right">
                      {moneyFormatter.format(digitalAmount ?? 0)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>APP</TableCell>
                    <TableCell align="right">
                      {moneyFormatter.format(app.amount ?? 0)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>PDV</TableCell>
                    <TableCell align="right">
                      {moneyFormatter.format(physicalAmount ?? 0)}
                    </TableCell>
                  </TableRow>
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TableCell>TOTAL</TableCell>
                    <TableCell align="right">
                      {moneyFormatter.format(
                        digitalAmount + (app.amount ?? 0) + physicalAmount,
                      )}
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>

        <Grid item xs={12} border={1}>
          <Typography
            style={{
              backgroundColor: '#003971',
              color: theme.palette.common.white,
              textAlign: 'center',
              fontWeight: 'bold',
            }}
          >
            REPASSES / SAQUES
          </Typography>

          <Grid item xs={12}>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Data</TableCell>
                    <TableCell>Canal de vendas</TableCell>
                    <TableCell>Beneficiário</TableCell>
                    <TableCell align="right">Valor</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {physical.withdraws.items.map((withdrawn) => (
                    <TableRow key={withdrawn.id}>
                      <TableCell>
                        {dayjs(withdrawn.date).format('DD/MM/YYYY')}
                      </TableCell>
                      <TableCell>Físico</TableCell>
                      <TableCell>{withdrawn.recipient}</TableCell>
                      <TableCell align="right">
                        {moneyFormatter.format(withdrawn.value ?? 0)}
                      </TableCell>
                    </TableRow>
                  ))}
                  {digital.withdraws.items.map((withdrawn) => (
                    <TableRow key={withdrawn.id}>
                      <TableCell>
                        {dayjs(withdrawn.date).format('DD/MM/YYYY')}
                      </TableCell>
                      <TableCell>Digital</TableCell>
                      <TableCell>{withdrawn.recipient}</TableCell>
                      <TableCell align="right">
                        {moneyFormatter.format(withdrawn.value ?? 0)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TableCell>TOTAL</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell align="right">
                      {moneyFormatter.format(
                        (digital.withdraws.total ?? 0) +
                          (physical.withdraws.total ?? 0),
                      )}
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>

        <Grid item xs={12} border={1}>
          <Typography
            variant="h4"
            component="p"
            style={{
              backgroundColor: theme.palette.success.light,
              color: theme.palette.common.white,
              textAlign: 'center',
              fontWeight: 'bold',
            }}
          >
            RESULTADO DO ACERTO: {moneyFormatter.format(totalValue)}
          </Typography>
          <Typography
            variant="caption"
            textAlign="center"
            display="flex"
            justifyContent="center"
            component="p"
          >
            Gerado em: {dayjs().format('DD/MM/YYYY HH:mm:ss')}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}
