import axios from 'axios'
import { isPagSeguroError, PagSeguroError } from './PagSeguroError'

export class AppError {
  message: string

  constructor(message: string) {
    this.message = message
  }
}

export function getErrorMessage(error: unknown, defaultMessage?: string) {
  let message = 'Ocorreu um imprevisto, por favor tente novamente!'

  if (defaultMessage) message = defaultMessage

  if (error instanceof AppError) {
    message = error.message
  } else if (axios.isAxiosError(error)) {
    message = error.response?.data.message ?? error.message
  } else if (error instanceof PagSeguroError) {
    message = error.detail.errorMessages[0].description
  } else if (isPagSeguroError(error)) {
    message = error.detail.errorMessages[0].description
  }

  return message
}
