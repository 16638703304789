import React from 'react'
import { Box, CircularProgress } from '@mui/material'

const Loading: React.FC = () => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <CircularProgress />
    </Box>
  )
}

export default Loading
