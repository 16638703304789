import { zodResolver } from '@hookform/resolvers/zod'
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  Paper,
} from '@mui/material'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { apiV1 } from 'services'
import { z } from 'zod'

const allTopics = ['nome', 'sobrenome', 'email', 'ddd', 'telefone']

const defaultValues = {
  topics: ['nome', 'sobrenome', 'email', 'ddd', 'telefone'],
}

const validationSchema = z.object({
  topics: z.array(z.string()).min(1),
})

const ExportCustomers: React.FC = () => {
  const { eventId } = useParams()

  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: zodResolver(validationSchema),
  })

  const exportCustomers = () => {
    apiV1.admin.eventService
      .exportCustomers(Number(eventId), watch('topics'))
      .then(({ data, headers }) => {
        const headerLine =
          headers['content-disposition'] || headers['Content-Disposition']
        const startFileNameIndex = headerLine.indexOf('"') + 1
        const endFileNameIndex = headerLine.lastIndexOf('"')
        const filename = headerLine.substring(
          startFileNameIndex,
          endFileNameIndex,
        )
        const url = window.URL.createObjectURL(new Blob([data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${filename}`)
        document.body.appendChild(link)
        link.click()
      })
  }

  return (
    <Grid container component={Paper}>
      <FormControl component="fieldset" error={!!errors?.topics}>
        <FormLabel component="legend">Campos</FormLabel>
        <FormGroup row>
          <Controller
            name="topics"
            control={control}
            render={({ field }) => (
              <React.Fragment>
                {allTopics.map((item) => (
                  <FormControlLabel
                    {...field}
                    key={item}
                    label={item}
                    control={
                      <Checkbox
                        onChange={() => {
                          if (!field.value.includes(item)) {
                            field.onChange([...field.value, item])
                            return
                          }
                          const newTopics = field.value.filter(
                            (topic) => topic !== item,
                          )
                          field.onChange(newTopics)
                        }}
                        checked={field.value.includes(item)}
                      />
                    }
                  />
                ))}
              </React.Fragment>
            )}
          />
        </FormGroup>
        <FormHelperText>{errors?.topics?.message}</FormHelperText>
      </FormControl>

      <Grid item xs={12}>
        <Button
          onClick={() => handleSubmit(exportCustomers)()}
          variant="contained"
        >
          Exportar clientes
        </Button>
      </Grid>
    </Grid>
  )
}

export default ExportCustomers
