import { AxiosError } from 'axios'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { apiV1 } from 'services'
import { IUsuario } from 'types/usuario'
import { functionWithLoading } from 'utils'
import { UserContextType } from '../types/user'

export const UserContext = React.createContext<UserContextType | null>(null)

export const LogoutUser = () => {
  localStorage.removeItem('user')
  localStorage.removeItem('user_token')
  window.location.reload()
}

const UserProvider: React.FC<React.ReactNode> = ({ children }) => {
  const [user, setUser] = React.useState<IUsuario>({} as IUsuario)
  const [loading, setLoading] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState('')

  const navigate = useNavigate()

  React.useEffect(() => {
    const sessionUser = localStorage.getItem('user')
    if (sessionUser) {
      setUser(JSON.parse(sessionUser))
    }
  }, [])

  const login = (username: string, password: string) => {
    functionWithLoading(
      apiV1.userService
        .loginUser(username, password)
        .then((res) => {
          if (res.data.auth) {
            localStorage.setItem('user', JSON.stringify(res.data.usuario))
            localStorage.setItem('user_token', res.data.token)
            setUser(res.data.usuario)
            setErrorMessage('')
          } else {
            setErrorMessage('Usuário ou senha incorretos!')
          }
        })
        .catch((error: AxiosError) =>
          setErrorMessage(error.response?.data.message ?? error.message),
        ),
      setLoading,
    )
  }

  const logout = React.useCallback(() => {
    setUser({} as IUsuario)
    localStorage.removeItem('user')
    localStorage.removeItem('user_token')
    navigate('/')
  }, [navigate])

  return (
    <UserContext.Provider
      value={{
        user,
        login,
        logout,
        loading,
        errorMessage,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

const ERROR_MSG_PROVIDER_NEEDED = 'useUser must be used within a UserProvider'

export const useUser = () => {
  const context = React.useContext(UserContext)
  if (!context) {
    throw new Error(ERROR_MSG_PROVIDER_NEEDED)
  }
  return context
}

export default UserProvider
