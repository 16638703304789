import { Skeleton } from '@mui/material'
import { Loading } from 'components'
import { useAdmin } from 'contexts/adminContext'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import clientAdmin from 'services/api/v1/admin/client'
import { getErrorMessage } from 'utils/AppError'

export function UserDetails() {
  const [user, setUser] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const { addErrorMessage } = useAdmin()
  const { userId } = useParams()

  useEffect(() => {
    async function main() {
      try {
        setIsLoading(true)
        const { data } = await clientAdmin.get(`/users/${userId}`)
        setUser(data.user)
      } catch (error) {
        addErrorMessage(getErrorMessage(error))
      } finally {
        setIsLoading(false)
      }
    }

    main()
  }, [addErrorMessage, userId])

  if (isLoading)
    return (
      <Loading>
        <Skeleton variant="rectangular" height={400} />
      </Loading>
    )

  return (
    <div>
      <pre>
        <code>{JSON.stringify(user, null, 2)}</code>
      </pre>
    </div>
  )
}
