import { ISetor } from 'types/setor'
import clientAdmin from './client'

const getAll = (eventId: number) => {
  return clientAdmin.get<Array<ISetor>>(`events/${eventId}/sectors`)
}

const get = (eventId: number, sectorId: number) => {
  return clientAdmin.get<ISetor>(`events/${eventId}/sectors/${sectorId}`)
}

const create = (eventId: number, sector: ISetor) => {
  return clientAdmin.post<ISetor>(`events/${eventId}/sectors`, { sector })
}

const update = (eventId: number, sector: ISetor) => {
  return clientAdmin.patch<ISetor>(`events/${eventId}/sectors/${sector.id}`, {
    sector,
  })
}

const remove = (eventId: number, sectorId: number) => {
  return clientAdmin.delete<ISetor>(`events/${eventId}/sectors/${sectorId}`)
}

const updateOrdinations = (eventId: number, sectors: ISetor[]) => {
  return clientAdmin.patch(`events/${eventId}/sectors/ordinations`, {
    sectors,
  })
}

const sectorService = {
  getAll,
  get,
  create,
  update,
  remove,
  updateOrdinations,
}

export default sectorService
