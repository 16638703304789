import axios from 'axios'
import { AppError } from 'utils/AppError'

type AddressProps = {
  cep: string
  logradouro: string
  complemento: string
  bairro: string
  localidade: string
  uf: string
  ibge: string
  gia: string
  ddd: string
  siafi: string
}

async function findAddressByCEP(cep: string) {
  try {
    return await axios.get<AddressProps>(
      `https://viacep.com.br/ws/${cep}/json/`,
    )
  } catch (error) {
    throw new AppError('Erro ao acessar serviço de consulta de CEP!')
  }
}

const addressService = {
  findAddressByCEP,
}

export default addressService
