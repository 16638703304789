import { Button, Container, Paper, Stack } from '@mui/material'
import CountdownTimer from 'components/CountdownTimer'
import { Typography } from 'components/Typography'
import dayjs from 'dayjs'
import Image from 'mui-image'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { apiV1 } from 'services'

type RouteProps = {
  pix: {
    id: string
    expiration_date: string
    amount: {
      value: number
    }
    text: string
    links: {
      rel: string
      href: string
      media: string
      type: string
    }[]
  }
}

const PaymentWithPix: React.FC = () => {
  const { orderId } = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const { pix } = location.state as RouteProps

  const [isExpired, setIsExpired] = useState(
    dayjs(pix.expiration_date).isBefore(dayjs()),
  )

  async function copyCodePix() {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(pix.text)
    } else {
      return document.execCommand('copy', true, pix.text)
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isExpired && orderId) {
        apiV1.orderService
          .verifyPayment(orderId)
          .then(({ data }) => {
            if (data.order.cod_status !== 0) {
              navigate('/payment-gateway-return/pagseguro/' + orderId)
            }
          })
          .catch((err) => console.log(err))
      } else {
        clearInterval(interval)
      }
    }, 3000)

    return () => clearInterval(interval)
  }, [isExpired, navigate, orderId])

  return (
    <Container maxWidth="sm" sx={{ alignItems: 'center' }}>
      <Paper sx={{ p: 2 }}>
        <Typography variant="subtitle1">
          Leia esse QR Code no aplicativo do seu banco para efetuar o pagamento
          por Pix.
        </Typography>
        <Typography variant="subtitle2" textAlign="center">
          O código do Pix expira em:
        </Typography>
        <CountdownTimer
          targetDate={pix.expiration_date}
          callback={() => setIsExpired(true)}
        />
        {isExpired ? (
          <Stack m={4} mt={1} gap={4}>
            <Typography variant="h4" textAlign="center" color="red">
              O código do Pix expirou, refaça o processo de compra.
            </Typography>
            <Button variant="contained" onClick={() => window.history.back()}>
              Comprar novamente
            </Button>
          </Stack>
        ) : (
          <Stack m={4} mt={1} gap={4}>
            <Image
              src={pix.links[0].href}
              style={{ aspectRatio: '1 / 1' }}
              alt=""
            />

            <Typography variant="caption" sx={{ wordBreak: 'break-word' }}>
              {pix.text}
            </Typography>

            <Button variant="contained" onClick={copyCodePix}>
              Copiar Pix
            </Button>
          </Stack>
        )}

        <Typography variant="subtitle2">
          Após efetuar o pagamento aguarde alguns segundos para atualizar no
          sistema, após a verificação você será redirecionado para a página com
          seus ingressos.
        </Typography>
      </Paper>
    </Container>
  )
}

export default PaymentWithPix
