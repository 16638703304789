import { zodResolver } from '@hookform/resolvers/zod'
import {
  Autocomplete,
  Button,
  Grid,
  Paper,
  Skeleton,
  TextField,
} from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { DateTimePicker } from '@mui/x-date-pickers'
import { Loading } from 'components'
import { Typography } from 'components/Typography'
import { useAdmin } from 'contexts/adminContext'
import dayjs from 'dayjs'
import { useCallback, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { apiV1 } from 'services'
import clientAdmin from 'services/api/v1/admin/client'
import { IPromoter } from 'types/promoter'
import { datagrid } from 'utils'
import { getErrorMessage } from 'utils/AppError'
import { z } from 'zod'

const suspectedUsersFormSchema = z.object({
  initialDate: z.date(),
  finalDate: z.date(),
  cod_promoter: z
    .array(z.object({ id: z.number(), label: z.string() }))
    .transform((val) => val.map((x) => x.id))
    .optional(),
})

type SuspectedUsersFormInput = z.input<typeof suspectedUsersFormSchema>

type SuspectedUser = {
  id: number
  name: string
  alert_quantity: number
  blocked: boolean
}

export default function SuspectedUsers() {
  const [suspectedUsers, setSuspectedUsers] = useState<SuspectedUser[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [producers, setProducers] = useState<{ id: number; label: string }[]>(
    [],
  )

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm<SuspectedUsersFormInput>({
    resolver: zodResolver(suspectedUsersFormSchema),
    defaultValues: {
      initialDate: dayjs().startOf('year').toDate(),
      finalDate: dayjs().endOf('seconds').toDate(),
    },
  })

  const { addErrorMessage } = useAdmin()

  const fetchData = useCallback(
    async (formData: SuspectedUsersFormInput) => {
      try {
        setIsLoading(true)
        const suspectedUsersResponse = await clientAdmin.post(
          `/users/suspected`,
          {
            formData,
          },
        )
        setSuspectedUsers(suspectedUsersResponse.data.suspectedUsers)
      } catch (error) {
        addErrorMessage(getErrorMessage(error))
      } finally {
        setIsLoading(false)
      }
    },
    [addErrorMessage],
  )

  useEffect(() => {
    async function main() {
      try {
        setIsLoading(true)
        const promotersResponse = await apiV1.admin.promoterService.getList()
        setProducers(
          promotersResponse.data.promoters.map((x: IPromoter) => ({
            id: x.id,
            label: x.razao_social,
          })),
        )
      } catch (error) {
        addErrorMessage(getErrorMessage(error))
      } finally {
        setIsLoading(false)
      }
    }

    main()
  }, [addErrorMessage])

  const columns: GridColDef<SuspectedUser>[] = [
    { field: 'id', headerName: '#', flex: 1 },
    { field: 'name', headerName: 'Usuário', flex: 1 },
    { field: 'alert_quantity', headerName: 'Quantidade de alertas', flex: 1 },
    {
      field: 'blocked',
      headerName: 'Situação',
      flex: 1,
      renderCell: ({ value }) => (value ? 'Bloqueado' : 'Ativo'),
    },
    {
      field: 'actions',
      headerName: 'Ações',
      renderCell: (values) => {
        return <Link to={`/admin/usuario/${values.row.id}`}>Ver usuário</Link>
      },
    },
  ]

  if (isLoading)
    return (
      <Loading>
        <Skeleton variant="rectangular" height={400} />
      </Loading>
    )

  return (
    <Grid container spacing={2} component={Paper} p={2}>
      <Grid item xs={12}>
        <Typography variant="h4">
          Usuários com comportamento suspeito
        </Typography>
      </Grid>
      <Grid container item xs={12} spacing={2}>
        <Grid item xs={4}>
          <Controller
            control={control}
            name="initialDate"
            rules={{ required: true }}
            render={({ field }) => (
              <DateTimePicker
                label="Início do período"
                value={dayjs(field.value).toDate()}
                inputRef={field.ref}
                onChange={(date) => {
                  field.onChange(date)
                }}
                slotProps={{
                  textField: {
                    error: !!errors.initialDate,
                    helperText: errors.initialDate?.message,
                    fullWidth: true,
                  },
                }}
                disablePast
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            control={control}
            name="finalDate"
            rules={{ required: true }}
            render={({ field }) => (
              <DateTimePicker
                label="Fim do período"
                value={dayjs(field.value).toDate()}
                inputRef={field.ref}
                onChange={(date) => {
                  field.onChange(date)
                }}
                slotProps={{
                  textField: {
                    error: !!errors.finalDate,
                    helperText: errors.finalDate?.message,
                    fullWidth: true,
                  },
                }}
                disablePast
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            control={control}
            name="cod_promoter"
            render={({ field, formState: { errors } }) => (
              <Autocomplete
                disablePortal
                multiple
                options={producers}
                getOptionLabel={(option) => option.label}
                id="producers-autocomplete"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    key={params.id}
                    label="Produtora"
                    fullWidth
                    error={!!errors.cod_promoter}
                    helperText={errors.cod_promoter?.message}
                  />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option.id}>
                    {option.label}
                  </li>
                )}
                {...field}
                onChange={(event, value) => field.onChange(value)}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            onClick={handleSubmit(fetchData)}
            disabled={isSubmitting}
          >
            Carregar dados
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <DataGrid
          rows={suspectedUsers}
          columns={columns}
          autoHeight
          localeText={datagrid.localizedTextsMap}
        />
      </Grid>
    </Grid>
  )
}
