import { zodResolver } from '@hookform/resolvers/zod'
import { Save } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Alert, Grid } from '@mui/material'
import { FormItem } from 'components'
import { Typography } from 'components/Typography'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import clientUser from 'services/api/v1/clientUser'
import { getErrorMessage } from 'utils/AppError'
import { z } from 'zod'

const userChangePasswordFromSchema = z
  .object({
    currentPassword: z.string(),
    newPassword: z
      .string()
      .min(8, 'A senha precisa ter no mínimo 8 caracteres'),
    confirmPassword: z.string(),
  })
  .refine((data) => data.newPassword === data.confirmPassword, {
    message: 'As senhas não conferem',
    path: ['confirmPassword'],
  })

type UserChangePasswordFromSchema = z.infer<typeof userChangePasswordFromSchema>

export const ChangePassword = () => {
  const [successMessage, setSuccessMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<UserChangePasswordFromSchema>({
    defaultValues: {},
    resolver: zodResolver(userChangePasswordFromSchema),
  })

  const onSubmit = async ({
    currentPassword,
    newPassword,
  }: UserChangePasswordFromSchema) => {
    if (isSubmitting) return
    try {
      setIsLoading(true)
      setErrorMessage('')
      setSuccessMessage('')
      await clientUser.post('users/change-password', {
        currentPassword,
        newPassword,
      })
      setSuccessMessage('Senha alterada com sucesso!')
    } catch (error) {
      setErrorMessage(getErrorMessage(error))
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={10}>
        <Typography variant="h4">Alterar Senha</Typography>
      </Grid>

      <Grid item xs={12} mt={2}>
        <FormItem.Password
          label="Senha Atual"
          {...register('currentPassword')}
          error={!!errors.currentPassword}
          helperText={errors.currentPassword?.message}
          required
          size="small"
        />
      </Grid>

      <Grid item xs={12}>
        <FormItem.Password
          label="Nova senha"
          {...register('newPassword')}
          error={!!errors.newPassword}
          helperText={errors.newPassword?.message}
          required
          size="small"
        />
      </Grid>

      <Grid item xs={12}>
        <FormItem.Password
          label="Confirmar a nova senha"
          {...register('confirmPassword')}
          error={!!errors.confirmPassword}
          helperText={errors.confirmPassword?.message}
          required
          size="small"
        />
      </Grid>

      <Grid item xs={12}>
        {errorMessage && (
          <Alert severity="error" onClose={() => setErrorMessage('')}>
            {errorMessage}
          </Alert>
        )}
        {successMessage && (
          <Alert severity="success" onClose={() => setSuccessMessage('')}>
            {successMessage}
          </Alert>
        )}
        <LoadingButton
          loading={isLoading}
          loadingPosition="start"
          startIcon={<Save />}
          onClick={handleSubmit(onSubmit)}
          color={errorMessage ? 'error' : 'primary'}
          variant="contained"
          fullWidth
        >
          Salvar
        </LoadingButton>
      </Grid>
    </Grid>
  )
}
