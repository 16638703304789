import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded'
import RemoveCircleOutlineRoundedIcon from '@mui/icons-material/RemoveCircleOutlineRounded'
import {
  Box,
  Chip,
  IconButton,
  ListItem,
  ListItemBaseProps,
  ListItemText,
  Typography,
} from '@mui/material'
import { useShoppingCart } from 'contexts/shoppingCartContext'
import { IIngresso } from 'types/ingresso'
import { moneyFormatter } from 'utils/formatter'

type Props = ListItemBaseProps & {
  batch: IIngresso
}

export function ShoppingCartItem({ batch }: Props) {
  const { shoppingCart, addShoppingCartItem, removeShoppingCartItem } =
    useShoppingCart()
  let fee = 0
  const maxItemsByBatch = 10

  if (batch.valor > 0) {
    fee =
      (Number(batch.taxa_percentual) * Number(batch.valor)) / 100 +
      parseFloat(batch.taxa_fixa.toString())
    if (Number(batch.taxa_absorvida) > 0) {
      fee = fee - fee * (Number(batch.taxa_absorvida) / 100)
    }
  }

  const soldOut =
    batch.esgotado &&
    Boolean(batch.valida_quantidade) &&
    Number(batch.quantidade_disponivel) <= 0

  return (
    <ListItem
      alignItems="flex-start"
      secondaryAction={
        soldOut ? (
          <Chip label="esgotado" color="error" />
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              flex: 1,
            }}
          >
            <IconButton
              edge="start"
              aria-label="remover"
              onClick={() => removeShoppingCartItem(batch)}
              disabled={
                (shoppingCart.find((x) => x.id === batch.id)?.amount ?? 0) === 0
              }
            >
              <RemoveCircleOutlineRoundedIcon />
            </IconButton>
            <Typography variant="button">
              {shoppingCart.find((x) => x.id === batch.id)?.amount ?? 0}
            </Typography>
            <IconButton
              edge="end"
              aria-label="adicionar"
              onClick={() => addShoppingCartItem(batch)}
              disabled={(() => {
                const items = shoppingCart.find((x) => x.id === batch.id)
                if (items?.amount) {
                  const isGte10 = items?.amount >= maxItemsByBatch
                  const isGteAvailableAmount =
                    items?.amount >= (batch.quantidade_disponivel ?? 0)
                  return batch.valida_quantidade
                    ? isGte10 || isGteAvailableAmount
                    : isGte10
                }
                return false
              })()}
            >
              <AddCircleOutlineRoundedIcon />
            </IconButton>
          </Box>
        )
      }
      sx={{
        paddingY: 0,
      }}
    >
      <Box
        sx={{
          borderTop: 'solid 1px',
          borderColor: '#d0d0d0',
          width: '100%',
        }}
      >
        <ListItemText
          primary={batch.titulo}
          secondary={
            <Box display="flex" flexDirection="column">
              {!!batch.descricao && (
                <Typography
                  sx={{ display: 'inline' }}
                  component="span"
                  variant="caption"
                >
                  {batch.descricao}
                </Typography>
              )}
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                {moneyFormatter.format(batch.valor ?? 0)}
                <Typography variant="caption" component="span" px={1}>
                  + {moneyFormatter.format(fee)} (taxa de serviço)
                </Typography>
              </Typography>
            </Box>
          }
          secondaryTypographyProps={{ component: 'span' }}
          sx={{
            width: '80%',
          }}
        />
      </Box>
    </ListItem>
  )
}
