import DeleteIcon from '@mui/icons-material/Delete'
import { LoadingButton } from '@mui/lab'
import { Alert, ButtonBaseProps } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import * as React from 'react'
import { apiV1 } from 'services'
import { IIngresso } from 'types/ingresso'
import { functionWithLoading } from 'utils'
import { getErrorMessage } from 'utils/AppError'

interface BatchDeleteButtonProps {
  ticket: IIngresso
  isAdmin?: boolean
  callback: () => void
}

const BatchDeleteButton = ({
  ticket,
  isAdmin = false,
  callback,
  children,
}: BatchDeleteButtonProps & ButtonBaseProps) => {
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState('')

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleClickRemove = () => {
    if (isAdmin) {
      functionWithLoading(
        apiV1.admin.ticketService
          .remove(ticket.cod_evento, ticket.id)
          .then(() => callback())
          .catch((err) => {
            setError(err.response?.data.message ?? err.message)
          }),
        setLoading,
      )
    } else {
      functionWithLoading(
        apiV1.producer.ticketService
          .remove(ticket.cod_evento, ticket.id)
          .then(() => callback())
          .catch((error) => {
            setError(getErrorMessage(error))
          }),
        setLoading,
      )
    }
  }

  return (
    <React.Fragment>
      <Button
        onClick={handleClickOpen}
        endIcon={<DeleteIcon />}
        variant="contained"
        color="error"
      >
        {children}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`Excluir o lote ${ticket.titulo}?`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Ao excluir o lote sairá da lista de lotes.
          </DialogContentText>
          {error && (
            <Alert severity="error" sx={{ mt: 1 }} onClose={() => setError('')}>
              {error}
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <LoadingButton
            loading={loading}
            loadingPosition="start"
            startIcon={<DeleteIcon />}
            onClick={handleClickRemove}
            color="error"
            variant="contained"
          >
            Sim
          </LoadingButton>{' '}
          <Button
            onClick={handleClose}
            color="primary"
            variant="outlined"
            autoFocus
          >
            Não
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default BatchDeleteButton
