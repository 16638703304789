import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import { Button, Chip, Grid, Paper, Skeleton } from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { Typography } from 'components/Typography'
import { useAdmin } from 'contexts/adminContext'
import dayjs from 'dayjs'
import { SaqueDTO } from 'dto/SaqueDTO'
import { useCallback, useEffect, useState } from 'react'
import { Link, Link as RouterLink } from 'react-router-dom'
import { apiV1 } from 'services'
import { getErrorMessage } from 'utils/AppError'
import { moneyFormatter } from 'utils/formatter'

export function WithdrawalRequests() {
  const [isLoading, setIsLoading] = useState(false)
  const [withdrawals, setWithdrawals] = useState<SaqueDTO[]>([])

  const { addErrorMessage } = useAdmin()

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true)
      const { data } = await apiV1.admin.withdrawalService.getNews()
      setWithdrawals(data)
    } catch (error) {
      addErrorMessage(getErrorMessage(error))
    } finally {
      setIsLoading(false)
    }
  }, [addErrorMessage])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  if (isLoading) return <PageLoading />

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      width: 50,
      renderCell: (cell) => (
        <Link to={`/admin/solicitacoes-de-saque/${cell.value}`}>
          #{cell.value}
        </Link>
      ),
    },
    {
      field: 'cod_evento',
      headerName: 'Evento',
      flex: 1,
      renderCell: (cell) => (
        <Link to={`/admin/solicitacoes-de-saque/${cell.row.id}`}>
          {cell.row.evento.id} - {cell.row.evento.titulo}
        </Link>
      ),
    },
    {
      field: 'data_solicitacao',
      headerName: 'Data solicitação',
      width: 150,
      renderCell: (cell) => dayjs(cell.value).format('DD/MM/YYYY HH:mm'),
    },
    {
      field: 'evento',
      headerName: 'Data fim do evento',
      width: 150,
      renderCell: (cell) =>
        dayjs(cell.row.evento.data_final).format('DD/MM/YYYY HH:mm'),
    },
    {
      field: 'valor',
      headerName: 'Valor',
      width: 120,
      renderCell: (cell) => moneyFormatter.format(cell.value),
    },
    {
      field: 'cod_saque_status',
      headerName: 'Situação',
      type: 'number',
      width: 160,
      renderCell: (cell) => (
        <Chip
          label={cell.row.saque_status.titulo}
          color={
            cell.value === 1 ? 'info' : cell.value === 2 ? 'success' : 'error'
          }
        />
      ),
    },
  ]

  return (
    <Grid container spacing={2} component={Paper} p={2}>
      <Grid
        item
        xs={12}
        display="flex"
        justifyContent="space-between"
        flexDirection="row"
      >
        <Typography variant="h5">Últimas solicitações de saque</Typography>
        <Button
          color="success"
          component={RouterLink}
          to="/admin/solicitacoes-de-saque/extrato"
          variant="contained"
          sx={{ ml: 1 }}
        >
          Extrato <FormatListBulletedIcon />
        </Button>
        {/* <Button
          color="success"
          component={RouterLink}
          to="/admin/events/unpaid"
          variant="contained"
          sx={{ ml: 1 }}
        >
          Saques pendentes <FormatListBulletedIcon />
        </Button> */}
      </Grid>
      <Grid item xs={12}>
        <DataGrid rows={withdrawals} columns={columns} autoHeight />
      </Grid>
    </Grid>
  )
}

function PageLoading() {
  return (
    <Grid item xs={12}>
      <Skeleton height={20} />
      <Skeleton />
      <Skeleton />
      <Skeleton />
      <Skeleton />
    </Grid>
  )
}
