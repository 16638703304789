import MUICard, { CardProps } from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Stack from '@mui/material/Stack'
import { ReactNode } from 'react'

type Props = CardProps & {
  title: string
  color?: string
  icon?: ReactNode
  dark?: boolean
}

function Card({
  title,
  icon,
  children,
  color = '#FFFFFF',
  dark = false,
  ...rest
}: Props) {
  return (
    <MUICard
      variant="outlined"
      sx={{
        backgroundColor: `${color}80`,
        borderColor: color,
        cursor: rest.onClick ? 'pointer' : 'default',
      }}
      {...rest}
    >
      <CardHeader
        title={title}
        sx={{ pb: 0 }}
        titleTypographyProps={{ color: dark ? '#FFFFFF' : '#000000' }}
      />
      <CardContent>
        <Stack direction="row" color={dark ? '#FFFFFF' : '#000000'}>
          {icon}
          <Stack
            direction="row"
            sx={{
              borderLeft: 'solid 2px',
              borderLeftColor: dark ? '#FFFFFF' : '#000000',
              ml: 1,
              pl: 1,
              color: dark ? '#FFFFFF' : '#000000',
              flex: 1,
            }}
          >
            {children}
          </Stack>
        </Stack>
      </CardContent>
    </MUICard>
  )
}

export { Card }
