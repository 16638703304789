import { zodResolver } from '@hookform/resolvers/zod'
import { Grid, Typography } from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import clientProducer from 'services/api/v1/producer/clientProducer'
import { moneyFormatter } from 'utils/formatter'
import { z } from 'zod'

const filterBatchFormSchema = z.object({
  batches: z.array(
    z.object({ id: z.number(), titulo: z.string(), checked: z.boolean() }),
  ),
})

type FilterBatchFormSchema = z.infer<typeof filterBatchFormSchema>

export function BatchUserReport() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [report, setReport] = useState<any[]>([])
  const { eventId } = useParams()

  const { setValue } = useForm<FilterBatchFormSchema>({
    defaultValues: {
      batches: [],
    },
    resolver: zodResolver(filterBatchFormSchema),
  })

  useEffect(() => {
    async function fetchBatches() {
      if (eventId) {
        const { data } = await clientProducer.get(
          `/events/${eventId}/reports/ranking-per-batch`,
        )

        setValue('batches', data.batches)
        setReport(data.report)
      }
    }

    fetchBatches()
  }, [eventId, setValue])

  const columns: GridColDef[] = report.length
    ? Object.keys(report[0]).map((key, index) => {
        const column: GridColDef = {
          field: key,
          ...(index === 0 ? { width: 400 } : { width: 100 }),
        }
        if (key === 'valor_total') {
          return {
            ...column,
            renderCell: (cell) => moneyFormatter.format(cell.value),
          }
        }
        return column
      })
    : // .filter(
      //   ({ field }) =>
      //     watch('batches')
      //       .filter((batch) => batch.checked)
      //       .map((x) => x.titulo)
      //       .includes(field) || field === 'nome',
      // )
      [{ field: 'id' }]

  return (
    <Grid
      container
      // component="form"
      // onSubmit={handleSubmit(handleSetTimeIntervals)}
      spacing={4}
    >
      <Grid item xs={12}>
        <Typography variant="h4">Ranking de vendas por lote</Typography>
      </Grid>
      {/* <Grid item xs={12}>
        <Typography variant="h6">Selecione os lotes</Typography>
        {fields.map((field, index) => {
          return (
            <FormControlLabel
              key={field.id}
              control={
                <Controller
                  name={`batches.${index}.checked`}
                  control={control}
                  render={({ field }) => {
                    return (
                      <Checkbox
                        onChange={(_, checked) =>
                          field.onChange(checked === true)
                        }
                        checked={field.value}
                      />
                    )
                  }}
                />
              }
              label={field.titulo}
              sx={{ pr: 2, mr: 4 }}
            />
          )
        })}
      </Grid> */}
      {report.length > 0 && (
        <Grid item xs={12}>
          <DataGrid
            rows={report}
            columns={columns}
            autoHeight
            getRowId={(row) => row.nome}
          />
        </Grid>
      )}
    </Grid>
  )
}
