import { ILogin } from 'types/login'
import clientRest from './client'

const login = (email: string, password: string) => {
  return clientRest.post<ILogin>(`promoter/login`, {
    email,
    password,
  })
}

const promoterService = {
  login,
}

export default promoterService
