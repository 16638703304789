import { GridLocaleText } from '@mui/x-data-grid'

const localizedTextsMap: Partial<GridLocaleText> = {
  columnMenuUnsort: 'não classificado',
  columnMenuSortAsc: 'Classificar por ordem crescente',
  columnMenuSortDesc: 'Classificar por ordem decrescente',
  columnMenuFilter: 'Filtro',
  columnMenuHideColumn: 'Ocultar',
  columnMenuShowColumns: 'Mostrar colunas',
  noRowsLabel: 'Sem registros para exibir',
  footerRowSelected: (count) =>
    count > 1 ? `${count} linhas selecionadas` : `${count} linha selecionada`,
}

export { localizedTextsMap }
