import PaymentsIcon from '@mui/icons-material/Payments'
import { LoadingButton, LoadingButtonProps } from '@mui/lab'

const PaymentButton = ({ loading, onClick, ...props }: LoadingButtonProps) => {
  return (
    <LoadingButton
      loading={loading}
      loadingPosition="start"
      startIcon={<PaymentsIcon />}
      onClick={onClick}
      variant="contained"
      {...props}
    >
      Fazer pagamento
    </LoadingButton>
  )
}

export default PaymentButton
