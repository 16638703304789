import { ISetor } from 'types/setor'
import clientProducer from './clientProducer'

const getAll = (eventId: number) => {
  return clientProducer.get<Array<ISetor>>(`events/${eventId}/sectors`)
}

const get = (eventId: number, sectorId: number) => {
  return clientProducer.get<ISetor>(`events/${eventId}/sectors/${sectorId}`)
}

const create = (eventId: number, sector: ISetor) => {
  return clientProducer.post<ISetor>(`events/${eventId}/sectors`, { sector })
}

const update = (eventId: number, sector: ISetor) => {
  return clientProducer.patch<ISetor>(
    `events/${eventId}/sectors/${sector.id}`,
    {
      sector,
    },
  )
}

const remove = (eventId: number, sectorId: number) => {
  return clientProducer.delete<ISetor>(`events/${eventId}/sectors/${sectorId}`)
}

const updateOrdinations = (eventId: number, sectors: ISetor[]) => {
  return clientProducer.patch(`events/${eventId}/sectors/ordinations`, {
    sectors,
  })
}

const sectorService = {
  getAll,
  get,
  create,
  update,
  remove,
  updateOrdinations,
}

export default sectorService
