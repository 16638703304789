import Add from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import {
  Button,
  Card,
  CardHeader,
  CardMedia,
  Grid,
  IconButton,
  Paper,
  Skeleton,
} from '@mui/material'
import { Typography } from 'components/Typography'
import { constants } from 'config'
import { usePromoter } from 'contexts/promoterContext'
import { useEffect, useState } from 'react'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { apiV1 } from 'services'
import { IPromoter } from 'types/promoter'
import { getErrorMessage } from 'utils/AppError'

export function Producers() {
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState<IPromoter[]>([])

  const { addErrorMessage } = usePromoter()
  const navigate = useNavigate()

  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true)
        const response = await apiV1.producer.promoterService.getAll()
        setData(response.data)
      } catch (error) {
        addErrorMessage(getErrorMessage(error))
      } finally {
        setIsLoading(false)
      }
    }
    fetchData()
  }, [addErrorMessage])

  return (
    <Grid container spacing={2} component={Paper} py={2} pr={2}>
      <Grid
        item
        xs={12}
        display="flex"
        justifyContent="space-between"
        flexDirection="row"
      >
        <Typography variant="h5">Minhas produtoras</Typography>
        <Button
          color="success"
          aria-label="cadastrar nova produtora"
          component={RouterLink}
          to="/organizador/produtoras/nova"
          variant="contained"
          sx={{ ml: 1 }}
        >
          Cadastrar <Add />
        </Button>
      </Grid>
      {isLoading && <PageLoading />}
      {data.map((producer) => (
        <Grid key={producer.id} item xs={12} sm={6} md={4} lg={3}>
          <Card
            onClick={() => navigate(`/organizador/produtoras/${producer.id}`)}
          >
            <CardHeader
              title={producer.razao_social}
              subheader={producer.username}
              action={
                <IconButton aria-label="settings">
                  <EditIcon />
                </IconButton>
              }
            />
            <CardMedia
              component="img"
              image={constants.URL_FTP + producer.imagem}
              alt="Imagem da produtora"
              height="100%"
              style={{ aspectRatio: '3 / 1' }}
            />
          </Card>
        </Grid>
      ))}
    </Grid>
  )
}

function PageLoading() {
  return (
    <Grid item xs={12}>
      <Skeleton height={200} />
    </Grid>
  )
}
