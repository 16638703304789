import { validation } from 'utils'
import * as yup from 'yup'

const validationSchema = yup.object({
  nome: yup
    .string()
    .min(1)
    .required('É necessário preencher o sobrenome')
    .nullable(),
  cpf: yup
    .string()
    .required('É necessário preencher o CPF')
    .test('cpf', 'CPF Inválido', (value) => validation.isValidCPF(value ?? ''))
    .nullable(),
  data_nascimento: yup
    .string()
    .required('Informe a data de nascimento')
    .nullable(),
  telefone: yup
    .string()
    .min(11, 'O número deve conter 11 digitos')
    .max(15, 'O número deve conter 11 digitos')
    .required('É necessário preencher o telefone')
    .nullable(),
  sexo: yup
    .string()
    .not(['0'])
    .required('Por favor selecione o sexo')
    .nullable(),
  cod_brasil_cidade: yup
    .number()
    .not([0])
    .required('É necessário selecionar a cidade')
    .nullable(),
})

export default validationSchema
